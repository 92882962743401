import React, { FC, useContext } from 'react';

import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem as ChakraBreadcrumbItem,
  BreadcrumbLink as ChakraBreadcrumbLink,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ComponentReceivedProps } from '@types';

import { capitalize } from '@application/utils/stringManipulation';
import { CompilerContext } from '@application/compiler/contexts';
import { Icon } from '@application/components/ds';

import { BreadcrumbProps } from './index';
import { defaultProps } from './protocol';

const exampleData = '/example/nested/route';

export const component: FC<ComponentReceivedProps<BreadcrumbProps, unknown>> = ({ props = defaultProps }) => {
  const routeMatch = useRouteMatch();
  const router = useHistory();

  const { config } = useContext(CompilerContext);
  const { 'breadcrumb-container': breadcrumbContainerStyles, 'breadcrumb-item': breadcrumbItemStyles } =
    useMultiStyleConfig('PTBreadcrumb', {});

  const { ...rest } = props;
  const { path, params } = routeMatch;

  const isThemeMode = config.mode === 'theme';

  const getSplitedRoute = (isThemeMode ? (exampleData as string) : path)
    .replace(/[:]/gi, '')
    .split('/')
    .filter((r) => r)
    .map((path) => (params[path] ? params[path] : capitalize(path)));

  const getPreparedRoute = (paramPathIndex: number): string => {
    const deleteCountIncrease = 2;
    const previousArguments = path.split('/').splice(0, paramPathIndex + deleteCountIncrease);

    return previousArguments.join('/');
  };

  return (
    <ChakraBreadcrumb separator={<Icon name="chevron-right" />} sx={breadcrumbContainerStyles} {...rest}>
      {getSplitedRoute.map((paramName, index) => {
        const isCurrentPage = (isThemeMode && index === 2) || index + 1 === getSplitedRoute.length;

        return (
          <ChakraBreadcrumbItem
            sx={breadcrumbItemStyles}
            key={index}
            aria-selected={isCurrentPage}
            isCurrentPage={isCurrentPage}
          >
            <ChakraBreadcrumbLink
              onClick={() => !isCurrentPage && !isThemeMode && router.push(getPreparedRoute(index))}
            >
              {paramName}
            </ChakraBreadcrumbLink>
          </ChakraBreadcrumbItem>
        );
      })}
    </ChakraBreadcrumb>
  );
};
