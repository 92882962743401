import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { RadioAlternativeProps } from './index';

export const protocolFactory = new ProtocolFactory<RadioAlternativeProps>({
  name: 'RadioAlternative',
  editorProps: [
    {
      type: EPropType.String,
      name: 'width',
      required: true,
      label: 'Width',
      description: 'Horizontal size of the container',
      defaultValue: '100%',
    },
    {
      type: EPropType.Boolean,
      name: 'isInline',
      required: false,
      label: 'isInline',
      description: 'Direction of radios',
      defaultValue: false,
      values: [true, false],
    },
  ],
});

export const defaultProps = protocolFactory.createDefaultProps();
