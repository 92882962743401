import React, { FC } from 'react';

import { Flex as ChakraFlex } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { ContainerProps } from './index';
import { defaultProps } from './protocol';

export const component: FC<ComponentReceivedProps<ContainerProps, unknown>> = ({
  props = defaultProps,
  children,
}) => {
  const { width = '100%', height = '100%', ...rest } = props;

  return (
    <ChakraFlex width={width} height={height} {...rest}>
      {children}
    </ChakraFlex>
  );
};
