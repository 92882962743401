import React, { FC, useContext } from 'react';

import {
  CircularProgress as ChakraCircularProgress,
  CircularProgressLabel as ChakraCircularProgressLabel,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { CompilerContext } from '@application/compiler/contexts';

import { CircularProgressProps } from './index';
import { defaultProps } from './protocol';

export const component: FC<ComponentReceivedProps<CircularProgressProps, unknown>> = ({
  props = defaultProps,
}) => {
  const { colorScheme, value, property, size, list, ...rest } = props;

  const { config } = useContext(CompilerContext);

  const themeStyles = useMultiStyleConfig('PTCircularProgress', { size, colorScheme });

  const maxSize = list ? list.length : 100;
  const currentValue = property && list ? list?.filter((item) => !!item[property])?.length : value;

  return (
    <ChakraCircularProgress
      size="100px"
      max={maxSize}
      value={config?.mode === 'theme' ? 50 : currentValue}
      // @ts-ignore
      color={themeStyles?.filledTrack?.background}
      // @ts-ignore
      thickness={themeStyles?.track?.height}
      // @ts-ignore
      trackColor={themeStyles?.track?.background}
      {...rest}
    >
      <ChakraCircularProgressLabel sx={themeStyles.label}>
        {config?.mode === 'theme' ? 50 : (currentValue / maxSize) * 100}%
      </ChakraCircularProgressLabel>
    </ChakraCircularProgress>
  );
};
