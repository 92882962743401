import { ComponentBlock } from '@types';

import { types } from '@application/core/types';

import { component } from './component';
import { ModalProps, ModalTriggers } from './index';

export const block: ComponentBlock<'modal', ModalProps, ModalTriggers> = {
  id: 'modal',
  render: component,
  props: {
    modalHiddenFooter: {
      type: types.boolean,
    },
    modalTriggerLabel: {
      type: types.string,
    },
    modalTitle: {
      type: types.string,
    },
    modalSubmitLabel: {
      type: types.string,
    },
    modalCancelLabel: {
      type: types.string,
    },
  },
  triggers: {
    onConfirm: {
      name: 'On confirm',
      description: 'When the confirm button is clicked',
    },
    onCancel: {
      name: 'On cancel',
      description: 'When the cancel button is clicked',
    },
  },
};
