import React, { FC } from 'react';

import { ChakraProvider, ChakraProviderProps } from '@chakra-ui/react';
import { CacheProvider as EmotionCacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import weakMemoize from '@emotion/weak-memoize';

import gStudioTheme from '@application/theme';

export const createCacheWithContainer = weakMemoize((container: HTMLElement) => {
  const newCache = createCache({
    container,
    key: 'compiler-theme',
  });

  return newCache;
});

interface CompilerThemeProvider extends Partial<ChakraProviderProps> {
  document: Document;
}

export const ScopedThemeProvider: FC<CompilerThemeProvider> = ({
  theme = gStudioTheme,
  document,
  children,
  ...props
}) => {
  return (
    <EmotionCacheProvider value={createCacheWithContainer(document.head)}>
      <ChakraProvider theme={theme} {...props}>
        {children}
      </ChakraProvider>
    </EmotionCacheProvider>
  );
};
