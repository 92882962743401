/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { FC, useMemo } from 'react';

import { EmbedDescriptor } from 'rich-markdown-editor/dist/types';

interface YoutubeEmbedProps {
  attrs: Record<string, string>;
  isSelected: boolean;
}

const YoutubeEmbed: FC<YoutubeEmbedProps> = ({ attrs, isSelected }) => {
  const videoId = attrs.matches && attrs.matches[1];
  const dynamicHeight = useMemo(
    () => typeof window !== 'undefined' && window.innerWidth * 0.4275,
    [window.innerWidth],
  );

  return (
    <iframe
      src={`https://www.youtube.com/embed/${videoId}?modestbranding=1`}
      style={{ width: '100%', height: dynamicHeight }}
      className={isSelected ? 'ProseMirror-selectednode' : ''}
    />
  );
};

export const richTextEditorEmbeds: EmbedDescriptor[] = [
  {
    title: 'YouTube',
    keywords: 'youtube video tube google',
    component: YoutubeEmbed,
    icon: () => (
      <img
        width="24px"
        height="24px"
        src="https://upload.wikimedia.org/wikipedia/commons/7/75/YouTube_social_white_squircle_%282017%29.svg"
      />
    ),
    matcher: (url: string): boolean | RegExpMatchArray | [] => {
      return url.match(
        // eslint-disable-next-line no-irregular-whitespace
        /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/i,
      );
    },
  },
];
