import React, { FC } from 'react';

import { chakra, forwardRef } from '@chakra-ui/react';
import { debounce } from 'lodash';
import RichMarkdownEditor, { Props as RichMarkdownEditorProps } from 'rich-markdown-editor';

import { uploadFileToS3 } from '@services';

import { richTextEditorEmbeds } from './embeds';

const RichTextEditorFactory = chakra(RichMarkdownEditor);

interface RichEditorProps
  extends Omit<
    RichMarkdownEditorProps,
    | 'embeds'
    | 'tooltip'
    | 'extensions'
    | 'placeholder'
    | 'defaultValue'
    | 'onFocus'
    | 'onChange'
    | 'onClickLink'
  > {
  readOnly?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

export const RichTextEditor: FC<RichEditorProps> = forwardRef(
  (
    { value, readOnly = false, placeholder = 'Here is a sample placeholder', defaultValue, onChange },
    ref,
  ) => {
    const handleOnChange = debounce<(value: () => string) => void>((value) => {
      const textValue = value();

      onChange(textValue);
    }, 250);

    const handleImageUpload = async (file: File) => {
      const result = await uploadFileToS3(file);
      return result;
    };

    return (
      //  @ts-ignore
      <RichTextEditorFactory
        // @ts-ignore
        ref={ref}
        width="100%"
        color="ui.700"
        fontSize="md"
        fontFamily="body"
        value={value}
        readOnly={readOnly}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={handleOnChange}
        uploadImage={handleImageUpload}
        embeds={richTextEditorEmbeds}
        style={{
          color: 'var(--chakra-colors-ui-500)',
          justifyContent: 'flex-start',
        }}
      />
    );
  },
);
