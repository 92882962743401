import { AWSConnection, AWSInput, Project } from '@types';

import { queryAdmin } from '../adminApi';

import { CreateProject, UpdateProject } from './mutations';
import { GetProject, ListProjects } from './queries';

// List Projects
export const listProjects = (
  variables: Pick<Project, 'organizationId'>,
  token: string,
): Promise<{ listProjects: AWSConnection<Project> }> => {
  return queryAdmin(ListProjects, variables)(token);
};

// List Projects
export const getProject = (
  variables: Pick<Project, 'id' | 'organizationId'>,
  token: string,
): Promise<{ getProject: Project }> => {
  return queryAdmin(GetProject, variables)(token);
};

// ----------------------- // MUTATIONS // ------------------------- //

export type CreateProjectInput = Pick<Project, 'organizationId' | 'name' | 'themeId' | 'layouts'>;
export const createProject = (
  variables: AWSInput<CreateProjectInput>,
  token: string,
): Promise<{ createProject: Project }> => {
  return queryAdmin(CreateProject, variables)(token);
};

export type UpdateProjectInput = Pick<Project, 'id' | 'organizationId' | 'name' | 'themeId' | 'layouts'>;
export const updateProject = (
  variables: AWSInput<UpdateProjectInput>,
  token: string,
): Promise<{ updateProject: Project }> => {
  return queryAdmin(UpdateProject, variables)(token);
};
