import { BoxProps } from '@chakra-ui/react';

import { PropType } from '@types';

import { InputTypes } from './Protocols';

export interface EditorFormField {
  name: string;
  label: string;
  description?: string;
  groupBy?: string[];
  defaultValue?: any;
  options?: (string | number | { label: string; value: string | number })[];
  type: PropType | PropType[];
  input?: InputTypes;
  inputProps?: Partial<BoxProps>;
  inputConfig?: {
    required?: boolean;
    visible?: boolean;
    disabled?: boolean;
  };
}

export const defaultInputs: Record<PropType, InputTypes> = {
  Any: 'InputText',
  ID: 'InputSelect',
  List: 'InputJson',
  String: 'InputText',
  Enum: 'InputSelect',
  Object: 'InputJson',
  Number: 'InputNumber',
  Boolean: 'InputCheckbox',
  Image: 'InputImage',
  Richtext: 'InputRichtext',
  Textarea: 'InputTextarea',
  Email: 'InputText',
  Phone: 'InputText',
  Date: 'InputText',
};
