import React from 'react';

export const customIconsObj: Record<string, { path: any; viewBox?: string }> = {
  logo: {
    path: (
      <svg width="3000" height="3163" viewBox="0 0 3000 3163" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="3000" height="3162.95" fill="none" />
        <path
          d="M1470.89 1448.81L2170 2488.19H820V706.392H2170L1470.89 1448.81ZM1408.21 1515.37L909.196 2045.3V2393.46H1998.84L1408.21 1515.37Z"
          fill="currentColor"
        />
      </svg>
    ),
    viewBox: '0 0 3000 3163',
  },
  dataIcon: {
    path: (
      <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="none"
          d="M6.33333 6V6.0125M0.999999 6C0.999999 4.67392 1.5619 3.40215 2.5621 2.46447C3.56229 1.52678 4.91884 1 6.33333 1L22.3333 1C23.0406 1 23.7189 1.26339 24.219 1.73223C24.719 2.20107 25 2.83696 25 3.5V8.5C25 9.16304 24.719 9.79893 24.219 10.2678C23.7189 10.7366 23.0406 11 22.3333 11L6.33333 11C4.91884 11 3.56229 10.4732 2.5621 9.53553C1.5619 8.59785 0.999999 7.32608 0.999999 6Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="none"
          d="M6.33333 20V20.0125M0.999999 20C0.999999 18.6739 1.5619 17.4021 2.5621 16.4645C3.56229 15.5268 4.91884 15 6.33333 15L22.3333 15C23.0406 15 23.7189 15.2634 24.219 15.7322C24.719 16.2011 25 16.837 25 17.5V22.5C25 23.163 24.719 23.7989 24.219 24.2678C23.7189 24.7366 23.0406 25 22.3333 25L6.33333 25C4.91884 25 3.56229 24.4732 2.5621 23.5355C1.5619 22.5979 0.999999 21.3261 0.999999 20Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 26 26',
  },
  editorIcon: {
    path: (
      <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.21618 0H3.57089C1.59707 0 0 1.61093 0 3.59462V7.27156C0 9.25444 1.59741 10.8662 3.57089 10.8662H7.21618C9.18966 10.8662 10.7871 9.25444 10.7871 7.27156V3.59462C10.7871 1.61093 9.19 0 7.21618 0ZM3.57086 1.67439H7.21615C8.26196 1.67439 9.11262 2.53244 9.11262 3.59459V7.27153C9.11262 8.33298 8.26151 9.19173 7.21615 9.19173H3.57086C2.5255 9.19173 1.67439 8.33298 1.67439 7.27153V3.59459C1.67439 2.53244 2.52505 1.67439 3.57086 1.67439ZM20.4304 0H16.7839C14.8101 0 13.213 1.61093 13.213 3.59462V7.27156C13.213 9.25444 14.8104 10.8662 16.7839 10.8662H20.4304C22.4029 10.8662 24.0001 9.2542 24.0001 7.27156V3.59462C24.0001 1.61117 22.4033 0 20.4304 0ZM16.784 1.67439H20.4304C21.4752 1.67439 22.3258 2.53256 22.3258 3.59459V7.27153C22.3258 8.33286 21.4748 9.19173 20.4304 9.19173H16.784C15.7386 9.19173 14.8875 8.33298 14.8875 7.27153V3.59459C14.8875 2.53244 15.7382 1.67439 16.784 1.67439ZM3.57089 13.1338H7.21618C9.19023 13.1338 10.7871 14.745 10.7871 16.7296V20.4053C10.7871 22.389 9.19 24 7.21618 24H3.57089C1.59707 24 0 22.389 0 20.4053V16.7296C0 14.745 1.59684 13.1338 3.57089 13.1338ZM7.21615 14.8082H3.57086C2.52493 14.8082 1.67439 15.6663 1.67439 16.7295V20.4053C1.67439 21.4675 2.52505 22.3255 3.57086 22.3255H7.21615C8.26196 22.3255 9.11262 21.4675 9.11262 20.4053V16.7295C9.11262 15.6663 8.26208 14.8082 7.21615 14.8082ZM20.4303 13.1338H16.7839C14.8098 13.1338 13.213 14.745 13.213 16.7296V20.4053C13.213 22.389 14.81 24 16.7839 24H20.4303C22.4032 24 24 22.3888 24 20.4053V16.7296C24 14.7452 22.4034 13.1338 20.4303 13.1338ZM16.784 14.8082H20.4304C21.4753 14.8082 22.3257 15.6665 22.3257 16.7295V20.4053C22.3257 21.4673 21.4752 22.3255 20.4304 22.3255H16.784C15.7382 22.3255 14.8875 21.4675 14.8875 20.4053V16.7295C14.8875 15.6663 15.7381 14.8082 16.784 14.8082Z"
          fill="currentColor"
        />
      </svg>
    ),
    viewBox: '0 0 26 26',
  },
  insertIcon: {
    path: (
      <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 8.59277V17.3844"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.4 12.9886H8.59998"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.6229 1H7.37714C3.45714 1 1 3.7745 1 7.70219V18.2978C1 22.2255 3.44571 25 7.37714 25H18.6229C22.5543 25 25 22.2255 25 18.2978V7.70219C25 3.7745 22.5543 1 18.6229 1Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 26 26',
  },
  themeIcon: {
    path: (
      <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.33333 25C4.91885 25 3.56229 24.4381 2.5621 23.4379C1.5619 22.4377 1 21.0812 1 19.6667V3.66667C1 2.95942 1.28095 2.28115 1.78105 1.78105C2.28115 1.28095 2.95942 1 3.66667 1H9C9.70724 1 10.3855 1.28095 10.8856 1.78105C11.3857 2.28115 11.6667 2.95942 11.6667 3.66667V19.6667C11.6667 21.0812 11.1048 22.4377 10.1046 23.4379M6.33333 25C7.74782 25 9.10438 24.4381 10.1046 23.4379M6.33333 25H22.3333C23.0406 25 23.7189 24.719 24.219 24.219C24.719 23.7189 25 23.0406 25 22.3333V17C25 16.2928 24.719 15.6145 24.219 15.1144C23.7189 14.6143 23.0406 14.3333 22.3333 14.3333H19.2093M10.1046 23.4379L15.7616 17.781L21.4187 12.124C21.9186 11.6239 22.1994 10.9458 22.1994 10.2387C22.1994 9.53156 21.9186 8.85341 21.4187 8.35333L17.6467 4.58133C17.1466 4.08141 16.4684 3.80057 15.7613 3.80057C15.0542 3.80057 14.3761 4.08141 13.876 4.58133L11.6667 6.79067"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 26 26',
  },
  settingsIcon: {
    path: (
      <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.8851 7.67429L22.1079 6.32571C21.4505 5.18462 19.9934 4.79097 18.8508 5.44571V5.44571C18.3069 5.76613 17.6578 5.85704 17.0468 5.69839C16.4358 5.53974 15.913 5.14456 15.5936 4.6C15.3882 4.25387 15.2778 3.85963 15.2736 3.45714V3.45714C15.2922 2.81186 15.0487 2.18655 14.5988 1.72366C14.1488 1.26078 13.5306 0.999734 12.8851 1H11.3194C10.6869 0.999993 10.0805 1.25201 9.63441 1.70028C9.18828 2.14856 8.93918 2.75614 8.94222 3.38857V3.38857C8.92347 4.69433 7.85955 5.74299 6.55365 5.74286C6.15117 5.73868 5.75692 5.62829 5.41079 5.42286V5.42286C4.26813 4.76811 2.81111 5.16176 2.15365 6.30286L1.31936 7.67429C0.662693 8.81396 1.05099 10.2701 2.18793 10.9314V10.9314C2.92697 11.3581 3.38223 12.1466 3.38223 13C3.38223 13.8534 2.92697 14.6419 2.18793 15.0686V15.0686C1.05243 15.7255 0.663713 17.1781 1.31936 18.3143V18.3143L2.10793 19.6743C2.41598 20.2301 2.93284 20.6403 3.54412 20.814C4.15541 20.9878 4.81073 20.9107 5.36508 20.6V20.6C5.91003 20.282 6.55942 20.1949 7.16891 20.358C7.7784 20.5211 8.29749 20.9209 8.61079 21.4686C8.81622 21.8147 8.92661 22.2089 8.93079 22.6114V22.6114C8.93079 23.9306 10.0002 25 11.3194 25H12.8851C14.1998 25 15.2674 23.9376 15.2736 22.6229V22.6229C15.2706 21.9884 15.5213 21.3791 15.9699 20.9305C16.4185 20.4819 17.0278 20.2312 17.6622 20.2343C18.0637 20.245 18.4564 20.355 18.8051 20.5543V20.5543C19.9447 21.211 21.4008 20.8227 22.0622 19.6857V19.6857L22.8851 18.3143C23.2036 17.7676 23.291 17.1165 23.128 16.5051C22.9649 15.8938 22.5649 15.3727 22.0165 15.0571V15.0571C21.4681 14.7416 21.0681 14.2205 20.905 13.6092C20.742 12.9978 20.8294 12.3467 21.1479 11.8C21.3551 11.4384 21.6549 11.1386 22.0165 10.9314V10.9314C23.1466 10.2704 23.534 8.82282 22.8851 7.68571V7.68571V7.67429Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.108 16.2914C13.9258 16.2914 15.3994 14.8177 15.3994 12.9999C15.3994 11.1821 13.9258 9.7085 12.108 9.7085C10.2902 9.7085 8.81653 11.1821 8.81653 12.9999C8.81653 14.8177 10.2902 16.2914 12.108 16.2914Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 26 26',
  },
  successIcon: {
    path: (
      <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 12L11 14L15 10M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 26 26',
  },
  colorPicker: {
    path: (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.9993 5.99998L17.9993 13"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.99983 20V15.4239L15.0968 4.32701C15.3106 4.1174 15.5981 3.99998 15.8976 3.99998C16.197 3.99998 16.4845 4.1174 16.6984 4.32701L19.6728 7.30144C19.8824 7.51529 19.9998 7.8028 19.9998 8.10225C19.9998 8.4017 19.8824 8.68921 19.6728 8.90306L8.57588 20H3.99983Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 26 26',
  },
  examplesIcon: {
    path: (
      <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.0491 2.92702C11.3491 2.00602 12.6521 2.00602 12.9511 2.92702L14.4701 7.60102C14.5355 7.80151 14.6625 7.9762 14.8332 8.10012C15.0038 8.22405 15.2092 8.29086 15.4201 8.29102H20.3351C21.3041 8.29102 21.7061 9.53102 20.9231 10.101L16.9471 12.989C16.7763 13.1132 16.6492 13.2882 16.584 13.4891C16.5188 13.6899 16.5188 13.9062 16.5841 14.107L18.1021 18.781C18.4021 19.703 17.3471 20.469 16.5641 19.899L12.5881 17.011C12.4172 16.8868 12.2114 16.8199 12.0001 16.8199C11.7888 16.8199 11.583 16.8868 11.4121 17.011L7.4361 19.899C6.6531 20.469 5.5981 19.702 5.8981 18.781L7.4161 14.107C7.48137 13.9062 7.48141 13.6899 7.4162 13.4891C7.351 13.2882 7.22389 13.1132 7.0531 12.989L3.0771 10.101C2.2931 9.53102 2.6971 8.29102 3.6651 8.29102H8.5791C8.79014 8.29107 8.9958 8.22436 9.16662 8.10042C9.33744 7.97648 9.46467 7.80167 9.5301 7.60102L11.0491 2.92702V2.92702Z"
          strokeWidth="2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 23 23',
  },
  menuVertical: {
    path: (
      <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.2929 5.70711C11.4804 5.89464 11.7348 6 12 6C12.2652 6 12.5196 5.89464 12.7071 5.70711C12.8946 5.51957 13 5.26522 13 5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5C11 5.26522 11.1054 5.51957 11.2929 5.70711Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          d="M11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12C11 12.2652 11.1054 12.5196 11.2929 12.7071Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          d="M11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19C13 18.7348 12.8946 18.4804 12.7071 18.2929C12.5196 18.1054 12.2652 18 12 18C11.7348 18 11.4804 18.1054 11.2929 18.2929C11.1054 18.4804 11 18.7348 11 19C11 19.2652 11.1054 19.5196 11.2929 19.7071Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 26 26',
  },
  playIcon: {
    path: (
      <svg width="34" height="35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.84 14.8262C23.8 15.3804 23.8 16.7661 22.84 17.3203L9.16 25.2185C8.2 25.7727 7 25.0799 7 23.9714L7 8.17509C7 7.06658 8.2 6.37376 9.16 6.92801L22.84 14.8262Z"
          fill="white"
          fillOpacity="0.3"
        />
        <path
          d="M27.84 14.8262C28.8 15.3804 28.8 16.7661 27.84 17.3203L14.16 25.2185C13.2 25.7727 12 25.0799 12 23.9714L12 8.17509C12 7.06658 13.2 6.37376 14.16 6.92801L27.84 14.8262Z"
          fill="white"
        />
      </svg>
    ),
    viewBox: '0 0 34 35',
  },
  closeIcon: {
    path: (
      <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 4L5 12"
          strokeWidth="2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 4L13 12"
          strokeWidth="2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 20 20',
  },
  watchIcon: {
    path: (
      <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.25 9C11.25 9.59674 11.0129 10.169 10.591 10.591C10.169 11.0129 9.59674 11.25 9 11.25C8.40326 11.25 7.83097 11.0129 7.40901 10.591C6.98705 10.169 6.75 9.59674 6.75 9C6.75 8.40326 6.98705 7.83097 7.40901 7.40901C7.83097 6.98705 8.40326 6.75 9 6.75C9.59674 6.75 10.169 6.98705 10.591 7.40901C11.0129 7.83097 11.25 8.40326 11.25 9V9Z"
          strokeWidth="2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.84375 9C2.79925 5.95725 5.6425 3.75 9.00025 3.75C12.3587 3.75 15.2012 5.95725 16.1567 9C15.2012 12.0428 12.3587 14.25 9.00025 14.25C5.6425 14.25 2.79925 12.0428 1.84375 9Z"
          strokeWidth="2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 18 18',
  },
  downloadIcon: {
    path: (
      <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 12V12.75C3 13.3467 3.23705 13.919 3.65901 14.341C4.08097 14.7629 4.65326 15 5.25 15H12.75C13.3467 15 13.919 14.7629 14.341 14.341C14.7629 13.919 15 13.3467 15 12.75V12M12 9L9 12M9 12L6 9M9 12V3"
          strokeWidth="2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 18 18',
  },
  addIcon: {
    path: (
      <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.38037 4.82544V11.9366"
          strokeWidth="2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.82471 8.38086H11.9358"
          strokeWidth="2"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    viewBox: '0 0 16 16',
  },
  spacingXIcon: {
    path: (
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="19.9"
          y="3.8998"
          width="16.2"
          height="16.2"
          rx="1.8"
          transform="rotate(90 19.9 3.8998)"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6927 19.2001H17.846V19.2H7.04636L5.54636 19.2L3 19.2C3.37065 20.2487 4.37077 21 5.54636 21H18.1464C19.3219 21 20.322 20.2487 20.6927 19.2001ZM20.6928 4.8001H18.1598C18.1553 4.80003 18.1508 4.8 18.1464 4.8H7.04636L5.54636 4.8C5.54595 4.8 5.54554 4.8 5.54513 4.8H3C3.37065 3.75133 4.37077 3 5.54636 3H18.1464C19.322 3 20.3221 3.75137 20.6928 4.8001Z"
          fill="#DBD9DE"
        />
      </svg>
    ),
    viewBox: '0 0 24 24',
  },
  spacingYIcon: {
    path: (
      <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="3.8998"
          y="4.1"
          width="16.2"
          height="16.2"
          rx="1.8"
          stroke="currentColor"
          strokeWidth="1.8"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2001 3.30731L19.2001 6.15398L19.2 6.15398L19.2 16.9536L19.2 18.4536L19.2 21C20.2487 20.6293 21 19.6292 21 18.4536L21 5.85364C21 4.67808 20.2487 3.67799 19.2001 3.30731ZM4.8001 3.30724L4.8001 5.84021C4.80003 5.84468 4.8 5.84915 4.8 5.85364L4.8 16.9536L4.8 18.4536C4.8 18.454 4.8 18.4545 4.8 18.4549L4.8 21C3.75133 20.6293 3 19.6292 3 18.4536L3 5.85364C3 4.678 3.75137 3.67786 4.8001 3.30724Z"
          fill="#DBD9DE"
        />
      </svg>
    ),
    viewBox: '0 0 24 24',
  },
  home: {
    viewBox: '0 0 25 26',
    path: (
      <svg viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.98866 23.5257V19.8456C8.98864 18.9096 9.75174 18.149 10.6972 18.1427H14.1605C15.1105 18.1427 15.8806 18.9051 15.8806 19.8456V19.8456V23.5371C15.8804 24.3318 16.5211 24.9814 17.3236 25H19.6325C21.9341 25 23.8 23.1528 23.8 20.8742V20.8742V10.4054C23.7877 9.50899 23.3626 8.66722 22.6456 8.11964L14.7493 1.82236C13.3659 0.72588 11.3994 0.72588 10.0161 1.82236L2.15443 8.13107C1.43471 8.67643 1.00886 9.5196 1 10.4168V20.8742C1 23.1528 2.86585 25 5.16749 25H7.47635C8.29883 25 8.96557 24.3399 8.96557 23.5257V23.5257"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  desktop: {
    viewBox: '0 0 19 17',
    path: (
      <svg viewBox="0 0 19 17" xmlns="http://www.w3.org/2000/svg" fill="transparent">
        <path
          d="M5.15385 16.4286H13.4615M1 2.18681L1 11.6813C1 12.3368 1.53135 12.8681 2.18681 12.8681L16.4286 12.8681C17.084 12.8681 17.6154 12.3368 17.6154 11.6813V2.18681C17.6154 1.53136 17.084 1 16.4286 1L2.18681 1C1.53135 1 1 1.53135 1 2.18681Z"
          stroke="currentColor"
        />
      </svg>
    ),
  },
  notebook: {
    viewBox: '0 0 20 18',
    path: (
      <svg viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg" fill="transparent">
        <path
          d="M0 17H20M2 2.33333L2 13C2 13.7364 2.59695 14.3333 3.33333 14.3333H16.6667C17.403 14.3333 18 13.7364 18 13V2.33333C18 1.59695 17.403 1 16.6667 1L3.33333 1C2.59695 1 2 1.59695 2 2.33333Z"
          stroke="currentColor"
        />
      </svg>
    ),
  },
  tablet: {
    viewBox: '0 0 16 18',
    path: (
      <svg viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg" fill="transparent">
        <path
          d="M6.14286 13.5714H9.57143M2.14286 17H13.5714C14.2026 17 14.7143 16.4883 14.7143 15.8571V2.14286C14.7143 1.51167 14.2026 1 13.5714 1H2.14286C1.51167 1 1 1.51168 1 2.14286V15.8571C1 16.4883 1.51167 17 2.14286 17Z"
          stroke="currentColor"
        />
      </svg>
    ),
  },
  mobile: {
    viewBox: '0 0 13 18',
    path: (
      <svg viewBox="0 0 13 18" xmlns="http://www.w3.org/2000/svg" fill="transparent">
        <path
          d="M5 13.5714H8.42857M2.14286 17H11.2857C11.9169 17 12.4286 16.4883 12.4286 15.8571V2.14286C12.4286 1.51167 11.9169 1 11.2857 1H2.14286C1.51167 1 1 1.51168 1 2.14286V15.8571C1 16.4883 1.51167 17 2.14286 17Z"
          stroke="currentColor"
        />
      </svg>
    ),
  },
  'solid-chevron-down': {
    path: (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.6928 14.8C12.3849 15.3333 11.6151 15.3333 11.3072 14.8L9.22872 11.2C8.9208 10.6667 9.3057 10 9.92154 10L14.0785 10C14.6943 10 15.0792 10.6667 14.7713 11.2L12.6928 14.8Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  'solid-chevron-up': {
    path: (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.6928 9.2C12.3849 8.66667 11.6151 8.66667 11.3072 9.2L9.22872 12.8C8.9208 13.3333 9.3057 14 9.92154 14L14.0785 14C14.6943 14 15.0792 13.3333 14.7713 12.8L12.6928 9.2Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  add: {
    viewBox: '0 0 13 13',
    path: (
      <svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.38086 2.82544V9.93655"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.82422 6.38086H9.93533"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  delete: {
    viewBox: '0 0 16 16',
    path: (
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 4H3.33333H14"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33398 3.99992V2.66659C5.33398 2.31296 5.47446 1.97382 5.72451 1.72378C5.97456 1.47373 6.3137 1.33325 6.66732 1.33325H9.33398C9.68761 1.33325 10.0267 1.47373 10.2768 1.72378C10.5268 1.97382 10.6673 2.31296 10.6673 2.66659V3.99992M12.6673 3.99992V13.3333C12.6673 13.6869 12.5268 14.026 12.2768 14.2761C12.0267 14.5261 11.6876 14.6666 11.334 14.6666H4.66732C4.3137 14.6666 3.97456 14.5261 3.72451 14.2761C3.47446 14.026 3.33398 13.6869 3.33398 13.3333V3.99992H12.6673Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  duplicate: {
    viewBox: '0 0 16 16',
    path: (
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.33268 4.66667V10C5.33268 10.3536 5.47316 10.6928 5.72321 10.9428C5.97326 11.1929 6.31239 11.3333 6.66602 11.3333H10.666M5.33268 4.66667V3.33333C5.33268 2.97971 5.47316 2.64057 5.72321 2.39052C5.97326 2.14048 6.31239 2 6.66602 2H9.72335C9.90015 2.00004 10.0697 2.0703 10.1947 2.19533L13.1373 5.138C13.2624 5.263 13.3326 5.43254 13.3327 5.60933V10C13.3327 10.3536 13.1922 10.6928 12.9422 10.9428C12.6921 11.1929 12.353 11.3333 11.9993 11.3333H10.666M5.33268 4.66667H3.99935C3.64573 4.66667 3.30659 4.80714 3.05654 5.05719C2.80649 5.30724 2.66602 5.64638 2.66602 6V12.6667C2.66602 13.0203 2.80649 13.3594 3.05654 13.6095C3.30659 13.8595 3.64573 14 3.99935 14H9.33268C9.6863 14 10.0254 13.8595 10.2755 13.6095C10.5255 13.3594 10.666 13.0203 10.666 12.6667V11.3333"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  'rectangle-outline': {
    viewBox: '0 0 11 8',
    path: (
      <svg viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0.75"
          y="0.75"
          width="9.16667"
          height="6.5"
          rx="1.25"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </svg>
    ),
  },
  'data-link': {
    path: (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.48569 9.35412L4.69897 11.706C3.76065 12.2888 3.47244 13.5219 4.05523 14.4602L5.83868 17.3317C6.42146 18.27 7.65456 18.5582 8.59287 17.9754L12.3796 15.6235L11.5882 14.3493L7.80145 16.7012C7.56687 16.8469 7.2586 16.7748 7.1129 16.5403L5.32945 13.6688C5.18376 13.4342 5.25581 13.126 5.49039 12.9803L9.27711 10.6283L8.48569 9.35412ZM14.7229 12.4023L18.5096 10.0504C18.7442 9.90468 18.8162 9.5964 18.6705 9.36183L16.8871 6.49038C16.7414 6.25581 16.4331 6.18375 16.1985 6.32945L12.4118 8.6814L11.6204 7.40717L15.4071 5.05522C16.3454 4.47244 17.5785 4.76065 18.1613 5.69896L19.9448 8.5704C20.5276 9.50872 20.2393 10.7418 19.301 11.3246L15.5143 13.6765L14.7229 12.4023Z"
          fill="currentColor"
        />
        <path
          d="M9.45746 13.0945L14.5101 9.95632"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    ),
  },
  boolean: {
    path: (
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3.75" y="3.75" width="16.5" height="6.5" rx="3.25" stroke="currentColor" strokeWidth="1.5" />
        <circle cx="17" cy="7" r="0.75" fill="currentColor" stroke="currentColor" strokeWidth="0.5" />
        <rect
          x="3.75"
          y="13.75"
          width="16.5"
          height="6.5"
          rx="3.25"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <circle cx="7" cy="17" r="0.75" fill="currentColor" stroke="currentColor" strokeWidth="0.5" />
      </svg>
    ),
  },
};
