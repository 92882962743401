import { ComponentBlock } from '@types';

import { types } from '@application/core/types';

import { component } from './component';
import { WordCloudProps } from '.';

export const block: ComponentBlock<'wordCloud', WordCloudProps, unknown> = {
  id: 'wordCloud',
  render: component,
  props: {
    wordCloudData: {
      type: types.list,
    },
    wordCloudTextProperty: {
      type: types.string,
    },
  },
};
