import { ComponentBlock } from '@types';

import { types } from '@application/core/types';

import { component } from './component';
import { StoriesProps } from './index';

export const block: ComponentBlock<'stories', StoriesProps, any> = {
  id: 'stories',
  render: component,
  props: {
    width: {
      type: types.string,
    },
    height: {
      type: types.string,
    },
    storiesData: {
      type: types.list,
    },
    storiesLinkProperty: {
      type: types.string,
    },
    storiesSourceProperty: {
      type: types.string,
    },
    storiesTextProperty: {
      type: types.string,
    },
    storiesHasLoop: {
      type: types.boolean,
    },
  },
};
