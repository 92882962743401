import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { RichTextEditorParts, RichTextEditorProps } from './index';

export const protocolFactory = new ProtocolFactory<RichTextEditorProps, RichTextEditorParts>({
  name: 'RichTextEditor',
  editorProps: [
    {
      type: EPropType.String,
      name: 'name',
      required: false,
      label: 'Name',
      description: 'Name for the rich text editor',
      defaultValue: 'RichTextEditor',
    },
    {
      type: EPropType.String,
      name: 'value',
      required: true,
      label: 'Value',
      description: 'Value for the rich editor',
      defaultValue: 'Foo',
      allowDataLink: true,
    },
    {
      type: EPropType.String,
      name: 'placeholder',
      required: false,
      label: 'Placeholder',
      description: 'Text inside the input if no value is provided',
      defaultValue: 'Insert some text here',
    },
    {
      type: EPropType.String,
      name: 'label',
      required: false,
      label: 'Label',
      description: 'Label of the rich text editor input',
      defaultValue: 'Example',
    },
    {
      type: EPropType.Boolean,
      name: 'readOnly',
      required: false,
      label: 'Preview mode',
      description: 'Show markdown in preview mode',
      defaultValue: false,
    },
  ],
  themeProps: ['width', 'height', 'size'],
  themeConfig: {
    allowMultipart: true,
    defaultProps: ['size', 'variant'],
    sizes: ['sm', 'md', 'lg'],
    parts: ['editor-label', 'editor-control'],
    partsConfig: {
      'editor-label': {
        baseStyleProps: ['color', 'fontWeight'],
        sizeProps: ['fontSize', 'marginBottom'],
      },
      'editor-control': {
        pseudos: ['_hover', '_invalid', '_disabled'],
        pseudoProps: ['color', 'background', 'border', 'borderColor', 'borderRadius'],
        baseStyleProps: ['color', 'background', 'border', 'borderColor', 'borderRadius'],
        sizeProps: ['fontSize', 'paddingHorizontal', 'paddingVertical'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
