import { ThemeMultipartComponent } from '@types';

export type WordCloudSizes = 'sm' | 'md' | 'lg';
export type WordCloudVariants = 'default';
export type WordCloudParts = 'word-cloud-container' | 'word-cloud-text';

export const PTWordCloud: ThemeMultipartComponent<WordCloudSizes, WordCloudVariants, WordCloudParts> = {
  parts: ['word-cloud-container', 'word-cloud-text'],

  baseStyle: {
    'word-cloud-container': {
      border: 'pt-sm',
      borderColor: 'pt-ui.300',
      borderRadius: 'pt-sm',
      background: 'pt-ui.50',
    },
    'word-cloud-text': {
      fontSize: 'pt-md',
      fontWeight: 'pt-normal',
      fontFamily: 'pt-body',
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: 'pt-primary',
  },
};
