import React, { FC, useContext } from 'react';

import { Flex as ChakraFlex, useMultiStyleConfig } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { CompilerContext } from '@application/compiler/contexts';

import { CardProps } from '.';
import { defaultProps } from './protocol';

export const component: FC<ComponentReceivedProps<CardProps, unknown>> = ({
  props = defaultProps,
  children,
}) => {
  const { size } = props;

  const { config } = useContext(CompilerContext);

  const themeStyles = useMultiStyleConfig('PTCard', { size });

  return (
    <ChakraFlex width="fit-content" height="fit-content" sx={themeStyles?.card || {}} {...props}>
      {props.cardTitle && <ChakraFlex sx={themeStyles?.['card-header']}>{props.cardTitle}</ChakraFlex>}
      <ChakraFlex sx={themeStyles?.['card-body']}>
        {config.mode === 'theme' ? 'Example content' : children}
      </ChakraFlex>
    </ChakraFlex>
  );
};
