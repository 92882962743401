import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface DevelopmentEnvironmentProps {
  theme: 'monokai' | 'solarized_dark' | 'xcode';
  renderBgColor: string;

  hideEditor: boolean;

  html: string;
  css: string;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<DevelopmentEnvironmentProps>;
