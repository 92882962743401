import { PropProtocol } from '@gsystem/protocols';

import { Block, BlockDefs, BlockParams, ComponentBlock } from '@types';
import { BlockFactory } from '@application/lib/core';

// TODO: Move this to BlockFactory
export const BlockBuilder =
  <ComponentBlockParams extends ComponentBlock<any, any, PropProtocol<any>>>(
    params: BlockParams<ComponentBlockParams>,
  ) =>
  (defs: BlockDefs<ComponentBlockParams> = {}): Block<ComponentBlockParams> => {
    const selectedComponent = BlockFactory.getCompilerComponent(params.component);

    if (!selectedComponent) {
      return {
        component: params.component,
      };
    }

    return {
      component: params.component,

      condition: defs.condition,

      // Props
      staticPropValues: defs.staticPropValues,
      dynamicPropValues: {},

      // Events
      triggers: selectedComponent.block.triggers ?? {},
      events: selectedComponent.block.events ?? {},
    };
  };
