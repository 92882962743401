import { ProtocolFactory } from '@application/lib/protocols';
import { WordCloudParts } from '@application/lib/defaultTheme';

import { WordCloudProps } from '.';

export const protocolFactory = new ProtocolFactory<WordCloudProps, WordCloudParts>({
  name: 'WordCloud',
  editorProps: [],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
  themeProps: ['width', 'height'],
  themeConfig: {
    allowMultipart: true,
    defaultProps: [],
    parts: ['word-cloud-container', 'word-cloud-text'],
    partsConfig: {
      'word-cloud-container': {
        baseStyleProps: ['border', 'borderColor', 'borderRadius', 'background'],
      },
      'word-cloud-text': {
        baseStyleProps: ['fontSize', 'fontWeight', 'fontFamily'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
