import { ThemeMultipartComponent } from '@types';

export type BarChartSizes = 'sm' | 'md' | 'lg';
export type BarChartVariants = 'default';
export type BarChartParts = 'chart-series' | 'chart-axis';

export const PTBarChart: ThemeMultipartComponent<BarChartSizes, BarChartVariants, BarChartParts> = {
  parts: ['chart-series', 'chart-axis'],

  baseStyle: {
    'chart-series': {
      background: 'pt-primary.500',
      fontWeight: 'pt-normal',
      fontSize: 'pt-sm',
      color: 'pt-white',
    },
    'chart-axis': {
      fontWeight: 'pt-normal',
      fontSize: 'pt-sm',
      color: 'pt-ui.500',
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: 'pt-primary',
  },
};
