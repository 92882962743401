import { AWSConnection, Deployment, Id } from '@types';

import { ListDeployments } from './queries';
import { queryAdmin } from '../adminApi';

export const listDeployments = (
  variables: Record<'organizationId' | 'projectId', Id>,
  token: string,
): Promise<{ listDeployments: AWSConnection<Deployment> }> => {
  return queryAdmin(ListDeployments, variables)(token);
};
