import { ComponentBlock } from '@types';

import { types } from '@application/core/types';

import { BarChartProps } from '.';
import { component } from './component';

export const block: ComponentBlock<'barChart', BarChartProps, unknown> = {
  id: 'barChart',
  render: component,
  props: {
    chartCategory: {
      type: types.string,
    },
    chartCategoryLabel: {
      type: types.string,
    },
    chartValue: {
      type: types.string,
    },
    chartValueLabel: {
      type: types.string,
    },
    chartData: {
      type: types.list,
    },
  },
};
