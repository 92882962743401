import { CompilerComponent, DataComponentField, DataHandlersComponents } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface ListProps extends DataHandlersComponents {
  list: any[];
  columnsNumber: number;
  fields?: DataComponentField[];
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<ListProps>;
