import { ProtocolFactory } from 'lib/protocols';

import { PageProps } from './index';

export const protocolFactory = new ProtocolFactory<PageProps>({
  name: 'Page',
  editorProps: [],
  editorConfig: {
    disableDesignSection: true,
    disableDataLinkSection: true,
    disableLogicSection: true,
  },
});

export const defaultProps = {
  ...protocolFactory.createDefaultProps(),
  fields: [
    {
      id: '$routeParams',
      name: 'Route Params',
      path: '',
      type: 'Object',
    },
  ],
};
