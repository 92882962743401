import { ThemeMultipartComponent } from '@types';

export type BoardSizes = 'sm' | 'md' | 'lg';
export type BoardVariants = 'default';
export type BoardParts =
  | 'board-container'
  | 'board-column-container'
  | 'board-column-header'
  | 'board-column-counter'
  | 'board-column-list'
  | 'board-column-item'
  | 'board-column-item-name'
  | 'board-column-item-description'
  | 'board-column-item-tag'
  | 'board-column-button';

export const PTBoard: ThemeMultipartComponent<BoardSizes, BoardVariants, BoardParts> = {
  parts: [
    'board-container',
    'board-column-container',
    'board-column-header',
    'board-column-counter',
    'board-column-button',
    'board-column-list',
    'board-column-item',
    'board-column-item-name',
    'board-column-item-description',
    'board-column-item-tag',
  ],

  variants: {
    default: {
      'board-container': {
        border: 'pt-sm',
        padding: 'pt-md',
        background: 'pt-white',
        borderColor: 'pt-ui.200',
        borderRadius: 'pt-sm',
        boxShadow: 'pt-large',
      },
      'board-column-container': {
        border: 'pt-sm',
        borderColor: 'pt-ui.100',
        borderRadius: 'pt-sm',
        background: 'pt-ui.50',
      },
      'board-column-header': {
        padding: 'pt-md',
        fontSize: 'pt-md',
        fontWeight: 'pt-medium',
        fontFamily: 'pt-heading',
        background: 'pt-transparent',
        color: 'pt-ui.700',
      },
      'board-column-counter': {
        color: 'pt-primary.300',
        fontWeight: 'pt-bold',
        background: 'pt-primary.50',
        borderRadius: 'pt-xs',
      },
      'board-column-list': {
        padding: 'md',
        background: 'pt-transparent',
      },
      'board-column-item': {
        border: 'pt-sm',
        padding: 'pt-md',
        boxShadow: 'pt-large',
        background: 'pt-white',
        borderColor: 'pt-ui.200',
        borderRadius: 'pt-sm',
        _active: {
          outline: 'unset',
          borderColor: 'pt-primary.200',
          boxShadow: '0px 0px 0px 3px var(--chakra-colors-pt-primary-100), var(--chakra-shadows-pt-large)',
        },
      },
      'board-column-item-name': {
        color: 'pt-ui.700',
        fontSize: 'pt-sm',
        fontWeight: 'pt-medium',
        fontFamily: 'pt-heading',
      },
      'board-column-item-description': {
        color: 'pt-ui.500',
        fontSize: 'pt-xs',
        fontWeight: 'pt-normal',
        fontFamily: 'pt-body',
      },
      'board-column-item-tag': {
        colorScheme: 'pt-primary',
        borderRadius: 'pt-xs',
      },
      'board-column-button': {
        paddingY: 'pt-md',
        paddingX: 'pt-md',
        border: 'pt-sm',
        borderColor: 'pt-ui.200',
        borderRadius: 'pt-sm',
        backgrounrd: 'pt-transparent',
        color: 'pt-ui.500',
        fontWeight: 'pt-normal',
        fontSize: 'pt-xs',
        _hover: {
          color: 'pt-ui.600',
          background: 'pt-ui.100',
        },
      },
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: null,
  },
};
