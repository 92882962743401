import React, { FC } from 'react';

import {
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  forwardRef,
  useStyleConfig,
} from '@chakra-ui/react';

import { InputSizes, InputVariants } from '@application/theme';

export interface TextareaProps extends ChakraTextareaProps {
  size?: InputSizes;
  variant?: InputVariants | ChakraTextareaProps['variant'];
}

export const Textarea: FC<TextareaProps> = forwardRef(({ size, variant, colorScheme, ...props }, ref) => {
  const styles = useStyleConfig('Textarea', { size, variant, colorScheme });

  return <ChakraTextarea data-testid="textarea" sx={styles} ref={ref} {...props} />;
});

Textarea.displayName = 'Textarea';
