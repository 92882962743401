import React, { FC, useState } from 'react';

import { AxiosError } from 'axios';
import {
  Flex as ChakraFlex,
  FormControl as ChakraFormControl,
  FormLabel as ChakraFormLabel,
  Grid,
  useDisclosure,
} from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { useMutation } from 'react-query';

import { GstudioOrganizationRoles } from '@types';

import { AlertDialog, Button, Icon, Input, MenuSelect, Text, useToast } from 'components/ds';

import { ApiClient } from '@services';
import { ApplicationApi } from '@api';
import { useOrganization } from 'lib/apiHooks';

interface UserOrganizationMemberDetails {
  name: string;
  email: string;
  role: GstudioOrganizationRoles;
}

export const InviteOrganizationMembersButtonModal: FC = () => {
  const toast = useToast();

  const { organizationId } = useOrganization();

  const [usersList, setUsersList] = useState<UserOrganizationMemberDetails[]>([]);

  const {
    isOpen: isInviteModalOpen,
    onOpen: onOpenInviteModal,
    onClose: onCloseInviteModal,
  } = useDisclosure();

  const addUsersMutation = useMutation(
    async (body: ApplicationApi.InviteOrganizationMembersInput) => ApiClient.inviteOrganizationMembers(body),
    {
      onSuccess() {
        toast({
          title: 'Successfully invited users',
          status: 'success',
        });
      },
      onError(err: AxiosError) {
        const { error } = err?.response?.data;

        toast({
          title: 'Error inviting user',
          description: error,
          status: 'danger',
        });
      },
    },
  );

  const getRoleOptions = [
    {
      label: GstudioOrganizationRoles.Editor,
      value: GstudioOrganizationRoles.Editor,
    },
    {
      label: GstudioOrganizationRoles.Viewer,
      value: GstudioOrganizationRoles.Viewer,
    },
  ];

  const onConfirmInvitation = () => {
    if (!usersList.some(({ email }) => email === '')) {
      addUsersMutation.mutate({
        organizationId,
        usersList,
      });
      onCloseInviteModal();
    } else {
      toast({
        status: 'warning',
        title: 'Missing e-mail',
        description: 'Please, insert the missing e-mail and try again.',
      });
    }
  };

  const handleModalOpen = () => {
    setUsersList([
      {
        name: '',
        email: '',
        role: GstudioOrganizationRoles.Editor,
      },
    ]);

    onOpenInviteModal();
  };

  const handleUserInvitationUpdate = (
    updatedUserInvitation: UserOrganizationMemberDetails,
    index: number,
  ) => {
    const clonedUsersInvitations = cloneDeep(usersList);

    clonedUsersInvitations.splice(index, 1, updatedUserInvitation);

    setUsersList(clonedUsersInvitations);
  };

  const handleUsersInvitationsInsert = () => {
    const clonedUsersInvitations = cloneDeep(usersList);

    clonedUsersInvitations.push({
      name: '',
      email: '',
      role: GstudioOrganizationRoles.Editor,
    });

    setUsersList(clonedUsersInvitations);
  };

  const handleUsersInvitationsDelete = (idx: number) => {
    const clonedUsersInvitations = cloneDeep(usersList);

    if (clonedUsersInvitations.length < 2) return;

    clonedUsersInvitations.splice(idx, 1);

    setUsersList(clonedUsersInvitations);
  };

  return (
    <ChakraFlex width="100%">
      <Button
        onClick={handleModalOpen}
        size="sm"
        variant="secondary"
        isFullWidth={true}
        leftIcon={<Icon name="small-add" fill="white" />}
      >
        Invite members
      </Button>

      <AlertDialog
        size="2xl"
        title="Add Members"
        description="Add members to your organization to start contributing into your projects."
        buttonLabel="Add Members"
        isOpen={isInviteModalOpen}
        onClose={onCloseInviteModal}
        onConfirm={onConfirmInvitation}
      >
        <ChakraFlex gridGap="md" flexDirection="column">
          {usersList?.map((userInvitation, idx) => (
            <Grid templateColumns="4fr 4fr 4fr 1fr" gap="md" key={idx}>
              <ChakraFormControl>
                {idx === 0 && <ChakraFormLabel>Name</ChakraFormLabel>}
                <Input
                  width="100%"
                  placeholder="User name"
                  value={userInvitation.name}
                  onChange={({ target }) =>
                    handleUserInvitationUpdate({ ...userInvitation, name: target.value }, idx)
                  }
                />
              </ChakraFormControl>
              <ChakraFormControl>
                {idx === 0 && <ChakraFormLabel>E-mail</ChakraFormLabel>}
                <Input
                  width="100%"
                  placeholder="User e-mail"
                  value={userInvitation.email}
                  onChange={({ target }) =>
                    handleUserInvitationUpdate({ ...userInvitation, email: target.value }, idx)
                  }
                />
              </ChakraFormControl>
              <ChakraFormControl>
                {idx === 0 && <ChakraFormLabel>Role</ChakraFormLabel>}
                <MenuSelect
                  value={getRoleOptions.find(({ value }) => value === userInvitation.role)}
                  options={getRoleOptions}
                  onChange={({ value }) =>
                    handleUserInvitationUpdate({ ...userInvitation, role: value }, idx)
                  }
                />
              </ChakraFormControl>

              {idx !== 0 && (
                <ChakraFlex
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => handleUsersInvitationsDelete(idx)}
                >
                  <Icon name="delete" cursor="pointer" color="supportA.500" />
                </ChakraFlex>
              )}
            </Grid>
          ))}

          <Text cursor="pointer" color="primary.500" onClick={handleUsersInvitationsInsert}>
            + New user
          </Text>
        </ChakraFlex>
      </AlertDialog>
    </ChakraFlex>
  );
};
