import { ThemeMultipartComponent } from '@types';

type ModalSizes = 'sm' | 'md' | 'lg';
type ModalVariants = 'default';

export type ModalParts = 'modal' | 'modal-header' | 'modal-header-title' | 'modal-body' | 'modal-footer';

export const PTModal: ThemeMultipartComponent<ModalSizes, ModalVariants, ModalParts> = {
  parts: ['modal', 'modal-header', 'modal-body', 'modal-footer'],

  variants: {
    default: {
      modal: {
        border: 'pt-sm',
        borderColor: 'pt-ui.100',
        borderRadius: 'pt-sm',
      },
      'modal-header': {
        borderBottom: 'pt-sm',
        borderColor: 'pt-ui.100',
        background: 'pt-white',
        paddingY: 'pt-md',
        paddingX: 'pt-md',
      },
      'modal-header-title': {
        color: 'pt-ui.700',
        fontSize: 'pt-lg',
        fontWeight: 'pt-bold',
        fontFamily: 'pt-body',
      },
      'modal-body': {
        paddingY: 'pt-md',
        paddingX: 'pt-md',
        background: 'pt-white',
      },
      'modal-footer': {
        borderTop: 'pt-sm',
        borderColor: 'pt-ui.100',
        background: 'pt-white',
        paddingY: 'pt-md',
        paddingX: 'pt-md',
      },
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: null,
  },
};
