import { ThemeMultipartComponent } from '@types';

type TableSizes = 'sm' | 'md' | 'lg';
type TableVariants = 'default';

type TableParts = 'table' | 'table-header' | 'table-cell';

export const PTTable: ThemeMultipartComponent<TableSizes, TableVariants, TableParts> = {
  parts: ['table', 'table-header', 'table-cell'],

  baseStyle: {
    table: {
      border: 'pt-sm',
      borderColor: 'pt-ui.200',
      borderRadius: 'pt-sm',
      background: 'pt-white',
      boxShadow: 'pt-large',
    },
    'table-header': {
      fontWeight: 'pt-bold',
      textAlign: 'left',
      color: 'pt-ui.700',
      background: 'pt-transparent',
      borderBottom: 'pt-sm',
      borderColor: 'pt-ui.200',
    },
    'table-cell': {
      fontWeight: 'pt-normal',
      textAlign: 'left',
      color: 'pt-ui.500',
      background: 'pt-transparent',
    },
  },

  sizes: {
    sm: {
      'table-header': {
        paddingX: 'pt-sm',
        paddingY: 'pt-sm',
        fontSize: 'pt-sm',
        lineHeight: 'pt-none',
      },
      'table-cell': {
        paddingX: 'pt-sm',
        paddingY: 'pt-sm',
        fontSize: 'pt-sm',
        lineHeight: 'pt-none',
      },
    },
    md: {
      'table-header': {
        paddingX: 'pt-md',
        paddingY: 'pt-md',
        fontSize: 'pt-md',
        lineHeight: 'pt-none',
      },
      'table-cell': {
        paddingX: 'pt-md',
        paddingY: 'pt-md',
        fontSize: 'pt-md',
        lineHeight: 'pt-none',
      },
    },
    lg: {
      'table-header': {
        paddingX: 'pt-lg',
        paddingY: 'pt-lg',
        fontSize: 'pt-lg',
        lineHeight: 'pt-none',
      },
      'table-cell': {
        paddingX: 'pt-lg',
        paddingY: 'pt-lg',
        fontSize: 'pt-lg',
        lineHeight: 'pt-none',
      },
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: 'pt-primary',
  },
};
