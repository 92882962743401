import { ProtocolFactory } from 'lib/protocols';

import { ContainerProps } from './index';

export const protocolFactory = new ProtocolFactory<ContainerProps>({
  name: 'Container',
  editorProps: [],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: true,
    disableLogicSection: true,
  },
  themeProps: [
    'width',
    'height',
    'minWidth',
    'minHeight',
    'maxWidth',
    'maxHeight',
    'gridGap',
    'gridColumnGap',
    'gridRowGap',
    'paddingHorizontal',
    'paddingVertical',
    'marginHorizontal',
    'marginVertical',
    'flexDirection',
    'alignItems',
    'justifyContent',
    'background',
    'boxShadow',
    'borderRadius',
    'border',
    'borderColor',
    'overflow',
  ],
});

export const defaultProps = protocolFactory.createDefaultProps();
