import { component } from './component';
import { ComponentBlock } from '@types';
import { TextProps } from './index';
import { types } from 'core/types';

export const block: ComponentBlock<'text', TextProps, unknown> = {
  id: 'text',
  render: component,
  props: {
    content: {
      type: types.string,
    },
    isTruncated: {
      type: types.boolean,
    },
  },
};
