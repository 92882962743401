import React, { FC, useContext, useEffect } from 'react';

import {
  chakra,
  Button as ChakraButton,
  Modal as ChakraModal,
  ModalBody as ChakraModalBody,
  ModalCloseButton as ChakraModalCloseButton,
  ModalContent as ChakraModalContent,
  ModalFooter as ChakraModalFooter,
  ModalHeader as ChakraModalHeader,
  ModalOverlay as ChakraModalOverlay,
  useDisclosure,
  useMultiStyleConfig,
  useStyleConfig,
} from '@chakra-ui/react';

import { CompilerContext, EngineContext } from '@application/compiler/contexts';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { ModalProps, ModalTriggers } from './index';

export const component: FC<ComponentReceivedProps<ModalProps, ModalTriggers>> = ({
  props = defaultProps,
  triggers,
  children,
}) => {
  const {
    modalTitle = 'Modal title',
    modalCancelLabel = 'Cancel',
    modalSubmitLabel = 'Confirm',
    modalTriggerLabel = 'Open modal',
    modalHiddenFooter = false,
    ...rest
  } = props;

  const { engine } = useContext(EngineContext);
  const { config } = useContext(CompilerContext);

  const modalDisclosure = useDisclosure();

  const secondaryButtonStyles = useStyleConfig('PTButton', { variant: 'ghost' });
  const primaryButtonStyles = useStyleConfig('PTButton', { variant: 'solid' });

  const isThemeMode = config.mode === 'theme';

  const {
    modal: modalStyles,
    'modal-header': modalHeaderStyles,
    'modal-header-title': modalHeaderTitleStyles,
    'modal-body': modalBodyStyles,
    'modal-footer': modalFooterStyles,
  } = useMultiStyleConfig('PTModal', {});

  const handleCancel = () => {
    triggers.onConfirm && triggers.onCancel();

    modalDisclosure.onClose();
  };

  const handleConfirm = () => {
    triggers.onConfirm && triggers.onConfirm();

    modalDisclosure.onClose();
  };

  useEffect(() => {
    if (engine) {
      const operatorName = 'close_modal';

      engine.addOperators({
        [operatorName]: {
          name: operatorName,
          description: 'Close the modal',
          handler: () => modalDisclosure.onClose(),
        },
      });
    }
  }, [engine]);

  return (
    <>
      <ChakraButton
        id={(rest as { id: string }).id}
        sx={primaryButtonStyles}
        onClick={() => modalDisclosure.onOpen()}
      >
        {modalTriggerLabel}
      </ChakraButton>
      <ChakraModal
        size="lg"
        isCentered={true}
        scrollBehavior="inside"
        isOpen={modalDisclosure.isOpen}
        onClose={() => modalDisclosure.onClose()}
      >
        <ChakraModalOverlay />
        <ChakraModalContent overflow="hidden" sx={modalStyles}>
          <ChakraModalHeader
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={modalHeaderStyles}
          >
            <chakra.span sx={modalHeaderTitleStyles}>{modalTitle}</chakra.span>
            <ChakraModalCloseButton
              top="0px"
              right="0px"
              position="relative"
              borderRadius="pt-xs"
              color={modalHeaderTitleStyles?.color as string}
            />
          </ChakraModalHeader>
          <ChakraModalBody sx={modalBodyStyles}>
            {isThemeMode && (
              <chakra.span fontFamily="pt-body" color="pt-ui.500">
                Example content
              </chakra.span>
            )}
            {children}
          </ChakraModalBody>
          {!modalHiddenFooter && (
            <ChakraModalFooter gridGap="pt-sm" sx={modalFooterStyles}>
              <ChakraButton sx={secondaryButtonStyles} onClick={() => handleCancel()}>
                {modalCancelLabel}
              </ChakraButton>
              <ChakraButton sx={primaryButtonStyles} onClick={() => handleConfirm()}>
                {modalSubmitLabel}
              </ChakraButton>
            </ChakraModalFooter>
          )}
        </ChakraModalContent>
      </ChakraModal>
    </>
  );
};
