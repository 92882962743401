import React, { FC } from 'react';

import { Grid as ChakraGrid, useStyleConfig } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { GridProps } from '.';

export const component: FC<ComponentReceivedProps<GridProps, unknown>> = ({
  children,
  props = defaultProps,
}) => {
  const { templateColumns, templateRows } = props;

  const themeStyle = useStyleConfig('Grid', {});

  return (
    <ChakraGrid
      sx={themeStyle}
      gridTemplateRows={templateRows ? `repeat(${templateRows}, 1fr)` : '1fr'}
      gridTemplateColumns={templateColumns ? `repeat(${templateColumns}, 1fr)` : '1fr'}
      {...props}
    >
      {children}
    </ChakraGrid>
  );
};
