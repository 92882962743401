import { CompilerComponent } from '@types';

import { MapParts } from '@application/lib/defaultTheme';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export type MapProps = {
  mapMarkersData: Record<string, unknown>[];
  mapMarkersTooltip: string;
  mapMarkersGeolocation: string;
};

export default {
  component,
  block,
  defaultProps,
  protocolFactory,
} as CompilerComponent<MapProps, MapParts>;
