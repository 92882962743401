import React, { FC, useContext, useEffect, useMemo } from 'react';

import { ASTNode } from 'graphql';
import { FormControl as ChakraFormControl, Text as ChakraText } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { find, get, isEmpty, isEqual, some } from 'lodash';
import { useQueryClient } from 'react-query';

import { ApplicationUser, PropType } from '@types';

import { capitalize, showDatasetFormatted } from '@application/utils/stringManipulation';
import { CompilerContext, EngineContext } from '@application/compiler/contexts';

import { FactoryDataset } from '@introspection/factories/DatasetsOperationsFactory';

import { DatasetRelationInput, UserRelationInput } from './SpecialInputs';
import { DynamicCompilerInput } from './DynamicCompilerInput';

import { DynamicFormProps } from '..';
import { exampleParentDatasetFieldsReference, exampleParentDatasetFormFields } from '../mock';

interface Props {
  inheritedData: Record<string, any>;
  user: ApplicationUser;
  dataset: FactoryDataset;
  datasets: Record<string, FactoryDataset>;
  operation: DynamicFormProps['operation'];
  datasetName: string;
  attributeToCurrentUser?: boolean;
  datasetsFieldsReference: DynamicFormProps['datasetsFieldsReference'];
  fieldsCustomization: DynamicFormProps['fieldsCustomization'];
  executeApiOperation: (
    operation: ASTNode,
    operationName: string,
    variables: Record<string, unknown>,
  ) => Promise<unknown>;
}

const userDatasetMappings = {
  id: 'sub',
  userName: 'name',
  userEmail: 'email',
  userPicture: 'picture',
};

export const ParentForm: FC<Props> = ({
  inheritedData,
  user,
  dataset,
  datasets,
  operation,
  datasetName,
  executeApiOperation,
  attributeToCurrentUser,
  datasetsFieldsReference,
  fieldsCustomization,
}) => {
  const queryClient = useQueryClient();

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { config } = useContext(CompilerContext);
  const { engine } = useContext(EngineContext);

  const isThemeMode = config.mode === 'theme';

  const datasetOperation = !isThemeMode && dataset.operations[operation];
  const datasetReferenceFields = get(datasetsFieldsReference, [datasetName]);

  const formFields = isThemeMode ? exampleParentDatasetFormFields : datasetOperation.formFields;

  const isUserDataset = some(formFields, { name: 'id' }) && datasetName.endsWith('User');

  const updateUserFields = (selectedUser: ApplicationUser) => {
    for (const formField of formFields) {
      const userFieldMapping = userDatasetMappings[formField.name];

      if (userFieldMapping) {
        const oldValue = getValues(`${datasetName}.${formField.name}`);
        const newValue = get(selectedUser, userFieldMapping);
        if (isEqual(oldValue, newValue)) continue;

        setValue(`${datasetName}.${formField.name}`, get(selectedUser, userFieldMapping));
        engine.runOperation({
          operator: 'dynamicFormInput',
          args: {
            name: formField.name,
            value: get(selectedUser, userFieldMapping),
          },
        });
      }
    }
  };

  useEffect(() => {
    if (engine && !isThemeMode) {
      engine.addOperators({
        dynamicFormInput: {
          name: 'dynamicFormInput',
          description: 'Handle input events',
          handler: ({ name, value }: { name: string; value: string }) => {
            if (name === 'id' && isUserDataset) {
              const usersListQueryKey = ['query', 'organization', config.organizationId, 'users'];
              const users: ApplicationUser[] = queryClient.getQueryData(usersListQueryKey) ?? [];

              const foundUser = users.find((u) => u.sub === value);
              if (foundUser) {
                return updateUserFields(foundUser);
              }
            }

            setValue(`${datasetName}.${name}`, value);
          },
        },
      });
    }

    return () => !isThemeMode && engine.removeOperators(['dynamicFormInput']);
  }, [engine]);

  useEffect(() => {
    if (engine && isUserDataset && attributeToCurrentUser) {
      updateUserFields(user);
    }
  }, [engine, isUserDataset, datasetName, formFields, attributeToCurrentUser]);

  const fields = useMemo(() => {
    if (!formFields) return [];

    if (fieldsCustomization?.length) {
      return fieldsCustomization
        .map((fieldCustomization) => {
          const conditionalFieldName =
            datasetReferenceFields[fieldCustomization?.name]?.type === 'DATASET'
              ? `${fieldCustomization.name}RelationId`
              : fieldCustomization.name;

          const formField = find(formFields, {
            name: conditionalFieldName,
          });

          if (!formField) return null;

          return {
            ...formField,
            defaultValue: fieldCustomization.defaultValue,
            inputConfig: {
              ...formField?.inputConfig,
              disabled: fieldCustomization.disabled ?? false,
              visible: fieldCustomization.visible ?? true,
            },
          };
        })
        .filter((f) => f);
    }

    return formFields;
  }, [fieldsCustomization, formFields]);

  return (
    <>
      {fields.map((formField) => {
        return (
          <Controller
            control={control}
            key={`${datasetName}.${formField.name}`}
            name={`${datasetName}.${formField.name}`}
            rules={{ required: formField?.inputConfig?.required }}
            defaultValue={formField.defaultValue ?? ''}
            render={({ field: { value, onChange } }) => {
              const isVisible =
                typeof formField.inputConfig?.visible === 'boolean' && formField.inputConfig?.visible;

              if (
                !attributeToCurrentUser &&
                formField.name === 'id' &&
                isUserDataset &&
                operation === 'create'
              ) {
                return <UserRelationInput value={value} name={formField.name} />;
              }

              if (!isVisible) {
                return null;
              }

              const fieldError = get(errors, [datasetName, formField.name]);
              const relationalField = formField.name.endsWith('RelationId');

              if (relationalField) {
                const relationalDatasetName = formField.name.replace('RelationId', '');
                const referenceField = datasetReferenceFields[relationalDatasetName];

                return (
                  <ChakraFormControl isInvalid={fieldError}>
                    <DatasetRelationInput
                      value={value}
                      datasets={datasets}
                      datasetName={capitalize(relationalDatasetName)}
                      indexFieldName={referenceField?.indexFieldName}
                      executeApiOperation={executeApiOperation}
                      onChange={onChange}
                    />
                    {!isEmpty(fieldError) && (
                      <ChakraText fontSize="pt-sm" color="pt-danger.500" marginTop="pt-xs">
                        Required
                      </ChakraText>
                    )}
                  </ChakraFormControl>
                );
              }

              const currentField = isThemeMode
                ? exampleParentDatasetFieldsReference[formField.name]
                : datasetReferenceFields[formField.name];

              return (
                <ChakraFormControl id={`${datasetName}.${formField.name}`} isInvalid={fieldError}>
                  <DynamicCompilerInput
                    inheritedData={inheritedData}
                    name={formField.name}
                    value={value}
                    label={showDatasetFormatted(formField.name)}
                    options={formField.options}
                    isInvalid={!isEmpty(fieldError)}
                    isDisabled={
                      formField.inputConfig?.disabled ||
                      (isUserDataset && userDatasetMappings[formField.name])
                    }
                    fieldType={
                      (currentField?.controlType &&
                        (capitalize(currentField?.controlType?.toLowerCase()) as PropType)) ||
                      (formField.type as PropType)
                    }
                  />
                  {!isEmpty(fieldError) && (
                    <ChakraText fontFamily="pt-body" fontSize="pt-sm" color="pt-danger.500" marginTop="pt-xs">
                      Required
                    </ChakraText>
                  )}
                </ChakraFormControl>
              );
            }}
          />
        );
      })}
    </>
  );
};
