import { ThemeMultipartComponent } from '@types';

export type PieChartSizes = 'sm' | 'md' | 'lg';
export type PieChartVariants = 'default';
export type PieChartParts = 'chart-slices' | 'chart-labels';

export const PTPieChart: ThemeMultipartComponent<PieChartSizes, PieChartVariants, PieChartParts> = {
  parts: ['chart-slices', 'chart-labels'],

  baseStyle: {
    'chart-slices': {
      background: 'pt-primary.500',
      color: 'pt-white',
    },
    'chart-labels': {
      fontWeight: 'pt-normal',
      fontSize: 'pt-sm',
      color: 'pt-primary.500',
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: 'pt-primary',
  },
};
