import React, { FC, useEffect, useState } from 'react';

import { FormControl as ChakraFormControl, Text as ChakraText, useMultiStyleConfig } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { ImageUpload } from '@application/components/common';

import { uploadFileToS3 } from '@services';

import { defaultProps } from './protocol';
import { ImageUploadProps, ImageUploadTriggers } from './index';

export const component: FC<ComponentReceivedProps<ImageUploadProps, ImageUploadTriggers>> = ({
  props = defaultProps,
  triggers,
}) => {
  const { label, size, value, ...rest } = props;
  const { onChange } = triggers;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(value);

  const baseInputStyles = useMultiStyleConfig('PTBaseInput', { size });

  useEffect(() => {
    setSelectedImage(value);
  }, [value]);

  const handleFileInput = async (selectedFile: File) => {
    setIsLoading(true);

    const uploadResponse = await uploadFileToS3(selectedFile);

    setIsLoading(false);
    setSelectedImage(uploadResponse);

    onChange && onChange(uploadResponse);
  };

  const handleFileRemove = () => {
    setSelectedImage(null);
    onChange && onChange(null);
  };

  return (
    <ChakraFormControl>
      {label && <ChakraText sx={baseInputStyles['base-input-label'] || {}}>{label}</ChakraText>}
      <ImageUpload
        {...rest}
        sx={baseInputStyles['base-input-control'] || {}}
        value={selectedImage}
        isLoading={isLoading}
        onInputFile={(file) => handleFileInput(file)}
        onRemoveFile={() => handleFileRemove()}
      />
    </ChakraFormControl>
  );
};
