import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { TextProps } from './index';

export const protocolFactory = new ProtocolFactory<TextProps>({
  name: 'Text',
  editorProps: [
    {
      type: [EPropType.String, EPropType.Float],
      name: 'content',
      label: 'Content',
      required: true,
      description: 'Content of the text',
      defaultValue: 'Foo',
      allowDataLink: true,
    },
  ],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
  themeProps: ['color', 'textStyle', 'isTruncated'],
});

export const defaultProps = protocolFactory.createDefaultProps();
