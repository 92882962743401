import React, { FC } from 'react';

import { AspectRatio } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { AspectRatioBoxProps } from './index';
import { defaultProps } from './protocol';

export const component: FC<ComponentReceivedProps<AspectRatioBoxProps, any>> = ({
  props = defaultProps,
  children,
  style,
}) => {
  const { ratio, ...rest } = props;

  const radioNumberProportion: Record<AspectRatioBoxProps['ratio'], number> = {
    '1/1': 1,
    '4/3': 1.33,
    '16/9': 1.78,
  };

  return (
    <AspectRatio style={style} ratio={radioNumberProportion[ratio]} {...rest}>
      {children}
    </AspectRatio>
  );
};
