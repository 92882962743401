import React, { FC, useContext, useEffect, useState } from 'react';

import { ASTNode } from 'graphql';
import {
  chakra,
  Flex as ChakraFlex,
  Tag as ChakraTag,
  useDisclosure,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { cloneDeep, findIndex, isNil } from 'lodash';
import { DropResult } from 'react-beautiful-dnd';
import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql/language/printer';
import { useMutation } from 'react-query';
import { v4 as uuidV4 } from 'uuid';

import {
  Board,
  BoardColumn,
  BoardColumnButton,
  BoardColumnCounter,
  BoardColumnHeader,
  BoardColumnItem,
  BoardColumnList,
} from '@application/components/common';
import { CompilerContext, EngineContext } from '@application/compiler/contexts';
import { MenuSelect, MenuSelectOption, useToast } from '@application/components/ds';
import { showDatasetFormatted } from '@application/utils/stringManipulation';
import { useIntrospectionFactory } from '@application//lib/customHooks/useIntrospection';

import { ComponentReceivedProps } from '@types';

import { AvailableOperations, handleOperationSubmit } from './operations';
import { BoardCardModal } from './components/BoardCardModal';
import { BoardProps, BoardTriggers, Card, Column } from './index';
import { defaultProps } from './protocol';

const exampleColumns: Column[] = [
  { id: uuidV4(), label: 'Backlog' },
  { id: uuidV4(), label: 'In progress' },
  { id: uuidV4(), label: 'Done' },
];

const exampleData: Card[] = [
  {
    id: uuidV4(),
    title: 'Develop board component',
    description: 'Develop a POC for the board component with static and dynamic configurations.',
    tag: 'Front-end',
    column: null,
  },
  {
    id: uuidV4(),
    title: 'Document project',
    description: 'Document the project specifications for the front-end and back-end development.',
    tag: 'Documentation',
    column: null,
  },
  {
    id: uuidV4(),
    title: 'Organize cycles',
    description: 'Organize futures cycles of the project scope.',
    tag: 'Agile',
    column: null,
  },
  {
    id: uuidV4(),
    title: 'Google integration',
    description: 'Develop the google integration for the project authentication.',
    tag: 'Front-end',
    column: null,
  },
];

export const component: FC<ComponentReceivedProps<BoardProps, BoardTriggers>> = ({
  props = defaultProps,
  inheritedData,
  triggers,
}) => {
  const toast = useToast();

  const { engine } = useContext(EngineContext);
  const { config } = useContext(CompilerContext);

  const { endpoint } = config.api;
  const { user } = inheritedData;
  const { onDoubleClick } = triggers;
  const {
    boardData,
    boardColumns,
    boardIsBlocked,
    boardCardTitleProperty,
    boardCardDescriptionProperty,
    boardCardTagProperty,
    boardCardTagPropertyOptions,
    boardCardColumnIndexProperty,
    boardCardRowIndexProperty,
    boardDataCollection,
    fieldsRelations,
    ...rest
  } = props;

  const [editedCard, setEditedCard] = useState<Card>();
  const [formattedCards, setFormattedCards] = useState<Card[]>([]);

  const [introspectionFactory] = useIntrospectionFactory(endpoint, user?.token);

  const modalDisclosure = useDisclosure();

  const isThemeMode = config.mode === 'theme';

  const getFormattedCards = () =>
    (boardData?.map((item) => ({
      id: (item?.id as string) || (!boardDataCollection && uuidV4()),
      tag: (!isNil(item[boardCardTagProperty]) && item[boardCardTagProperty]) || null,
      title: (!isNil(item[boardCardTitleProperty]) && (item[boardCardTitleProperty] as string)) || null,
      row: (!isNil(item[boardCardRowIndexProperty]) && (item[boardCardRowIndexProperty] as number)) || 0,
      description:
        (!isNil(item[boardCardDescriptionProperty]) && (item[boardCardDescriptionProperty] as string)) ||
        null,
      column:
        (!isNil(item[boardCardColumnIndexProperty]) && (item[boardCardColumnIndexProperty] as string)) ||
        boardColumns[0]?.label,
    })) as Card[]) || [];

  const getColumnCounter = (columnLabel: string) =>
    formattedCards?.filter((card) => card?.column === columnLabel)?.length || 0;

  const getCardsByColumn = (columnLabel: string) => {
    const getFilteredCards = formattedCards?.filter((card) => card?.column === columnLabel);
    const getOrderedCards = getFilteredCards?.sort((a, b) => a.row - b.row);

    return getOrderedCards;
  };

  const {
    'board-container': boardContainerStyles,
    'board-column-container': boardColumnContainerStyles,
    'board-column-header': boardColumnHeaderStyles,
    'board-column-counter': boardColumnCounterStyles,
    'board-column-list': boardColumnListStyles,
    'board-column-item': boardColumnItemStyles,
    'board-column-item-name': boardColumnItemNameStyles,
    'board-column-item-description': boardColumnItemDescriptionStyles,
    'board-column-item-tag': boardColumnItemTagStyles,
    'board-column-button': boardColumnButtonStyles,
  } = useMultiStyleConfig('PTBoard', {});

  async function executeApiOperation(
    operation: ASTNode,
    operationName: string,
    variables: Record<string, unknown>,
  ) {
    const resp = await new GraphQLClient(endpoint, {
      headers: { Authorization: user?.token },
    }).request(print(operation), variables);

    return resp[operationName] ?? {};
  }

  const handleCardInsertion = (columnLabel: string) => {
    const newCard: Card = {
      id: null,
      title: null,
      description: null,
      tag: null,
      row: 0,
      column: columnLabel,
    };

    setEditedCard(newCard);

    modalDisclosure.onOpen();
  };

  const onSubmitCardMutation = useMutation(
    (input: { values: Card; operation: AvailableOperations }) => {
      const datasets = introspectionFactory?.datasets ?? {};

      const formatedValues = {
        id: input.values.id,
        [fieldsRelations.title]: input.values.title,
        [fieldsRelations.description]: input.values.description,
        [fieldsRelations.tag]: input.values.tag,
        [fieldsRelations.column]: input.values.column,
        [fieldsRelations.row]: input.values.row,
      };

      const response = handleOperationSubmit({
        executeApiOperation,
        dataset: datasets[boardDataCollection],
        operation: input.operation,
      })(formatedValues);

      return response;
    },
    {
      onSuccess: () => {
        toast({
          title: 'Card updated!',
          description: `The card was successfully updated.`,
        });

        const refetchOperator = engine
          ?.getOperatorList()
          ?.find(({ operator }) => operator === `refetch_list${boardDataCollection}`);

        if (refetchOperator)
          engine?.runOperation({
            operator: refetchOperator.operator,
            args: {},
          });
      },
      onError: () => {
        setFormattedCards(getFormattedCards());

        toast({
          title: 'Something wrong happened!',
          description: 'Check your fields configuration or your collection, then try again.',
          status: 'danger',
        });
      },
    },
  );

  const handleModalOperation = (card: Card) => {
    const clonedFormattedCards = cloneDeep(formattedCards);

    modalDisclosure.onClose();

    if (card.id) {
      const cardIndex = findIndex(clonedFormattedCards, { id: card.id });

      clonedFormattedCards.splice(cardIndex, 1, card);

      setFormattedCards(clonedFormattedCards);

      onSubmitCardMutation.mutate({
        values: card,
        operation: 'update',
      });
    } else {
      clonedFormattedCards.push(card);

      setFormattedCards(clonedFormattedCards);

      onSubmitCardMutation.mutate({
        values: card,
        operation: 'create',
      });
    }
  };

  const handleDragOperation = (result: DropResult) => {
    const clonedFormattedCards = cloneDeep(formattedCards);

    const foundedCard = formattedCards.find(({ id }) => id === result.draggableId);
    const cardIndex = findIndex(clonedFormattedCards, { id: foundedCard.id });

    if (
      result?.destination?.droppableId !== foundedCard?.column ||
      result?.destination?.index !== foundedCard.row
    ) {
      let clonedCard = cloneDeep(foundedCard);

      clonedCard = {
        ...clonedCard,
        row: result?.destination?.index,
        column: result?.destination?.droppableId,
      };

      clonedFormattedCards.splice(cardIndex, 1, clonedCard);

      setFormattedCards(clonedFormattedCards);

      onSubmitCardMutation.mutate({
        values: clonedCard,
        operation: 'update',
      });
    }
  };

  const getCardOptions: MenuSelectOption[] = [
    {
      label: 'Edit card',
      value: {
        action: (card: Card) => {
          setEditedCard(card);
          modalDisclosure.onOpen();
        },
      },
    },
    {
      label: 'Delete card',
      value: {
        action: (card: Card) => {
          const clonedFormattedCards = cloneDeep(formattedCards);
          const cardIndex = findIndex(clonedFormattedCards, { id: card.id });

          clonedFormattedCards.splice(cardIndex, 1);

          setFormattedCards(clonedFormattedCards);

          onSubmitCardMutation.mutate({
            values: card,
            operation: 'delete',
          });
        },
      },
    },
  ];

  useEffect(
    () => setFormattedCards(getFormattedCards()),
    [boardData, boardCardTitleProperty, boardCardDescriptionProperty, boardCardTagProperty],
  );

  return (
    <>
      <Board sx={boardContainerStyles} onDragEnd={(result) => handleDragOperation(result)} {...rest}>
        {(isThemeMode ? exampleColumns : boardColumns)?.map((column, columnIndex) => (
          <BoardColumn sx={boardColumnContainerStyles} key={columnIndex}>
            <BoardColumnHeader sx={boardColumnHeaderStyles}>
              <chakra.h1>{column.label}</chakra.h1>
              <BoardColumnCounter sx={boardColumnCounterStyles}>
                {getColumnCounter(column.label)}
              </BoardColumnCounter>
            </BoardColumnHeader>
            <BoardColumnList sx={boardColumnListStyles} droppableId={column.label}>
              {(isThemeMode ? exampleData : getCardsByColumn(column.label))?.map(
                (card, cardIndex) =>
                  (card?.column === column.label || (isThemeMode && columnIndex === 0)) && (
                    <BoardColumnItem
                      sx={boardColumnItemStyles}
                      key={card.id}
                      index={cardIndex}
                      draggableId={card.id || cardIndex.toString()}
                      isDragDisabled={
                        !boardDataCollection ||
                        isThemeMode ||
                        !Object.keys(fieldsRelations)?.length ||
                        boardIsBlocked
                      }
                      onDoubleClick={() => onDoubleClick && onDoubleClick({ card })}
                    >
                      <ChakraFlex gridGap="pt-sm">
                        <ChakraFlex width="100%" gridGap="pt-sm" flexDirection="column">
                          <ChakraFlex width="100%" flexDirection="column">
                            <chakra.p noOfLines={2} title={card.title} sx={boardColumnItemNameStyles}>
                              {card.title || 'No title'}
                            </chakra.p>
                            <chakra.p
                              sx={boardColumnItemDescriptionStyles}
                              title={card.description}
                              noOfLines={3}
                            >
                              {card.description || 'No description'}
                            </chakra.p>
                          </ChakraFlex>
                          {card?.tag && (
                            <ChakraFlex gridGap="pt-sm" overflow="hidden">
                              <ChakraTag
                                size="sm"
                                width="fit-content"
                                whiteSpace="nowrap"
                                colorScheme={boardColumnItemTagStyles.colorScheme as string}
                                borderRadius={boardColumnItemTagStyles.borderRadius as string}
                              >
                                {showDatasetFormatted(card.tag)}
                              </ChakraTag>
                            </ChakraFlex>
                          )}
                        </ChakraFlex>
                        {boardDataCollection &&
                          !!Object.keys(fieldsRelations)?.length &&
                          !boardIsBlocked &&
                          !isThemeMode && (
                            <MenuSelect
                              icon="menu-vertical"
                              variant="with-icon"
                              options={getCardOptions}
                              onChange={({ value }) => value.action(card)}
                            />
                          )}
                      </ChakraFlex>
                    </BoardColumnItem>
                  ),
              )}
              {boardDataCollection &&
                !!Object.keys(fieldsRelations)?.length &&
                !boardIsBlocked &&
                !isThemeMode && (
                  <BoardColumnButton
                    sx={boardColumnButtonStyles}
                    onClick={() => handleCardInsertion(column.label)}
                  >
                    + New card
                  </BoardColumnButton>
                )}
            </BoardColumnList>
          </BoardColumn>
        ))}
      </Board>
      {modalDisclosure.isOpen && (
        <BoardCardModal
          card={editedCard}
          tagOptions={boardCardTagPropertyOptions}
          isEditMode={!!editedCard.id}
          onClose={() => modalDisclosure.onClose()}
          onConfirm={(card) => handleModalOperation(card)}
        />
      )}
    </>
  );
};
