export enum EOperationStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum EDatasetFieldType {
  // id = 'ID',
  string = 'STRING',
  number = 'NUMBER',
  float = 'FLOAT',
  boolean = 'BOOLEAN',
  collection = 'COLLECTION',
  // TODO: Collection and dataset are the same thing, please remove the collection one
  dataset = 'DATASET',
}

export enum EControlType {
  date = 'DATE',
  // file = 'FILE',
  list = 'LIST',
  // user = 'USER',
  image = 'IMAGE',
  phone = 'PHONE',
  email = 'EMAIL',
  richText = 'RICHTEXT',
  textArea = 'TEXTAREA',
  geoLocation = 'GEOLOCATION',
}
