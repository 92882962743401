import { ThemeMultipartComponent } from '@types';

export type CalendarSizes = 'sm' | 'md' | 'lg';
export type CalendarVariants = 'default';
export type CalendarParts =
  | 'calendar-container'
  | 'calendar-month-header'
  | 'calendar-month-cell-date'
  | 'calendar-month-cell-date-current'
  | 'calendar-month-event'
  | 'calendar-week-time-cell'
  | 'calendar-week-header'
  | 'calendar-week-header-date'
  | 'calendar-week-header-date-current'
  | 'calendar-week-event'
  | 'calendar-toolbar-title';

export const PTCalendar: ThemeMultipartComponent<CalendarSizes, CalendarVariants, CalendarParts> = {
  parts: [
    'calendar-container',
    'calendar-month-header',
    'calendar-month-cell-date',
    'calendar-month-cell-date-current',
    'calendar-month-event',
    'calendar-week-time-cell',
    'calendar-week-header',
    'calendar-week-header-date',
    'calendar-week-header-date-current',
    'calendar-week-event',
    'calendar-toolbar-title',
  ],

  variants: {
    default: {
      'calendar-container': {
        border: 'pt-sm',
        boxShadow: 'pt-large',
        background: 'pt-white',
        borderColor: 'pt-ui.100',
        borderRadius: 'pt-sm',
      },
      'calendar-month-header': {
        color: 'pt-ui.900',
        padding: 'pt-sm',
        fontSize: 'pt-xs',
        fontFamily: 'pt-body',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        background: 'pt-transparent',
      },
      'calendar-month-cell-date': {
        color: 'pt-ui.700',
        paddingY: 'pt-sm',
        paddingX: 'pt-md',
        fontFamily: 'pt-heading',
      },
      'calendar-month-cell-date-current': {
        color: 'pt-primary.500',
      },
      'calendar-month-event': {
        color: 'pt-primary.400',
        padding: 'pt-sm',
        borderLeft: 'pt-md',
        background: 'pt-primary.50',
        borderColor: 'pt-primary.300',
      },
      'calendar-week-time-cell': {
        height: '100%',
        paddingX: 'pt-md',
        textAlign: 'right',
        fontFamily: 'pt-body',
        fontSize: 'pt-xs',
        color: 'pt-ui.500',
      },
      'calendar-week-header': {
        color: 'pt-ui.500',
        gridGap: 'pt-xs',
        padding: 'pt-sm',
        fontSize: 'pt-xs',
        fontFamily: 'pt-body',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      'calendar-week-header-date': {
        color: 'pt-ui.900',
        boxSize: '2.75rem',
        lineHeight: 'pt-none',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 'pt-lg',
        fontSize: 'pt-2xl',
        fontFamily: 'pt-heading',
      },
      'calendar-week-header-date-current': {
        color: 'pt-primary.300',
        background: 'pt-primary.50',
      },
      'calendar-week-event': {
        width: '100%',
        height: '100%',
        color: 'pt-primary.300',
        padding: 'pt-sm',
        gridGap: 'pt-xs',
        overflow: 'hidden',
        fontSize: 'pt-xs',
        fontFamily: 'pt-body',
        background: 'pt-primary.50',
        borderRadius: 'pt-none',
        borderLeft: 'pt-md',
        borderColor: 'pt-primary.200',
        flexDirection: 'column',
      },
      'calendar-toolbar-title': {
        color: 'pt-ui.900',
        fontSize: '3xl',
        fontFamily: 'heading',
      },
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: null,
  },
};
