import { CompilerConfig } from '@types';
import { createContext } from 'react';

export interface CompilerContextState {
  config: CompilerConfig;
}

export const CompilerContext = createContext<CompilerContextState>({
  config: {
    mode: 'edit',
    router: {
      currentRoute: '/',
      baseDeploymentPath: '/',

      isCustomDomain: false,
      customDomain: undefined,
    },
    auth: {
      domain: 'gstudio.auth0.com',
      clientId: '',
    },
    api: {
      endpoint: '',
    },
    organizationId: '',
    projectId: '',
  },
});
CompilerContext.displayName = 'CompilerContext';

export const CompilerProvider = CompilerContext.Provider;
