import { ASTNode } from 'graphql';
import { filter, find, isEmpty, merge, omit } from 'lodash';

import { toCamelCase } from '@application/utils/stringManipulation';

import { FactoryDataset } from '@introspection/factories/DatasetsOperationsFactory';

import { DynamicFormProps } from '../..';

interface UseDynamicFormArgs {
  dataset: string;
  datasets: Record<string, FactoryDataset>;
  updateId: string;
  operation: DynamicFormProps['operation'];
  initialValues: Record<string, any>;
  executeApiOperation: (
    operation: ASTNode,
    operationName: string,
    variables: Record<string, any>,
  ) => Record<string, any>;
  refetchInitialValues: () => Promise<any>;
}

export const handleSubmit = ({
  dataset,
  datasets,
  updateId,
  operation,
  initialValues,
  executeApiOperation,
  refetchInitialValues,
}: UseDynamicFormArgs): any => {
  return async (datasetsValues: Record<string, any>): Promise<any> => {
    const parentDataset = datasets[dataset];

    const handleCreate = async () => {
      const { create } = parentDataset.operations;

      const parentData = datasetsValues[dataset];

      const parentResp = await executeApiOperation(create.operation, create.operationName, {
        input: parentData,
      });

      const promisesList: any[] = [];

      for (const childDatasetName of parentDataset.childrenDatasets) {
        const datasetValues = datasetsValues[childDatasetName];

        if (isEmpty(datasetValues)) continue;

        const childDataset = datasets[childDatasetName];
        const createOperation = childDataset.operations.create;

        const values = Array.isArray(datasetValues) ? datasetValues : [datasetValues];

        const parentIdField = `${toCamelCase(dataset)}RelationId`;

        for (const value of values) {
          promisesList.push(
            executeApiOperation(createOperation.operation, createOperation.operationName, {
              input: merge(value, { [parentIdField]: parentResp.id }),
            }),
          );
        }
      }

      await Promise.all(promisesList);

      return parentResp;
    };

    const handleUpdate = async () => {
      const { update } = parentDataset.operations;

      const parentData = datasetsValues[dataset];

      const parentResp = await executeApiOperation(update.operation, update.operationName, {
        id: updateId,
        input: omit(parentData, ['id']),
      });

      const promisesList: any[] = [];

      for (const childDatasetName of parentDataset.childrenDatasets) {
        const datasetValues = datasetsValues[childDatasetName];
        const datasetInitialValues = initialValues[childDatasetName];

        if (isEmpty(datasetValues)) continue;

        const childDataset = datasets[childDatasetName];

        const initialValuesArray = Array.isArray(datasetInitialValues)
          ? datasetInitialValues
          : [datasetInitialValues];
        const values = Array.isArray(datasetValues) ? datasetValues : [datasetValues];

        const createOperation = childDataset.operations.create;
        const updateOperation = childDataset.operations.update;
        const deleteOperation = childDataset.operations.delete;

        const updateValues = filter(values, (val) => val.id);
        const newValues = filter(values, (val) => !val.id);
        const deletedValues = filter(initialValuesArray, (oldVal) => !find(values, { id: oldVal?.id }));

        const parentIdField = `${toCamelCase(dataset)}RelationId`;

        for (const newValue of newValues) {
          const input = merge(newValue, { [parentIdField]: updateId });
          promisesList.push(
            executeApiOperation(createOperation.operation, createOperation.operationName, {
              input: omit(input, ['id']),
            }),
          );
        }

        for (const updateValue of updateValues) {
          const input = merge(updateValue, { [parentIdField]: updateId });

          promisesList.push(
            executeApiOperation(updateOperation.operation, updateOperation.operationName, {
              id: updateValue.id,
              input: omit(input, ['id']),
            }),
          );
        }

        for (const deletedValue of deletedValues) {
          promisesList.push(
            executeApiOperation(deleteOperation.operation, deleteOperation.operationName, {
              id: deletedValue.id,
            }),
          );
        }
      }

      await Promise.all(promisesList);

      return parentResp;
    };

    let resp;
    if (operation === 'create') {
      resp = await handleCreate();
    }

    if (operation === 'update') {
      resp = await handleUpdate();
    }

    await refetchInitialValues();

    return resp;
  };
};
