import { PieChartParts } from '@application/lib/defaultTheme';
import { ProtocolFactory } from '@application/lib/protocols';

import { PieChartProps } from '.';

export const protocolFactory = new ProtocolFactory<PieChartProps, PieChartParts>({
  name: 'PieChart',
  editorConfig: {
    disableDesignSection: true,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
  themeConfig: {
    allowMultipart: true,
    defaultProps: [],
    parts: ['chart-slices', 'chart-labels'],
    partsConfig: {
      'chart-slices': {
        baseStyleProps: ['background', 'color'],
      },
      'chart-labels': {
        baseStyleProps: ['fontSize', 'fontWeight', 'color'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
