import { ProtocolFactory } from '@application/lib/protocols';
import { SidebarParts } from '@application/lib/defaultTheme';

import { SidebarProps } from './index';

export const protocolFactory = new ProtocolFactory<SidebarProps, SidebarParts>({
  name: 'Sidebar',
  editorProps: [],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: true,
    disableLogicSection: true,
  },
  themeProps: [],
  themeConfig: {
    allowMultipart: true,
    defaultProps: [],
    parts: [
      'sidebar-container',
      'sidebar-item',
      'sidebar-profile-container',
      'sidebar-profile-name',
      'sidebar-profile-email',
      'sidebar-button',
    ],
    partsConfig: {
      'sidebar-container': {
        variantProps: ['background', 'borderRight', 'borderRightColor'],
      },
      'sidebar-item': {
        pseudos: ['_selected', '_hover'],
        pseudoProps: ['borderLeftColor', 'background', 'color'],
        variantProps: [
          'color',
          'borderLeft',
          'borderLeftColor',
          'paddingVertical',
          'paddingHorizontal',
          'fontFamily',
        ],
      },
      'sidebar-profile-container': {
        variantProps: ['paddingHorizontal', 'paddingVertical', 'borderTop', 'borderTopColor', 'background'],
      },
      'sidebar-profile-name': {
        variantProps: ['fontSize', 'color'],
      },
      'sidebar-profile-email': {
        variantProps: ['fontSize', 'color'],
      },
      'sidebar-button': {
        variantProps: ['padding', 'border', 'borderColor', 'borderRadius', 'background', 'color'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
