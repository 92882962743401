import React, { FC } from 'react';

import { CloseButton as ChakraCloseButton, Flex as ChakraFlex } from '@chakra-ui/react';

import { Icon, Text } from 'components/ds';

export type ToastStatus = 'success' | 'warning' | 'info' | 'danger';

export interface ToastProps {
  title?: string;
  description?: string;
  status?: ToastStatus;
  onClose: () => void;
}

export const Toast: FC<ToastProps> = ({ title, description, status = 'success', onClose }) => {
  const statusColors: Record<ToastStatus & string, string> = {
    danger: 'supportA.600',
    warning: 'supportB.600',
    success: 'supportC.600',
    info: 'supportD.600',
  };

  return (
    <ChakraFlex
      bg="ui.900"
      paddingY="md"
      paddingX="lg"
      borderRadius="sm"
      alignItems="center"
      justifyContent="space-between"
      boxShadow="large"
      width="23rem"
      data-testid={`${status}-toast-container`}
    >
      <Icon name="info-outline" color={statusColors[status]} />
      <ChakraFlex marginX="md" flexDirection="column" justifyContent="center" flex={2}>
        <Text
          color="white"
          variant="body-2"
          data-testid={`${status}-toast-title`}
          isTruncated={true}
          marginBottom={description && 'xs'}
        >
          {title}
        </Text>
        {description && (
          <Text data-testid={`${status}-toast-description`} variant="caption" color="ui.200">
            {description}
          </Text>
        )}
      </ChakraFlex>
      <ChakraCloseButton borderRadius="sm" color="ui.500" onClick={() => onClose()} />
    </ChakraFlex>
  );
};

Toast.displayName = 'Toast';
