import { OptionBlocks } from '@types';

import { BlockBuilder } from '@application/core/block';
import { components } from '@application/components/compiler';

// todo: Improve the any structure
export const blocks: Record<OptionBlocks, any> = {
  accordion: BlockBuilder<typeof components.accordion.block>({
    component: 'accordion',
  }),
  aspectRatio: BlockBuilder<typeof components.aspectRatio.block>({
    component: 'aspectRatio',
  }),
  badge: BlockBuilder<typeof components.badge.block>({
    component: 'badge',
  }),
  barChart: BlockBuilder<typeof components.barChart.block>({
    component: 'barChart',
  }),
  baseInput: BlockBuilder<typeof components.baseInput.block>({
    component: 'baseInput',
  }),
  breadcrumb: BlockBuilder<typeof components.breadcrumb.block>({
    component: 'breadcrumb',
  }),
  button: BlockBuilder<typeof components.button.block>({
    component: 'button',
  }),
  board: BlockBuilder<typeof components.board.block>({
    component: 'board',
  }),
  card: BlockBuilder<typeof components.card.block>({
    component: 'card',
  }),
  calendar: BlockBuilder<typeof components.calendar.block>({
    component: 'calendar',
  }),
  checkbox: BlockBuilder<typeof components.checkbox.block>({
    component: 'checkbox',
  }),
  circularProgress: BlockBuilder<typeof components.circularProgress.block>({
    component: 'circularProgress',
  }),
  container: BlockBuilder<typeof components.container.block>({
    component: 'container',
  }),
  dataContainer: BlockBuilder<typeof components.dataContainer.block>({
    component: 'dataContainer',
  }),
  dateInput: BlockBuilder<typeof components.dateInput.block>({
    component: 'dateInput',
  }),
  developmentEnvironment: BlockBuilder<typeof components.developmentEnvironment.block>({
    component: 'developmentEnvironment',
  }),
  divider: BlockBuilder<typeof components.divider.block>({
    component: 'divider',
  }),
  dynamicForm: BlockBuilder<typeof components.dynamicForm.block>({
    component: 'dynamicForm',
  }),
  grid: BlockBuilder<typeof components.grid.block>({
    component: 'grid',
  }),
  gridItem: BlockBuilder<typeof components.gridItem.block>({
    component: 'gridItem',
  }),
  header: BlockBuilder<typeof components.header.block>({
    component: 'header',
  }),
  image: BlockBuilder<typeof components.image.block>({
    component: 'image',
  }),
  imageGallery: BlockBuilder<typeof components.imageGallery.block>({
    component: 'imageGallery',
  }),
  imageUpload: BlockBuilder<typeof components.imageUpload.block>({
    component: 'imageUpload',
  }),
  input: BlockBuilder<typeof components.input.block>({
    component: 'input',
  }),
  icon: BlockBuilder<typeof components.icon.block>({
    component: 'icon',
  }),
  layoutRouterView: BlockBuilder<typeof components.layoutRouterView.block>({
    component: 'layoutRouterView',
  }),
  list: BlockBuilder<typeof components.list.block>({
    component: 'list',
  }),
  lineChart: BlockBuilder<typeof components.lineChart.block>({
    component: 'lineChart',
  }),
  map: BlockBuilder<typeof components.map.block>({
    component: 'map',
  }),
  menu: BlockBuilder<typeof components.menu.block>({
    component: 'menu',
  }),
  modal: BlockBuilder<typeof components.modal.block>({
    component: 'modal',
  }),
  mutationContainer: BlockBuilder<typeof components.mutationContainer.block>({
    component: 'mutationContainer',
  }),
  page: BlockBuilder<typeof components.page.block>({
    component: 'page',
  }),
  pieChart: BlockBuilder<typeof components.pieChart.block>({
    component: 'pieChart',
  }),
  numberInput: BlockBuilder<typeof components.numberInput.block>({
    component: 'numberInput',
  }),
  progress: BlockBuilder<typeof components.progress.block>({
    component: 'progress',
  }),
  radio: BlockBuilder<typeof components.radio.block>({
    component: 'radio',
  }),
  radioAlternative: BlockBuilder<typeof components.radioAlternative.block>({
    component: 'radioAlternative',
  }),
  radioAlternativeButton: BlockBuilder<typeof components.radioAlternativeButton.block>({
    component: 'radioAlternativeButton',
  }),
  richTextEditor: BlockBuilder<typeof components.richTextEditor.block>({
    component: 'richTextEditor',
  }),
  select: BlockBuilder<typeof components.select.block>({
    component: 'select',
  }),
  sidebar: BlockBuilder<typeof components.sidebar.block>({
    component: 'sidebar',
  }),
  stories: BlockBuilder<typeof components.stories.block>({
    component: 'stories',
  }),
  subForm: BlockBuilder<typeof components.dynamicForm.block>({
    component: 'dynamicForm',
  }),
  table: BlockBuilder<typeof components.table.block>({
    component: 'table',
  }),
  text: BlockBuilder<typeof components.text.block>({
    component: 'text',
  }),
  textarea: BlockBuilder<typeof components.textarea.block>({
    component: 'textarea',
  }),
  title: BlockBuilder<typeof components.title.block>({
    component: 'title',
  }),
  wordCloud: BlockBuilder<typeof components.wordCloud.block>({
    component: 'wordCloud',
  }),
};
