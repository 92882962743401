import { ComponentBlock } from '@types';

import { types } from 'core/types';

import { BaseInputProps } from './index';
import { component } from './component';

export const block: ComponentBlock<'baseInput', BaseInputProps, unknown> = {
  id: 'baseInput',
  render: component,
  props: {
    name: {
      type: types.string,
    },
    label: {
      type: types.string,
    },
    placeholder: {
      type: types.string,
    },
    size: {
      type: types.string,
    },
  },
};
