import { MenuParts } from '@application/lib/defaultTheme';

import { CompilerComponent, IconName } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface MenuOption {
  id: string;
  label: string;
  icon: IconName;
}

export interface MenuProps {
  menuOptions: MenuOption[];
  menuButtonIsIcon: boolean;
  menuButtonIcon: IconName;
  menuButtonPlaceholder: string;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<MenuProps, MenuParts>;
