import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface AspectRatioBoxProps {
  px: number;
  py: number;
  ratio: '1/1' | '4/3' | '16/9';
  borderWidth?: string;
  borderColor?: string;
  borderRadius?: 'sm' | 'md' | 'lg';
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<AspectRatioBoxProps>;
