/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CheckboxProps as ChakraCheckboxProps } from '@chakra-ui/react';

import { ThemeComponent } from '@types';

export type CheckboxSizes = 'sm' | 'md' | 'lg' | string;
export type CheckboxVariants = 'default';

export const Checkbox: ThemeComponent<CheckboxSizes, CheckboxVariants, ChakraCheckboxProps> = {
  // @ts-ignore
  baseStyle: () => ({
    control: {
      borderRadius: 'xs',
      background: 'white',
      _checked: {
        background: 'primary.500',
        borderColor: 'primary.500',
      },
    },
  }),

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: null,
  },
};
