import gql from 'graphql-tag';

export const CreateProject = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      organizationId

      id
      name

      themeId

      pages {
        id
        projectId
        organizationId

        nodesStructure

        path
        isProtected

        createdAt
        updatedAt

        #TTL
        expiresAt
      }

      members

      createdAt
      updatedAt

      #TTL
      expiresAt
    }
  }
`;

export const UpdateProject = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      organizationId
      members

      name

      themeId
      layouts

      theme {
        id
        organizationId

        name
        theme

        customFonts {
          link
          name
        }

        customIcons {
          link
          name
        }

        createdAt
        updatedAt

        #TTL
        expiresAt
      }

      createdAt
      updatedAt

      #TTL
      expiresAt
    }
  }
`;
