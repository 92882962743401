import React, { FC, useContext, useMemo } from 'react';

import {
  Avatar as ChakraAvatar,
  Flex as ChakraFlex,
  Img as ChakraImg,
  Menu as ChakraMenu,
  MenuButton as ChakraMenuButton,
  MenuItem as ChakraMenuItem,
  MenuList as ChakraMenuList,
  useDisclosure,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { useQuery } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';

import { ComponentReceivedProps } from '@types';

import { AuthContext, CompilerContext, EngineContext } from '@application/compiler/contexts';
import { Icon } from '@application/components/ds';

import { defaultProps } from './protocol';
import { HeaderProps, Route } from './index';

import { ApiClient } from '@services';

const exampleHeaderRoutes: Route[] = [
  { id: uuidV4(), path: '/', label: 'Foo' },
  { id: uuidV4(), path: '/bar', label: 'Bar' },
  { id: uuidV4(), path: '/lorem', label: 'Lorem' },
  { id: uuidV4(), path: '/ipsum', label: 'Ipsum' },
];

export const component: FC<ComponentReceivedProps<HeaderProps, unknown>> = ({ props = defaultProps }) => {
  const { engine } = useContext(EngineContext);
  const { config } = useContext(CompilerContext);
  const { user, logout } = useContext(AuthContext);

  const { headerRoutes, headerShowLogo, ...rest } = props;

  const isThemeMode = config?.mode === 'theme';
  const isDeploymentMode = config?.mode === 'production';

  const { isOpen: isDropdownOpen, onToggle: onToggleDropdown } = useDisclosure();

  const router = useRouteMatch();

  const {
    header: headerStyles,
    'header-item': headerItemStyles,
    'header-item-selected': headerItemSelectedStyles,
  } = useMultiStyleConfig('PTHeader', {});
  const { 'menu-list': menuListStyles, 'menu-item': menuItemStyles } = useMultiStyleConfig('PTMenu', {});

  const getDisplayRoutes = useMemo(() => {
    const clonedRoutes = cloneDeep(headerRoutes);
    return clonedRoutes?.splice(0, 3);
  }, [headerRoutes]);

  const handleRouteChange = (path: string) => {
    engine.runOperation({
      operator: 'link',
      args: {
        path,
        route: path,
      },
    });
  };

  const logoQuery = useQuery(
    ['query', 'organization', config.organizationId, 'logo'],
    async () => {
      const resp = await ApiClient.getOrganizationLogo(config.organizationId);
      return resp.data;
    },
    {
      enabled: headerShowLogo,
      refetchOnMount: false,
    },
  );

  return (
    <ChakraFlex width="100%" position="relative" zIndex="50">
      <ChakraFlex sx={headerStyles} {...rest}>
        {useMemo(() => {
          if (headerShowLogo && logoQuery?.data?.logo) {
            return (
              <ChakraFlex minWidth="fit-content" height="100%" alignItems="center">
                <ChakraImg height="2rem" src={logoQuery?.data?.logo} />
              </ChakraFlex>
            );
          }
        }, [headerShowLogo, logoQuery.data])}
        <ChakraFlex
          width="100%"
          height="100%"
          gridGap="pt-sm"
          alignItems="center"
          display={{
            xs: 'none',
            sm: 'none',
            md: 'flex',
          }}
        >
          {getDisplayRoutes?.map(({ label, path }, idx) => (
            <ChakraFlex
              key={idx}
              isTruncated={true}
              sx={{
                ...headerItemStyles,
                ...(router?.path === path && headerItemSelectedStyles),
              }}
              onClick={() => handleRouteChange(path)}
            >
              {label}
            </ChakraFlex>
          ))}
        </ChakraFlex>
        {(config?.auth?.user || user) && (
          <ChakraMenu>
            <ChakraMenuButton>
              <ChakraAvatar
                size="sm"
                cursor="pointer"
                src={config.auth.user?.picture || user.picture}
                display={{
                  xs: 'none',
                  sm: 'node',
                  md: 'flex',
                }}
              />
            </ChakraMenuButton>
            <ChakraMenuList sx={menuListStyles}>
              <ChakraMenuItem
                sx={menuItemStyles}
                onClick={() => !!isDeploymentMode && logout.mutateAsync({})}
              >
                Logout
              </ChakraMenuItem>
            </ChakraMenuList>
          </ChakraMenu>
        )}
        <ChakraFlex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="flex-end"
          display={{
            xs: 'flex',
            sm: 'flex',
            md: 'none',
          }}
        >
          <Icon
            size="lg"
            name="hamburger"
            cursor="pointer"
            transitionDuration="0.3s"
            color={headerItemStyles?.color as string}
            _hover={{
              // @ts-ignore
              color: headerItemStyles?._hover?.color,
            }}
            onClick={() => onToggleDropdown()}
          />
        </ChakraFlex>
      </ChakraFlex>
      {isDropdownOpen ? (
        <ChakraFlex
          top="4rem"
          width="100%"
          padding="pt-xl"
          paddingTop="pt-none"
          gridGap="pt-sm"
          position="absolute"
          flexDirection="column"
          display={{
            sm: 'flex',
            md: 'none',
          }}
          background={(headerStyles?.background || 'pt-white') as string}
        >
          {(isThemeMode ? exampleHeaderRoutes : headerRoutes)?.map(({ label, path }, idx) => (
            <ChakraFlex
              borderRadius="pt-sm"
              paddingY="pt-md"
              _notFirst={{
                marginTop: 'pt-sm',
              }}
              key={idx}
              sx={{
                ...headerItemStyles,
                ...(router?.path === path && headerItemSelectedStyles),
                borderBottom: 'none',
              }}
              onClick={() => handleRouteChange(path)}
            >
              {label}
            </ChakraFlex>
          ))}
          {(config?.auth?.user || user) && (
            <ChakraFlex
              borderRadius="pt-sm"
              paddingY="pt-md"
              _notFirst={{ marginTop: 'pt-sm' }}
              sx={{
                ...headerItemStyles,
                borderBottom: 'none',
              }}
              onClick={() => !!isDeploymentMode && logout.mutateAsync({})}
            >
              Logout
            </ChakraFlex>
          )}
        </ChakraFlex>
      ) : (
        <></>
      )}
    </ChakraFlex>
  );
};
