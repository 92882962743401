import { BadgeProps as ChakraBadgeProps } from '@chakra-ui/react';

import { ThemeComponent } from '@types';

type BadgeSizes = 'sm' | 'md' | 'lg';
type BadgeVariants = 'solid' | 'subtle' | 'outline';

export const PTBadge: ThemeComponent<BadgeSizes, BadgeVariants, ChakraBadgeProps> = {
  baseStyle: {
    textTransform: 'uppercase',
    fontFamily: 'pt-body',
  },

  sizes: {
    sm: {
      fontSize: 'pt-xs',
      lineHeight: 'pt-normal',
      paddingY: 'pt-xs',
      paddingX: 'pt-sm',
    },
    md: {
      fontSize: 'sm',
      lineHeight: 'pt-normal',
      paddingY: 'pt-xs',
      paddingX: 'pt-sm',
    },
    lg: {
      fontSize: 'pt-md',
      lineHeight: 'pt-normal',
      paddingY: 'pt-sm',
      paddingX: 'pt-lg',
    },
  },

  variants: {
    solid: {
      borderRadius: 'pt-xs',
      background: 'pt-primary.500',
      color: 'pt-white',
    },
    subtle: {
      borderRadius: 'pt-xs',
      background: 'pt-ui.100',
      color: 'pt-ui.700',
    },
    outline: {
      borderRadius: 'pt-xs',
      background: 'pt-info.500',
      color: 'pt-white',
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'solid',
    colorScheme: 'pt-primary',
  },
};
