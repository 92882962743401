import { Box, BoxProps } from '@chakra-ui/react';
import { Icon, Text } from 'components/common';
import { IconName } from '@types';
import React, { FC } from 'react';

interface TemplateBoxProps extends BoxProps {
  onSelectedTemplate?: (selectedTemplate: string) => void;
  template: { icon?: IconName; name: string };
  selectedTemplate: string;
}

const TemplateBox: FC<TemplateBoxProps> = ({
  children,
  template,
  onSelectedTemplate,
  selectedTemplate,
  ...props
}) => {
  const borderProps =
    template.name === selectedTemplate
      ? { borderColor: 'primary.500', borderWidth: 'sm' }
      : { borderColor: 'ui.200', borderWidth: 'xs' };

  return (
    <Box
      height="144px"
      width="144px"
      backgroundColor="white"
      borderRadius="md"
      boxShadow="sm"
      cursor="pointer"
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={() => onSelectedTemplate(template.name)}
      transition="all ease 100ms"
      {...props}
      {...borderProps}
    >
      {children}
    </Box>
  );
};

interface Props {
  selectedTemplate: string;
  onChange: (template: string) => void;
  templateList: { icon?: IconName; name: string }[];
}

export const TemplateList: FC<Props> = ({ templateList, selectedTemplate, onChange }) => {
  return (
    <>
      <Box marginRight={4}>
        <TemplateBox
          selectedTemplate={selectedTemplate}
          template={{ name: 'Blank' }}
          onSelectedTemplate={onChange}
        />
        <Text textAlign="center" marginTop={2} color="ui.700">
          Blank
        </Text>
      </Box>

      {templateList.map((templateOption, idx) => (
        <Box marginRight={4} key={idx}>
          <TemplateBox
            selectedTemplate={selectedTemplate}
            template={templateOption}
            onSelectedTemplate={onChange}
          >
            <Icon name={templateOption.icon} w={18} h={18} color="ui.400" />
          </TemplateBox>
          <Text textAlign="center" marginTop={2} color="ui.700">
            {templateOption.name}
          </Text>
        </Box>
      ))}
    </>
  );
};
