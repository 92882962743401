import { apiRequestClient } from './index';
import { CreateStripeCheckoutSessionResponse } from '@api/routes/stripe';

export const newStripeCheckoutSession = (body: Record<'organizationId', string>) => {
  return apiRequestClient()
    .method('post')
    .append('/api/stripe/checkout/sessions')
    .setData(body)
    .build<CreateStripeCheckoutSessionResponse>();
};
