import { CompilerComponent, IconName } from '@types';

import { SidebarParts } from '@application/lib/defaultTheme';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface Route {
  id: string;
  label: string;
  path: string;
  icon: IconName;
}

export interface SidebarProps {
  sidebarRoutes: Route[];
  sidebarHasLogo: boolean;
  sidebarHasProfile: boolean;
  sidebarIsCollapsed: boolean;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<SidebarProps, SidebarParts>;
