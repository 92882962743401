import { ProtocolFactory } from 'lib/protocols';

import { DataContainerProps } from './index';

export const protocolFactory = new ProtocolFactory<DataContainerProps>({
  name: 'DataContainer',
  editorProps: [],
  editorConfig: {
    disableDesignSection: true,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
