import { IconProps as ChakraIconProps } from '@chakra-ui/react';

import { ThemeComponent } from '@types';

export type IconSizes = 'sm' | 'md' | 'lg';
export type IconVariants = 'default';

export const PTIcon: ThemeComponent<IconSizes, IconVariants, ChakraIconProps> = {
  sizes: {
    sm: {
      boxSize: '16px',
    },
    md: {
      boxSize: '20px',
    },
    lg: {
      boxSize: '24px',
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: null,
  },
};
