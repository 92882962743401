import React, { FC, useContext, useState } from 'react';

import {
  chakra,
  Avatar as ChakraAvatar,
  Flex as ChakraFlex,
  Img as ChakraImg,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';

import { ComponentReceivedProps } from '@types';

import { ApiClient } from '@services';

import { AuthContext, CompilerContext, EngineContext } from '@application/compiler/contexts';
import { Icon } from '@application/components/ds';

import { defaultProps } from './protocol';
import { Route, SidebarProps } from './index';

const exampleData: Route[] = [
  {
    id: uuidV4(),
    label: 'Home',
    path: '/home',
    icon: 'home',
  },
  {
    id: uuidV4(),
    label: 'Profile',
    path: '/profile',
    icon: 'datafield-user',
  },
];

export const component: FC<ComponentReceivedProps<SidebarProps, unknown>> = ({ props = defaultProps }) => {
  const { sidebarIsCollapsed, sidebarHasLogo, sidebarHasProfile, sidebarRoutes, ...rest } = props;

  const [isCollapsed, setIsCollapsed] = useState(sidebarIsCollapsed || false);

  const { engine } = useContext(EngineContext);
  const { config } = useContext(CompilerContext);
  const { user } = useContext(AuthContext);

  const isThemeMode = config.mode === 'theme';

  const {
    'sidebar-container': sidebarContainerStyles,
    'sidebar-item': sidebarItemStyles,
    'sidebar-profile-container': sidebarProfileContainerStyles,
    'sidebar-profile-name': sidebarProfileNameStyles,
    'sidebar-profile-email': sidebarProfileEmailStyles,
    'sidebar-button': sidebarButtonStyles,
  } = useMultiStyleConfig('PTSidebar', {});

  const router = useRouteMatch();

  const logoQuery = useQuery(
    ['query', 'organization', config.organizationId, 'logo'],
    async () => {
      const resp = await ApiClient.getOrganizationLogo(config.organizationId);

      return resp.data;
    },
    {
      refetchOnMount: false,
    },
  );

  const handleRouteChange = (path: string) => {
    engine.runOperation({
      operator: 'link',
      args: {
        path,
        route: path,
      },
    });
  };

  return (
    <ChakraFlex width={isCollapsed ? 'fit-content' : '18rem'} sx={sidebarContainerStyles} {...rest}>
      {!isCollapsed && (sidebarHasLogo || isThemeMode) && logoQuery?.data?.logo && (
        <ChakraFlex width="100%" justifyContent="center" alignItems="center" padding="xl">
          <ChakraImg height="2.5rem" src={logoQuery?.data?.logo} />
        </ChakraFlex>
      )}
      <ChakraFlex height="100%" flexDirection="column">
        <ChakraFlex width="100%" height="100%" flexDirection="column">
          {(isThemeMode ? exampleData : sidebarRoutes)?.map((route, index) => (
            <ChakraFlex
              sx={sidebarItemStyles}
              key={index}
              isTruncated={true}
              aria-selected={isThemeMode && index === 0 ? true : router?.path === route?.path}
              justifyContent={isCollapsed && 'center'}
              onClick={() => !isThemeMode && handleRouteChange(route?.path)}
            >
              <Icon name={route.icon} />
              {!isCollapsed && <chakra.p isTruncated={true}>{route.label}</chakra.p>}
            </ChakraFlex>
          ))}
        </ChakraFlex>
        <ChakraFlex minHeight="6rem" position="relative">
          {sidebarHasProfile && (config?.auth?.user || user) && (
            <ChakraFlex
              width="100%"
              height="100%"
              alignItems="center"
              gridGap="sm"
              isTruncated={true}
              sx={sidebarProfileContainerStyles}
            >
              <ChakraAvatar size="sm" src={config.auth.user?.picture || user.picture} />
              {!isCollapsed && (
                <ChakraFlex flexDirection="column" isTruncated={true}>
                  <chakra.p isTruncated={true} sx={sidebarProfileNameStyles}>
                    {config.auth.user?.name || user.name}
                  </chakra.p>
                  <chakra.p isTruncated={true} sx={sidebarProfileEmailStyles}>
                    {config.auth.user?.email || user.email}
                  </chakra.p>
                </ChakraFlex>
              )}
            </ChakraFlex>
          )}
          <ChakraFlex sx={sidebarButtonStyles} onClick={() => setIsCollapsed(!isCollapsed)}>
            <Icon size="md" name={isCollapsed ? 'chevron-right' : 'chevron-left'} />
          </ChakraFlex>
        </ChakraFlex>
      </ChakraFlex>
    </ChakraFlex>
  );
};
