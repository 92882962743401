import { ModalParts } from '@application/lib/defaultTheme';
import { ProtocolFactory } from '@application/lib/protocols';

import { EPropType } from '@types';

import { ModalProps } from './index';

export const protocolFactory = new ProtocolFactory<ModalProps, ModalParts>({
  name: 'Modal',
  editorProps: [
    {
      name: 'modalHiddenFooter',
      label: 'Hide footer',
      description: 'The hidden visibility of the modal footer',
      defaultValue: false,
      type: EPropType.Boolean,
      required: false,
    },
    {
      name: 'modalTriggerLabel',
      label: 'Modal trigger label',
      description: 'The label of the modal trigger',
      defaultValue: 'Open modal',
      type: EPropType.String,
      required: false,
    },
    {
      name: 'modalTitle',
      label: 'Modal title',
      description: 'The title of the modal',
      defaultValue: 'Modal title',
      type: EPropType.String,
      required: false,
    },
    {
      name: 'modalSubmitLabel',
      label: 'Modal submit label',
      description: 'The submit button label of the modal',
      defaultValue: 'Confirm',
      type: EPropType.String,
      required: false,
    },
    {
      name: 'modalCancelLabel',
      label: 'Modal cancel label',
      description: 'The cancel button label of the modal',
      defaultValue: 'Cancel',
      type: EPropType.String,
      required: false,
    },
  ],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: true,
    disableLogicSection: false,
  },
  themeProps: [],
  themeConfig: {
    allowMultipart: true,
    defaultProps: [],
    parts: ['modal', 'modal-header', 'modal-header-title', 'modal-body', 'modal-footer'],
    partsConfig: {
      modal: {
        variantProps: ['border', 'borderColor', 'borderRadius'],
      },
      'modal-header': {
        variantProps: ['borderBottom', 'borderColor', 'background', 'paddingVertical', 'paddingHorizontal'],
      },
      'modal-header-title': {
        variantProps: ['color', 'fontSize', 'fontWeight', 'fontFamily'],
      },
      'modal-body': {
        variantProps: ['background', 'paddingVertical', 'paddingHorizontal'],
      },
      'modal-footer': {
        variantProps: ['borderTop', 'borderColor', 'background', 'paddingVertical', 'paddingHorizontal'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
