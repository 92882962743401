import { ComponentBlock } from '@types';

import { component } from './component';

export const block: ComponentBlock<'layoutRouterView', {}, {}> = {
  id: 'layoutRouterView',
  render: component,
  props: {},
  triggers: {
    onClick: {
      name: 'On click',
      description: 'When the button is clicked',
    },
  },
};
