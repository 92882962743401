import { ProtocolFactory } from '@application/lib/protocols';
import { StoriesParts } from '@application/lib/defaultTheme';

import { StoriesProps } from './index';

export const protocolFactory = new ProtocolFactory<StoriesProps, StoriesParts>({
  name: 'Stories',
  editorProps: [
    {
      name: 'storiesHasLoop',
      label: 'Loop stories',
      description: 'Restart the stories after the end',
      type: 'Boolean',
      required: false,
      defaultValue: true,
    },
  ],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
  themeProps: ['width', 'height'],
  themeConfig: {
    allowMultipart: true,
    allowColorScheme: false,
    defaultProps: [],
    parts: ['stories-container', 'stories-text', 'stories-see-more'],
    partsConfig: {
      'stories-container': {
        baseStyleProps: ['boxShadow', 'borderRadius', 'border', 'borderColor'],
      },
      'stories-text': {
        baseStyleProps: ['textStyle', 'textAlign', 'color', 'padding', 'justifyContent', 'alignItems'],
      },
      'stories-see-more': {
        baseStyleProps: [
          'paddingHorizontal',
          'paddingVertical',
          'color',
          'background',
          'justifyContent',
          'alignItems',
          'fontSize',
        ],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
