/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ThemeMultipartComponent } from '@types';

export type MultiToggleSizes = string;
export type MultiToggleVariants = 'default';

type MultiToggleParts = 'multi-toggle' | 'multi-toggle-item';

export const MultiToggle: ThemeMultipartComponent<MultiToggleSizes, MultiToggleVariants, MultiToggleParts> = {
  parts: ['multi-toggle', 'multi-toggle-item'],

  baseStyle: () => ({
    'multi-toggle': {
      width: 'fit-content',
      height: '2rem',
      border: 'sm',
      borderColor: 'ui.100',
      borderRadius: 'xs',
      bg: 'ui.100',
    },
    'multi-toggle-item': {
      width: '3rem',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      transitionDuration: '0.3s',
      borderRadius: 'xs',
    },
  }),

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: null,
  },
};
