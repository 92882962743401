import { ComponentBlock } from '@types';

import { types } from 'core/types';

import { component } from './component';
import { GridProps } from '.';

export const block: ComponentBlock<'grid', GridProps, unknown> = {
  id: 'grid',
  render: component,
  props: {
    width: {
      type: types.string,
    },
    height: {
      type: types.string,
    },
    templateColumns: {
      type: types.number,
    },
    templateRows: {
      type: types.number,
    },
  },
};
