import { ASTNode } from 'graphql';
import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql/language/printer';

const adminClient = (token: string) =>
  new GraphQLClient(process.env.URL_ADMIN, {
    headers: { Authorization: token },
  });

export const queryAdmin =
  (query: ASTNode, variables?: Record<string, any>) =>
  (token: string): Promise<any> => {
    return adminClient(token).request(print(query), variables);
  };
