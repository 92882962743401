import {
  Box,
  Drawer as ChakraDrawer,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
  Flex,
} from '@chakra-ui/react';
import { Text } from 'components/common';
import React, { FC } from 'react';

interface Props extends DrawerProps {
  title: string;
}

export const Drawer: FC<Props> = ({ title, children, isOpen, onClose, ...props }) => {
  return (
    <ChakraDrawer isOpen={isOpen} placement="right" onClose={onClose} {...props}>
      <DrawerOverlay>
        <DrawerContent>
          <Box bg="ui.100" display="flex" padding={2} flexDir="column">
            <Flex
              bg="white"
              borderRadius="sm"
              padding={3}
              justifyContent="space-between"
              alignItems="center"
              boxShadow="sm"
            >
              <Text color="primary.500" fontSize="md">
                {title}
              </Text>
              <Text color="ui.700" fontSize="xs">
                Properties
              </Text>
            </Flex>
          </Box>
          <Box bg="ui.100" display="flex" px={2} flexDir="column" height="100vh">
            {children}
          </Box>
        </DrawerContent>
      </DrawerOverlay>
    </ChakraDrawer>
  );
};
