import { Layout, Node, Page, Theme } from '@types';

export enum EComposerMode {
  Page = 'Page',
  Layout = 'Layout',
}

export interface ComposerState {
  root: Node;
  mode: EComposerMode;

  actions: {
    prev: ComposerAction[];
    next: ComposerAction[];
    error?: ComposerError;
  };

  pageId?: string;
  layoutId?: string;

  pages: ComposerPage[];
  layouts: ComposerLayout[];

  theme?: Theme;
}

type ComposerActionPayload =
  | AddComposerActionPayload
  | DeleteComposerActionPayload
  | DuplicateComposerActionPayload
  | EditComposerActionPayload
  | MoveComposerAction;
export interface ComposerAction<T = ComposerActionPayload> {
  id: string;

  type: ComposerActionTypes;
  payload: T & ComposerActionPayload;

  // userId: string;
  // createdAt: string;
  // applyed: boolean;
}

export interface ComposerLayout extends Omit<Layout, 'nodesStructure'> {
  nodesStructure: Node;
}

export interface ComposerPage extends Omit<Page, 'nodesStructure'> {
  nodesStructure: Node;
}

export enum ComposerErrorCode {
  ROOT_NODE_NOT_FOUND = 'ROOT_NODE_NOT_FOUND',
  PARENT_NODE_NOT_FOUND = 'PARENT_NODE_NOT_FOUND',
  NODE_NOT_FOUND = 'NODE_NOT_FOUND',
  NODE_NOT_DELETABLE = 'NODE_NOT_DELETABLE',
  NODE_NOT_EDITABLE = 'NODE_NOT_EDITABLE',
  UNIQUE_NODE = 'UNIQUE_NODE',
  NODE_NOT_CHILDREN = 'NODE_NOT_CHILDREN',
}

export interface ComposerError {
  code: ComposerErrorCode;
  message: string;
}

// Composer actions
interface BaseActionPayload {
  // It reffers to the pageId or the layoutId
  id?: string;
  mode?: EComposerMode;

  targetNodeId: string;
  node: Node;
}
type ComposerActionTypes =
  | 'add'
  | 'delete'
  | 'edit'
  | 'duplicate'
  | 'moveUp'
  | 'moveDown'
  | 'moveIn'
  | 'moveOut';

export interface AddComposerActionPayload extends BaseActionPayload {
  node: Node;
}

export type DeleteComposerActionPayload = BaseActionPayload;

export interface DuplicateComposerActionPayload extends BaseActionPayload {
  parentId: string;

  newNode?: Node;
}

export interface EditComposerActionPayload extends BaseActionPayload {
  node: Node;
  oldNode?: Node;
}

export interface MoveComposerAction extends Omit<BaseActionPayload, 'node'> {
  prevParentId?: string;
}
