import { cloneDeep, isNil, merge, mergeWith } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Block, Node } from '@types';
import { BlockFactory } from '@application/lib/core';
import { reservedEvents } from './events';

export const isNode = (t: Node | Block): t is Node => 'block' in t;

export function node(block?: Block): Node {
  block = block === undefined ? BlockFactory.initializeBlock('container') : block;

  const createBlockEventIds = (events: Block['events']): Block['events'] => {
    const eventsWithId = cloneDeep(events);

    for (const eventName in events) {
      let id = uuidv4();

      if (reservedEvents.includes(eventName)) {
        id = eventName;
      }
      eventsWithId[eventName] = {
        ...events[eventName],
        id,
      };
    }

    return eventsWithId;
  };

  return {
    id: uuidv4(),
    block: {
      ...block,
      events: createBlockEventIds(block.events),
    },
    children: [],
    availableDynamicProperties: [],
    availableEvents: [],
  };
}

export function add(source: Node | Block, target: Node | Block): Node {
  const parent: Node = isNode(source) ? source : node(source);
  const child: Node = isNode(target) ? target : node(target);

  return {
    ...parent,
    children: [...parent.children, child],
  };
}

export function deleteChildByIndex(source: Node | Block, index: number): Node {
  const parent: Node = isNode(source) ? source : node(source);

  const newChildren = parent.children.filter((el, elementIndex) => index !== elementIndex);

  return {
    ...parent,
    children: newChildren,
  };
}

export function deleteChildById(source: Node | Block, id: string): Node {
  const parent: Node = isNode(source) ? source : node(source);

  const newChildren = parent.children.filter((el) => el.id !== id);

  return {
    ...parent,
    children: newChildren,
  };
}

export function findChildById(source: Node | Block, id: string): Node {
  const parent: Node = isNode(source) ? source : node(source);

  return parent.children.find((child) => child.id === id);
}

export function findChildByIndex(source: Node | Block, index: number): Node {
  const parent: Node = isNode(source) ? source : node(source);

  return parent.children.find((child, elementIndex) => elementIndex === index);
}

export function editNode(source: Node, newNode: Node): Node {
  const sourceNode: Node = isNode(source) ? source : node(source);

  const customizedMerger = (objVal: any, srcVal: any, key: string) => {
    if (key === 'operation') return srcVal;

    // Events queue merge
    if (key === 'renderCondition') {
      if (Array.isArray(srcVal)) {
        return srcVal;
      }
    }

    if (!isNil(srcVal)) {
      return srcVal;
    }
  };

  const newMergedNode = merge(newNode, { id: sourceNode.id });

  return mergeWith(sourceNode, newMergedNode, customizedMerger);
}

export function duplicateNode(source: Node): Node {
  const sourceNode: Node = isNode(source) ? source : node(source);

  return merge(cloneDeep(sourceNode), { id: uuidv4() });
}
