import React, { FC, useContext } from 'react';

import { Progress as ChakraProgress, useMultiStyleConfig } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { CompilerContext } from '@application/compiler/contexts';

import { defaultProps } from './protocol';
import { ProgressProps } from './index';

export const component: FC<ComponentReceivedProps<ProgressProps, unknown>> = ({ props = defaultProps }) => {
  const { colorScheme, value, property, size, list, ...rest } = props;

  const { config } = useContext(CompilerContext);

  const themeStyles = useMultiStyleConfig('Progress', { size, colorScheme });

  const maxSize = list ? list.length : 100;
  const currentValue = property && list ? list?.filter((item) => !!item[property])?.length : value;

  return (
    <ChakraProgress
      width="100%"
      sx={themeStyles}
      max={maxSize}
      value={config?.mode === 'theme' ? 50 : currentValue}
      {...rest}
    />
  );
};
