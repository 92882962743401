import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { ImageProps } from './index';

export const protocolFactory = new ProtocolFactory<ImageProps>({
  name: 'Image',
  editorProps: [
    {
      type: EPropType.String,
      name: 'url',
      required: true,
      label: 'Url',
      description: 'Location of the image',
      defaultValue: 'https://live.staticflickr.com/4561/38054606355_26429c884f_b.jpg',
      allowDataLink: true,
    },
  ],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
  themeProps: ['width', 'height', 'boxShadow', 'borderRadius', 'border', 'borderColor'],
});

export const defaultProps = protocolFactory.createDefaultProps();
