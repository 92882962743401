import React, { FC, useContext, useMemo } from 'react';

import {
  Flex as ChakraFlex,
  Heading as ChakraHeading,
  Img as ChakraImg,
  Text as ChakraText,
  useMultiStyleConfig,
  useToken,
} from '@chakra-ui/react';
import { Story } from 'react-insta-stories/dist/interfaces';
import ReactInstaStories, { WithSeeMore } from 'react-insta-stories';

import { ComponentReceivedProps } from '@types';

import { CompilerContext } from '@application/compiler/contexts';
import { Icon } from '@application/components/ds';

import { defaultProps } from './protocol';
import { StoriesProps } from './index';

const exampleStoriesConfig: Omit<StoriesProps, 'width' | 'height'> = {
  storiesHasLoop: true,
  storiesTextProperty: 'storyText',
  storiesLinkProperty: 'storyLink',
  storiesSourceProperty: 'storySource',
  storiesData: [
    {
      storyText: 'Magnam, ab perferendis neque esse asperiores eius, delectus laborum nam harum dolor.',
      storyLink: 'https://docs.gstudio.app/',
      storySource:
        'https://images.unsplash.com/photo-1493612276216-ee3925520721?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80',
    },
    {
      storyText: 'Magnam, ab perferendis neque esse asperiores eius, delectus laborum nam harum dolor.',
      storyLink: 'https://docs.gstudio.app/',
      storySource:
        'https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
    },
  ],
};

export const component: FC<ComponentReceivedProps<StoriesProps, unknown>> = ({ props = defaultProps }) => {
  const { config } = useContext(CompilerContext);
  const {
    'stories-container': containerStyles,
    'stories-text': textStyles,
    'stories-see-more': seeMoreStyles,
  } = useMultiStyleConfig('PTStories', {});

  const [containerBorder] = useToken('borders', [containerStyles?.border as string]);
  const [containerBorderRadius] = useToken('radii', [containerStyles?.borderRadius as string]);
  const [containerBorderColor] = useToken('colors', [containerStyles?.borderColor as string]);
  const [containerBoxShadow] = useToken('shadows', [containerStyles?.boxShadow as string]);

  const {
    width = '360px',
    height = '100%',
    storiesData,
    storiesHasLoop,
    storiesLinkProperty,
    storiesSourceProperty,
    storiesTextProperty,
  } = props;

  const isThemeMode = config.mode === 'theme';

  const getComponentConfig = isThemeMode
    ? exampleStoriesConfig
    : {
        storiesData,
        storiesHasLoop,
        storiesLinkProperty,
        storiesSourceProperty,
        storiesTextProperty,
      };

  if (
    (!storiesData ||
      storiesData?.length === 0 ||
      !storiesLinkProperty ||
      !storiesSourceProperty ||
      !storiesTextProperty) &&
    !isThemeMode
  )
    return (
      <ChakraFlex width="100%" height="100%">
        <ChakraFlex
          width="100%"
          height="100%"
          border="pt-sm"
          gridGap="pt-sm"
          background="pt-ui.50"
          alignItems="center"
          borderStyle="dashed"
          borderColor="pt-info.200"
          borderRadius="pt-xs"
          flexDirection="column"
          justifyContent="center"
        >
          <ChakraHeading color="pt-info.500" fontSize="pt-xl">
            {storiesData?.length === 0 ? 'Empty data' : 'Missing configuration'}
          </ChakraHeading>
          {storiesData?.length === 0 ? (
            <ChakraText maxWidth="20rem" color="pt-ui.500" textAlign="center">
              The data provided for the component is empty. Create more registers for the collection and try
              again.
            </ChakraText>
          ) : (
            <ChakraText maxWidth="20rem" color="pt-ui.500" textAlign="center">
              For the propertly work of the chart you need to define a{' '}
              <ChakraText as="span" color="pt-info.500">
                data
              </ChakraText>
              ,{' '}
              <ChakraText as="span" color="pt-info.500">
                source
              </ChakraText>
              ,{' '}
              <ChakraText as="span" color="pt-info.500">
                text
              </ChakraText>{' '}
              and{' '}
              <ChakraText as="span" color="pt-info.500">
                link
              </ChakraText>
              .
            </ChakraText>
          )}
        </ChakraFlex>
      </ChakraFlex>
    );

  const getFormattedStories = useMemo<Story[]>(() => {
    const { storiesData, storiesLinkProperty, storiesSourceProperty, storiesTextProperty } =
      getComponentConfig;

    return storiesData?.map((story) => ({
      content: ({ action }) => (
        <WithSeeMore
          action={action}
          story={{
            seeMore: story[storiesLinkProperty] ? () => !!story[storiesLinkProperty] : null,
            seeMoreCollapsed: ({ action }) => (
              <ChakraFlex
                zIndex="10"
                sx={seeMoreStyles}
                onClick={() => {
                  action('pause');
                  window.open(story[storiesLinkProperty] as string, '_blank');
                }}
              >
                <Icon name="chevron-up" />
                <ChakraText>See more</ChakraText>
              </ChakraFlex>
            ),
          }}
        >
          <ChakraFlex
            width="100%"
            height="100%"
            position="relative"
            paddingTop="pt-lg"
            flexDirection="column"
          >
            {!!story[storiesSourceProperty] && (
              <ChakraFlex
                top="0"
                left="0"
                width="100%"
                height="100%"
                overflow="hidden"
                position="absolute"
                justifyContent="center"
                alignItems="center"
              >
                <ChakraImg
                  width="auto"
                  height="auto"
                  objectFit="cover"
                  borderRadius={containerBorderRadius}
                  src={story[storiesSourceProperty] as string}
                />
              </ChakraFlex>
            )}
            {!!story[storiesTextProperty] && (
              <ChakraFlex zIndex="10" height="100%" sx={textStyles}>
                {story[storiesTextProperty]}
              </ChakraFlex>
            )}
          </ChakraFlex>
        </WithSeeMore>
      ),
    }));
  }, [getComponentConfig, textStyles, width, height]);

  return (
    <ReactInstaStories
      width="100%"
      height={height}
      keyboardNavigation={true}
      loop={getComponentConfig.storiesHasLoop}
      stories={getFormattedStories}
      storyContainerStyles={{
        height,
        zIndex: '1',
        maxWidth: width,
        maxHeight: height,
        overflow: 'hidden',
        border: containerBorder,
        borderColor: containerBorderColor,
        borderRadius: containerBorderRadius,
        boxShadow: containerBoxShadow,
      }}
    />
  );
};
