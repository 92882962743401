import { format } from 'date-fns';

export const formatDate = (date: string) => {
  if (date === '') {
    return '';
  }
  return format(new Date(date), 'd MMMM y p');
};

export const checkTypeAndFormatDate = (value: any) => {
  if (!value) {
    return '';
  }
  if (typeof value !== 'string') {
    return value;
  }
  const isDate: boolean =
    value.replace(
      /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)/,
      'true',
    ) === 'true';
  if (isDate) {
    return formatDate(value);
  } else {
    return value;
  }
};
