import React, { FC, useMemo } from 'react';

import { useStyleConfig } from '@chakra-ui/system';

import { ComponentReceivedProps } from '@types';

import { Icon } from '@application/components/ds';

import { defaultProps } from './protocol';
import { IconProps } from './index';

export const component: FC<ComponentReceivedProps<IconProps, unknown>> = ({ props = defaultProps }) => {
  const { icon, size, ...rest } = props;

  const themeStyles = useStyleConfig('PTIcon', { size });

  const isCustomIcon = useMemo(() => icon?.includes('https://'), [icon]);

  return <Icon name={icon} src={isCustomIcon && icon} size={size} sx={themeStyles} {...rest} />;
};
