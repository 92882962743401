import { EPropType, ThemeOverride, ThemeProtocolItem } from '@types';
import { replaceThemePrefix } from 'lib/defaultTheme/helpers';

export type TypographyKeys =
  | 'fontWeight'
  | 'fontSize'
  | 'fontFamily'
  | 'lineHeight'
  | 'letterSpacing'
  | 'isTruncated'
  | 'textStyle'
  | 'textAlign';

type Typography = ThemeProtocolItem<TypographyKeys>;

export const typography: Typography = {
  fontSize: {
    type: EPropType.String,
    name: 'fontSize',
    required: false,
    label: 'Font size',
    description: 'Size of your font',
    defaultValue: 'md',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.fontSizes ?? {}).map((fontSize) => ({
        label: replaceThemePrefix(fontSize),
        value: fontSize,
      })),
  },
  fontWeight: {
    type: EPropType.String,
    name: 'fontWeight',
    required: false,
    label: 'Font weight',
    description: 'Define the thinness of the font',
    defaultValue: 500,
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.fontWeights ?? {}).map((fontWeight) => ({
        label: replaceThemePrefix(fontWeight),
        value: fontWeight,
      })),
  },
  lineHeight: {
    type: EPropType.String,
    name: 'lineHeight',
    required: false,
    label: 'Line height',
    description: 'Height of each line',
    defaultValue: 'normal',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.lineHeights ?? {}).map((lineHeight) => ({
        label: replaceThemePrefix(lineHeight),
        value: lineHeight,
      })),
  },
  fontFamily: {
    type: EPropType.String,
    name: 'fontFamily',
    label: 'Font family',
    description: 'Font family',
    required: false,
    defaultValue: '',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.fonts ?? {}).map((font) => ({
        label: replaceThemePrefix(font),
        value: font,
      })),
  },
  letterSpacing: {
    type: EPropType.String,
    name: 'letterSpacing',
    label: 'Letter spacing',
    description: 'Space between the letters ',
    required: false,
    defaultValue: 'normal',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.letterSpacings ?? {}).map((letterSpacing) => ({
        label: replaceThemePrefix(letterSpacing),
        value: letterSpacing,
      })),
  },
  isTruncated: {
    type: EPropType.Boolean,
    name: 'isTruncated',
    label: 'Truncated',
    description: 'A ellipsis will appear instead of breaking the text to fit',
    required: false,
    defaultValue: false,
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },
  textAlign: {
    type: EPropType.String,
    name: 'textAlign',
    label: 'Text align',
    description: 'Alignment of the text',
    required: false,
    defaultValue: 'left',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    options: ['left', 'center', 'right'],
  },
  textStyle: {
    type: EPropType.String,
    name: 'textStyle',
    label: 'Text style',
    description: 'Style of the text',
    required: false,
    defaultValue: '',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride, component: string) => {
      if (component === 'Text') {
        return Object.keys(theme?.textStyles || {})
          .filter((textStyle) => !textStyle.includes('title'))
          .map((textStyle) => ({
            label: replaceThemePrefix(textStyle),
            value: textStyle,
          }));
      } else if (component === 'Title') {
        return Object.keys(theme?.textStyles || {})
          .filter((textStyle) => textStyle.includes('title'))
          .map((textStyle) => ({
            label: replaceThemePrefix(textStyle),
            value: textStyle,
          }));
      }

      return Object.keys(theme?.textStyles || {}).map((textStyle) => ({
        label: replaceThemePrefix(textStyle),
        value: textStyle,
      }));
    },
  },
};
