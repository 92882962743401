import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { ProgressParts, ProgressProps } from './index';

export const protocolFactory = new ProtocolFactory<ProgressProps, ProgressParts>({
  name: 'Progress',
  editorProps: [
    {
      type: EPropType.Int,
      name: 'value',
      label: 'Value',
      required: true,
      description: 'The value of the progress',
      defaultValue: 0,
    },
  ],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
  themeProps: ['size', 'colorScheme'],
  themeConfig: {
    allowMultipart: true,
    allowColorScheme: true,
    parts: ['track', 'filledTrack'],
    sizes: ['sm', 'md', 'lg'],
    defaultProps: ['size', 'colorScheme'],
    partsConfig: {
      track: {
        variantProps: ['borderRadius', 'background'],
      },
      filledTrack: {
        variantProps: ['background'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
