import { CompilerComponent } from '@types';

import { LineChartParts } from '@application/lib/defaultTheme';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface LineChartProps {
  chartCategory: string;
  chartCategoryLabel: string;
  chartValue: string;
  chartValueLabel: string;
  chartData: {
    [key: string]: string | number;
  }[];
}

export default {
  component,
  block,
  defaultProps,
  protocolFactory,
} as CompilerComponent<LineChartProps, LineChartParts>;
