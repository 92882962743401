import gql from 'graphql-tag';

export const ListProjects = gql`
  query ListProjects($organizationId: ID!, $limit: Int, $nextToken: String) {
    listProjects(organizationId: $organizationId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        organizationId

        themeId

        members

        createdAt
        updatedAt

        expiresAt
      }
    }
  }
`;

export const GetProject = gql`
  query GetProject($organizationId: ID!, $id: ID!) {
    getProject(organizationId: $organizationId, id: $id) {
      id
      organizationId
      members

      name
      themeId
      layouts

      domain
      isVerifiedDomain
      domainVerifiedAt

      pages {
        id

        projectId
        organizationId

        name
        path
        role

        isProtected

        nodesStructure

        createdAt
        updatedAt
      }

      theme {
        id
        organizationId

        name
        theme

        customFonts {
          link
          name
        }

        customIcons {
          link
          name
        }

        createdAt
        updatedAt
      }

      createdAt
      updatedAt

      expiresAt
    }
  }
`;
