import { MapParts } from '@application/lib/defaultTheme';
import { ProtocolFactory } from '@application/lib/protocols';

import { MapProps } from '.';

export const protocolFactory = new ProtocolFactory<MapProps, MapParts>({
  name: 'Map',
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
  themeProps: ['width', 'height'],
  themeConfig: {
    allowMultipart: true,
    defaultProps: [],
    parts: ['map-container'],
    partsConfig: {
      'map-container': {
        baseStyleProps: ['border', 'borderColor', 'borderRadius', 'boxShadow'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
