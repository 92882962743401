import { CompilerComponent } from '@types';

import { WordCloudParts } from '@application/lib/defaultTheme';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface WordCloudProps {
  wordCloudData: string[];
  wordCloudTextProperty: string;
}

export default {
  component,
  block,
  defaultProps,
  protocolFactory,
} as CompilerComponent<WordCloudProps, WordCloudParts>;
