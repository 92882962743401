import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { RadioProps } from './index';

export const protocolFactory = new ProtocolFactory<RadioProps>({
  name: 'Radio',
  editorProps: [
    {
      type: EPropType.String,
      name: 'label',
      required: true,
      label: 'Label',
      description: 'Text at side of the radio',
      defaultValue: 'Radio',
    },
    {
      type: EPropType.String,
      name: 'size',
      required: true,
      label: 'Size',
      description: 'Size of the radio',
      defaultValue: 'md',
      values: ['sm', 'md', 'lg'],
    },
  ],
});

export const defaultProps = protocolFactory.createDefaultProps();
