import { AWSInput, Page } from '@types';

import { queryAdmin } from '../adminApi';

import { CreatePage, DeletePage, UpdatePage } from './mutations';

export const createPage = (
  variables: AWSInput<
    Pick<Page, 'projectId' | 'organizationId' | 'name' | 'path' | 'role' | 'isProtected' | 'nodesStructure'>
  >,
  token: string,
): Promise<{ createPage: Page }> => {
  return queryAdmin(CreatePage, variables)(token);
};

export const deletePage = (
  variables: AWSInput<Pick<Page, 'id' | 'projectId' | 'organizationId'>>,
  token: string,
): Promise<{ deletePage: Page }> => {
  return queryAdmin(DeletePage, variables)(token);
};

export const updatePage = (
  variables: AWSInput<
    Pick<
      Page,
      'id' | 'projectId' | 'organizationId' | 'isProtected' | 'name' | 'path' | 'role' | 'nodesStructure'
    >
  >,
  token: string,
): Promise<{ updatePage: Page }> => {
  return queryAdmin(UpdatePage, variables)(token);
};
