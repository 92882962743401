import { ThemeOverride } from '@types';

import {
  borders,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  space,
  textStyles,
} from './foundations';

import {
  PTAccordion as Accordion,
  PTCheckbox as Checkbox,
  PTProgress as Progress,
  PTBadge,
  PTBarChart,
  PTBaseInput,
  PTBoard,
  PTBreadcrumb,
  PTButton,
  PTCalendar,
  PTCard,
  PTCircularProgress,
  PTDynamicForm,
  PTHeader,
  PTIcon,
  PTImageUpload,
  PTLineChart,
  PTMap,
  PTMenu,
  PTModal,
  PTPieChart,
  PTRichTextEditor,
  PTSidebar,
  PTStories,
  PTWordCloud,
  PTTable as Table,
} from './components';

export * from './components';

export const defaultTheme: ThemeOverride = {
  borders,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  space,
  textStyles,

  components: {
    Accordion,
    PTBarChart,
    PTBadge,
    PTBaseInput,
    PTButton,
    PTBoard,
    PTBreadcrumb,
    PTCard,
    PTCalendar,
    PTCircularProgress,
    Checkbox,
    PTHeader,
    PTIcon,
    PTImageUpload,
    PTLineChart,
    PTMap,
    PTMenu,
    PTModal,
    PTDynamicForm,
    Table,
    Progress,
    PTPieChart,
    PTRichTextEditor,
    PTSidebar,
    PTStories,
    PTWordCloud,
  },

  styles: {
    global: {
      'html, body, #__next': {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        scrollBehavior: 'smooth',
      },
      '.image-gallery': {
        width: '100%',
        height: '100%',
      },
      '.image-gallery-image': {
        width: '100%',
        height: '100%',
      },
    },
  },
};
