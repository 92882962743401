import React, { FC } from 'react';

import { Flex as ChakraFlex, FlexProps as ChakraFlexProps, useToken } from '@chakra-ui/react';
import { Draggable, DraggableProps } from 'react-beautiful-dnd';

export type BoardColumnItemProps = Omit<DraggableProps, 'children'> & ChakraFlexProps;

export const BoardColumnItem: FC<BoardColumnItemProps> = ({
  index,
  draggableId,
  isDragDisabled,
  children,
  ...rest
}) => {
  const [primary200] = useToken('colors', ['primary.200']);
  const [shadowLarge] = useToken('shadows', ['large']);

  return (
    <Draggable index={index} draggableId={draggableId} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <ChakraFlex
          width="100%"
          border="sm"
          padding="md"
          gridGap="sm"
          boxShadow="large"
          background="white"
          borderColor="ui.200"
          borderRadius="sm"
          flexDirection="column"
          _active={{
            outline: 'unset',
            borderColor: 'primary.300',
            boxShadow: `0px 0px 0px 3px ${primary200}, ${shadowLarge}`,
          }}
          ref={provided.innerRef}
          snapshot={snapshot}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          {...rest}
        >
          {children}
        </ChakraFlex>
      )}
    </Draggable>
  );
};
