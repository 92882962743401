import React, { FC, useEffect, useMemo, useState } from 'react';

import {
  FormControl as ChakraFormControl,
  Select as ChakraSelect,
  Text as ChakraText,
  forwardRef,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { showDatasetFormatted } from '@application/utils/stringManipulation';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { SelectOption, SelectProps, SelectTriggers } from './index';

export const component: FC<ComponentReceivedProps<SelectProps, SelectTriggers>> = forwardRef(
  ({ props = defaultProps, triggers }, ref) => {
    const { label, size, options, value, ...rest } = props;
    const { onBlur } = triggers;

    const [state, setState] = useState(value);

    useEffect(() => {
      setState(value);
    }, [value]);

    const baseInputStyles = useMultiStyleConfig('PTBaseInput', { size });

    const getFormatedOptions = useMemo<SelectOption[]>(
      () =>
        options?.map((option) => {
          if (typeof option === 'string') {
            return {
              label: showDatasetFormatted(option),
              value: option,
            };
          }

          return option;
        }),
      [options],
    );

    return (
      <ChakraFormControl>
        {label && <ChakraText sx={baseInputStyles['base-input-label'] || {}}>{label}</ChakraText>}
        <ChakraSelect
          {...rest}
          sx={baseInputStyles['base-input-control'] || {}}
          ref={ref}
          value={state}
          onBlur={({ target }) => onBlur && onBlur(target.value)}
          onChange={({ target }) => setState(target.value)}
        >
          {getFormatedOptions?.map((option, idx) => (
            <option value={option?.value} key={idx}>
              {option?.label}
            </option>
          ))}
        </ChakraSelect>
      </ChakraFormControl>
    );
  },
);
