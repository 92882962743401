import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export type CheckboxParts = 'label' | 'control';

export interface CheckboxProps {
  label: string;
  value: boolean;
  colorScheme: string;
}

export interface CheckboxTriggers {
  onChange: (value: boolean) => void;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<CheckboxProps, CheckboxParts>;
