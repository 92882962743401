import React, { FC, useContext, useMemo } from 'react';

import {
  Flex as ChakraFlex,
  Heading as ChakraHeading,
  Text as ChakraText,
  useMultiStyleConfig,
  useToken,
} from '@chakra-ui/react';
import { ResponsiveBar } from '@nivo/bar';

import { ComponentReceivedProps } from '@types';

import { CompilerContext } from '@application/compiler/contexts';

import { BarChartProps } from '.';
import { defaultProps } from './protocol';

const exampleData = [
  { category: 'Coffee', value: 100 },
  { category: 'Rice', value: 50 },
];

export const component: FC<ComponentReceivedProps<BarChartProps, unknown>> = ({ props = defaultProps }) => {
  const { chartData, chartCategory, chartValue, chartCategoryLabel, chartValueLabel } = props;

  const { config } = useContext(CompilerContext);
  const { 'chart-series': seriesStyle, 'chart-axis': axisStyle } = useMultiStyleConfig('PTBarChart', {});

  const [seriesBackground, seriesFontColor, axisFontColor] = useToken('colors', [
    seriesStyle.background as string,
    seriesStyle.color as string,
    axisStyle.color as string,
  ]);

  const [axisFontSize, seriesFontSize] = useToken('fontSizes', [
    axisStyle.fontSize as string,
    seriesStyle.fontSize as string,
  ]);

  const [axisFontWeight, seriesFontWeight] = useToken('fontWeights', [
    axisStyle.fontWeight as string,
    seriesStyle.fontWeight as string,
  ]);

  if ((!chartData || !chartCategory || !chartValue) && config.mode !== 'theme')
    return (
      <ChakraFlex width="100%" height="100%">
        <ChakraFlex
          width="100%"
          height="100%"
          border="pt-sm"
          gridGap="pt-sm"
          background="pt-ui.50"
          alignItems="center"
          borderStyle="dashed"
          borderColor="pt-info.200"
          borderRadius="pt-xs"
          flexDirection="column"
          justifyContent="center"
        >
          <ChakraHeading color="pt-info.500" fontSize="pt-xl">
            Missing configuration
          </ChakraHeading>
          <ChakraText maxWidth="20rem" color="pt-ui.500" textAlign="center">
            For the propertly work of the chart you need to define a{' '}
            <ChakraText as="span" color="pt-info.500">
              data
            </ChakraText>
            ,{' '}
            <ChakraText as="span" color="pt-info.500">
              category
            </ChakraText>{' '}
            and{' '}
            <ChakraText as="span" color="pt-info.500">
              value
            </ChakraText>
            .
          </ChakraText>
        </ChakraFlex>
      </ChakraFlex>
    );

  const isThemeMode = useMemo(() => config.mode === 'theme', [config.mode]);

  const getFormatedData = useMemo(() => {
    if (!isThemeMode) {
      const items = [];

      const formatedChartData = chartData?.map(
        (item) =>
          item[chartCategory] &&
          item[chartValue] && {
            category: item[chartCategory],
            value: item[chartValue] as number,
          },
      );

      formatedChartData.reduce((previous, current) => {
        if (!previous[current?.category]) {
          previous[current?.category] = {
            category: current?.category,
            value: 0,
          };

          items.push(previous[current?.category]);
        }

        previous[current?.category].value += current?.value;

        return previous;
      }, {});

      return items;
    }
  }, [chartData]);

  return (
    <ResponsiveBar
      indexBy="category"
      keys={['value']}
      data={isThemeMode ? exampleData : getFormatedData}
      colors={seriesBackground}
      margin={{
        top: 60,
        bottom: 60,
        left: 70,
        right: 70,
      }}
      theme={{
        labels: {
          text: {
            fontSize: seriesFontSize,
            fontWeight: seriesFontWeight,
            fill: seriesFontColor,
          },
        },
        axis: {
          ticks: {
            text: {
              fontSize: axisFontSize,
              fontWeight: axisFontWeight,
              fill: axisFontColor,
            },
          },
          legend: {
            text: {
              fontSize: axisFontSize,
              fontWeight: axisFontWeight,
              fill: axisFontColor,
            },
          },
        },
      }}
      axisBottom={{
        legend: chartCategoryLabel || chartCategory || 'Product',
        legendPosition: 'middle',
        legendOffset: 42,
      }}
      axisLeft={{
        legend: chartValueLabel || chartValue || 'Total',
        legendPosition: 'middle',
        legendOffset: -52,
      }}
    />
  );
};
