import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface GridItemProps {
  colStart: number;
  colEnd: number;
  rowStart: number;
  rowEnd: number;
}

export default {
  component,
  block,
  defaultProps,
  protocolFactory,
} as CompilerComponent<GridItemProps>;
