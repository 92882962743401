import { BoardParts } from '@application/lib/defaultTheme';
import { ProtocolFactory } from '@application/lib/protocols';

import { BoardProps } from './index';

export const protocolFactory = new ProtocolFactory<BoardProps, BoardParts>({
  name: 'Board',
  editorProps: [],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: false,
  },
  themeProps: ['width', 'height'],
  themeConfig: {
    allowMultipart: true,
    defaultProps: [],
    parts: [
      'board-container',
      'board-column-container',
      'board-column-header',
      'board-column-counter',
      'board-column-button',
      'board-column-list',
      'board-column-item',
      'board-column-item-name',
      'board-column-item-description',
      'board-column-item-tag',
    ],
    partsConfig: {
      'board-container': {
        variantProps: ['border', 'borderColor', 'background', 'borderRadius', 'padding', 'boxShadow'],
      },
      'board-column-container': {
        variantProps: ['border', 'borderColor', 'background', 'borderRadius'],
      },
      'board-column-header': {
        variantProps: ['fontSize', 'fontWeight', 'fontFamily', 'padding', 'background', 'color'],
      },
      'board-column-counter': {
        variantProps: ['fontWeight', 'borderRadius', 'background', 'color'],
      },
      'board-column-list': {
        variantProps: ['padding', 'background'],
      },
      'board-column-item': {
        variantProps: ['border', 'borderRadius', 'borderColor', 'background', 'padding', 'boxShadow'],
      },
      'board-column-item-name': {
        variantProps: ['color', 'fontSize', 'fontWeight', 'fontFamily'],
      },
      'board-column-item-description': {
        variantProps: ['color', 'fontSize', 'fontWeight', 'fontFamily'],
      },
      'board-column-item-tag': {
        variantProps: ['colorScheme', 'borderRadius'],
      },
      'board-column-button': {
        pseudos: ['_hover'],
        pseudoProps: ['color', 'background'],
        variantProps: [
          'paddingVertical',
          'paddingHorizontal',
          'border',
          'borderColor',
          'borderRadius',
          'color',
          'background',
          'fontWeight',
          'fontSize',
        ],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
