import gql from 'graphql-tag';

export const GetOrganization = gql`
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      accountId

      clientId
      connection
      logo

      ownerUser
      customerId

      members {
        name
        email
        picture

        role
        userId
      }

      createdAt
      updatedAt

      expiresAt
    }
  }
`;

export const ListOrganizations = gql`
  query listOrganizations($limit: Int, $nextToken: String) {
    listOrganizations(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        accountId

        clientId
        connection
        logo

        ownerUser

        createdAt
        updatedAt

        expiresAt
      }
    }
  }
`;
