import React, { Children, cloneElement, FC, isValidElement, useContext } from 'react';

import { Box, Spinner } from '@chakra-ui/react';
import { GraphQLClient } from 'graphql-request/dist';
import { print } from 'graphql/language/printer';
import { useMutation } from 'react-query';

import { ComponentReceivedProps } from '@types';

import { CompilerContext } from '@application/compiler/contexts';
import { Freemium, Text } from '@application/components/common';
import { useIntrospectionByOperationName } from '@application/lib/customHooks/useIntrospection';

import { defaultProps } from './protocol';
import { MutationContainerProps, MutationContainerTriggers } from './index';

export const component: FC<ComponentReceivedProps<MutationContainerProps, MutationContainerTriggers>> = ({
  props = defaultProps,
  children,
  inheritedData,
  node,
  triggers,
  style,
}) => {
  const { config } = useContext(CompilerContext);

  const { operationName } = props;
  const { onFailure, onSuccess } = triggers;
  const { endpoint } = config.api;
  const { user } = inheritedData;

  if (config && !config.accountId) {
    return <Freemium isFreemium={true} />;
  }

  if (!operationName)
    return (
      <Box
        minH="100px"
        border="1px solid"
        display="flex"
        alignItems="center"
        borderColor="pt-red.300"
        justifyContent="center"
      >
        <Text fontWeight="pt-bold" color="pt-red.300">
          Operation was not defined
        </Text>
      </Box>
    );

  const [mutationOperation, loadingMutation] = useIntrospectionByOperationName(
    endpoint,
    user?.token,
    operationName,
  );

  const mutation = useMutation(
    (args: any) => {
      return new GraphQLClient(endpoint, {
        headers: { Authorization: user?.token },
      }).request(print(mutationOperation.operation), args);
    },
    {
      onSuccess(data) {
        if (onSuccess) {
          onSuccess({
            ...inheritedData,
            $resp: data?.[operationName] || {},
          });
        }
      },
      onError(err) {
        if (onFailure) {
          onFailure({
            ...inheritedData,
            $error: err,
          });
        }
      },
    },
  );

  if (loadingMutation || mutation.isLoading || !user) {
    return (
      <Box minHeight="100px" display="flex" justifyContent="center" alignItems="center">
        <Spinner thickness="4px" speed="0.65s" emptyColor="pt-gray.200" color="pt-purple.500" size="xl" />
      </Box>
    );
  }

  if (mutation.isError) {
    return (
      <Box
        minH="100px"
        display="flex"
        border="1px solid"
        alignItems="center"
        borderColor="pt-red.300"
        justifyContent="center"
      >
        <pre>{JSON.stringify(mutation.error)}</pre>
      </Box>
    );
  }

  return (
    <Box style={style}>
      {Children.map(children, (child) => {
        if (!isValidElement(child)) return <></>;

        const newEvents: any = {};

        if (mutation) {
          const mutationEventId: string = node.block.events.mutation.id;
          newEvents[mutationEventId] = mutation.mutate;

          return cloneElement(child, {
            // events: merge(newEvents, inheritedEvents),
          });
        }

        return child;
      })}
    </Box>
  );
};
