import { cloneDeep, find, get } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleRedoNodeTreeAction, handleUndoNodeTreeAction } from './helpers/index';
import { v4 as uuidV4 } from 'uuid';

import {
  AddComposerActionPayload,
  ComposerLayout,
  ComposerPage,
  ComposerState,
  DeleteComposerActionPayload,
  DuplicateComposerActionPayload,
  EComposerMode,
  EditComposerActionPayload,
  Layout,
  MoveComposerAction,
  Node,
  Page,
} from '@types';

import { BlockFactory } from '@application/lib/core';
import { createNodeTreeFromLayout, createNodeTreeFromPage } from '@application/lib/nodeTree/nodeTreeAdapter';
import { node } from '@application/core/node';

import { handleNodeTreeAction } from './helpers';

export const defaultInitialState: ComposerState = {
  root: node(BlockFactory.initializeBlock('container')),
  mode: EComposerMode.Page,

  actions: {
    prev: [],
    next: [],
    error: null,
  },

  pageId: '',
  layoutId: '',

  pages: [],
  layouts: [],

  theme: null,
};

// Called to handle the update of the root node after an operation
const updateRootNode = (state: ComposerState): ComposerState => {
  if (state.mode === EComposerMode.Page) {
    const page = find(state.pages, { id: state.pageId });

    const newRootNode = createNodeTreeFromPage(page);

    state.root = newRootNode;
  }

  if (state.mode === EComposerMode.Layout) {
    const layout = find(state.layouts, { id: state.layoutId });

    // @ts-ignore
    state.root = createNodeTreeFromLayout(layout);
  }

  return null;
};

export const composerReducerInitializer = (initialState = defaultInitialState) =>
  createSlice({
    name: 'composer',
    initialState: initialState,
    reducers: {
      setComposerMode: {
        reducer(state, action: PayloadAction<{ mode: EComposerMode }>) {
          const { mode } = action.payload;
          state.mode = mode;

          state.actions.prev = [];
          state.actions.next = [];

          if (state.layouts?.length) {
            state.layoutId = state.layouts[0].id;
          }

          updateRootNode(state);

          return state;
        },
        prepare(mode: EComposerMode) {
          return { payload: { mode } };
        },
      },

      clearError: (state) => {
        state.actions.error = null;
      },
      undoAction: (state) => {
        const newState = handleUndoNodeTreeAction(state);

        return newState;
      },
      redoAction: (state) => {
        const newState = handleRedoNodeTreeAction(state);

        return newState;
      },

      addNodeElementToParent: {
        reducer(state, action: PayloadAction<{ targetNodeId: string; node: Node }>) {
          const { targetNodeId, node } = action.payload;

          const composerActionPayload: AddComposerActionPayload = {
            targetNodeId,
            node,
          };

          const newState = handleNodeTreeAction(state, {
            id: uuidV4(),
            type: 'add',
            payload: composerActionPayload,
          });

          return newState;
        },
        prepare(targetNodeId: string, node: Node) {
          return { payload: { targetNodeId, node } };
        },
      },
      deleteNodeElementById: {
        reducer(state, action: PayloadAction<{ targetNodeId: string; node: Node }>) {
          const { targetNodeId, node } = action.payload;

          const composerActionPayload: DeleteComposerActionPayload = {
            targetNodeId,
            node,
          };

          const newState = handleNodeTreeAction(state, {
            id: uuidV4(),
            type: 'delete',
            payload: composerActionPayload,
          });

          return newState;
        },
        prepare(targetNodeId: string, node: Node) {
          return { payload: { targetNodeId, node } };
        },
      },

      editNodeById: {
        reducer(state, action: PayloadAction<{ targetNodeId: string; node: Node }>) {
          const { targetNodeId, node } = action.payload;

          const composerActionPayload: EditComposerActionPayload = {
            targetNodeId,
            node,
          };

          const newState = handleNodeTreeAction(state, {
            id: uuidV4(),
            type: 'edit',
            payload: composerActionPayload,
          });

          return newState;
        },
        prepare(targetNodeId: string, node: Node) {
          return { payload: { targetNodeId, node } };
        },
      },
      duplicateNodeElement: {
        reducer(state, action: PayloadAction<{ parentId: string; node: Node }>) {
          const { parentId, node } = action.payload;

          const composerActionPayload: DuplicateComposerActionPayload = {
            parentId,
            targetNodeId: node.id,
            node,
          };

          const newState = handleNodeTreeAction(state, {
            id: uuidV4(),
            type: 'duplicate',
            payload: composerActionPayload,
          });

          return newState;
        },
        prepare(parentId: string, node: Node) {
          return { payload: { parentId, node } };
        },
      },

      moveUpNode: {
        reducer(state, action: PayloadAction<{ targetNodeId: string }>) {
          const { targetNodeId } = action.payload;

          const composerActionPayload: MoveComposerAction = {
            targetNodeId,
          };

          const newState = handleNodeTreeAction(state, {
            id: uuidV4(),
            type: 'moveUp',
            payload: composerActionPayload,
          });

          return newState;
        },
        prepare(targetNodeId: string) {
          return { payload: { targetNodeId } };
        },
      },
      moveDownNode: {
        reducer(state, action: PayloadAction<{ targetNodeId: string }>) {
          const { targetNodeId } = action.payload;

          const composerActionPayload: MoveComposerAction = {
            targetNodeId,
          };

          const newState = handleNodeTreeAction(state, {
            id: uuidV4(),
            type: 'moveDown',
            payload: composerActionPayload,
          });

          return newState;
        },
        prepare(targetNodeId: string) {
          return { payload: { targetNodeId } };
        },
      },

      moveInNode: {
        reducer(state, action: PayloadAction<{ targetNodeId: string }>) {
          const { targetNodeId } = action.payload;

          const composerActionPayload: MoveComposerAction = {
            targetNodeId,
          };

          const newState = handleNodeTreeAction(state, {
            id: uuidV4(),
            type: 'moveIn',
            payload: composerActionPayload,
          });

          return newState;
        },
        prepare(targetNodeId: string) {
          return { payload: { targetNodeId } };
        },
      },
      moveOutNode: {
        reducer(state, action: PayloadAction<{ targetNodeId: string }>) {
          const { targetNodeId } = action.payload;

          const composerActionPayload: MoveComposerAction = {
            targetNodeId,
          };

          const newState = handleNodeTreeAction(state, {
            id: uuidV4(),
            type: 'moveOut',
            payload: composerActionPayload,
          });

          return newState;
        },
        prepare(targetNodeId: string) {
          return { payload: { targetNodeId } };
        },
      },

      // Page
      setPageId: {
        reducer(state, action: PayloadAction<{ pageId: string }>) {
          const { pageId } = action.payload;

          state.actions.prev = [];
          state.actions.next = [];

          state.pageId = pageId;
          updateRootNode(state);

          return state;
        },
        prepare(pageId: string) {
          return { payload: { pageId } };
        },
      },
      setPages: {
        reducer(state, action: PayloadAction<{ pages: Page[] }>) {
          const { pages } = action.payload;

          state.actions.prev = [];
          state.actions.next = [];

          state.pages = pages.map((page) => ({ ...page, nodesStructure: JSON.parse(page.nodesStructure) }));
          updateRootNode(state);

          return state;
        },
        prepare(pages: Page[]) {
          return { payload: { pages } };
        },
      },

      addPage: {
        reducer(state, action: PayloadAction<{ page: Page }>) {
          const { page } = action.payload;

          const pages = state.pages ?? [];
          pages.push({
            ...page,
            nodesStructure: JSON.parse(page.nodesStructure),
          });

          state.pageId = page.id;

          state.actions.prev = [];
          state.actions.next = [];

          updateRootNode(state);

          return state;
        },
        prepare(page: Page) {
          return { payload: { page } };
        },
      },
      updatePage: {
        reducer(state, action: PayloadAction<{ page: ComposerPage }>) {
          const { page } = action.payload;

          if (state.mode !== EComposerMode.Page || !page.id || !page.path || !page.nodesStructure) {
            return state;
          }

          const pageIndex = state.pages.findIndex((p) => p.id === page.id);

          if (pageIndex < 0) {
            return state;
          }

          const pages = cloneDeep(state.pages);

          if (typeof page.nodesStructure === 'string') {
            page.nodesStructure = JSON.parse(page.nodesStructure);
          }

          pages[pageIndex] = page;

          state.pages = pages;

          return state;
        },
        prepare(page: ComposerPage) {
          return { payload: { page } };
        },
      },
      deletePage: {
        reducer(state, action: PayloadAction<{ id: string }>) {
          const { id } = action.payload;

          const pageIndex = state.pages.findIndex((page) => page.id === id);

          if (pageIndex < 0) {
            return state;
          }

          state.pages.splice(pageIndex, 1);

          const otherPages = state.pages.filter((page) => page.id !== id);
          state.pageId = otherPages[0]?.id ?? null;

          updateRootNode(state);

          state.actions.prev = [];
          state.actions.next = [];

          return state;
        },
        prepare({ id }: { id: string }) {
          return { payload: { id } };
        },
      },

      // LayoutAndPageShared
      setLayoutId: {
        reducer(state, action: PayloadAction<{ layoutId: string }>) {
          const { layoutId } = action.payload;

          state.layoutId = layoutId;

          return state;
        },
        prepare(pageId: string, layoutId: string) {
          return { payload: { pageId, layoutId } };
        },
      },

      setPagesAndLayouts: {
        reducer(state, action: PayloadAction<{ pages: Page[]; layouts: Layout[] }>) {
          const { layouts = [], pages = [] } = action.payload;

          state.layouts = layouts?.map((layout) => ({
            ...layout,
            nodesStructure: createNodeTreeFromLayout(layout),
          }));

          state.layoutId = get(layouts, [0, 'id'], null);

          state.pages = pages.map((page) => ({
            ...page,
            nodesStructure: createNodeTreeFromPage(page),
          }));

          updateRootNode(state);

          return state;
        },
        prepare(pages: Page[], layouts: Layout[]) {
          return { payload: { pages, layouts } };
        },
      },

      addLayout: {
        reducer(state, action: PayloadAction<{ layout: ComposerLayout }>) {
          const { layout } = action.payload;

          const layouts = state.layouts ?? [];
          layouts.push(layout);

          state.layoutId = layout.id;
          updateRootNode(state);

          return state;
        },
        prepare(layout: ComposerLayout) {
          return { payload: { layout } };
        },
      },
    },
  });

const composer = composerReducerInitializer();

export const {
  setComposerMode,
  setPagesAndLayouts,

  // Undo / redo
  undoAction,
  redoAction,
  clearError,

  // Root node actions
  addNodeElementToParent,
  deleteNodeElementById,
  editNodeById,
  duplicateNodeElement,

  // Node reordering
  moveUpNode,
  moveDownNode,
  moveInNode,
  moveOutNode,

  // Pages
  setPages,
  setPageId,
  addPage,
  updatePage,
  deletePage,

  // Layouts
  setLayoutId,
  addLayout,
} = composer.actions;

export default composer.reducer;
