import { gql } from '@apollo/client';

export const GetDataset = gql`
  query getDataset($accountId: ID!, $id: ID!) {
    dataset(accountId: $accountId, id: $id) {
      id
      accountId

      name
      description
      public

      fields
      fatherDataset
      childrenDatasets

      generatedSchema
      userId

      createDate
      updateDate

      hasControlledId

      fields {
        name
        fieldNullable
        type
        datasetId
        controlType
        helpText
        enumValues
      }

      childrenDatasets {
        items {
          id
          accountId
          description
          userId
          fatherDataset
          name
          fields {
            name
            fieldNullable
            type
            datasetId
            controlType
            helpText
            enumValues
            datasetId
          }
        }
      }
    }
  }
`;

export const ListDatasets = gql`
  query GetDatasets($accountId: ID!, $filter: TableDatasetFilterInput, $limit: Int, $nextToken: String) {
    datasets(accountId: $accountId, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        public
        name
        userId
        createDate
        updateDate
        generatedSchema
        description
        fatherDataset

        hasControlledId

        fields {
          name
          fieldNullable
          type
          datasetId
          controlType
          helpText
          enumValues
        }

        childrenDatasets {
          items {
            id
            accountId
            description
            userId
            fatherDataset
            name
            fields {
              name
              fieldNullable
              type
              datasetId
              controlType
              helpText
              enumValues
              datasetId
            }
          }
        }
      }
      nextToken
    }
  }
`;
