import React, { FC, useMemo } from 'react';

import { Avatar as ChakraAvatar, Flex as ChakraFlex } from '@chakra-ui/react';
import { destroyCookie, setCookie } from 'nookies';
import { signOut, useSession } from 'next-auth/client';
import { useRouter } from 'next/router';

import { MenuSelect, Text } from '@application/components/ds';

interface NavigationUserInfoProps {
  organizationId: string;
}

export const NavigationUserInfo: FC<NavigationUserInfoProps> = ({ organizationId }) => {
  const router = useRouter();
  const [session, loadingSession] = useSession();

  const getMenuList = useMemo(
    () => [
      {
        label: 'Account Settings',
        value: {
          path: `/${organizationId}/account-settings`,
        },
      },
      {
        label: 'Organization Settings',
        value: {
          path: `/${organizationId}/organization-settings`,
        },
      },
      {
        label: 'Organizations',
        value: {
          action: function () {
            setCookie(null, 'organizations:can-choose', 'true', { path: '/', maxAge: 2 });
            setCookie(null, 'organizations:selected-organization', organizationId, { path: '/' });

            router.push('/organizations');
          },
        },
      },
      {
        label: 'Logout',
        value: {
          action: function () {
            setCookie(null, 'organizations:can-choose', 'true');
            destroyCookie(null, 'organizations:selected-organization');

            signOut({
              callbackUrl: '/organizations',
            });
          },
        },
      },
    ],
    [],
  );

  if (loadingSession) return null;

  return (
    <ChakraFlex width="100%" alignItems="center">
      <ChakraAvatar name={session.user.name} src={session.user.picture} marginRight="md" size="sm" />
      <Text width="100%" color="ui.900" variant="body-2" isTruncated={true}>
        {session.user.name}
      </Text>
      <MenuSelect
        variant="with-icon"
        icon="menu-vertical"
        options={getMenuList}
        onChange={({ value }) => (value.action ? value.action() : router.push(value?.path))}
      />
    </ChakraFlex>
  );
};

NavigationUserInfo.displayName = 'NavigationUserInfo';
