import { ButtonProps as ChakraButtonProps } from '@chakra-ui/react';

import { ThemeComponent } from '@types';

type ButtonSizes = 'sm' | 'md' | 'lg';
type ButtonVariants = 'solid' | 'ghost' | 'outline' | 'link';

export const PTButton: ThemeComponent<ButtonSizes, ButtonVariants, ChakraButtonProps> = {
  baseStyle: {
    transitionDuration: '0.3s',
    fontFamily: 'pt-body',
  },

  sizes: {
    sm: {
      fontSize: 'pt-sm',
      lineHeight: 'pt-normal',
      paddingY: 'pt-xs',
      paddingX: 'pt-sm',
    },
    md: {
      fontSize: 'pt-md',
      lineHeight: 'pt-normal',
      paddingY: 'pt-sm',
      paddingX: 'pt-md',
    },
    lg: {
      fontSize: 'pt-lg',
      lineHeight: 'pt-normal',
      paddingY: 'pt-md',
      paddingX: 'pt-lg',
    },
  },

  variants: {
    solid: {
      border: 'pt-sm',
      borderColor: 'pt-primary.600',
      borderRadius: 'pt-sm',
      background: 'pt-primary.500',
      color: 'pt-white',
      _hover: {
        background: 'pt-primary.600',
        border: 'pt-sm',
        borderColor: 'pt-primary.700',
        color: 'pt-white',
      },
      _focus: {
        border: 'pt-sm',
        background: 'pt-primary.900',
      },
      _disabled: {
        border: 'pt-sm',
        background: 'pt-primary.300',
        cursor: 'not-allowed',
      },
    },
    ghost: {
      border: 'pt-none',
      borderRadius: 'pt-sm',
      background: 'pt-ui.100',
      color: 'pt-ui.700',
      _hover: {
        color: 'pt-ui.700',
        background: 'pt-ui.200',
      },
      _focus: {
        boxShadow: 'pt-large',
      },
      _disabled: {
        color: 'pt-ui.500',
        background: 'pt-ui.50',
        cursor: 'not-allowed',
        _hover: {
          background: 'pt-ui.100',
        },
      },
    },
    outline: {
      border: 'pt-none',
      borderRadius: 'pt-sm',
      background: 'pt-ui.500',
      color: 'pt-white',
      _hover: {
        color: 'pt-white',
        background: 'pt-ui.600',
      },
      _focus: {
        boxShadow: 'pt-large',
      },
      _disabled: {
        cursor: 'not-allowed',
        background: 'pt-ui.300',
        _hover: {
          background: 'pt-ui.300',
        },
      },
    },
    link: {
      border: 'pt-none',
      borderRadius: 'pt-none',
      background: 'pt-info.500',
      color: 'pt-white',
      _hover: {
        color: 'pt-white',
        background: 'pt-info.600',
      },
      _focus: {
        boxShadow: 'pt-large',
      },
      _disabled: {
        cursor: 'not-allowed',
        background: 'pt-info.300',
        borderColor: 'pt-info.300',
        _hover: {
          background: 'pt-info.300',
        },
      },
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'solid',
    colorScheme: 'pt-primary',
  },
};
