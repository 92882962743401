import gql from 'graphql-tag';

export const CreateDataset = gql`
  mutation CreateDataset($organizationId: ID!, $accountId: ID!, $data: CreateDatasetInput!) {
    createDataset(organizationId: $organizationId, accountId: $accountId, data: $data) {
      dataset {
        id
        name
        userId
        accountId
        public
        createDate
        updateDate
        description
        fatherDataset
        generatedSchema
        # datasetResolvers

        hasControlledId

        fields {
          name
          fieldNullable
          type
          datasetId
          controlType
          helpText
          enumValues
        }

        childrenDatasets {
          items {
            id
            accountId
            description
            userId
            fatherDataset
            name
            fields {
              name
              fieldNullable
              type
              datasetId
              controlType
              helpText
              enumValues
              datasetId
            }
          }
        }
      }

      datasetOperation {
        id
        datasetId
        accountId
        alias
        action
        datasetOperationStatus
        userId
        createDate
        updateDate
      }
    }
  }
`;

export const UpdateDataset = gql`
  mutation UpdateDataset($organizationId: ID!, $accountId: ID!, $id: ID!, $data: UpdateDatasetInput!) {
    updateDataset(organizationId: $organizationId, accountId: $accountId, id: $id, data: $data) {
      dataset {
        id
        accountId
        name
        public
        description
        fatherDataset
        generatedSchema
        userId
        createDate
        updateDate

        hasControlledId

        fields {
          name
          fieldNullable
          type
          datasetId
          controlType
          helpText
          enumValues
        }

        childrenDatasets {
          items {
            id
            accountId
            description
            userId
            fatherDataset
            name
            fields {
              name
              fieldNullable
              type
              datasetId
              controlType
              helpText
              enumValues
              datasetId
            }
          }
        }
      }

      datasetOperation {
        id
        datasetId
        accountId
        alias
        action
        datasetOperationStatus
        userId
        createDate
        updateDate
      }
    }
  }
`;

export const DeleteDataset = gql`
  mutation DeleteDataset($organizationId: ID!, $accountId: ID!, $id: ID!) {
    deleteDataset(organizationId: $organizationId, accountId: $accountId, id: $id) {
      dataset {
        id
        accountId
        public
        name
        description
        fatherDataset
        generatedSchema

        hasControlledId

        fields {
          name
          fieldNullable
          type
          datasetId
          controlType
          helpText
          enumValues
        }

        childrenDatasets {
          items {
            id
            accountId
            description
            userId
            fatherDataset
            name
            fields {
              name
              fieldNullable
              type
              datasetId
              controlType
              helpText
              enumValues
              datasetId
            }
          }
        }

        userId
        createDate
        updateDate
      }

      datasetOperation {
        id
        datasetId
        accountId
        alias
        action
        datasetOperationStatus
        userId
        createDate
        updateDate
      }
    }
  }
`;

export const ReprocessDataset = gql`
  mutation ReprocessDataset($organizationId: ID!, $accountId: ID!, $id: ID!) {
    reprocessDataset(organizationId: $organizationId, accountId: $accountId, id: $id) {
      dataset {
        id
        name
        userId
        public
        accountId
        createDate
        updateDate
        description
        fatherDataset
        generatedSchema
        # datasetResolvers

        hasControlledId

        fields {
          name
          fieldNullable
          type
          datasetId
          controlType
          helpText
          enumValues
        }

        childrenDatasets {
          items {
            id
            accountId
            description
            userId
            fatherDataset
            name
            fields {
              name
              fieldNullable
              type
              datasetId
              controlType
              helpText
              enumValues
              datasetId
            }
          }
        }
      }

      datasetOperation {
        id
        datasetId
        accountId
        alias
        action
        datasetOperationStatus
        userId
        createDate
        updateDate
      }
    }
  }
`;
