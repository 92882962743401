import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export type ProgressParts = 'track' | 'filledTrack';

export interface ProgressProps {
  list: Record<string, unknown>[];
  size: 'sm' | 'md' | 'lg';
  value: number;
  property: string;
  colorScheme: string;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<ProgressProps, ProgressParts>;
