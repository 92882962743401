import React, { FC, useMemo } from 'react';

import {
  Flex as ChakraFlex,
  Table as ChakraTable,
  Tbody as ChakraTbody,
  Td as ChakraTd,
  Text as ChakraText,
  Th as ChakraTh,
  Thead as ChakraThead,
  Tr as ChakraTr,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { Row, useSortBy, useTable } from 'react-table';

import { ComponentReceivedProps } from '@types';

import { createColumn, createData } from '@application/utils/tableUtils';
import { showDatasetFormatted } from '@application/utils/stringManipulation';

import { defaultProps } from './protocol';
import { TableProps } from './index';

const removedFields = ['id', 'userId', 'creationDate', 'updateUserId', 'updateDate'];

export const component: FC<ComponentReceivedProps<TableProps, unknown>> = ({ props = defaultProps }) => {
  const { data, size, isFullHeight } = props;

  const themeStyles = useMultiStyleConfig('Table', { size });

  const getTableData = useMemo(() => createData(data), [data]);
  const getTableColumns = useMemo(() => createColumn(data, false, removedFields), [data]);

  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns: getTableColumns,
      data: getTableData,
      initialState: {},
    },
    useSortBy,
  );

  return (
    <>
      {data?.length ? (
        <ChakraTable
          display="inline-table"
          overflow="auto"
          height={isFullHeight ? '100%' : 'fit-content'}
          sx={themeStyles?.table || {}}
          css={{
            tableLayout: 'fixed',
          }}
        >
          <ChakraThead>
            {headerGroups.map(({ headers }, idx) => (
              <ChakraTr key={idx}>
                {headers.map((column, idx) => (
                  <ChakraTh
                    width="100%"
                    textTransform="unset"
                    title={showDatasetFormatted(String(column.render('Header')) || '-')}
                    isTruncated={true}
                    key={idx}
                    sx={themeStyles?.['table-header'] || {}}
                  >
                    {showDatasetFormatted(String(column.render('Header')))}
                  </ChakraTh>
                ))}
              </ChakraTr>
            ))}
          </ChakraThead>
          <ChakraTbody>
            {rows.map((row: Row, idx) => {
              prepareRow(row);

              return (
                <ChakraTr key={idx}>
                  {row.cells.map((cell, idx) => (
                    <ChakraTd
                      title={cell.value || '-'}
                      isTruncated={true}
                      sx={themeStyles?.['table-cell'] || {}}
                      key={idx}
                    >
                      {cell.value ? cell.render('Cell') : '-'}
                    </ChakraTd>
                  ))}
                </ChakraTr>
              );
            })}
          </ChakraTbody>
        </ChakraTable>
      ) : (
        <ChakraFlex
          width="100%"
          height="100%"
          boxShadow="pt-base"
          background="pt-white"
          alignItems="center"
          borderRadius="pt-sm"
          justifyContent="center"
        >
          No data found.
        </ChakraFlex>
      )}
    </>
  );
};
