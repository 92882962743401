import { LineChartParts } from '@application/lib/defaultTheme';
import { ProtocolFactory } from '@application/lib/protocols';

import { LineChartProps } from '.';

export const protocolFactory = new ProtocolFactory<LineChartProps, LineChartParts>({
  name: 'LineChart',
  editorProps: [
    {
      name: 'chartCategoryLabel',
      label: 'Category label',
      description: 'The custom label of the category',
      type: 'String',
      required: false,
      defaultValue: '',
    },
    {
      name: 'chartValueLabel',
      label: 'Value label',
      description: 'The custom label of the value',
      type: 'String',
      required: false,
      defaultValue: '',
    },
  ],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
  themeConfig: {
    allowMultipart: true,
    defaultProps: [],
    parts: ['chart-series', 'chart-axis'],
    partsConfig: {
      'chart-series': {
        baseStyleProps: ['background'],
      },
      'chart-axis': {
        baseStyleProps: ['fontSize', 'fontWeight', 'color'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
