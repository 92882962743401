import { Account, Dataset } from '@types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DataState {
  readonly forms: Dataset[];
  readonly answers: { formId: string; answer: Record<string, any> }[];
  readonly account: Account;
}

export const defaultInitialState: DataState = {
  forms: [],
  answers: [],
  account: null,
};

export const formsReducerInitializer = (initialState = defaultInitialState) =>
  createSlice({
    name: 'forms',
    initialState: initialState,
    reducers: {
      createOrUpdateForm: {
        reducer(state, action: PayloadAction<{ collection: Dataset }>) {
          const { forms } = state;
          const { collection } = action.payload;

          state.forms = [...forms.filter((c) => c.id !== collection.id), collection];

          return state;
        },
        prepare(collection: Dataset) {
          return { payload: { collection } };
        },
      },
      createOrAnswer: {
        reducer(state, action: PayloadAction<{ formId: string; answer: Record<string, any> }>) {
          const { answers } = state;
          const { formId, answer } = action.payload;

          state.answers = [
            ...answers.filter((ans) => ans.answer.userId !== answer.userId),
            { formId, answer },
          ];

          return state;
        },
        prepare(formId: string, answer: any) {
          return { payload: { formId, answer } };
        },
      },
      createOrUpdateAccount: {
        reducer(state, action: PayloadAction<{ newAccount: Account }>) {
          const { account } = state;
          const { newAccount } = action.payload;

          state.account = newAccount;

          return state;
        },
        prepare(newAccount: Account) {
          return { payload: { newAccount } };
        },
      },
    },
  });

const forms = formsReducerInitializer();

export const { createOrUpdateForm, createOrAnswer, createOrUpdateAccount } = forms.actions;

export default forms.reducer;
