import React, { FC } from 'react';

import { Flex as ChakraFlex, Text as ChakraText } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { ComponentReceivedProps, EComposerMode } from '@types';

import { Store } from '@application/state/store';

export const component: FC<ComponentReceivedProps<unknown, unknown>> = ({ children }) => {
  const composerMode = useSelector((state: Store) => state?.composer?.mode);

  if (composerMode && composerMode === EComposerMode.Layout) {
    return (
      <ChakraFlex width="100%" height="100%" padding="pt-lg">
        <ChakraFlex
          width="100%"
          height="100%"
          border="pt-sm"
          textAlign="center"
          alignItems="center"
          color="pt-primary.500"
          background="pt-primary.50"
          borderColor="pt-primary.300"
          borderStyle="dashed"
          borderRadius="pt-sm"
          justifyContent="center"
        >
          <ChakraText>Your route will apear here</ChakraText>
        </ChakraFlex>
      </ChakraFlex>
    );
  }

  return (
    <ChakraFlex width="100%" height="100%">
      {children}
    </ChakraFlex>
  );
};
