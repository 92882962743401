import { component } from './component';
import { ComponentBlock } from '@types';
import { RadioAlternativeProps } from './index';
import { types } from 'core/types';

export const block: ComponentBlock<'radioAlternative', RadioAlternativeProps, any> = {
  id: 'radioAlternative',
  render: component,
  props: {
    isInline: {
      type: types.boolean,
    },
    width: {
      type: types.string,
    },
  },
};
