import { CompilerComponent, IconName } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface ButtonProps {
  label: string;
  isDisabled: boolean;
  leftIcon: IconName;
  rightIcon: IconName;
  colorScheme: string;
  size: 'sm' | 'md' | 'lg';
  variant: 'ghost' | 'outline' | 'solid' | 'link' | 'unstyled';
}

export interface ButtonTriggers {
  onClick: () => void;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<ButtonProps>;
