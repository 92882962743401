import { border, BorderKeys } from './border';
import { ColorKeys, colors } from './colors';
import { flex, FlexKeys } from './flex';
import { shared, SharedKeys } from './editorShared';
import { space, SpaceKeys } from './space';
import { typography, TypographyKeys } from './typography';

import { ThemeProtocolItem } from '@types';

export type ThemeProtocolKeys = (
  | BorderKeys
  | ColorKeys
  | FlexKeys
  | SharedKeys
  | SpaceKeys
  | TypographyKeys
) &
  string;

export const themeProtocols: ThemeProtocolItem<ThemeProtocolKeys> = {
  ...border,
  ...colors,
  ...flex,
  ...shared,
  ...space,
  ...typography,
};
