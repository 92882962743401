import { CompilerComponent, IconName } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface BadgeProps {
  label: string;
  colorScheme: string;
  size?: string;
  variant?: string;
  leftIcon?: IconName;
  rightIcon?: IconName;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<BadgeProps>;
