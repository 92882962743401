import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { Flex as ChakraFlex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { ComponentReceivedProps } from '@types';

import { CompilerContext, EngineContext } from '@application/compiler/contexts';
import { Loading, useToast } from '@application/components/ds';

import { defaultProps } from './protocol';
import { FreePlanBadge, ProtectedPage, PublicPage } from './components';
import { PageProps } from './index';

export const component: FC<ComponentReceivedProps<PageProps, unknown>> = ({
  props = defaultProps,
  children,
}) => {
  const { path, role, isProtected, ...styleProps } = props;

  const [isMounted, setIsMounted] = useState(false);

  const { engine } = useContext(EngineContext);
  const { config } = useContext(CompilerContext);

  const toast = useToast();

  // System operations
  useEffect(() => {
    if (engine) {
      engine.addOperators({
        link: {
          name: 'link',
          description: 'Send the user to a new route',
          // path - Used for routing - /courses/123
          // @readonly:
          // route - used to identify which route is defined - /courses/:courseId
          // params - Used to identify the params of the path
          handler: (args: { path: string; route: string; params: Record<string, any> }) => {
            config.router.push(args);
            return true;
          },
        },
        toast: {
          name: 'toast',
          description: 'Visually feedback the user with a message',
          // @ts-ignore
          handler: ({ title, description, status }) => {
            toast({ title, description, status });
            return true;
          },
        },
      });

      setIsMounted(true);
    }
  }, [engine, path]);

  const dynamicData = {
    $pageIsProtected: isProtected,
    $pageParams: useParams(),
    $pageRole: role,
  };

  const isBadgeVisible = useMemo(() => !config?.options?.hideBadge && config.mode !== 'edit', [config]);

  return (
    <Loading width="100vw" height="100vh" isLoading={!isMounted}>
      <ChakraFlex width="100vw" height="100vh" position="relative" overflow="auto" {...styleProps}>
        {isBadgeVisible && <FreePlanBadge />}
        {isProtected ? (
          <ProtectedPage dynamicData={dynamicData}>{children}</ProtectedPage>
        ) : (
          <PublicPage dynamicData={dynamicData}>{children}</PublicPage>
        )}
      </ChakraFlex>
    </Loading>
  );
};
