import React, { FC } from 'react';

import {
  Modal as ChakraModal,
  ModalBody as ChakraModalBody,
  ModalCloseButton as ChakraModalCloseButton,
  ModalContent as ChakraModalContent,
  ModalFooter as ChakraModalFooter,
  ModalHeader as ChakraModalHeader,
  ModalOverlay as ChakraModalOverlay,
} from '@chakra-ui/react';

import { Button, Heading, Text } from 'components/ds';

interface ModalProps {
  title: string;
  subTitle?: string;
  onClose: () => void;
  onConfirm?: () => void;
  showHeader?: boolean;
  showFooter?: boolean;
  isOpen: boolean;
}

export const Modal: FC<ModalProps> = ({
  title,
  subTitle,
  children,
  isOpen,
  onClose,
  onConfirm = console.warn,
  showHeader = true,
  showFooter = true,
}) => {
  return (
    <ChakraModal size="xl" isOpen={isOpen} onClose={onClose}>
      <ChakraModalOverlay />
      <ChakraModalContent borderRadius="sm">
        {showHeader && (
          <ChakraModalHeader>
            <Heading>{title}</Heading>
            {subTitle && <Text color="ui.500">{subTitle}</Text>}
            <ChakraModalCloseButton borderRadius="sm" />
          </ChakraModalHeader>
        )}
        <ChakraModalBody paddingY="md">{children}</ChakraModalBody>
        {showFooter && (
          <ChakraModalFooter borderTop="sm" borderTopColor="ui.200">
            <Button marginRight="md" variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onConfirm}>Confirm</Button>
          </ChakraModalFooter>
        )}
      </ChakraModalContent>
    </ChakraModal>
  );
};
