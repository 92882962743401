import { ComposerErrorCode } from '@types';

type Errors = Record<ComposerErrorCode, { code: ComposerErrorCode; message: string }>;

export const composerErrors: Errors = {
  [ComposerErrorCode.NODE_NOT_CHILDREN]: {
    code: ComposerErrorCode.NODE_NOT_CHILDREN,
    message: "This component can't have children components",
  },
  [ComposerErrorCode.NODE_NOT_DELETABLE]: {
    code: ComposerErrorCode.NODE_NOT_DELETABLE,
    message: "This component can't be deleted",
  },
  [ComposerErrorCode.NODE_NOT_EDITABLE]: {
    code: ComposerErrorCode.NODE_NOT_EDITABLE,
    message: "This component can't be edited",
  },
  [ComposerErrorCode.NODE_NOT_FOUND]: {
    code: ComposerErrorCode.NODE_NOT_FOUND,
    message: 'The component was not found',
  },
  [ComposerErrorCode.PARENT_NODE_NOT_FOUND]: {
    code: ComposerErrorCode.PARENT_NODE_NOT_FOUND,
    message: 'Parent component not found',
  },
  [ComposerErrorCode.ROOT_NODE_NOT_FOUND]: {
    code: ComposerErrorCode.ROOT_NODE_NOT_FOUND,
    message: 'Page or layout not found!',
  },
  [ComposerErrorCode.UNIQUE_NODE]: {
    code: ComposerErrorCode.UNIQUE_NODE,
    message: 'This component should be unique',
  },
};
