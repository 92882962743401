import React, { FC } from 'react';

import Head from 'next/head';

interface Props {
  fonts: string[];
}
export const NextFontLoader: FC<Props> = ({ fonts }) => {
  if (!fonts?.length) return null;

  return (
    <Head>
      {fonts.map((fontUrl) => (
        <link href={fontUrl} key={fontUrl} rel="stylesheet" />
      ))}
    </Head>
  );
};
