import React, { FC, useContext, useMemo } from 'react';

import {
  Flex as ChakraFlex,
  Heading as ChakraHeading,
  Text as ChakraText,
  useMultiStyleConfig,
  useToken,
} from '@chakra-ui/react';
import ReactWordCloud, { Word } from 'react-wordcloud';

import { ComponentReceivedProps } from '@types';

import { CompilerContext } from '@application/compiler/contexts';

import { defaultProps } from './protocol';
import { WordCloudProps } from '.';

const exampleData = [
  { text: 'Front-end', value: 10 },
  { text: 'Back-end', value: 32 },
  { text: 'Devops', value: 5 },
  { text: 'Scrum', value: 13 },
  { text: 'UX', value: 50 },
];

export const component: FC<ComponentReceivedProps<WordCloudProps, unknown>> = ({ props = defaultProps }) => {
  const { wordCloudData, wordCloudTextProperty, ...rest } = props;

  const { config } = useContext(CompilerContext);
  const { 'word-cloud-container': containerStyles, 'word-cloud-text': textStyles } = useMultiStyleConfig(
    'PTWordCloud',
    {},
  );

  const [textFontSize] = useToken('fontSizes', [textStyles.fontSize as string]);
  const [textFontWeight] = useToken('fontWeights', [textStyles.fontWeight as string]);
  const [textFontFamily] = useToken('fonts', [textStyles.fontFamily as string]);

  const isThemeMode = useMemo(() => config.mode === 'theme', [config.mode]);

  if ((!wordCloudData || !wordCloudTextProperty) && !isThemeMode)
    return (
      <ChakraFlex width="100%" height="100%">
        <ChakraFlex
          width="100%"
          height="100%"
          border="pt-sm"
          gridGap="pt-sm"
          background="pt-ui.50"
          alignItems="center"
          borderStyle="dashed"
          borderColor="pt-info.200"
          borderRadius="pt-xs"
          flexDirection="column"
          justifyContent="center"
        >
          <ChakraHeading color="pt-info.500" fontSize="pt-xl">
            Missing configuration
          </ChakraHeading>
          <ChakraText maxWidth="20rem" color="pt-ui.500" textAlign="center">
            For the propertly work of the word cloud you need to define a{' '}
            <ChakraText as="span" color="pt-info.500">
              data{' '}
            </ChakraText>
            and{' '}
            <ChakraText as="span" color="pt-info.500">
              text property
            </ChakraText>
            .
          </ChakraText>
        </ChakraFlex>
      </ChakraFlex>
    );

  const getFormattedData = useMemo(() => {
    const items: Word[] = [];

    if (wordCloudData?.length)
      wordCloudData.map((word) => {
        if (
          items.some(
            ({ text }) => text.toLocaleLowerCase() === word[wordCloudTextProperty].toLocaleLowerCase(),
          )
        )
          return;

        const filteredMatchWordsLength = wordCloudData?.filter(
          (value) =>
            value[wordCloudTextProperty]?.toLowerCase() === word[wordCloudTextProperty]?.toLowerCase(),
        )?.length;

        items.push({
          text: word[wordCloudTextProperty]?.toLowerCase(),
          value: filteredMatchWordsLength,
        });
      });

    return items;
  }, [wordCloudData]);

  return (
    <ChakraFlex width="100%" height="100%" sx={containerStyles} {...rest}>
      <ReactWordCloud
        words={isThemeMode ? exampleData : getFormattedData}
        options={{
          fontSizes: [textFontSize.replace('rem', '') * 16, textFontSize.replace('rem', '') * 16],
          fontWeight: textFontWeight,
          fontFamily: textFontFamily,
        }}
      />
    </ChakraFlex>
  );
};
