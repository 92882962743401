import { ThemeMultipartComponent } from '@types';

type HeaderSizes = 'sm' | 'md' | 'lg';
type HeaderVariants = 'default';

type HeaderParts = 'header' | 'header-item' | 'header-item-selected';

export const PTHeader: ThemeMultipartComponent<HeaderSizes, HeaderVariants, HeaderParts> = {
  parts: ['header', 'header-item'],

  baseStyle: {
    header: {
      width: '100%',
      height: '4rem',
      gridGap: 'pt-xl',
      border: 'pt-none',
      paddingX: 'pt-xl',
      alignItems: 'center',
      background: 'pt-white',
      borderColor: 'pt-transparent',
    },
  },

  variants: {
    default: {
      'header-item': {
        height: '100%',
        color: 'pt-primary.300',
        cursor: 'pointer',
        paddingX: 'pt-md',
        fontFamily: 'pt-body',
        fontSize: 'pt-md',
        fontWeight: 'pt-normal',
        alignItems: 'center',
        transitionDuration: '0.3s',
        _hover: {
          color: 'pt-primary.500',
          background: 'pt-primary.50',
        },
      },
      'header-item-selected': {
        color: 'pt-primary.500',
        fontSize: 'pt-md',
        fontWeight: 'pt-normal',
        background: 'pt-transparent',
        borderBottom: 'pt-md',
        borderColor: 'pt-primary.500',
      },
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: null,
  },
};
