import { gql } from '@apollo/client';

export const GetAccountOperation = gql`
  query GetAccountOperation($id: ID!, $accountId: ID!) {
    accountOperation(id: $id, accountId: $accountId) {
      id
      accountId
      organizationId
      alias
      userId
      action
      accountOperationStatus
      createDate
      updateDate
    }
  }
`;

export const ListAccountOperations = gql`
  query ListAccountOperations(
    $accountId: ID!
    $filter: TableAccountOperationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountOperations(accountId: $accountId, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        organizationId
        alias
        userId
        action
        accountOperationStatus
        createDate
        updateDate
      }
      nextToken
    }
  }
`;
