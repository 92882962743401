/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SelectProps as ChakraSelectProps } from '@chakra-ui/react';

import { ThemeComponent } from '@types';

import { colors } from '../foundations';
import { getColorSchemeOrDefault } from '../utils/variants';

export type SelectSizes = 'sm' | 'md' | 'lg';
export type SelectVariants = 'default';

export const Select: ThemeComponent<SelectSizes, SelectVariants, ChakraSelectProps> = {
  baseStyle: () => ({
    outline: 'none',
    fontFamily: 'body',
    borderRadius: 'xs',
    transitionDuration: '0.1s',
  }),

  sizes: {
    sm: {
      fontSize: 'sm',
      lineHeight: 'sm',
    },
    md: {
      fontSize: 'md',
      lineHeight: 'md',
    },
    lg: {
      fontSize: 'lg',
      lineHeight: 'lg',
    },
  },

  variants: {
    default: ({ colorScheme }) => {
      const colorSchemaDefault = getColorSchemeOrDefault({
        colorScheme,
        colorSchemeDefault: 'ui',
      });

      return {
        background: 'white',
        border: 'sm',
        borderColor: `${colorSchemaDefault}.200`,
        color: `${colorSchemaDefault}.700`,
        _placeholder: {
          color: `${colorSchemaDefault}.500`,
          _disabled: {
            background: 'ui.50',
            borderColor: `${colorSchemaDefault}.200`,
          },
        },
        _hover: {
          borderColor: `${colorSchemaDefault}.500`,
        },
        _focus: {
          border: 'md',
          borderColor: 'primary.500',
          boxShadow: `0px 0px 0px 3px ${colors.primary[100]}`,
          _hover: {
            borderColor: 'primary.500',
          },
        },
        _disabled: {
          cursor: 'not-allowed',
          background: 'ui.50',
          borderColor: `${colorSchemaDefault}.200`,
        },
        _invalid: {
          borderColor: 'supportA.600',
          _focus: {
            borderColor: 'supportA.600',
            boxShadow: `0px 0px 0px 3px ${colors.supportA[100]}`,
          },
        },
      };
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: null,
  },
};
