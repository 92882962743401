import { flattenDeep, mapValues } from 'lodash';

import { allowedUserColors, EPropType, ThemeOverride, ThemeProtocolItem } from '@types';

export type ColorKeys =
  | 'background'
  | 'color'
  | 'borderColor'
  | 'borderTopColor'
  | 'borderBottomColor'
  | 'borderLeftColor'
  | 'borderRightColor';

type Colors = ThemeProtocolItem<ColorKeys>;

export const getColorsOptions = (theme: ThemeOverride) => {
  const colors = mapValues(theme.colors, (color, colorName) => {
    if (!allowedUserColors.includes(colorName)) return [];

    if (typeof color === 'string') {
      return [colorName];
    }

    const colorHues = Object.keys(color);

    return colorHues.map((colorHue) => `${colorName}.${colorHue}`);
  });

  return flattenDeep(Object.values(colors));
};

export const colors: Colors = {
  background: {
    type: EPropType.String,
    name: 'background',
    required: false,
    label: 'Background color',
    description: 'Color of the background of this component',
    defaultValue: 'pt-transparent',
    input: 'InputColor',
    inputProps: {
      width: '50%',
    },
    themeGetter: getColorsOptions,
  },
  color: {
    type: EPropType.String,
    name: 'color',
    required: false,
    label: 'Font color',
    description: 'Font color',
    defaultValue: 'pt-black',
    input: 'InputColor',
    inputProps: {
      width: '50%',
    },
    themeGetter: getColorsOptions,
  },
  borderColor: {
    type: EPropType.String,
    name: 'borderColor',
    required: false,
    label: 'Border color',
    description: 'Color of your border',
    defaultValue: 'pt-black',
    input: 'InputColor',
    inputProps: {
      width: '50%',
    },
    themeGetter: getColorsOptions,
  },
  borderTopColor: {
    type: EPropType.String,
    name: 'borderTopColor',
    required: false,
    label: 'Border top color',
    description: 'Color of your top border',
    defaultValue: 'pt-black',
    input: 'InputColor',
    inputProps: {
      width: '50%',
    },
    themeGetter: getColorsOptions,
  },
  borderBottomColor: {
    type: EPropType.String,
    name: 'borderBottomColor',
    required: false,
    label: 'Border bottom color',
    description: 'Color of your bottom border',
    defaultValue: 'pt-black',
    input: 'InputColor',
    inputProps: {
      width: '50%',
    },
    themeGetter: getColorsOptions,
  },
  borderLeftColor: {
    type: EPropType.String,
    name: 'borderLeftColor',
    required: false,
    label: 'Border left color',
    description: 'Color of your left border',
    defaultValue: 'pt-black',
    input: 'InputColor',
    inputProps: {
      width: '50%',
    },
    themeGetter: getColorsOptions,
  },
  borderRightColor: {
    type: EPropType.String,
    name: 'borderRightColor',
    required: false,
    label: 'Border right color',
    description: 'Color of your right border',
    defaultValue: 'pt-black',
    input: 'InputColor',
    inputProps: {
      width: '50%',
    },
    themeGetter: getColorsOptions,
  },
};
