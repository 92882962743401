import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { RadioAlternativeButtonProps } from './index';

export const protocolFactory = new ProtocolFactory<RadioAlternativeButtonProps>({
  name: 'RadioAlternativeButton',
  editorProps: [
    {
      type: EPropType.String,
      name: 'label',
      required: true,
      label: 'Label',
      description: 'Text at side of the radio',
      defaultValue: 'Radio',
    },
    {
      type: EPropType.String,
      name: 'index',
      required: true,
      label: 'Index',
      description: 'Text at side of the label',
      defaultValue: '1.',
    },
    {
      type: EPropType.String,
      name: 'value',
      required: true,
      label: 'Value',
      description: 'Value',
      defaultValue: '',
    },
    {
      type: EPropType.String,
      name: 'disabled',
      required: false,
      label: 'Disabled input',
      description: 'The input will be disabled',
      defaultValue: false,
    },
  ],
});

export const defaultProps = protocolFactory.createDefaultProps();
