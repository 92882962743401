import React, { FC } from 'react';

import { Flex as ChakraFlex, Img as ChakraImg, Text as ChakraText } from '@chakra-ui/react';

export const FreePlanBadge: FC = () => (
  <ChakraFlex
    color="ui.500"
    right="16px"
    bottom="16px"
    border="sm"
    gridGap="xs"
    paddingY="xs"
    paddingX="sm"
    cursor="pointer"
    position="absolute"
    boxShadow="large"
    background="white"
    alignItems="center"
    userSelect="none"
    borderColor="ui.200"
    borderRadius="xs"
    transitionDuration="0.3s"
    zIndex="100"
    _hover={{
      borderColor: 'ui.300',
    }}
    onClick={() => window.open('https://home.gstudioapp.com/', '_blank')}
  >
    <ChakraImg width="16px" height="16px" src="https://avatars.githubusercontent.com/u/46030240?s=200&v=4" />
    <ChakraText fontSize="sm">Made with gStudio</ChakraText>
  </ChakraFlex>
);
