import gql from 'graphql-tag';

export const CreateTheme = gql`
  mutation CreateTheme($input: CreateThemeInput!) {
    createTheme(input: $input) {
      id
      organizationId

      name
      theme

      customFonts {
        link
        name
      }

      customIcons {
        link
        name
      }

      createdAt
      updatedAt

      expiresAt
    }
  }
`;

export const UpdateTheme = gql`
  mutation UpdateTheme($input: UpdateThemeInput!) {
    updateTheme(input: $input) {
      id
      organizationId

      name
      theme

      customFonts {
        link
        name
      }

      customIcons {
        link
        name
      }

      createdAt
      updatedAt

      expiresAt
    }
  }
`;

export const DeleteTheme = gql`
  mutation DeleteTheme($input: DeleteThemeInput!) {
    deleteTheme(input: $input) {
      id
      organizationId
      name
    }
  }
`;
