import React, { FC, useRef } from 'react';

import {
  Flex as ChakraFlex,
  FlexProps as ChakraFlexProps,
  Img as ChakraImg,
  Text as ChakraText,
  CSSObject,
} from '@chakra-ui/react';

import { Icon, Loading } from 'components/ds';

export interface ImageUploadProps extends ChakraFlexProps {
  sx: CSSObject;
  value: string;
  isLoading?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  onInputFile: (file: File) => void;
  onRemoveFile: () => void;
}

export const ImageUpload: FC<ImageUploadProps> = ({
  sx,
  value,
  isLoading,
  isInvalid,
  isDisabled,
  onInputFile,
  onRemoveFile,
  ...props
}) => {
  const fileInputRef = useRef();

  return (
    <>
      <ChakraFlex
        {...props}
        height="10rem"
        gridGap="sm"
        position="relative"
        overflow="hidden"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        transitionDuration="0.3s"
        userSelect="none"
        aria-invalid={isInvalid}
        aria-disabled={isDisabled}
        cursor={!value && 'pointer'}
        // @ts-ignore
        onClick={() => !isLoading && !value && fileInputRef?.current?.click()}
        sx={sx}
      >
        <Loading height="100%" isLoading={isLoading}>
          {value ? (
            <>
              <ChakraImg objectFit="cover" src={value} />
              <ChakraFlex
                width="100%"
                height="100%"
                padding="sm"
                opacity="0"
                color="white"
                background="black"
                position="absolute"
                transitionDuration="0.3s"
                justifyContent="flex-end"
                _hover={{
                  opacity: '0.3',
                }}
              >
                <Icon cursor="pointer" name="delete" onClick={() => !isDisabled && onRemoveFile()} />
              </ChakraFlex>
            </>
          ) : (
            <>
              <Icon color="ui.200" size="lg" name="datafield-image" />
              <ChakraText textAlign="center" color="ui.500" fontSize="xs">
                Empty image.
                <br />
                Upload one to show there.
              </ChakraText>
            </>
          )}
        </Loading>
      </ChakraFlex>
      <ChakraFlex display="none">
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          disabled={isDisabled}
          onChange={({ target }) => onInputFile(target.files[0])}
        />
      </ChakraFlex>
    </>
  );
};
