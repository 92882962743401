import React, { FC } from 'react';

import { Flex as ChakraFlex, FlexProps, useDisclosure } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { useOrganization } from 'lib/apiHooks';

import { AlertDialog, Button, Icon, Text, useToast } from '@application/components/ds';
import { ApiClient } from '@services/clients';

interface Props extends FlexProps {
  isFreemium: boolean;
}

export const Freemium: FC<Props> = ({ children, isFreemium, ...rest }) => {
  const { organizationId } = useOrganization();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const createCheckoutSessionMutation = useMutation(
    async () => {
      const resp = await ApiClient.newStripeCheckoutSession({
        organizationId,
      });

      return resp.data;
    },
    {
      onSuccess: (checkoutSession) => {
        toast({ status: 'info', title: 'You are going to be redirected' });

        document.location.href = checkoutSession.url;
      },
      onError: (error: string) => {
        toast({
          title: 'An error has ocurred',
          status: 'danger',
          description: error,
        });
      },
    },
  );

  if (!isFreemium) {
    return <>{children}</>;
  }

  return (
    <>
      <AlertDialog
        title="Upgrade to pro plan"
        description="Let's go to the next level!"
        buttonLabel="Upgrade"
        isOpen={isOpen}
        isLoading={createCheckoutSessionMutation.isLoading}
        onClose={() => onClose()}
        onConfirm={createCheckoutSessionMutation.mutate}
      >
        <Text color="ui.600">
          To use all gStudio features, you must upgrade your current organization free plan to a pro plan.
        </Text>
      </AlertDialog>
      <ChakraFlex
        flexWrap="wrap"
        gridRowGap="md"
        gridColumnGap="xl"
        justifyContent="center"
        alignItems="center"
        {...rest}
      >
        <Text variant="body-1" color="ui.600">
          You must upgrade your plan <br /> in order to use this feature
        </Text>
        <Button size="sm" leftIcon={<Icon name="lightning" size="sm" />} onClick={onOpen}>
          Upgrade
        </Button>
      </ChakraFlex>
    </>
  );
};
