/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { InputProps as ChakraInputProps } from '@chakra-ui/react';

import { ThemeComponent } from '@types';

import { colors } from '../foundations';

export type ImageUploadSizes = 'sm' | 'md' | 'lg' | string;
export type ImageUploadVariants = 'default';

export const ImageUpload: ThemeComponent<ImageUploadSizes, ImageUploadVariants, ChakraInputProps> = {
  baseStyle: () => ({
    border: 'sm',
    borderColor: 'ui.200',
    borderStyle: 'dashed',
    borderRadius: 'xs',
    background: 'ui.50',
    _hover: {
      background: 'ui.100',
      borderColor: 'ui.300',
    },
    _disabled: {
      cursor: 'not-allowed',
      _hover: {
        background: 'ui.50',
        borderColor: 'ui.200',
      },
    },
    _invalid: {
      borderColor: 'supportA.600',
      _focus: {
        boxShadow: `0px 0px 0px 3px ${colors.supportA[100]}`,
      },
    },
  }),

  sizes: {
    sm: {
      fontSize: 'sm',
      lineHeight: 'sm',
    },
    md: {
      fontSize: 'md',
      lineHeight: 'md',
    },
    lg: {
      fontSize: 'lg',
      lineHeight: 'lg',
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: null,
  },
};
