/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { colors } from '../foundations';
import { getColorSchemeOrDefault } from '../utils/variants';

import { ThemeMultipartComponent } from '@types';

export type NumberInputSizes = 'sm' | 'md' | 'lg';
export type NumberInputVariants = 'default';

type NumberInputParts =
  | 'number-input'
  | 'number-input-field'
  | 'number-input-stepper'
  | 'number-input-increment-stepper'
  | 'number-input-decrement-stepper';

export const NumberInput: ThemeMultipartComponent<NumberInputSizes, NumberInputVariants, NumberInputParts> = {
  parts: [
    'number-input',
    'number-input-field',
    'number-input-stepper',
    'number-input-increment-stepper',
    'number-input-decrement-stepper',
  ],

  sizes: {
    sm: () => ({
      'number-input': {
        fontSize: 'sm',
        lineHeight: 'sm',
      },
    }),

    md: () => ({
      'number-input': {
        fontSize: 'md',
        lineHeight: 'md',
      },
    }),

    lg: () => ({
      'number-input': {
        fontSize: 'lg',
        lineHeight: 'lg',
      },
    }),
  },

  variants: {
    default: ({ colorScheme }) => {
      const colorSchemeDefault = getColorSchemeOrDefault<keyof typeof colors>({
        colorScheme: colorScheme as keyof typeof colors,
        colorSchemeDefault: 'ui',
      });

      return {
        'number-input-field': {
          height: '2.5rem',
          paddingX: 'md',
          color: `${colorSchemeDefault}.700`,
          border: 'sm',
          borderColor: `${colorSchemeDefault}.200`,
          borderRadius: 'xs',
          _placeholder: {
            color: `${colorSchemeDefault}.500`,
          },
          _hover: {
            borderColor: `${colorSchemeDefault}.500`,
          },
          _disabled: {
            cursor: 'not-allowed',
            background: 'ui.50',
            borderColor: `${colorSchemeDefault}.200`,
          },
          _focus: {
            border: 'md',
            borderColor: 'primary.500',
            borderRadius: 'xs',
            boxShadow: `0px 0px 0px 3px ${colors.primary[100]}`,
          },
          _invalid: {
            borderColor: 'supportA.600',
            _focus: {
              boxShadow: `0px 0px 0px 3px ${colors.supportA[100]}`,
            },
          },
        },
      };
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: null,
  },
};
