import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export type TableParts = 'table' | 'table-header' | 'table-cell';
export interface TableProps {
  size: 'sm' | 'md' | 'lg';
  data: Record<string, unknown>[];
  isFullHeight: boolean;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<TableProps, TableParts>;
