import * as qs from 'qs';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

export class RequestBuilder {
  config: AxiosRequestConfig;

  constructor(baseURL: string, config?: AxiosRequestConfig) {
    this.config = {
      baseURL,
      headers: {
        'content-type': 'application/json',
      },
      ...(config ?? {}),
    };

    return this;
  }

  contentType(type: string): RequestBuilder {
    this.config.headers['content-type'] = type;

    return this;
  }

  append(url: string): RequestBuilder {
    this.config.url = url;

    return this;
  }

  method(name: Method): RequestBuilder {
    this.config.method = name;

    return this;
  }

  params(params: Record<string, any>): RequestBuilder {
    this.config.params = params;

    return this;
  }

  setHeader(newHeader: Record<string, any>): RequestBuilder {
    this.config.headers = {
      ...this.config.headers,
      ...newHeader,
    };

    return this;
  }

  setData(data: any): RequestBuilder {
    this.config.data = data;

    if (this.config.headers['content-type'] === 'application/x-www-form-urlencoded') {
      this.config.data = qs.stringify(data);
    }

    if (this.config.headers['content-type'] === 'multipart/form-data') {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      this.config.data = formData;
    }

    return this;
  }

  build<T = unknown>(): Promise<AxiosResponse<T>> {
    return axios.create(this.config).request(this.config);
  }
}
