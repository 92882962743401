import { CustomIcon } from '@application/components/Theme/model/theme';
import { ThemeProtocolKeys, themeProtocols } from '@application/lib/protocols/themeProtocols';

import { GStudioEditorProps, ThemeOverride, ThemeProtocol } from '@types';

export class ComponentPropsFactory<ComponentProps> {
  protected name: string;
  protected themeProps: ThemeProtocolKeys[];
  protected themeVariants: string[];
  protected themeSizes: string[];
  protected editorProps: GStudioEditorProps<ComponentProps>[];

  constructor(
    name: string,
    editorProps: GStudioEditorProps<ComponentProps>[],
    themeProps: ThemeProtocolKeys[],
    themeVariants: string[],
    themeSizes: string[],
  ) {
    const fixedChakraComponentNames = ['Accordion', 'Checkbox', 'Table', 'Progress'];

    this.name = fixedChakraComponentNames.includes(name) ? name : `PT${name}`;
    this.themeProps = themeProps;
    this.editorProps = editorProps;
    this.themeVariants = themeVariants;
    this.themeSizes = themeSizes;
  }

  createEditorProps(): GStudioEditorProps<ComponentProps>[] {
    return this.editorProps;
  }

  createThemeProps(theme: ThemeOverride, customIcons?: CustomIcon[]): ThemeProtocol[] {
    return this.themeProps
      ?.map((themeKey) => {
        const themeProp = themeProtocols[themeKey];

        if (!themeProp) return null;

        if (themeProp.name === 'size') {
          return {
            ...themeProp,
            options: this.themeSizes,
          };
        }

        if (themeProp.name === 'variant') {
          const themeVariants = themeProp?.themeGetter(theme, this.name);

          return {
            ...themeProp,
            options: [...this.themeVariants, ...themeVariants],
          };
        }

        return {
          ...themeProp,
          options: themeProp?.options || themeProp?.themeGetter(theme, this.name, customIcons),
        };
      })
      .filter((config) => config);
  }

  createDefaultProps(): ComponentProps {
    const defaultProps: ComponentProps & any = {};

    if (this.editorProps) {
      for (const prop of this.editorProps) {
        const { name: propName, defaultValue } = prop;

        defaultProps[propName] = defaultValue;
      }
    }

    return defaultProps;
  }
}
