import { extendTheme } from '@chakra-ui/react';

import {
  AlertDialog,
  Button,
  Card,
  Checkbox,
  Heading,
  Icon,
  ImageUpload,
  Input,
  InterpolationInput,
  MenuSelect,
  MultiToggle,
  NumberInput,
  RichTextEditor,
  Select,
  Text,
  Textarea,
} from './components';

import {
  borders,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  shadows,
  space,
} from './foundations';

import { layerStyles, textStyles } from './styles';

export * from './utils';

export const theme = extendTheme({
  // Foundations
  borders,
  breakpoints,
  colors,
  radii,
  shadows,
  space,

  // Typography
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,

  // Custom components
  components: {
    AlertDialog,
    Button,
    Card,
    Checkbox,
    Heading,
    Icon,
    ImageUpload,
    Input,
    InterpolationInput,
    MenuSelect,
    MultiToggle,
    NumberInput,
    RichTextEditor,
    Text,
    Textarea,
    Select,
  },

  textStyles,
  layerStyles,

  styles: {
    global: {
      'html, body, #__next': {
        width: '100%',
        height: '100%',
        scrollBehavior: 'smooth',
        overflow: 'hidden',
      },
      '.twitter-picker input': {
        width: '80% !important',
      },
      '#nprogress .bar': {
        background: 'primary.500',
      },
    },
  },

  config: {
    useSystemColorMode: false,
    initialColorMode: 'light',
  },
});
