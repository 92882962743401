import { Text as ChakraText, TextProps } from '@chakra-ui/react';
import React, { FC } from 'react';

export const Text: FC<TextProps> = ({ children, ...props }) => {
  return (
    <ChakraText overflowWrap="break-word" {...props}>
      {children}
    </ChakraText>
  );
};
