import { CalendarParts } from '@application/lib/defaultTheme';
import { ProtocolFactory } from '@application/lib/protocols';

import { CalendarProps } from './index';

export const protocolFactory = new ProtocolFactory<CalendarProps, CalendarParts>({
  name: 'Calendar',
  editorProps: [],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
  themeProps: ['width', 'height'],
  themeConfig: {
    allowMultipart: true,
    defaultProps: [],
    parts: [
      'calendar-container',
      'calendar-month-header',
      'calendar-month-cell-date',
      'calendar-month-cell-date-current',
      'calendar-month-event',
      'calendar-week-time-cell',
      'calendar-week-header',
      'calendar-week-header-date',
      'calendar-week-header-date-current',
      'calendar-week-event',
      'calendar-toolbar-title',
    ],
    partsConfig: {
      'calendar-container': {
        variantProps: ['border', 'borderColor', 'borderRadius', 'background', 'boxShadow'],
      },
      'calendar-month-header': {
        variantProps: ['color', 'padding', 'fontSize', 'fontFamily', 'background'],
      },
      'calendar-month-cell-date': {
        variantProps: ['color', 'fontFamily', 'paddingVertical', 'paddingHorizontal'],
      },
      'calendar-month-cell-date-current': {
        variantProps: ['color'],
      },
      'calendar-month-event': {
        variantProps: ['color', 'padding', 'borderLeft', 'borderColor', 'background'],
      },
      'calendar-week-time-cell': {
        variantProps: ['fontFamily', 'fontSize', 'color'],
      },
      'calendar-week-header': {
        variantProps: ['color', 'padding', 'fontSize', 'fontFamily'],
      },
      'calendar-week-header-date': {
        variantProps: ['color', 'fontFamily', 'fontSize', 'borderRadius'],
      },
      'calendar-week-header-date-current': {
        variantProps: ['color', 'background'],
      },
      'calendar-week-event': {
        variantProps: [
          'color',
          'padding',
          'borderLeft',
          'borderRadius',
          'fontSize',
          'fontFamily',
          'borderColor',
          'background',
        ],
      },
      'calendar-toolbar-title': {
        variantProps: ['color', 'fontFamily', 'fontSize'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
