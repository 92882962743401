import { component } from './component';
import { ComponentBlock } from '@types';
import { TableProps } from './index';
import { types } from 'core/types';

export const block: ComponentBlock<'table', TableProps, any> = {
  id: 'table',
  render: component,
  props: {
    data: {
      type: types.list,
    },
    size: {
      type: types.string,
    },
    isFullHeight: {
      type: types.boolean,
    },
  },
  triggers: {},
};
