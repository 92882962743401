import { BreadcrumbParts } from '@application/lib/defaultTheme';
import { ProtocolFactory } from '@application/lib/protocols';

import { BreadcrumbProps } from './index';

export const protocolFactory = new ProtocolFactory<BreadcrumbProps, BreadcrumbParts>({
  name: 'Breadcrumb',
  editorConfig: {
    disableDesignSection: true,
    disableDataLinkSection: true,
    disableLogicSection: true,
  },
  themeProps: [],
  themeConfig: {
    allowMultipart: true,
    sizes: [],
    parts: ['breadcrumb-container', 'breadcrumb-item'],
    defaultProps: [],
    partsConfig: {
      'breadcrumb-container': {
        variantProps: ['borderBottom', 'borderBottomColor', 'paddingBottom'],
      },
      'breadcrumb-item': {
        pseudos: ['_selected'],
        pseudoProps: ['fontFamily', 'fontSize', 'fontWeight', 'color'],
        variantProps: ['fontFamily', 'fontSize', 'fontWeight', 'color'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
