import { component } from './component';
import { ComponentBlock } from '@types';
import { PageProps } from './index';
import { types } from 'core/types';

export const block: ComponentBlock<'page', PageProps, any> = {
  id: 'page',
  render: component,
  props: {
    name: {
      type: types.string,
    },
    path: {
      type: types.string,
    },
    role: {
      type: types.string,
    },
    isProtected: {
      type: types.boolean,
    },
    fields: {
      type: types.list,
    },
  },
  events: {
    link: {
      name: 'Link',
      inputArguments: [
        {
          name: 'path',
          required: true,
          type: types.string,
        },
      ],
      fields: [],
    },
    toast: {
      name: 'Toast',
      inputArguments: [
        {
          name: 'title',
          required: true,
          type: types.string,
        },
        {
          name: 'description',
          required: false,
          type: types.string,
        },
        {
          name: 'status',
          required: true,
          type: types.string,
          options: ['info', 'warning', 'success', 'error'],
        },
      ],
      fields: [],
    },
    data_query: {
      name: 'Data Query',
      inputArguments: [
        {
          name: 'operationName',
          required: false,
          type: types.string,
        },
        {
          name: 'input',
          required: false,
          type: types.object,
        },
      ],
      fields: [],
    },
    data_operation: {
      name: 'Data Operation',
      inputArguments: [
        {
          name: 'operationName',
          required: false,
          type: types.string,
        },
        {
          name: 'input',
          required: false,
          type: types.object,
        },
      ],
      fields: [],
    },
  },
};
