import React, { FC } from 'react';

import { GridItem as ChakraGridItem, useStyleConfig } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { GridItemProps } from '.';

export const component: FC<ComponentReceivedProps<GridItemProps, unknown>> = ({
  children,
  props = defaultProps,
}) => {
  const themeStyle = useStyleConfig('GridItem', {});

  return (
    <ChakraGridItem sx={themeStyle} {...props}>
      {children}
    </ChakraGridItem>
  );
};
