import { ThemeMultipartComponent } from '@types';

export type BreadcrumbSizes = 'sm' | 'md' | 'lg';
export type BreadcrumbVariants = 'default';
export type BreadcrumbParts = 'breadcrumb-container' | 'breadcrumb-item';

export const PTBreadcrumb: ThemeMultipartComponent<BreadcrumbSizes, BreadcrumbVariants, BreadcrumbParts> = {
  parts: ['breadcrumb-container', 'breadcrumb-item'],

  variants: {
    default: {
      'breadcrumb-container': {
        width: '100%',
        borderBottom: 'pt-sm',
        borderBottomColor: 'pt-ui.200',
        paddingBottom: 'pt-sm',
      },
      'breadcrumb-item': {
        fontSize: 'pt-md',
        fontFamily: 'pt-body',
        fontWeight: 'pt-normal',
        color: 'pt-ui.700',
        _selected: {
          color: 'pt-ui.900',
        },
      },
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: null,
  },
};
