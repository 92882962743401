import { types } from '@application/core/types';

import { ComponentBlock } from '@types';

import { component } from './component';
import { MenuProps } from './index';

export const block: ComponentBlock<'menu', MenuProps, unknown> = {
  id: 'menu',
  render: component,
  props: {
    menuButtonIcon: {
      type: types.string,
    },
    menuButtonIsIcon: {
      type: types.boolean,
    },
    menuButtonPlaceholder: {
      type: types.string,
    },
    menuOptions: {
      type: types.list,
    },
  },
};
