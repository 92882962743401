import { EPropType, ThemeOverride, ThemeProtocolItem } from '@types';

import { replaceThemePrefix } from '@application/lib/defaultTheme/helpers';

export type BorderKeys =
  | 'border'
  | 'borderTop'
  | 'borderBottom'
  | 'borderLeft'
  | 'borderRight'
  | 'borderRadius'
  | 'borderTopRadius'
  | 'borderBottomRadius';

type Border = ThemeProtocolItem<BorderKeys>;

export const border: Border = {
  border: {
    type: EPropType.String,
    name: 'border',
    required: false,
    label: 'Border width',
    description: 'Border width',
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.borders ?? {}).map((border) => ({
        label: replaceThemePrefix(border),
        value: border,
      })),
  },
  borderTop: {
    type: EPropType.String,
    name: 'borderTop',
    required: false,
    label: 'Border top',
    description: 'Border top width',
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.borders ?? {}).map((border) => ({
        label: replaceThemePrefix(border),
        value: border,
      })),
  },
  borderBottom: {
    type: EPropType.String,
    name: 'borderBottom',
    required: false,
    label: 'Border bottom',
    description: 'Border bottom width',
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.borders ?? {}).map((border) => ({
        label: replaceThemePrefix(border),
        value: border,
      })),
  },
  borderLeft: {
    type: EPropType.String,
    name: 'borderLeft',
    required: false,
    label: 'Border left',
    description: 'Border left width',
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.borders ?? {}).map((border) => ({
        label: replaceThemePrefix(border),
        value: border,
      })),
  },
  borderRight: {
    type: EPropType.String,
    name: 'borderRight',
    required: false,
    label: 'Border right',
    description: 'Border right width',
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.borders ?? {}).map((border) => ({
        label: replaceThemePrefix(border),
        value: border,
      })),
  },
  borderRadius: {
    type: EPropType.String,
    name: 'borderRadius',
    required: false,
    label: 'Border Radius',
    description: 'Radius of the border',
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.radii ?? {}).map((radii) => ({
        label: replaceThemePrefix(radii),
        value: radii,
      })),
  },
  borderTopRadius: {
    type: EPropType.String,
    name: 'borderTopRadius',
    required: false,
    label: 'Border Top Radius',
    description: 'Radius of the top border',
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.radii ?? {}).map((radii) => ({
        label: replaceThemePrefix(radii),
        value: radii,
      })),
  },
  borderBottomRadius: {
    type: EPropType.String,
    name: 'borderBottomRadius',
    required: false,
    label: 'Border Bottom Radius',
    description: 'Radius of the bottom border',
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.radii ?? {}).map((radii) => ({
        label: replaceThemePrefix(radii),
        value: radii,
      })),
  },
};
