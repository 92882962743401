import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { TableParts, TableProps } from './index';

export const protocolFactory = new ProtocolFactory<TableProps, TableParts>({
  name: 'Table',
  editorProps: [
    {
      type: EPropType.Boolean,
      name: 'isFullHeight',
      required: false,
      label: 'Full height',
      description: 'Allow the table to take 100% of the height',
      defaultValue: false,
    },
    {
      type: EPropType.List,
      name: 'data',
      required: true,
      label: 'Data',
      description: 'The data for the table',
      defaultValue: [
        { id: 1, name: 'foo', description: 'bar' },
        { id: 2, name: 'foo', description: 'bar' },
        { id: 3, name: 'foo', description: 'bar' },
      ],
      allowDataLink: true,
    },
  ],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
  themeProps: ['size', 'colorScheme'],
  themeConfig: {
    allowMultipart: true,
    allowColorScheme: true,
    sizes: ['sm', 'md', 'lg'],
    parts: ['table', 'table-header', 'table-cell'],
    defaultProps: ['size', 'colorScheme'],
    partsConfig: {
      table: {
        baseStyleProps: ['background', 'borderColor', 'border', 'borderRadius', 'boxShadow'],
      },
      'table-header': {
        baseStyleProps: ['fontWeight', 'textAlign', 'color', 'background'],
        sizeProps: ['fontSize', 'lineHeight', 'paddingHorizontal', 'paddingVertical'],
      },
      'table-cell': {
        baseStyleProps: ['fontWeight', 'textAlign', 'color', 'background'],
        sizeProps: ['fontSize', 'lineHeight', 'paddingHorizontal', 'paddingVertical'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
