import React, { FC, useContext, useMemo } from 'react';

import { CompiledComponentProps, Node } from '@types';

import { BlockFactory } from 'lib/core';

import { ConditionEngineWrapper } from './ConditionEngineWrapper';
import { EngineTriggerWrapper } from './EngineTriggerWrapper';

import { DynamicPropsContext } from '../contexts';
import { getComponentProps } from './layers';

import { CompilerContext } from '@application/compiler/contexts';
import { EditorContext } from 'components/pages/Project/Editor/context';

export const CompiledComponent: FC<CompiledComponentProps> = ({
  node,
  children,
  // The reason for this dynamicData:
  // The parent components can inject variables in the instance, but will not reflect any side effects on the contextApi nor on the siblings components
  dynamicData,
}) => {
  const { focusNode, isInspectModeActive } = useContext(EditorContext);
  const { config } = useContext(CompilerContext);
  const { data } = useContext(DynamicPropsContext);

  const isProduction = config.mode === 'production';

  const { block } = node;

  // Adding parent components variables with dynamic data
  const unifiedDynamicData = useMemo<Record<string, unknown>>(() => {
    return {
      ...data,
      ...dynamicData,
    };
  }, [data, dynamicData]);

  const componentProps = useMemo(() => {
    const computedProps = getComponentProps({ unifiedDynamicData, node });

    if (!isProduction && isInspectModeActive) {
      computedProps.onClick = (ev) => {
        ev.stopPropagation();
        focusNode(node.id);
      };
    }

    return {
      ...computedProps,
      id: `node-${node.id}`,
    };
  }, [node, unifiedDynamicData, isInspectModeActive]);

  const Component: FC<{ props: any; node: Node }> = useMemo(() => {
    return BlockFactory.getCompilerComponent(block.component).component;
  }, []);

  if (!Component) return <></>;

  return (
    <ConditionEngineWrapper key={node.id} node={node} inheritedData={unifiedDynamicData}>
      <EngineTriggerWrapper node={node}>
        {/* // The wrappers provide the inherited data to the compiler componnent througth propsInheritance */}
        <Component key={node.id} props={componentProps} node={node}>
          {children}
        </Component>
      </EngineTriggerWrapper>
    </ConditionEngineWrapper>
  );
};
