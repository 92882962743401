import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface DividerProps {
  orientation: 'vertical' | 'horizontal';
  borderColor: string;
  borderWidth: string;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<DividerProps>;
