import { ThemeMultipartComponent } from '@types';

export type MapSizes = 'sm' | 'md' | 'lg';
export type MapVariants = 'default';
export type MapParts = 'map-container';

export const PTMap: ThemeMultipartComponent<MapSizes, MapVariants, MapParts> = {
  parts: ['map-container'],

  baseStyle: {
    'map-container': {
      border: 'pt-none',
      borderColor: 'pt-transparent',
      borderRadius: 'pt-sm',
      boxShadow: 'pt-none',
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: 'pt-primary',
  },
};
