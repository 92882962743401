import { ComponentBlock } from '@types';

import { types } from '@application/core/types';

import { component } from './component';
import { SidebarProps } from './index';

export const block: ComponentBlock<'sidebar', SidebarProps, unknown> = {
  id: 'sidebar',
  render: component,
  props: {
    sidebarRoutes: {
      type: types.list,
    },
    sidebarHasLogo: {
      type: types.boolean,
    },
    sidebarHasProfile: {
      type: types.boolean,
    },
    sidebarIsCollapsed: {
      type: types.boolean,
    },
  },
};
