import { ComponentBlock } from '@types';

import { types } from 'core/types';

import { component } from './component';
import { TextareaProps, TextareaTriggers } from './index';

export const block: ComponentBlock<'textarea', TextareaProps, TextareaTriggers> = {
  id: 'textarea',
  render: component,
  props: {
    name: {
      type: types.string,
    },
    value: {
      type: types.string,
    },
    label: {
      type: types.string,
    },
    placeholder: {
      type: types.string,
    },
    size: {
      type: types.string,
    },
    resize: {
      type: types.string,
    },
  },
  triggers: {
    onBlur: {
      name: 'On blur',
      description: 'When the control is unfocused',
    },
  },
};
