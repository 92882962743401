import React, { FC, useContext } from 'react';

import { ASTNode } from 'graphql';
import {
  chakra,
  Button as ChakraButton,
  Flex as ChakraFlex,
  Tab as ChakraTab,
  TabList as ChakraTabList,
  TabPanel as ChakraTabPanel,
  TabPanels as ChakraTabPanels,
  Tabs as ChakraTabs,
  useMultiStyleConfig,
  useStyleConfig,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { capitalize, toDatasetFormat } from '@application/utils/stringManipulation';
import { CompilerContext } from '@application/compiler/contexts';

import { FactoryDataset } from '@introspection/factories/DatasetsOperationsFactory';

import { ApplicationUser } from '@types';

import { ChildForm } from './ChildForm';
import { ParentForm } from './ParentForm';

import { DynamicFormProps } from '..';
import { exampleChildrenDatasetsList } from '../mock';

interface Props {
  inheritedData: Record<string, any>;
  user: ApplicationUser;
  dataset: string;
  updateId: string;
  customButtonLabel: string;
  isFormButtonFullWidth: boolean;
  datasets: Record<string, FactoryDataset>;
  operation: DynamicFormProps['operation'];
  initialValues: Record<string, unknown>;
  attributeToCurrentUser?: boolean;
  datasetsFieldsReference: DynamicFormProps['datasetsFieldsReference'];
  fieldsCustomization: DynamicFormProps['fieldsCustomization'];
  executeApiOperation: (
    operation: ASTNode,
    operationName: string,
    variables: Record<string, unknown>,
  ) => Promise<unknown>;
  onSubmit: (datasetsValues: Record<string, unknown>) => Promise<unknown>;
}

export const DynamicForm: FC<Props> = ({
  inheritedData,
  user,
  dataset,
  datasets,
  updateId,
  onSubmit,
  operation,
  initialValues,
  customButtonLabel,
  isFormButtonFullWidth,
  executeApiOperation,
  attributeToCurrentUser,
  datasetsFieldsReference,
  fieldsCustomization,
}) => {
  const methods = useForm({
    defaultValues: initialValues,
  });

  const { config } = useContext(CompilerContext);
  const {
    'dynamic-form-children-tabs': dynamicFormChildrenTabsStyles,
    'dynamic-form-children-tab-panel': dynamicFormChildrenTabPanelStyles,
    'dynamic-form-button-container': dynamicFormButtonContainerStyles,
  } = useMultiStyleConfig('PTDynamicForm', {});

  const buttonStyles = useStyleConfig('PTButton', {
    variant: 'solid',
  });

  const parentDataset = datasets[dataset];

  const isThemeMode = config.mode === 'theme';

  if (!parentDataset && !isThemeMode) return null;

  const isUserDataset = !isThemeMode && dataset.endsWith('User') && attributeToCurrentUser;
  const isSubmitDisabled = config.mode !== 'production' && isUserDataset;

  const toRelationFieldFormat = (name: string) => `_${toDatasetFormat(name)}`;

  const childrenDatasets = isThemeMode
    ? exampleChildrenDatasetsList
    : parentDataset?.childrenDatasets.filter((dataset) => {
        const transformedDatasetName = toRelationFieldFormat(dataset);
        const filteredField = fieldsCustomization?.find(({ name }) => name === transformedDatasetName);

        return filteredField?.visible ? dataset : null;
      });

  return (
    <FormProvider {...methods}>
      <ChakraFlex
        as="form"
        marginBottom="pt-none"
        flexDirection="column"
        gridGap="pt-md"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <ParentForm
          inheritedData={inheritedData}
          user={user}
          dataset={parentDataset}
          datasets={datasets}
          operation={operation}
          datasetName={dataset}
          executeApiOperation={executeApiOperation}
          attributeToCurrentUser={attributeToCurrentUser}
          datasetsFieldsReference={datasetsFieldsReference}
          fieldsCustomization={fieldsCustomization}
        />
        {(childrenDatasets?.length > 0 || isThemeMode) && (
          <ChakraTabs variant="unstyled">
            <ChakraTabList>
              {(childrenDatasets ?? []).map((name) => (
                <ChakraTab isTruncated={true} key={name} sx={dynamicFormChildrenTabsStyles}>
                  <chakra.p isTruncated={true}>{name}</chakra.p>
                </ChakraTab>
              ))}
            </ChakraTabList>
            <ChakraTabPanels>
              {(childrenDatasets ?? []).map((name) => (
                <ChakraTabPanel key={name} sx={dynamicFormChildrenTabPanelStyles}>
                  <ChildForm
                    datasets={datasets}
                    updateId={updateId}
                    operation={operation}
                    datasetName={name}
                    parentDatasetName={dataset}
                    executeApiOperation={executeApiOperation}
                    attributeToCurrentUser={attributeToCurrentUser}
                    datasetsFieldsReference={datasetsFieldsReference}
                  />
                </ChakraTabPanel>
              ))}
            </ChakraTabPanels>
          </ChakraTabs>
        )}
        <ChakraFlex sx={dynamicFormButtonContainerStyles}>
          <ChakraButton
            type={!isThemeMode ? 'submit' : 'button'}
            isDisabled={isSubmitDisabled}
            isFullWidth={isFormButtonFullWidth}
            sx={buttonStyles}
          >
            {isThemeMode ? 'Submit' : customButtonLabel || capitalize(operation)}
          </ChakraButton>
        </ChakraFlex>
      </ChakraFlex>
    </FormProvider>
  );
};
