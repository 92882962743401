import React, { FC, useContext, useMemo } from 'react';

import {
  Flex as ChakraFlex,
  Heading as ChakraHeading,
  Text as ChakraText,
  useMultiStyleConfig,
  useToken,
} from '@chakra-ui/react';
import { ResponsivePie } from '@nivo/pie';

import { ComponentReceivedProps } from '@types';

import { CompilerContext } from '@application/compiler/contexts';
import { generateShadeColors } from '@application/components/Theme/components/utils';

import { defaultProps } from './protocol';
import { PieChartProps } from '.';

const exampleData = [
  { id: 'Coffee', value: 20 },
  { id: 'Rice', value: 30 },
  { id: 'Tomatoes', value: 70 },
  { id: 'Apple', value: 60 },
];

export const component: FC<ComponentReceivedProps<PieChartProps, unknown>> = ({ props = defaultProps }) => {
  const { chartData, chartCategory, chartValue } = props;

  const { config } = useContext(CompilerContext);
  const { 'chart-slices': slicesStyle, 'chart-labels': labelsStyle } = useMultiStyleConfig('PTPieChart', {});

  const [labelsFontSize] = useToken('fontSizes', [labelsStyle.fontSize as string]);
  const [labelsFontWeight] = useToken('fontWeights', [labelsStyle.fontWeight as string]);
  const [slicesBackground, slicesFontColor, labelsFontColor] = useToken('colors', [
    slicesStyle.background as string,
    slicesStyle.color as string,
    labelsStyle.color as string,
  ]);

  if ((!chartData || !chartCategory || !chartValue) && config.mode !== 'theme')
    return (
      <ChakraFlex width="100%" height="100%">
        <ChakraFlex
          width="100%"
          height="100%"
          border="pt-sm"
          gridGap="pt-sm"
          background="pt-ui.50"
          alignItems="center"
          borderStyle="dashed"
          borderColor="pt-info.200"
          borderRadius="pt-xs"
          flexDirection="column"
          justifyContent="center"
        >
          <ChakraHeading color="pt-info.500" fontSize="pt-xl">
            Missing configuration
          </ChakraHeading>
          <ChakraText maxWidth="20rem" color="pt-ui.500" textAlign="center">
            For the propertly work of the chart you need to define a{' '}
            <ChakraText as="span" color="pt-info.500">
              data
            </ChakraText>
            ,{' '}
            <ChakraText as="span" color="pt-info.500">
              category
            </ChakraText>{' '}
            and{' '}
            <ChakraText as="span" color="pt-info.500">
              value
            </ChakraText>
            .
          </ChakraText>
        </ChakraFlex>
      </ChakraFlex>
    );

  const isThemeMode = useMemo(() => config.mode === 'theme', [config.mode]);

  const getFormatedData = useMemo(() => {
    if (!isThemeMode) {
      const items: {
        id: string;
        value: number;
      }[] = [];

      const formatedChartData = chartData?.map(
        (item) =>
          item[chartCategory] &&
          item[chartValue] && {
            id: item[chartCategory],
            value: item[chartValue] as number,
          },
      );

      formatedChartData.reduce((previous, current) => {
        if (!previous[current?.id]) {
          previous[current?.id] = {
            id: current?.id,
            value: 0,
          };

          items.push(previous[current?.id]);
        }

        previous[current?.id].value += current?.value;

        return previous;
      }, {});

      return items;
    }
  }, [chartData]);

  return (
    <ResponsivePie
      data={isThemeMode ? exampleData : getFormatedData}
      colors={Object.values(generateShadeColors(slicesBackground))}
      margin={{ top: 60, bottom: 60, left: 70, right: 70 }}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      borderWidth={1}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      arcLinkLabelsTextColor={labelsFontColor}
      theme={{
        labels: {
          text: {
            fontSize: labelsFontSize,
            fontWeight: labelsFontWeight,
            fill: slicesFontColor,
          },
        },
      }}
    />
  );
};
