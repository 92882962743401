import React, { FC, useMemo } from 'react';

import {
  Flex as ChakraFlex,
  Popover as ChakraPopover,
  PopoverAnchor as ChakraPopoverAnchor,
  PopoverArrow as ChakraPopoverArrow,
  PopoverBody as ChakraPopoverBody,
  PopoverContent as ChakraPopoverContent,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import { Button, Heading, Icon, Input, Text } from '@application/components/ds';

import { Event } from '../component';

export interface CalendarPopoverProps {
  event: Event;
  isOpen: boolean;
  onClose: () => void;
  onDelete: (event: Event) => void;
  onEdit: (event: Event) => void;
}

export const CalendarPopover: FC<CalendarPopoverProps> = ({
  event,
  isOpen,
  onClose,
  onDelete,
  onEdit,
  children,
}) => {
  const { watch, register } = useForm({
    defaultValues: event,
  });

  const values = watch();

  const hasInvalidForm = useMemo(() => !values.title || !values.start || !values.end, [values]);

  return (
    <ChakraPopover placement="auto" matchWidth={true} isOpen={isOpen} onClose={() => onClose()}>
      <ChakraPopoverAnchor>{children}</ChakraPopoverAnchor>
      <ChakraPopoverContent boxShadow="large" borderColor="ui.200" borderRadius="xs">
        <ChakraPopoverArrow />
        <ChakraPopoverBody padding="md">
          <ChakraFlex gridGap="md" flexDirection="column">
            <Heading color="ui.900" variant="subtitle">
              Edit event
            </Heading>
            <ChakraFlex width="100%" gridGap="sm" flexDirection="column">
              <ChakraFlex gridGap="sm" flexDirection="column">
                <Text color="ui.700" variant="body-2">
                  Event Title
                </Text>
                <Input
                  width="100%"
                  placeholder="Insert event title..."
                  value={values?.title}
                  {...register('title')}
                />
              </ChakraFlex>
              <ChakraFlex gridGap="sm">
                <ChakraFlex width="50%" gridGap="sm" flexDirection="column">
                  <Text width="fit-content" color="ui.700" variant="body-2">
                    Event Start
                  </Text>
                  <Input
                    width="100%"
                    type="datetime-local"
                    value={moment(values?.start).format('YYYY-MM-DDTHH:mm')}
                    {...register('start')}
                  />
                </ChakraFlex>
                <ChakraFlex width="50%" gridGap="sm" flexDirection="column">
                  <Text width="fit-content" color="ui.700" variant="body-2">
                    Event End
                  </Text>
                  <Input
                    width="100%"
                    type="datetime-local"
                    value={moment(values?.end).format('YYYY-MM-DDTHH:mm')}
                    {...register('end')}
                  />
                </ChakraFlex>
              </ChakraFlex>
            </ChakraFlex>
            <ChakraFlex alignItems="center" justifyContent="space-between">
              <Icon
                name="delete"
                color="supportA.300"
                cursor="pointer"
                transitionDuration="0.3s"
                _hover={{
                  color: 'supportA.500',
                }}
                onClick={() => onDelete(values)}
              />
              <ChakraFlex gridGap="sm" alignItems="center">
                <Button variant="secondary" onClick={() => onClose()}>
                  Cancel
                </Button>
                <Button isDisabled={hasInvalidForm} onClick={() => onEdit(values)}>
                  Submit
                </Button>
              </ChakraFlex>
            </ChakraFlex>
          </ChakraFlex>
        </ChakraPopoverBody>
      </ChakraPopoverContent>
    </ChakraPopover>
  );
};
