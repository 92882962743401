import { ThemeMultipartComponent } from '@types';

export type MenuSizes = 'sm' | 'md' | 'lg' | string;
export type MenuVariants = 'default';
export type MenuParts = 'menu-button' | 'menu-icon-button' | 'menu-list' | 'menu-item';

export const PTMenu: ThemeMultipartComponent<MenuSizes, MenuVariants, MenuParts> = {
  parts: ['menu-button', 'menu-icon-button', 'menu-list', 'menu-item'],

  variants: {
    default: {
      'menu-button': {
        paddingX: 'pt-md',
        paddingY: 'pt-sm',

        border: 'pt-sm',
        borderRadius: 'pt-xs',

        whiteSpace: 'nowrap',
        fontFamily: 'pt-body',
        fontSize: 'pt-md',

        color: 'pt-ui.500',
        background: 'pt-transparent',
        borderColor: 'pt-ui.100',

        _hover: {
          background: 'pt-ui.50',
          borderColor: 'pt-ui.200',
        },
      },
      'menu-icon-button': {
        paddingX: 'pt-sm',
        paddingY: 'pt-sm',

        border: 'pt-none',
        borderRadius: 'pt-xs',

        whiteSpace: 'nowrap',
        fontSize: 'pt-md',
        fontFamily: 'pt-body',

        color: 'pt-ui.500',
        background: 'pt-transparent',
        borderColor: 'pt-transparent',

        _hover: {
          background: 'pt-ui.50',
          borderColor: 'pt-transparent',
        },
      },
      'menu-list': {
        paddingY: 'pt-sm',

        border: 'pt-sm',
        borderRadius: 'pt-xs',

        background: 'pt-white',
        borderColor: 'pt-ui.200',
      },
      'menu-item': {
        color: 'pt-ui.700',
        background: 'pt-transparent',

        fontSize: 'pt-sm',
        fontFamily: 'pt-body',

        _focus: {
          background: 'pt-ui.200',
        },

        _hover: {
          background: 'pt-ui.200',
        },
      },
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: null,
  },
};
