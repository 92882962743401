import { ComponentBlock } from '@types';

import { types } from 'core/types';

import { component } from './component';
import { ImageUploadProps, ImageUploadTriggers } from './index';

export const block: ComponentBlock<'imageUpload', ImageUploadProps, ImageUploadTriggers> = {
  id: 'imageUpload',
  render: component,
  props: {
    name: {
      type: types.string,
    },
    value: {
      type: types.string,
    },
    label: {
      type: types.string,
    },
    size: {
      type: types.string,
    },
  },
  triggers: {
    onChange: {
      name: 'On change',
      description: 'When the control is changed',
    },
  },
};
