import { ComponentBlock } from '@types';

import { types } from '@application/core/types';

import { component } from './component';
import { ContainerProps } from './index';

export const block: ComponentBlock<'container', ContainerProps, unknown> = {
  id: 'container',
  render: component,
  props: {
    width: {
      type: types.string,
    },
    height: {
      type: types.string,
    },
  },
};
