import React, { FC, useEffect, useState } from 'react';

import { Flex as ChakraFlex, FlexProps as ChakraFlexProps } from '@chakra-ui/react';
import { DragDropContext, DragDropContextProps, DropResult, ResponderProvided } from 'react-beautiful-dnd';

export type { DropResult, ResponderProvided } from 'react-beautiful-dnd';

export type BoardProps = DragDropContextProps & Omit<ChakraFlexProps, 'onDragEnd'>;

export const Board: FC<BoardProps> = ({ children, onDragEnd, ...rest }) => {
  return (
    <ChakraFlex
      width="100%"
      height="100%"
      border="sm"
      padding="md"
      gridGap="lg"
      overflow="auto"
      background="white"
      borderColor="ui.200"
      borderRadius="sm"
      boxShadow="large"
      userSelect="none"
      {...rest}
    >
      <DragDropContext onDragEnd={onDragEnd} {...rest}>
        {children}
      </DragDropContext>
    </ChakraFlex>
  );
};
