import React, { FC, useContext, useMemo } from 'react';

import { ASTNode } from 'graphql';
import {
  chakra,
  Alert as ChakraAlert,
  AlertDescription as ChakraAlertDescription,
  AlertIcon as ChakraAlertIcon,
  AlertTitle as ChakraAlertTitle,
  Flex as ChakraFlex,
  Spinner as ChakraSpinner,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { get } from 'lodash';
import { GraphQLClient } from 'graphql-request/dist';
import { print } from 'graphql/language/printer';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { v4 as uuidV4 } from 'uuid';

import { ComponentReceivedProps } from '@types';

import { CompilerContext } from '@application/compiler/contexts';
import { Freemium } from '@application/components/common';
import { toDatasetFormat } from '@application/utils/stringManipulation';
import { useIntrospectionFactory } from '@application/lib/customHooks/useIntrospection';

import { DynamicForm } from './components';
import { handleSubmit } from './components/helpers/handleSubmit';

import { defaultProps } from './protocol';
import { DynamicFormProps, DynamicFormTriggers } from '.';

export const component: FC<ComponentReceivedProps<DynamicFormProps, DynamicFormTriggers>> = ({
  props = defaultProps,
  inheritedData,
  triggers,
}) => {
  const {
    dataset,
    operation,
    updateId,
    datasetsFieldsReference,
    attributeToCurrentUser,
    variant = 'default',
    customButtonLabel,
    headerDescription,
    headerTitle,
    isFormButtonFullWidth,
    fieldsCustomization,
    ...rest
  } = props;
  const { onFailure, onSuccess } = triggers;
  const { user } = inheritedData;

  const { config } = useContext(CompilerContext);
  const {
    'dynamic-form-container': dynamicFormContainerStyles,
    'dynamic-form-header-container': dynamicFormHeaderContainerStyles,
    'dynamic-form-header-title': dynamicFormHeaderTitleStyles,
    'dynamic-form-header-description': dynamicFormHeaderDescriptionStyles,
  } = useMultiStyleConfig('PTDynamicForm', { variant });

  const { endpoint } = config.api;

  const [introspectionFactory, introspectionIsLoading] = useIntrospectionFactory(endpoint, user?.token);

  const datasets = introspectionFactory?.datasets ?? {};

  const isThemeMode = config.mode === 'theme';

  async function executeApiOperation(
    operation: ASTNode,
    operationName: string,
    variables: Record<string, any>,
  ) {
    const resp = await new GraphQLClient(endpoint, {
      headers: { Authorization: user.token },
    }).request(print(operation), variables);

    return resp[operationName] ?? {};
  }

  const formInitialState = useQuery<Record<string, any>>(
    [operation, dataset, updateId],
    async () => {
      const parentDataset = datasets[dataset];
      if (!parentDataset) return {};

      if (operation === 'create') {
        return {
          id: uuidV4(),
          datasets: {},
        };
      }

      const parentGetOperation = parentDataset?.operations.get;

      const parentResp = await executeApiOperation(
        parentGetOperation.operation,
        parentGetOperation.operationName,
        {
          id: updateId,
        },
      );

      const childrenDatasets: Record<string, unknown> = {};

      for (const childDatasetName of parentDataset.childrenDatasets) {
        const respField = `_${toDatasetFormat(childDatasetName)}`;

        childrenDatasets[childDatasetName] = get(parentResp, [respField, 'items']);
      }

      return {
        // This Id is used for reRender the form after each update
        id: uuidV4(),
        datasets: {
          ...childrenDatasets,
          [dataset]: parentResp,
        },
      };
    },
    {
      enabled: !introspectionIsLoading && !isThemeMode,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const onSubmitMutation = useMutation(
    (submittedValues: Record<string, any>) =>
      handleSubmit({
        datasets,
        dataset,
        operation,
        updateId,
        initialValues: formInitialState.data.datasets,
        executeApiOperation,
        refetchInitialValues: formInitialState.refetch,
      })(submittedValues),
    {
      onSuccess(data) {
        toast.success(`Your record was ${operation}d!`);

        if (onSuccess) {
          onSuccess({
            ...inheritedData,
            $resp: data ?? {},
          });
        }
      },
      onError(err) {
        toast.error('An error was ocurred');

        if (onFailure) {
          onFailure({
            ...inheritedData,
            $error: err,
          });
        }
      },
    },
  );

  if (!config.accountId && !isThemeMode) {
    return <Freemium isFreemium={true} />;
  }

  if ((!operation || !dataset) && !isThemeMode) {
    return (
      <ChakraFlex
        width="100%"
        height="100%"
        {...rest}
        border="pt-sm"
        padding="pt-lg"
        boxShadow="pt-large"
        borderRadius="pt-sm"
        alignItems="center"
        justifyContent="center"
        background="pt-danger.100"
        borderColor="pt-danger.200"
      >
        <chakra.p textAlign="center" fontSize="pt-md" fontFamily="pt-body" color="pt-danger.500">
          <chakra.span textDecoration="underline" fontWeight="pt-bold">
            Dataset
          </chakra.span>{' '}
          or{' '}
          <chakra.span textDecoration="underline" fontWeight="pt-bold">
            operation
          </chakra.span>{' '}
          were not defined
        </chakra.p>
      </ChakraFlex>
    );
  }

  if (operation === 'update' && !updateId && !isThemeMode) {
    return (
      <ChakraFlex
        width="100%"
        height="100%"
        {...rest}
        border="pt-sm"
        padding="pt-lg"
        boxShadow="pt-large"
        borderRadius="pt-sm"
        alignItems="center"
        justifyContent="center"
        background="pt-danger.100"
        borderColor="pt-danger.200"
      >
        <chakra.p textAlign="center" fontSize="pt-md" fontFamily="pt-body" color="pt-danger.500">
          <chakra.span textDecoration="underline" fontWeight="bold">
            UpdateId
          </chakra.span>{' '}
          was not defined
        </chakra.p>
      </ChakraFlex>
    );
  }

  const isLoading = useMemo(
    () =>
      !user ||
      introspectionIsLoading ||
      formInitialState.isLoading ||
      formInitialState.isIdle ||
      onSubmitMutation.isLoading,
    [
      !!user,
      introspectionIsLoading,
      formInitialState.isLoading,
      formInitialState.isIdle,
      onSubmitMutation.isLoading,
    ],
  );

  const LoadingComponent = () =>
    isLoading &&
    !fieldsCustomization?.length &&
    !isThemeMode && (
      <ChakraFlex
        top="0"
        left="0"
        width="100%"
        height="100%"
        padding="pt-lg"
        opacity="0.8"
        position="absolute"
        background="pt-white"
        borderRadius="pt-sm"
        borderColor="pt-ui.200"
        justifyContent="center"
        alignItems="center"
      >
        <ChakraSpinner thickness="3px" speed="0.5s" emptyColor="pt-ui.200" color="pt-primary.500" size="xl" />
      </ChakraFlex>
    );

  if (onSubmitMutation.isSuccess) {
    return (
      <ChakraAlert
        width="100%"
        {...rest}
        height="200px"
        status="success"
        variant="subtle"
        textAlign="center"
        border="pt-sm"
        borderColor="pt-success.200"
        borderRadius="pt-sm"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        boxShadow="large"
      >
        <ChakraAlertIcon boxSize="2rem" marginRight="pt-none" />
        <ChakraAlertTitle
          color="pt-success.800"
          fontSize="pt-lg"
          fontFamily="pt-body"
          lineHeight="pt-none"
          marginTop="pt-md"
          marginRight="pt-none"
          marginBottom="pt-sm"
        >
          Application submitted!
        </ChakraAlertTitle>
        <ChakraAlertDescription
          color="pt-success.700"
          maxWidth="sm"
          lineHeight="pt-none"
          marginBottom="pt-md"
        >
          The submittion was saved successfully
        </ChakraAlertDescription>
        <ChakraAlertDescription
          color="pt-success.600"
          cursor="pointer"
          maxWidth="sm"
          fontSize="pt-sm"
          lineHeight="pt-none"
          _hover={{
            textDecoration: 'underline',
          }}
          onClick={() => onSubmitMutation.reset()}
        >
          Send a new one
        </ChakraAlertDescription>
      </ChakraAlert>
    );
  }

  if (onSubmitMutation.isError) {
    return (
      <ChakraAlert
        width="100%"
        {...rest}
        height="200px"
        status="error"
        variant="subtle"
        textAlign="center"
        alignItems="center"
        border="pt-sm"
        borderRadius="pt-sm"
        borderColor="pt-danger.200"
        colorScheme="pt-danger"
        flexDirection="column"
        justifyContent="center"
        boxShadow="large"
      >
        <ChakraAlertIcon boxSize="2rem" marginRight="pt-none" />
        <ChakraAlertTitle
          color="pt-danger.800"
          fontSize="pt-lg"
          fontFamily="pt-body"
          lineHeight="pt-none"
          marginTop="pt-md"
          marginRight="pt-none"
          marginBottom="pt-sm"
        >
          An error has ocurred!
        </ChakraAlertTitle>
        <ChakraAlertDescription maxWidth="sm" lineHeight="pt-none" color="pt-danger.700" marginBottom="pt-md">
          Try again later or contact our support
        </ChakraAlertDescription>
        <ChakraAlertDescription
          color="pt-danger.600"
          cursor="pointer"
          fontSize="pt-sm"
          maxWidth="sm"
          lineHeight="pt-none"
          _hover={{
            textDecoration: 'underline',
          }}
          onClick={() => onSubmitMutation.reset()}
        >
          Send a new one
        </ChakraAlertDescription>
      </ChakraAlert>
    );
  }

  return (
    <ChakraFlex
      width={isThemeMode ? '35%' : '100%'}
      height={isThemeMode ? 'fit-content' : '100%'}
      position="relative"
      overflow={isLoading && !isThemeMode ? 'hidden' : 'auto'}
      sx={dynamicFormContainerStyles}
      {...rest}
    >
      {(headerTitle || headerDescription || isThemeMode) && (
        <ChakraFlex sx={dynamicFormHeaderContainerStyles}>
          {(headerTitle || isThemeMode) && (
            <chakra.p sx={dynamicFormHeaderTitleStyles}>{headerTitle || 'Example title'}</chakra.p>
          )}
          {(headerDescription || isThemeMode) && (
            <chakra.p sx={dynamicFormHeaderDescriptionStyles}>
              {headerDescription ||
                'Lorem, ipsum dolor sit amet elit. Quasi rem aut delectus ducimus, molestias!'}
            </chakra.p>
          )}
        </ChakraFlex>
      )}
      <DynamicForm
        key={formInitialState.data?.id}
        inheritedData={inheritedData}
        user={user}
        dataset={dataset}
        datasets={datasets}
        updateId={updateId}
        operation={operation}
        customButtonLabel={customButtonLabel}
        isFormButtonFullWidth={isFormButtonFullWidth}
        initialValues={formInitialState.data?.datasets}
        attributeToCurrentUser={attributeToCurrentUser}
        // @ts-ignore
        onSubmit={onSubmitMutation.mutate}
        executeApiOperation={executeApiOperation}
        datasetsFieldsReference={datasetsFieldsReference}
        fieldsCustomization={fieldsCustomization}
      />
      <LoadingComponent />
    </ChakraFlex>
  );
};
