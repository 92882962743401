import { CreateDeploymentInput, CreateDeploymentResponse } from '@api/routes/admin';

import { apiRequestClient } from './index';

export const createDeployment = async (body: CreateDeploymentInput) =>
  apiRequestClient()
    .method('post')
    .append('/api/admin/deployments/create-deployment')
    .setData(body)
    .build<CreateDeploymentResponse>();
