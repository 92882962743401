import { CompilerComponent, ComponentBlockTriggerConfig } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface MutationContainerProps {
  operationName: string;
}

export interface MutationContainerEvents {
  mutation: (...args: any) => any;
}

export interface MutationContainerTriggers {
  onSuccess: ComponentBlockTriggerConfig[];
  onFailure: ComponentBlockTriggerConfig[];
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<MutationContainerProps>;
