import React, { FC } from 'react';

import { chakra, Flex as ChakraFlex, useMultiStyleConfig } from '@chakra-ui/react';
import { HeaderProps } from 'react-big-calendar';
import moment from 'moment';

export const CalendarWeekHeader: FC<HeaderProps> = ({ date }) => {
  const {
    'calendar-week-header': calendarWeekHeaderStyles,
    'calendar-week-header-date': calendarWeekHeaderDateStyles,
    'calendar-week-header-date-current': calendarWeekHeaderDateCurrentStyles,
  } = useMultiStyleConfig('PTCalendar', {});

  const isSameDay = moment().isSame(moment(date), 'day');

  return (
    <ChakraFlex sx={calendarWeekHeaderStyles}>
      <chakra.p
        color={calendarWeekHeaderStyles.color as string}
        fontSize={calendarWeekHeaderStyles.fontSize as string}
        fontFamily={calendarWeekHeaderStyles.fontFamily as string}
      >
        {moment(date).format('ddd')}
      </chakra.p>
      <ChakraFlex
        background={isSameDay && (calendarWeekHeaderDateCurrentStyles.background as string)}
        sx={{
          ...calendarWeekHeaderDateStyles,
          color: isSameDay
            ? (calendarWeekHeaderDateCurrentStyles.color as string)
            : (calendarWeekHeaderDateStyles.color as string),
        }}
      >
        {moment(date).format('DD')}
      </ChakraFlex>
    </ChakraFlex>
  );
};
