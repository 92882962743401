import { BreadcrumbParts } from '@application/lib/defaultTheme';

import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export type BreadcrumbProps = Record<string, unknown>;

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<BreadcrumbProps, BreadcrumbParts>;
