import React, { FC } from 'react';

import { Img as ChakraImg } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { ImageProps } from './index';

export const component: FC<ComponentReceivedProps<ImageProps, unknown>> = ({ props = defaultProps }) => {
  const { url, width, height, ...rest } = props;

  return (
    <ChakraImg
      objectFit="cover"
      src={url}
      alt={rest.name}
      minWidth={width}
      maxWidth={width}
      maxHeight={height}
      minHeight={height}
      {...rest}
    />
  );
};
