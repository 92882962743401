import { ThemeMultipartComponent } from '@types';

type CardSizes = 'sm' | 'md' | 'lg';
type CardVariants = 'default';

type CardParts = 'card' | 'card-header' | 'card-body';

export const PTCard: ThemeMultipartComponent<CardSizes, CardVariants, CardParts> = {
  parts: ['card', 'card-header', 'card-body'],

  baseStyle: {
    card: {
      border: 'pt-sm',
      boxShadow: 'pt-large',
      background: 'pt-white',
      borderColor: 'pt-ui.100',
      borderRadius: 'pt-sm',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    'card-header': {
      color: 'pt-ui.700',
      border: 'pt-none',
      borderBottom: 'pt-sm',
      borderColor: 'pt-ui.100',
      background: 'pt-white',
    },
    'card-body': {
      background: 'pt-white',
    },
  },

  sizes: {
    sm: {
      'card-header': {
        paddingY: 'pt-sm',
        paddingX: 'pt-md',
      },
      'card-body': {
        paddingY: 'pt-sm',
        paddingX: 'pt-md',
      },
    },
    md: {
      'card-header': {
        paddingY: 'pt-md',
        paddingX: 'pt-lg',
      },
      'card-body': {
        paddingY: 'pt-md',
        paddingX: 'pt-lg',
      },
    },
    lg: {
      'card-header': {
        paddingY: 'pt-lg',
        paddingX: 'pt-xl',
      },
      'card-body': {
        paddingY: 'pt-lg',
        paddingX: 'pt-xl',
      },
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: null,
  },
};
