import { ASTNode } from 'graphql';
import { omit } from 'lodash';

import { FactoryDataset } from '@introspection/factories/DatasetsOperationsFactory';

export type AvailableOperations = 'create' | 'update' | 'delete';

interface HandleSubmitOperationArgs {
  dataset: FactoryDataset;
  operation: AvailableOperations;
  executeApiOperation: (
    operation: ASTNode,
    operationName: string,
    variables: Record<string, any>,
  ) => Record<string, any>;
}

export const handleOperationSubmit = ({
  dataset,
  operation,
  executeApiOperation,
}: HandleSubmitOperationArgs) => {
  return async (values: Record<string, any>): Promise<any> => {
    const handleCreate = () => {
      const {
        create: { operation, operationName },
      } = dataset.operations;

      const response = executeApiOperation(operation, operationName, {
        input: omit(values, ['id']),
      });

      return response;
    };

    const handleUpdate = () => {
      const {
        update: { operation, operationName },
      } = dataset.operations;

      const response = executeApiOperation(operation, operationName, {
        id: values.id,
        input: omit(values, ['id']),
      });

      return response;
    };

    const handleDelete = () => {
      const {
        delete: { operation, operationName },
      } = dataset.operations;

      const response = executeApiOperation(operation, operationName, {
        id: values.id,
      });

      return response;
    };

    if (operation === 'create') {
      return handleCreate();
    }

    if (operation === 'update') {
      return handleUpdate();
    }

    if (operation === 'delete') {
      return handleDelete();
    }
  };
};
