import { find, get } from 'lodash';

import { EPropType, Introspection, PropType } from '@types';

/**
 * This factory it's used as base to the other factories and it provides base and helper methods
 */
export class BaseIntrospectionFactory {
  protected introspectionTypes: Introspection.IntrospectionType[];

  constructor(introspectionTypes: Introspection.IntrospectionType[]) {
    this.introspectionTypes = introspectionTypes;
  }

  /**
   * Returns the query field of the schema
   */
  get queryType(): Introspection.IntrospectionType {
    return this.findTypeByName(Introspection.OperationsEnum.Query);
  }

  /**
   * Returns the mutation field of the schema
   */
  get mutationType(): Introspection.IntrospectionType {
    return this.findTypeByName(Introspection.OperationsEnum.Mutation);
  }

  /**
   * Checks if the dataset is a nested dataset
   * GSTUDIO-API-ENGINE exclusevely
   */
  typeIsNestedDataset(typeName: string): boolean {
    return typeName.startsWith('_') || typeName.endsWith('Relation');
  }

  /**
   * Returns the type by the name
   */
  findTypeByName(name: string): Introspection.IntrospectionType {
    return find(this.introspectionTypes, { name });
  }

  /**
   * Handles the find of the provided arg type
   */
  findArgType(arg: Introspection.IntrospectionArgumentsProps): Introspection.IntrospectionType {
    if (arg.type?.kind === 'NON_NULL') {
      return this.findTypeByName(arg.type.ofType?.name);
    }

    return this.findTypeByName(arg.type.name);
  }

  /**
   * Handles the find of the provided field type
   */
  findFieldType(field: Introspection.IntrospectionType): Introspection.IntrospectionType {
    if (field.type?.ofType) {
      return this.findTypeByName(field.type.ofType?.name);
    }

    return this.findTypeByName(field.type.name);
  }

  /**
   * Handles the find of the provided inputField type
   */
  findInputObjectFieldType(inputFieldType: Introspection.IntrospectionType): Introspection.IntrospectionType {
    if (!inputFieldType.type) {
      return this.findTypeByName(inputFieldType.name);
    }

    if (inputFieldType.type?.kind === 'NON_NULL') {
      return this.findTypeByName(inputFieldType.type.ofType?.name);
    }

    return this.findTypeByName(inputFieldType.type.name);
  }

  /**
   * Returns the provided type accepted propType
   */
  getTypeTypping(type: Introspection.IntrospectionType): PropType {
    if (!type) return 'Any';

    if (type.kind === 'ENUM') {
      return EPropType.Enum;
    }

    if (type.kind === 'SCALAR') {
      return this.scalarTypeToPropType(type.name);
    }

    if (type.kind === 'OBJECT' || type.kind === 'INPUT_OBJECT') {
      return EPropType.Object;
    }

    if (type.kind === 'LIST') {
      return 'List';
    }

    return 'Any';
  }

  /**
   * Returns the accepted proptype from a scalar graphqlType
   */
  scalarTypeToPropType(type: string): PropType {
    const dictionary: Record<Introspection.GrapqhQLScalarsType, PropType> = {
      Int: EPropType.Int,
      Float: EPropType.Int,
      String: EPropType.String,
      Boolean: EPropType.Boolean,
      ID: EPropType.Id,
    };

    return get(dictionary, type, 'Any');
  }

  mountEnumOptions(enumName: string): string[] {
    const enumType = this.findTypeByName(enumName);

    return enumType.enumValues.map(({ name }) => name);
  }
}
