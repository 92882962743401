import React, { cloneElement, FC, isValidElement, useContext } from 'react';

import { Box, Tooltip } from '@chakra-ui/react';
import { CompilerContext, EngineContext } from '../contexts';
import { isEmpty } from 'lodash';
import { Loading } from 'components/ds';
import { Node } from '@types';
import { useQuery } from 'react-query';

interface ConditionEngineWrapperProps {
  node: Node;
  inheritedData?: Record<string, any>;
}

export const ConditionEngineWrapper: FC<ConditionEngineWrapperProps> = ({
  node,
  inheritedData,
  children,
}) => {
  const { config } = useContext(CompilerContext);
  const { engine } = useContext(EngineContext);

  const { condition } = node.block;

  const hasCondition = !isEmpty(condition?.operation) && condition.operation.operator;

  const conditionQuery = useQuery(
    ['node', node.id, 'condition'],
    async () => {
      return engine.runOperation(condition.operation, inheritedData);
    },
    {
      enabled: !!hasCondition,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const newInheritedData = inheritedData ?? {};
  if (inheritedData.$condition) delete newInheritedData.$condition;

  if (hasCondition) {
    newInheritedData.$condition = conditionQuery.data;
  }

  const ChildrenWithResult = React.Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        inheritedData: newInheritedData,
      });
    }
  });

  if (!hasCondition) return <>{ChildrenWithResult}</>;

  return (
    <Loading isLoading={conditionQuery.isIdle || conditionQuery.isLoading}>
      {!condition.useResultForRender || conditionQuery.data ? (
        ChildrenWithResult
      ) : (
        <>
          {config.mode === 'production' ? null : (
            <Tooltip label="This component will be hidden when in production">
              <Box opacity={0.5}>{ChildrenWithResult}</Box>
            </Tooltip>
          )}
        </>
      )}
    </Loading>
  );
};
