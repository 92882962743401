/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ThemeMultipartComponent } from '@types';

import { AlertDialogProps } from 'components/ds';

export type AlertDialogSizes = AlertDialogProps['size'];
export type AlertDialogVariants = 'default';

type AlertDialogParts =
  | 'alert-dialog-content'
  | 'alert-dialog-body'
  | 'alert-dialog-header'
  | 'alert-dialog-header-close'
  | 'alert-dialog-footer';

export const AlertDialog: ThemeMultipartComponent<AlertDialogSizes, AlertDialogVariants, AlertDialogParts> = {
  parts: [
    'alert-dialog-content',
    'alert-dialog-body',
    'alert-dialog-header',
    'alert-dialog-header-close',
    'alert-dialog-footer',
  ],

  baseStyle: () => ({
    'alert-dialog-content': {
      borderRadius: 'sm',
    },
    'alert-dialog-body': {
      paddingY: 'lg',
      paddingX: 'lg',
      paddingTop: 'unset',
    },
    'alert-dialog-header': {
      display: 'flex',
      fontWeight: 'none',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    'alert-dialog-header-close': {
      borderRadius: 'sm',
      color: 'ui.500',
      _hover: {
        color: 'ui.800',
        background: 'ui.200',
      },
    },
    'alert-dialog-footer': {
      paddingX: 'lg',
      gridGap: 'sm',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderTop: 'sm',
      borderColor: 'ui.200',
    },
  }),

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: null,
  },
};
