import { ComponentBlock } from '@types';

import { types } from '@application/core/types';

import { CalendarProps } from './index';
import { component } from './component';

export const block: ComponentBlock<'calendar', CalendarProps, unknown> = {
  id: 'calendar',
  render: component,
  props: {
    calendarData: {
      type: types.list,
    },
    calendarDataCollection: {
      type: types.string,
    },
    calendarEventTitleProperty: {
      type: types.string,
    },
    calendarEventStartProperty: {
      type: types.string,
    },
    calendarEventEndProperty: {
      type: types.string,
    },
    calendarEventAllDayProperty: {
      type: types.string,
    },
    width: {
      type: types.string,
    },
    height: {
      type: types.string,
    },
    fieldsRelations: {
      type: types.object,
    },
  },
};
