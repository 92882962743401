import gql from 'graphql-tag';

export const GetDeployment = gql`
  query GetDeployment($id: ID!) {
    getDeployment(id: $id) {
      id
      organizationId
      projectId
      themeId

      config

      layouts

      pages {
        id

        name
        path
        role

        nodesStructure
      }

      # That's all we need to mount the deployment
      theme {
        id

        theme
        customFonts {
          link
          name
        }
      }
    }
  }
`;

export const ListDeployments = gql`
  query ListDeployments($organizationId: ID!, $projectId: ID!) {
    listDeployments(organizationId: $organizationId, projectId: $projectId) {
      items {
        id
        organizationId
        projectId
        themeId

        config
        pages {
          id

          organizationId

          name
          path
          role

          nodesStructure

          createdAt
          updatedAt

          expiresAt
        }

        createdAt
        expiresAt
        updatedAt
      }
    }
  }
`;

export const GetLastProjectDeployment = gql`
  query GetLastProjectDeployment($projectId: ID!) {
    getLastProjectDeployment(projectId: $projectId) {
      id
      organizationId
      projectId
      themeId

      layouts
      config

      pages {
        id

        name
        path
        role

        nodesStructure
      }

      # That's all we need to mount the deployment
      theme {
        id

        theme
        customFonts {
          link
          name
        }
      }
    }
  }
`;

export const GetLastProjectDeploymentByDomain = gql`
  query GetLastDeploymentByDomain($domain: String!) {
    getLastDeploymentByDomain(domain: $domain) {
      id
      organizationId
      projectId
      themeId

      layouts
      config

      pages {
        id

        name
        path
        role

        nodesStructure
      }

      # That's all we need to mount the deployment
      theme {
        id

        theme
        customFonts {
          link
          name
        }
      }
    }
  }
`;
