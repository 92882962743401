import { ProtocolFactory } from 'lib/protocols';

import { ListProps } from './index';

export const protocolFactory = new ProtocolFactory<ListProps>({
  name: 'List',
  editorProps: [],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
