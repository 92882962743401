import { ProtocolFactory } from 'lib/protocols';

import { IconProps } from './index';

export const protocolFactory = new ProtocolFactory<IconProps>({
  name: 'Icon',
  editorProps: [],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: true,
    disableLogicSection: true,
  },
  themeProps: ['size', 'icon', 'color', 'background'],
  themeConfig: {
    sizes: ['sm', 'md', 'lg'],
    defaultProps: ['size'],
    defaultConfig: {
      sizeProps: ['boxSize'],
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
