import { ComponentBlock } from '@types';

import { types } from '@application/core/types';

import { BoardProps, BoardTriggers } from './index';
import { component } from './component';

export const block: ComponentBlock<'board', BoardProps, BoardTriggers> = {
  id: 'board',
  render: component,
  props: {
    boardColumns: {
      type: types.list,
    },
    boardData: {
      type: types.list,
    },
    boardIsBlocked: {
      type: types.boolean,
    },
    boardCardTitleProperty: {
      type: types.string,
    },
    boardCardDescriptionProperty: {
      type: types.string,
    },
    boardCardTagProperty: {
      type: types.list,
    },
    boardCardTagPropertyOptions: {
      type: types.list,
    },
    boardCardColumnIndexProperty: {
      type: types.string,
    },
    boardCardRowIndexProperty: {
      type: types.number,
    },
    boardDataCollection: {
      type: types.string,
    },
    fieldsRelations: {
      type: types.object,
    },
  },
  triggers: {
    onDoubleClick: {
      name: 'On double click',
      description: 'When the card is double clicked',
    },
  },
};
