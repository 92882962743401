import { DocumentNode } from 'graphql';

import { find, merge, set } from 'lodash';

import { DataComponentField, EditorFormField, Introspection } from '@types';

import { capitalize } from '@application/utils/stringManipulation';

import { BaseIntrospectionFactory } from './BaseIntrospectionFactory';
import { OperationFactory } from './OperationFactory';
import { OperationTyppingsFactory } from './OperationTyppingsFactory';

export interface FactoryDataset {
  operations: DatasetCrud;
  childrenDatasets: string[];
  parentDatasets: string[];
}

type DatasetCrud = Record<
  Introspection.QueryNamePrefix | Introspection.MutationNamePrefix,
  IntrospectionOperation
>;

export interface IntrospectionOperation {
  operationName: string;
  operation: DocumentNode;
  inputArgs: Introspection.InputArguments;
  formFields: EditorFormField[];
  fields: DataComponentField[];
}

/**
 * It's used to create datasets operations and correlates its CRUD operations
 * @param operation {IntrospectionType} - The query/mutation/subscription operation that will be created
 */
export class DatasetsOperationsFactory extends BaseIntrospectionFactory {
  private operationFactory: OperationFactory;
  private operationTyppingsFactory: OperationTyppingsFactory;

  constructor(introspectionTypes: Introspection.IntrospectionType[]) {
    super(introspectionTypes);

    this.operationFactory = new OperationFactory(introspectionTypes);
    this.operationTyppingsFactory = new OperationTyppingsFactory(introspectionTypes);
  }

  getDatasets(): Record<string, FactoryDataset> {
    const datasetCruds = this.getDatsetsCrud();
    const datasets = {};

    // Getting relationals datasets
    for (const datasetName in datasetCruds) {
      const datasetCrud = datasetCruds[datasetName];

      const datasetType = this.findTypeByName(datasetName);

      const childrenDatasets = [];
      const parentDatasets = [];

      for (const field of datasetType.fields) {
        if (field.name.startsWith('_')) {
          const datasetName = capitalize(field.name.replace('_', ''));
          childrenDatasets.push(datasetName);
        }

        if (field.name.endsWith('Relation')) {
          const datasetName = capitalize(field.name.replace('Relation', ''));
          parentDatasets.push(datasetName);
        }
      }

      set(datasets, datasetName, {
        operations: datasetCrud,
        childrenDatasets,
        parentDatasets,
      });
    }

    return datasets;
  }

  getDatsetsCrud(): Record<string, FactoryDataset> {
    return merge(this.getDatasetQueryOperations(), this.getDatasetMutationOperations());
  }

  getDatasetQueryOperations(): Record<string, FactoryDataset> {
    const queryOperationsPrefix = [Introspection.QueryNamePrefix.Get, Introspection.QueryNamePrefix.List];

    const datasetsCruds = {};

    for (const query of this.queryType?.fields ?? []) {
      for (const operationPrefix of queryOperationsPrefix) {
        const operationName = query.name;
        if (!operationName.startsWith(operationPrefix)) continue;

        const formFields = [];

        const datasetName = operationName.replace(operationPrefix, '');

        if (operationPrefix === Introspection.QueryNamePrefix.List) {
          const operationInputArguments = this.operationTyppingsFactory.mountOperationInputArguments(query);

          const filterFields = find(operationInputArguments, { name: 'filter' });

          const filterInputFields: EditorFormField[] = filterFields.fields.map((filterField) => {
            const { name, required, type, fields } = filterField;

            const fieldType = fields[0]?.type ?? type;

            const field: EditorFormField = {
              name: name,
              label: capitalize(name),
              type: fieldType,
              input: 'InputSelect',
              options: fields.map((f) => f.name),
              inputConfig: {
                required,
                visible: true,
              },
              inputProps: {
                width: '100%',
              },
            };

            return field;
          });

          formFields.push(...filterInputFields);
        }

        if (operationPrefix === Introspection.QueryNamePrefix.Get) {
          formFields.push({
            name: 'id',
            label: 'Id',
            type: 'ID',
            inputConfig: {
              required: true,
              visible: false,
            },
            inputProps: {
              width: '100%',
            },
          });
        }

        const fields = this.operationTyppingsFactory.mountOperationReturnableFields(query);

        set(datasetsCruds, [datasetName, operationPrefix], {
          operationName,
          operation: this.operationFactory.mountOperation(query, 'query'),
          formFields,
          fields,
        });
      }
    }

    return datasetsCruds;
  }

  getDatasetMutationOperations(): Record<string, DatasetCrud> {
    const mutationOperationPrefix = [
      Introspection.MutationNamePrefix.Create,
      Introspection.MutationNamePrefix.Update,
      Introspection.MutationNamePrefix.Delete,
    ];

    const datasetsCruds = {};

    for (const mutation of this.mutationType?.fields ?? []) {
      for (const operationPrefix of mutationOperationPrefix) {
        const operationName = mutation.name;
        if (!operationName.startsWith(operationPrefix)) continue;

        const datasetName = operationName.replace(operationPrefix, '');
        const formFields = [];

        if (
          operationPrefix === Introspection.MutationNamePrefix.Create ||
          operationPrefix === Introspection.MutationNamePrefix.Update
        ) {
          const operationInputArguments =
            this.operationTyppingsFactory.mountOperationInputArguments(mutation);

          const inputFields = find(operationInputArguments, { name: 'input' });

          const formInputFields: EditorFormField[] = inputFields.fields.map(
            ({ name, required, type, options }) => {
              const field: EditorFormField = {
                name,
                label: capitalize(name),
                type,
                inputConfig: {
                  required,
                  visible: true,
                },
                inputProps: {
                  width: '100%',
                },
              };

              if (type === 'Boolean') {
                field.defaultValue = false;
              }

              if (type === 'Number') {
                field.defaultValue = 0;
              }

              const relationalField = name.endsWith('RelationId');
              const multiReferennceRelationalIdField = name.startsWith('_') && name.endsWith('Id');

              if (relationalField || options.length) {
                field.input = 'InputSelect';
                field.options = options;
              }
              if (multiReferennceRelationalIdField || name === 'id') {
                field.inputConfig.required = false;
                field.inputConfig.visible = false;
              }

              return field;
            },
          );

          if (operationPrefix === Introspection.MutationNamePrefix.Update) {
            formInputFields.push({
              name: 'id',
              label: 'Id',
              type: 'ID',
              inputConfig: {
                required: true,
                visible: false,
              },
              inputProps: {
                width: '100%',
              },
            });
          }

          formFields.push(...formInputFields);
        }

        const mountedOperation: IntrospectionOperation = {
          operationName,
          operation: this.operationFactory.mountOperation(mutation, 'mutation'),
          formFields,
          inputArgs: this.operationTyppingsFactory.mountOperationInputArguments(mutation),
          fields: this.operationTyppingsFactory.mountOperationReturnableFields(mutation),
        };

        set(datasetsCruds, [datasetName, operationPrefix], mountedOperation);
      }
    }

    return datasetsCruds;
  }
}
