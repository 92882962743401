import { Image as ChakraImage, ImageProps as ChakraImageProps } from '@chakra-ui/react';
import React, { FC } from 'react';

type LogoType = 'normal' | 'minimal';

export interface LogoProps extends ChakraImageProps {
  type?: LogoType;
}

export const Logo: FC<LogoProps> = ({ type = 'normal', ...props }) => {
  const logoTypes: Record<LogoType, string> = {
    normal: '/static/images/logo.png',
    minimal: '/static/images/logo-symbol.png',
  };

  return (
    <ChakraImage
      width={type === 'normal' ? '7.5rem' : '2.03rem'}
      height="2.03rem"
      src={logoTypes[type]}
      {...props}
    />
  );
};

Logo.displayName = 'Logo';
