import React, { FC, useEffect, useState } from 'react';

import { Flex as ChakraFlex, GridItem as ChakraGridItem, Tooltip as ChakraTooltip } from '@chakra-ui/react';

import { Icon } from 'components/ds';
import { IconName } from '@types';

export interface NavigationSection {
  key: string;
  label: string;
  icon: IconName;
}

interface SidebarNavigationProps {
  value: NavigationSection | undefined;
  navigationSections: NavigationSection[];
  onChangeSection: (navigationSection: NavigationSection) => void;
}

export const SidebarNavigation: FC<SidebarNavigationProps> = ({
  value,
  navigationSections,
  onChangeSection,
}) => {
  const [selectedSection, setSelectedSection] = useState<NavigationSection>();

  useEffect(() => {
    if (value) setSelectedSection(value);
  }, [value]);

  const handleSectionChange = (navigationSection: NavigationSection) => {
    setSelectedSection(navigationSection);
    onChangeSection(navigationSection);
  };

  return (
    <ChakraGridItem
      width="3.5rem"
      height="100%"
      gridRow="1/3"
      gridColumn="2/2"
      boxShadow="large"
      background="white"
      paddingTop="4.5rem"
      borderRadius="sm"
    >
      {navigationSections?.map((section, idx) => (
        <ChakraTooltip hasArrow={true} label={section.label} placement="left" openDelay={500} key={idx}>
          <ChakraFlex
            padding="sm"
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            transitionDuration="0.3s"
            _hover={{ bg: 'primary.100' }}
            onClick={() => handleSectionChange(section)}
          >
            <Icon
              size="lg"
              name={section.icon}
              color={selectedSection?.key === section.key && 'primary.500'}
            />
          </ChakraFlex>
        </ChakraTooltip>
      ))}
    </ChakraGridItem>
  );
};

SidebarNavigation.displayName = 'SidebarNavigation';
