import React, { FC, useEffect, useState } from 'react';

import { cloneDeep, set } from 'lodash';

import { Node, OptionBlocks, PropType } from '@types';

import { BlockFactory } from '@application/lib/core';
import { CompiledBlock } from '@application/compiler/CompiledBlock';
import { node } from '@application/core/node';
import { SelectOption } from '@application/components/compiler/Select';

const CompilerInput: Record<PropType, OptionBlocks> = {
  Any: 'input',
  Phone: 'input',
  Email: 'input',
  Image: 'imageUpload',
  String: 'input',
  ID: 'select',
  List: 'select',
  Enum: 'select',
  Object: 'select',
  Number: 'numberInput',
  Boolean: 'checkbox',
  Textarea: 'textarea',
  Richtext: 'richTextEditor',
  Date: 'dateInput',
};

interface DynamicCompilerInputProps {
  inheritedData?: Record<string, any>;
  name: string;
  label: string;
  value: string | number;
  options: (string | number | SelectOption)[];
  isDisabled?: boolean;
  isInvalid: boolean;
  fieldType: PropType;
}

export const DynamicCompilerInput: FC<DynamicCompilerInputProps> = ({
  inheritedData,
  name,
  label,
  value,
  options,
  fieldType,
  isDisabled,
  isInvalid,
}) => {
  const [initializedNode, setInitializedNode] = useState<Node>();

  useEffect(() => {
    if (fieldType) {
      const selectedBlock = CompilerInput[fieldType];

      if (selectedBlock) {
        const block = BlockFactory.initializeBlock(selectedBlock, {
          staticPropValues: {
            name,
            label,
            options,
          },
        });

        const newNode = node(block);

        set(newNode, ['block', 'triggers'], {
          onChange: {
            operation: {
              operator: 'dynamicFormInput',
              args: {
                name,
                value: '$ctx.data',
              },
            },
          },
          onBlur: {
            operation: {
              operator: 'dynamicFormInput',
              args: {
                name,
                value: '$ctx.data',
              },
            },
          },
        });

        setInitializedNode(newNode);
      }
    }
  }, [fieldType]);

  useEffect(
    () =>
      setInitializedNode((prev) => {
        const previousState = cloneDeep(prev);

        set(previousState, ['block', 'staticPropValues', 'value'], value);
        set(previousState, ['block', 'staticPropValues', 'isInvalid'], isInvalid);
        set(previousState, ['block', 'staticPropValues', 'isDisabled'], isDisabled ?? false);

        return previousState;
      }),
    [value, isInvalid],
  );

  return <CompiledBlock key={initializedNode?.id} node={initializedNode} dynamicData={inheritedData} />;
};
