import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export type HeaderParts = 'header' | 'header-item' | 'header-item-selected';

export interface Route {
  id: string;
  path: string;
  label: string;
}

export interface HeaderProps {
  headerRoutes: Route[];
  headerShowLogo: boolean;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<HeaderProps, HeaderParts>;
