import React, { FC } from 'react';

import { useStyleConfig } from '@chakra-ui/system';

import {
  ImageUpload as SharedImageUpload,
  ImageUploadProps as SharedImageUploadProps,
} from 'components/common';

export type ImageUploadProps = Omit<SharedImageUploadProps, 'sx'>;

export const ImageUpload: FC<ImageUploadProps> = (props) => {
  const styles = useStyleConfig('ImageUpload', {});

  return <SharedImageUpload sx={styles} {...props} />;
};
