import gql from 'graphql-tag';

export const GetDatasetOperation = gql`
  query GetDatasetOperation($id: ID!, $datasetId: ID!) {
    datasetOperation(id: $id, datasetId: $datasetId) {
      id
      datasetId
      accountId
      alias
      action
      datasetOperationStatus
      userId
      createDate
      updateDate
    }
  }
`;

export const ListDatasetOperations = gql`
  query ListDatasetOperations(
    $datasetId: ID!
    $filter: TableDatasetOperationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    datasetOperations(datasetId: $datasetId, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        datasetId
        accountId
        alias
        action
        datasetOperationStatus
        userId
        createDate
        updateDate
      }
      nextToken
    }
  }
`;
