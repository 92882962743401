import { apiRequestClient } from './index';
import {
  ChangeOrganizationOwnershipInput,
  ChangeOrganizationOwnershipResponse,
  CreateOrganizationResponse,
  DeleteOrganizationInput,
  DeleteOrganizationResponse,
  DeleteProjectInput,
  DeleteProjectResponse,
  DuplicateSharedProjectInput,
  DuplicateSharedProjectResponse,
  GetOrganizationLogoResponse,
  InviteOrganizationMembersInput,
  InviteOrganizationMembersResponse,
  InviteOrganizationProjectUsersInput,
  InviteOrganizationProjectUsersResponse,
  LeaveOrganizationInput,
  ListOrganizationApplicationUsersResponse,
  UpdateOrganizationInput,
  UpdateOrganizationResponse,
  UpdateProjectDomainResponse,
  UpdateUserInput,
  UpdateUserResponse,
} from '@api/routes/application';

import { GetDomainConfig } from '@services/clients/vercel';

export const updateUser = (body: UpdateUserInput) => {
  return apiRequestClient()
    .method('patch')
    .append('/api/auth/update-user')
    .setData(body)
    .build<UpdateUserResponse>();
};

export const changePassword = () => {
  return apiRequestClient().method('post').append('/api/auth/change-password').build<UpdateUserResponse>();
};

export const createOrganization = (name: string) => {
  return apiRequestClient()
    .method('post')
    .append('/api/admin/organization/create-organization')
    .setData({ name })
    .build<CreateOrganizationResponse>();
};

export const updateCustomDomain = (projectId: string, body: Record<'organizationId' | 'domain', string>) => {
  return apiRequestClient()
    .method('post')
    .append(`/api/admin/projects/${projectId}/domain/update`)
    .setData(body)
    .build<UpdateProjectDomainResponse>();
};

export const verifyProjectDomain = async (
  projectId: string,
  body: Record<'organizationId' | 'domain', string>,
) => {
  return apiRequestClient()
    .method('post')
    .append(`/api/admin/projects/${projectId}/domain/verify`)
    .setData(body)
    .build<GetDomainConfig>();
};

export const duplicateSharedProject = async (body: DuplicateSharedProjectInput) => {
  return apiRequestClient()
    .method('post')
    .append('/api/admin/projects/duplicate-shared-project')
    .setData(body)
    .build<DuplicateSharedProjectResponse>();
};

export const deleteProject = async (body: DeleteProjectInput) => {
  return apiRequestClient()
    .method('delete')
    .append('/api/admin/projects/delete-project')
    .setData(body)
    .build<DeleteProjectResponse>();
};

export const updateOrganization = async (body: UpdateOrganizationInput) => {
  return apiRequestClient()
    .method('put')
    .append('/api/admin/organization/update-organization')
    .setData(body)
    .build<UpdateOrganizationResponse>();
};

export const changeOrganizationOwnership = async (body: ChangeOrganizationOwnershipInput) => {
  return apiRequestClient()
    .method('post')
    .append('/api/admin/organization/change-organization-ownership')
    .setData(body)
    .build<ChangeOrganizationOwnershipResponse>();
};

export const deleteOrganization = async (body: DeleteOrganizationInput) => {
  return apiRequestClient()
    .method('delete')
    .append('/api/admin/organization/delete-organization')
    .setData(body)
    .build<DeleteProjectResponse>();
};

export const leaveOrganization = async (body: LeaveOrganizationInput) => {
  return apiRequestClient()
    .method('delete')
    .append('/api/admin/organization/delete-organization')
    .setData(body)
    .build<DeleteOrganizationResponse>();
};

export const getOrganizationLogo = (organizationId: string) => {
  return apiRequestClient()
    .method('get')
    .append(`api/admin/organization/${organizationId}/logo`)
    .build<GetOrganizationLogoResponse>();
};

export const listOrganizationApplicationUsers = (organizationId: string) => {
  return apiRequestClient()
    .method('get')
    .append(`/api/admin/organization/${organizationId}/application-users`)
    .build<ListOrganizationApplicationUsersResponse>();
};

export const inviteUsersToOrganizationProject = (body: InviteOrganizationProjectUsersInput) => {
  return apiRequestClient()
    .method('post')
    .append(`/api/admin/organization/${body?.organizationId}/invite-organization-project-users`)
    .setData(body)
    .build<InviteOrganizationProjectUsersResponse>();
};

export const inviteOrganizationMembers = (body: InviteOrganizationMembersInput) => {
  return apiRequestClient()
    .method('post')
    .append(`/api/admin/organization/${body?.organizationId}/invite-members`)
    .setData(body)
    .build<InviteOrganizationMembersResponse>();
};
