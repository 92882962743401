/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ThemeComponent } from '@types';

import { colors } from '../foundations';
import { getColorSchemeOrDefault } from '../utils/variants';

export type RichTextEditorSizes = 'sm' | 'md' | 'lg' | string;
export type RichTextEditorVariants = 'default';

export const RichTextEditor: ThemeComponent<RichTextEditorSizes, RichTextEditorVariants> = {
  baseStyle: () => ({
    paddingY: 'sm',
    paddingX: 'xl',
    minHeight: '4rem',
  }),

  sizes: {
    sm: {
      fontSize: 'sm',
      lineHeight: 'sm',
    },
    md: {
      fontSize: 'md',
      lineHeight: 'md',
    },
    lg: {
      fontSize: 'lg',
      lineHeight: 'lg',
    },
  },

  variants: {
    default: ({ colorScheme }) => {
      const colorSchemeDefault = getColorSchemeOrDefault({
        colorScheme,
        colorSchemeDefault: 'ui',
      });

      return {
        border: 'sm',
        borderColor: `${colorSchemeDefault}.200`,
        borderRadius: 'xs',
        background: 'white',
        _hover: {
          borderColor: `${colorSchemeDefault}.500`,
        },
        _disabled: {
          cursor: 'not-allowed',
          background: 'ui.50',
          _hover: {
            borderColor: `${colorSchemeDefault}.200`,
          },
        },
        _invalid: {
          borderColor: 'supportA.600',
          _focus: {
            boxShadow: `0px 0px 0px 3px ${colors.supportA[100]}`,
          },
        },
      };
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: null,
  },
};
