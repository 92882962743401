import { ThemeMultipartComponent } from '@types';

type CircularProgressSizes = 'sm' | 'md' | 'lg';
type CircularProgressVariants = 'default';
type CircularProgressParts = 'track' | 'filledTrack' | 'label';

export const PTCircularProgress: ThemeMultipartComponent<
  CircularProgressSizes,
  CircularProgressVariants,
  CircularProgressParts
> = {
  parts: ['track', 'filledTrack', 'label'],

  variants: {
    default: {
      track: {
        borderRadius: 'pt-sm',
        background: 'pt-ui.200',
      },
      filledTrack: {
        background: 'pt-primary.500',
      },
      label: {
        fontSize: 'pt-md',
        fontWeight: 'pt-normal',
        color: 'pt-ui.500',
      },
    },
  },

  sizes: {
    sm: {
      track: {
        height: '0.5rem',
      },
    },
    md: {
      track: {
        height: '0.75rem',
      },
    },
    lg: {
      track: {
        height: '1rem',
      },
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: 'pt-primary',
  },
};
