import { ThemeMultipartComponent } from '@types';

export type CheckboxSizes = 'sm' | 'md' | 'lg' | string;
export type CheckboxParts = 'control' | 'label';
export type CheckboxVariants = 'default';

export const PTCheckbox: ThemeMultipartComponent<CheckboxSizes, CheckboxVariants, CheckboxParts> = {
  parts: ['control', 'label'],

  variants: {
    default: {
      control: {
        width: '16px',
        height: '16px',
        color: 'pt-white',
        border: 'pt-sm',
        padding: '0.35rem',
        borderRadius: 'pt-xs',
        borderColor: 'pt-ui.300',
        _checked: {
          borderRadius: 'pt-xs',
          background: 'pt-primary.500',
          borderColor: 'pt-primary.500',
          fontSize: 'pt-xs',
        },
        _invalid: {
          borderColor: 'pt-danger.500',
        },
        _disabled: {
          cursor: 'not-allowed',
          borderColor: 'pt-ui.100',
          background: 'pt-ui.50',
        },
      },
      label: {
        color: 'pt-ui.500',
        fontSize: 'pt-sm',
        fontWeight: 'pt-normal',
      },
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: null,
  },
};
