import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export type RichTextEditorParts = 'editor-label' | 'editor-control';

export interface RichTextEditorProps {
  name?: string;
  value?: string;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  isInvalid?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export interface RichTextEditorTriggers {
  onBlur: (value: string) => void;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<RichTextEditorProps, RichTextEditorParts>;
