import React, { FC } from 'react';

import { RadioGroup } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { RadioAlternativeProps } from './index';

export const component: FC<ComponentReceivedProps<RadioAlternativeProps, any>> = ({
  props = defaultProps,
  children,
}) => {
  return <RadioGroup {...props}>{children}</RadioGroup>;
};
