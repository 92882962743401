import { ComponentBlock } from '@types';
import { types } from 'core/types';

import { component } from './component';
import { TitleProps } from './index';

export const block: ComponentBlock<'title', TitleProps, unknown> = {
  id: 'title',
  render: component,
  props: {
    content: {
      type: types.string,
    },
    htmlTag: {
      type: types.string,
    },
  },
};
