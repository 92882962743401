import gql from 'graphql-tag';

export const CreateOrganization = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
      name
      ownerUser

      createdAt
      updatedAt
    }
  }
`;
