import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { DevelopmentEnvironmentProps } from './index';

export const protocolFactory = new ProtocolFactory<DevelopmentEnvironmentProps>({
  name: 'DevelopmentEnvironment',
  editorProps: [
    {
      type: EPropType.String,
      name: 'theme',
      required: true,
      label: 'Theme',
      description: 'Background and font colors',
      defaultValue: 'monokai',
      values: ['monokai', 'solarized_dark', 'xcode'],
    },
    {
      type: EPropType.String,
      name: 'renderBgColor',
      required: true,
      label: 'Render background color',
      description: 'Background color of the render section',
      defaultValue: 'white',
    },
    {
      type: EPropType.Boolean,
      name: 'hideEditor',
      required: false,
      label: 'Hide',
      description: 'Hides the editor',
      defaultValue: false,
    },
  ],
});

export const defaultProps = protocolFactory.createDefaultProps();
