import gql from 'graphql-tag';

export const ListThemes = gql`
  query ListThemes($organizationId: ID!, $limit: Int, $nextToken: String) {
    listThemes(organizationId: $organizationId, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationId

        name
        theme

        customFonts {
          link
          name
        }

        customIcons {
          link
          name
        }

        createdAt
        updatedAt

        expiresAt
      }
    }
  }
`;

export const GetTheme = gql`
  query GetTheme($organizationId: ID!, $id: ID!) {
    getTheme(organizationId: $organizationId, id: $id) {
      id
      organizationId

      name
      theme

      customFonts {
        link
        name
      }

      customIcons {
        link
        name
      }

      createdAt
      updatedAt

      expiresAt
    }
  }
`;
