import { ProtocolFactory } from '@application/lib/protocols';

import { HeaderParts, HeaderProps } from './index';

export const protocolFactory = new ProtocolFactory<HeaderProps, HeaderParts>({
  name: 'Header',
  editorProps: [],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: true,
    disableLogicSection: true,
  },
  themeProps: [],
  themeConfig: {
    allowMultipart: true,
    defaultProps: [],
    parts: ['header', 'header-item', 'header-item-selected'],
    partsConfig: {
      header: {
        baseStyleProps: ['background', 'border', 'borderColor'],
      },
      'header-item': {
        pseudos: ['_hover'],
        pseudoProps: ['color', 'background'],
        variantProps: ['fontSize', 'fontWeight', 'color', 'background', 'paddingHorizontal'],
      },
      'header-item-selected': {
        pseudos: ['_hover'],
        pseudoProps: ['color', 'background'],
        variantProps: ['fontSize', 'fontWeight', 'color', 'background', 'borderBottom', 'borderColor'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
