import { DynamicFormParts } from '@application/lib/defaultTheme';
import { ProtocolFactory } from '@application/lib/protocols';

import { DynamicFormProps } from './index';

export const protocolFactory = new ProtocolFactory<DynamicFormProps, DynamicFormParts>({
  name: 'DynamicForm',
  editorProps: [
    {
      label: 'Header title',
      defaultValue: '',
      description: 'The title of the subform header',
      name: 'headerTitle',
      required: false,
      type: 'String',
    },
    {
      label: 'Header description',
      defaultValue: '',
      description: 'The description of the subform header',
      name: 'headerDescription',
      required: false,
      type: 'String',
    },
    {
      label: 'Button label',
      defaultValue: '',
      description: 'The custom button label',
      name: 'customButtonLabel',
      required: false,
      type: 'String',
    },
    {
      label: 'Full width button',
      defaultValue: false,
      description: 'Define if the button is full width',
      name: 'isFormButtonFullWidth',
      required: false,
      type: 'Boolean',
    },
  ],
  themeProps: ['width', 'height', 'variant'],
  themeConfig: {
    defaultProps: ['variant'],
    allowMultipart: true,
    parts: [
      'dynamic-form-container',
      'dynamic-form-header-container',
      'dynamic-form-header-title',
      'dynamic-form-header-description',
      'dynamic-form-children-tabs',
      'dynamic-form-children-tab-panel',
      'dynamic-form-children-tab-panel-record-card',
      'dynamic-form-children-tab-panel-action',
      'dynamic-form-button-container',
    ],
    partsConfig: {
      'dynamic-form-container': {
        variantProps: [
          'paddingHorizontal',
          'paddingVertical',
          'border',
          'borderRadius',
          'background',
          'borderColor',
          'boxShadow',
        ],
      },
      'dynamic-form-header-container': {
        variantProps: ['gridGap', 'marginBottom'],
      },
      'dynamic-form-header-title': {
        variantProps: ['fontSize', 'fontWeight', 'fontFamily', 'color'],
      },
      'dynamic-form-header-description': {
        variantProps: ['fontSize', 'fontWeight', 'fontFamily', 'color'],
      },
      'dynamic-form-children-tabs': {
        pseudos: ['_hover', '_selected'],
        pseudoProps: ['background', 'borderColor', 'color'],
        variantProps: [
          'paddingHorizontal',
          'paddingVertical',
          'fontSize',
          'fontFamily',
          'color',
          'borderColor',
          'borderTopRadius',
        ],
      },
      'dynamic-form-children-tab-panel': {
        variantProps: ['padding', 'gridGap', 'background', 'borderBottomRadius'],
      },
      'dynamic-form-children-tab-panel-record-card': {
        variantProps: [
          'border',
          'borderRadius',
          'padding',
          'gridGap',
          'background',
          'borderColor',
          'boxShadow',
        ],
      },
      'dynamic-form-children-tab-panel-action': {
        pseudos: ['_hover'],
        pseudoProps: ['color'],
        variantProps: [
          'borderColor',
          'background',
          'color',
          'border',
          'borderRadius',
          'padding',
          'fontSize',
          'fontFamily',
        ],
      },
      'dynamic-form-button-container': {
        variantProps: ['justifyContent'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
