import React, { FC } from 'react';

import { CompiledBlockProps } from '@types';
import { CompiledComponent } from './component/CompiledComponent';

export const CompiledBlock: FC<CompiledBlockProps> = ({ node, ...nextProps }) => {
  const Children = ({ ...parentNextProps }) => (
    <>
      {node.children.map((childNode) => (
        <CompiledBlock key={childNode.id} node={childNode} {...parentNextProps} />
      ))}
    </>
  );

  if (!node) return null;

  return (
    <CompiledComponent key={node.id} node={node} {...nextProps}>
      <Children {...nextProps} />
    </CompiledComponent>
  );
};
