import React, { FC } from 'react';

import { Divider } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { DividerProps } from './index';

export const component: FC<ComponentReceivedProps<DividerProps, any>> = ({ props = defaultProps, style }) => {
  return <Divider style={style} {...props} />;
};
