import React, { cloneElement, FC, isValidElement, useContext, useState } from 'react';

import * as Sentry from '@sentry/nextjs';

import { EngineContext } from '../contexts';
import { Loading } from 'components/ds';
import { Node } from '@types';

interface EngineTriggerWrapperProps {
  node: Node;
}

export const EngineTriggerWrapper: FC<EngineTriggerWrapperProps> = ({ node, children, ...nextProps }) => {
  const [operationIsRunning, setOperationIsRunning] = useState(false);
  const componentTriggers: Record<string, (args: unknown) => Promise<any>> = {};

  const { engine } = useContext(EngineContext);

  const { triggers } = node.block;

  if (!triggers || !Object.keys(triggers).length) {
    return (
      <>
        {React.Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child, {
              triggers: {},
              ...nextProps,
            });
          }
        })}
      </>
    );
  }

  const triggersEntries = Object.entries(triggers);

  for (const trigger of triggersEntries) {
    const [name, triggerDetails] = trigger;
    const { operation } = triggerDetails;

    if (!operation) continue;

    componentTriggers[name] = async (inheritedData: Record<string, any>) => {
      setOperationIsRunning(true);

      try {
        await engine.runOperation(operation, inheritedData);
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      } finally {
        setOperationIsRunning(false);
      }
    };
  }

  return (
    <Loading minHeight="auto" isLoading={operationIsRunning}>
      {React.Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            triggers: componentTriggers,
            ...nextProps,
          });
        }
      })}
    </Loading>
  );
};
