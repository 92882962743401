import { EPropType } from '@types';

import { ProtocolFactory } from 'lib/protocols';

import { AccordionParts, AccordionProps } from '.';

export const protocolFactory = new ProtocolFactory<AccordionProps, AccordionParts>({
  name: 'Accordion',
  editorProps: [
    {
      type: EPropType.Boolean,
      name: 'allowMultiple',
      label: 'Allow Multiple',
      description: 'If true, multiple accordion items can be expanded at once',
      defaultValue: false,
      required: false,
    },
    {
      type: EPropType.Boolean,
      name: 'allowToggle',
      label: 'Allow Toggle',
      description: 'If true, any expanded accordion item can be collapsed again',
      defaultValue: true,
      required: false,
    },
    {
      type: EPropType.String,
      name: 'title',
      label: 'Accordion Item Title',
      description: 'What property will be used as title for the accordion item',
      defaultValue: 'label',
      required: true,
    },
    {
      type: EPropType.List,
      name: 'data',
      label: 'Accordion Data',
      description: 'What property will be used as title for the accordion item',
      defaultValue: [
        {
          label: 'Example',
          value: 'Example',
        },
        {
          label: 'Example',
          value: 'Example',
        },
      ],
      required: true,
    },
  ],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: true,
    disableLogicSection: true,
  },
  themeProps: ['marginHorizontal', 'marginVertical'],
  themeConfig: {
    allowMultipart: true,
    defaultProps: [],
    sizes: [],
    parts: ['accordion', 'accordion-button', 'accordion-panel'],
    partsConfig: {
      accordion: {
        baseStyleProps: ['borderRadius', 'boxShadow', 'background', 'borderColor'],
      },
      'accordion-button': {
        baseStyleProps: ['background', 'color'],
      },
      'accordion-panel': {
        baseStyleProps: ['background', 'paddingHorizontal', 'paddingVertical'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
