import { cloneDeep, find } from 'lodash';

import { ComposerLayout, ComposerPage, ComposerState, EComposerMode } from '@types';
import { createNodeTreeFromLayout, createNodeTreeFromPage } from '@application/lib/nodeTree/nodeTreeAdapter';

export const getLayoutOrPageFromStateWithMode = (
  state: ComposerState,
): null | ComposerLayout | ComposerPage => {
  if (state.mode === EComposerMode.Page) {
    return find(state.pages, { id: state.pageId });
  }

  if (state.mode === EComposerMode.Layout) {
    return find(state.layouts, { id: state.layoutId });
  }

  return null;
};

export const updateRootNode = (state: ComposerState): ComposerState => {
  if (state.mode === EComposerMode.Page) {
    const page = find(state.pages, { id: state.pageId });

    const newRootNode = createNodeTreeFromPage(page);

    state.root = newRootNode;
  }

  if (state.mode === EComposerMode.Layout) {
    const layout = find(state.layouts, { id: state.layoutId });

    // @ts-ignore
    state.root = createNodeTreeFromLayout(layout);
  }

  return null;
};
