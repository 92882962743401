import { cloneDeep, isArray, isString, set } from 'lodash';

import { ComposerLayout, ComposerPage, Layout, Node, Page } from '@types';

import { BlockFactory } from '@application/lib/core';
import { node } from '@application/core/node';

import {
  filterNodeListByBlockType,
  getPageNodeEvents,
  getPageNodeFields,
  recursiveAddAvailableProps,
  transformNodeTreeToNodeList,
} from './nodeTreeUtils';
import { getEmptyPage } from './templates';

export function createNodeTreeFromLayout(layout: ComposerLayout | Layout): Node {
  if (!layout?.nodesStructure) return null;

  const nodesStructureIsString = isString(layout.nodesStructure);

  const layoutNode = cloneDeep(
    // @ts-ignore
    nodesStructureIsString ? JSON.parse(layout.nodesStructure) : layout.nodesStructure,
  );

  const layoutNodesList = transformNodeTreeToNodeList(layoutNode);
  const [layoutRouterView] = filterNodeListByBlockType(layoutNodesList, 'layoutRouterView');

  if (layoutRouterView && layoutRouterView?.id !== layout.id) {
    layoutRouterView.id = layout.id;
  }

  if (!layoutRouterView) {
    const layoutRouterViewNode = { ...node(BlockFactory.initializeBlock('layoutRouterView')), id: layout.id };
    layoutNode.children.push(layoutRouterViewNode);
  }

  layoutNode.id = `${layout.id}_container`;

  return layoutNode;
}

export function createNodeTreeFromPage(page: ComposerPage | Page): Node {
  if (!page?.nodesStructure) {
    return null;
  }

  const nodesStructureIsString = isString(page.nodesStructure);

  const nodesStructure = cloneDeep(
    // @ts-ignore
    nodesStructureIsString ? JSON.parse(page.nodesStructure) : page.nodesStructure,
  );

  const rootNodeIsPage = nodesStructure?.block?.component === 'page';

  const pageNode = rootNodeIsPage
    ? nodesStructure
    : getEmptyPage({ path: page.path, isProtected: page.isProtected });

  if (!rootNodeIsPage) {
    if (isArray(nodesStructure)) {
      set(pageNode, ['children'], nodesStructure);
    } else {
      set(pageNode, ['children'], [nodesStructure]);
    }
  }

  // @ts-ignore
  const pageNodeFields = getPageNodeFields(page);

  // @ts-ignore
  const pageNodeEvents = getPageNodeEvents(page);

  set(pageNode, ['id'], page.id);
  set(pageNode, ['block', 'events'], pageNodeEvents);
  set(pageNode, ['block', 'staticPropValues', 'name'], page.name ?? 'Untitled');
  set(pageNode, ['block', 'staticPropValues', 'path'], page.path ?? '/');
  set(pageNode, ['block', 'staticPropValues', 'role'], page.role);
  set(pageNode, ['block', 'staticPropValues', 'isProtected'], page.isProtected ?? false);
  set(pageNode, ['block', 'staticPropValues', 'fields'], pageNodeFields);

  return recursiveAddAvailableProps(pageNode);
}

export const recursiveRemoveAvailableProps = (parentNode: Node): Node => {
  if (!parentNode) return null;

  parentNode.children = parentNode.children
    ?.map((childNode) => ({
      ...childNode,
      availableEvents: [],
      availableDynamicProperties: [],
    }))
    ?.map(recursiveRemoveAvailableProps);

  return parentNode;
};
