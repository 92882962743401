export const fonts = {
  'pt-body': 'Helvetica Neue, system-ui, sans-serif',
  'pt-heading': 'Gilroy, system-ui, sans-serif',
  'pt-mono': 'Helvetica Neue, system-ui, sans-serif',
};

export const fontWeights = {
  'pt-normal': 500,
  'pt-medium': 600,
  'pt-bold': 700,
};

export const fontSizes = {
  'pt-none': '0px',
  'pt-xs': '0.75rem',
  'pt-sm': '0.875rem',
  'pt-md': '1rem',
  'pt-lg': '1.125rem',
  'pt-xl': '1.25rem',
  'pt-2xl': '1.5rem',
  'pt-3xl': '1.875rem',
  'pt-4xl': '2.25rem',
  'pt-5xl': '3rem',
  'pt-6xl': '3.75rem',
  'pt-8xl': '4.5rem',
  'pt-9xl': '6rem',
  'pt-10xl': '8rem',
};

export const lineHeights = {
  'pt-none': 1,
  'pt-shorter': 1.25,
  'pt-short': 1.375,
  'pt-base': 1.5,
  'pt-normal': 'normal',
  'pt-tall': 1.625,
  'pt-taller': '2',
};

export const letterSpacings = {
  'pt-none': 'none',
  'pt-tighter': '-0.05em',
  'pt-tight': '-0.025em',
  'pt-normal': '0',
  'pt-wide': '0.025em',
  'pt-wider': '0.05em',
  'pt-widest': '0.1em',
};

export const textStyles = {
  'pt-title-large': {
    fontFamily: 'pt-heading',
    fontSize: 'pt-4xl',
    fontWeight: 'pt-bold',
    letterSpacing: 'pt-wider',
    lineHeight: 'pt-none',
  },
  'pt-title-medium': {
    fontFamily: 'pt-heading',
    fontSize: 'pt-3xl',
    fontWeight: 'pt-bold',
    letterSpacing: 'pt-wider',
    lineHeight: 'pt-none',
  },
  'pt-title-small': {
    fontFamily: 'pt-heading',
    fontSize: 'pt-2xl',
    fontWeight: 'pt-bold',
    letterSpacing: 'pt-wider',
    lineHeight: 'pt-none',
  },
  'pt-extra-large': {
    fontFamily: 'pt-body',
    fontSize: 'pt-xl',
    fontWeight: 'pt-normal',
    letterSpacing: 'pt-wider',
    lineHeight: 'pt-none',
  },
  'pt-large': {
    fontFamily: 'pt-body',
    fontSize: 'pt-lg',
    fontWeight: 'pt-normal',
    letterSpacing: 'pt-wider',
    lineHeight: 'pt-none',
  },
  'pt-medium': {
    fontFamily: 'pt-body',
    fontSize: 'pt-md',
    fontWeight: 'pt-normal',
    letterSpacing: 'pt-wider',
    lineHeight: 'pt-none',
  },
  'pt-small': {
    fontFamily: 'pt-body',
    fontSize: 'pt-sm',
    fontWeight: 'pt-normal',
    letterSpacing: 'pt-wider',
    lineHeight: 'pt-none',
  },
  'pt-extra-small': {
    fontFamily: 'pt-body',
    fontSize: 'pt-xs',
    fontWeight: 'pt-normal',
    letterSpacing: 'wider',
    lineHeight: 'pt-none',
  },
};
