import { component } from './component';
import { ComponentBlock } from '@types';
import { DividerProps } from './index';
import { types } from 'core/types';

export const block: ComponentBlock<'divider', DividerProps, any> = {
  id: 'divider',
  render: component,
  props: {
    orientation: {
      type: types.string,
    },
    borderColor: {
      type: types.string,
    },
    borderWidth: {
      type: types.string,
    },
  },
};
