import { component } from './component';
import { ComponentBlock } from '@types';
import { RadioProps } from './index';
import { types } from 'core/types';

export const block: ComponentBlock<'radio', RadioProps, any> = {
  id: 'radio',
  render: component,
  props: {
    size: {
      type: types.string,
    },
    label: {
      type: types.string,
    },
  },
};
