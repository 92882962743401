import { gql } from '@apollo/client';

export const GetAccount = gql`
  query getAccount($organizationId: ID!, $id: ID!) {
    account(organizationId: $organizationId, id: $id) {
      id
      organizationId
      alias
      clientId
      apiUrl
      accountStatus
      userId
      createDate
      updateDate
    }
  }
`;
