import React, { FC } from 'react';

import { Flex as ChakraFlex, FlexProps as ChakraFlexProps } from '@chakra-ui/react';
import { Droppable, DroppableProps } from 'react-beautiful-dnd';

export const BoardColumn: FC<ChakraFlexProps> = ({ children, ...rest }) => (
  <ChakraFlex
    width="100%"
    minWidth="16rem"
    height="100%"
    overflow="auto"
    border="sm"
    borderColor="ui.100"
    borderRadius="sm"
    background="ui.50"
    flexDirection="column"
    {...rest}
  >
    {children}
  </ChakraFlex>
);

export const BoardColumnHeader: FC<ChakraFlexProps> = ({ children, ...rest }) => (
  <ChakraFlex
    color="ui.700"
    padding="md"
    fontSize="md"
    fontWeight="medium"
    fontFamily="heading"
    alignItems="center"
    justifyContent="space-between"
    {...rest}
  >
    {children}
  </ChakraFlex>
);

export const BoardColumnCounter: FC<ChakraFlexProps> = ({ children, ...rest }) => (
  <ChakraFlex
    minWidth="1.5rem"
    minHeight="1.5rem"
    color="primary.500"
    fontSize="xs"
    fontWeight="normal"
    borderRadius="xs"
    background="primary.100"
    alignItems="center"
    justifyContent="center"
    {...rest}
  >
    {children}
  </ChakraFlex>
);

export type BoardColumnListProps = Omit<DroppableProps, 'children' | 'direction'> & ChakraFlexProps;

export const BoardColumnList: FC<BoardColumnListProps> = ({ children, droppableId, ...rest }) => (
  <Droppable droppableId={droppableId}>
    {(provided) => (
      <>
        <ChakraFlex
          height="100%"
          padding="md"
          gridGap="sm"
          overflow="auto"
          flexDirection="column"
          {...provided.droppableProps}
          ref={provided.innerRef}
          {...rest}
        >
          {children}
          {provided.placeholder}
        </ChakraFlex>
      </>
    )}
  </Droppable>
);

export const BoardColumnButton: FC<ChakraFlexProps> = ({ children, ...rest }) => (
  <ChakraFlex
    cursor="pointer"
    paddingY="md"
    paddingX="md"
    border="sm"
    borderColor="ui.200"
    borderRadius="xs"
    borderStyle="dashed"
    userSelect="none"
    transitionDuration="0.3s"
    textAlign="center"
    color="ui.500"
    fontWeight="normal"
    fontSize="xs"
    _hover={{
      background: 'ui.100',
    }}
    {...rest}
  >
    {children}
  </ChakraFlex>
);
