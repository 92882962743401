import { EPropType, ThemeProtocolItem } from '@types';

export type FlexKeys = 'flexDirection' | 'alignItems' | 'justifyContent';

type Flex = ThemeProtocolItem<FlexKeys>;

export const flex: Flex = {
  flexDirection: {
    type: EPropType.String,
    name: 'flexDirection',
    required: false,
    label: 'Flex direction',
    description: 'Direction of elements disposition',
    defaultValue: 'row',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    options: ['row', 'row-reverse', 'column', 'column-reverse'],
  },
  justifyContent: {
    type: EPropType.String,
    name: 'justifyContent',
    required: false,
    label: 'Justify content',
    description: 'Justify disposition of the elements',
    defaultValue: 'flex-start',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    options: ['flex-start', 'flex-end', 'center', 'space-between', 'space-around'],
  },
  alignItems: {
    type: EPropType.String,
    name: 'alignItems',
    required: false,
    label: 'Align items',
    description: 'Align disposition of the elements',
    defaultValue: 'flex-start',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    options: ['center', 'flex-start', 'flex-end', 'baseline'],
  },
};
