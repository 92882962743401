import { ComponentBlock } from '@types';

import { types } from 'core/types';

import { AccordionProps } from '.';
import { component } from './component';

export const block: ComponentBlock<'accordion', AccordionProps, unknown> = {
  id: 'accordion',
  render: component,
  props: {
    allowMultiple: {
      type: types.boolean,
    },
    allowToggle: {
      type: types.boolean,
    },
    title: {
      type: types.string,
    },
    data: {
      type: types.list,
    },
  },
};
