import { flatMap, flatMapDeep, get, isPlainObject, set } from 'lodash';

import {
  AvailableDynamicProp,
  ComposerPage,
  DataComponentField,
  DynamicProperty,
  Node,
  OptionBlocks,
} from '@types';

import { BlockFactory } from '@application/lib/core';
import { duplicateNode } from 'core/node';
import { types } from 'core/types';

export const recursiveFindElement = (nodeElement: Node, id: string): Node | null => {
  if (!isPlainObject(nodeElement)) return null;

  if (nodeElement.id === id) return nodeElement;

  if (!nodeElement.children.length) return;

  const foundNode = nodeElement.children.find((child) => child.id === id);

  if (foundNode) {
    return foundNode;
  }

  for (const childNode of nodeElement.children) {
    const childFoundNode = recursiveFindElement(childNode, id);

    if (childFoundNode) return childFoundNode;
  }

  return null;
};

export const recursiveFindParentElement = (nodeElement: Node, id: string): Node | null => {
  if (!isPlainObject(nodeElement)) return null;

  if (nodeElement.id === id || !nodeElement.children?.length) return null;

  const foundNode = nodeElement.children.find((child) => child.id === id);

  if (foundNode) {
    return nodeElement;
  }

  for (const childNode of nodeElement.children) {
    const childFoundNode = recursiveFindParentElement(childNode, id);

    if (childFoundNode) return childFoundNode;
  }

  return null;
};

export const recursiveDuplicateNode = (node: Node): Node => {
  const newNode = duplicateNode(node);

  newNode.children = newNode.children.map(recursiveDuplicateNode);

  return newNode;
};

export const transformNodeTreeToNodeList = (node: Node): Node[] => {
  const recursiveGetChildren = (parentNode: Node): any => {
    const children = parentNode.children.map((childNode) => recursiveGetChildren(childNode));

    return [parentNode, ...children];
  };

  const allNodes = recursiveGetChildren(node);

  return flatMapDeep(allNodes);
};

export const recursiveSpreadDynamicAvailability = (parentNode: Node, childNode: Node): Node => {
  const { events = {} } = parentNode.block;

  const parentAvailableDynamicEvents = parentNode.availableEvents || [];
  const availableEvents = [...parentAvailableDynamicEvents];

  const childrenAvailableEvents = [];

  for (const event in events) {
    childrenAvailableEvents.push({ ownerNodeId: parentNode.id, ...events[event] });
  }
  availableEvents.push(...flatMap(childrenAvailableEvents));

  return {
    ...handleAvailableDynamicPropsPathSpread(parentNode, childNode),
    availableEvents,
  };
};

// Component Manipulation
export const handleAvailableDynamicPropsPathSpread = (parentNode: Node, childNode: Node): Node => {
  if (!parentNode?.block) return null;

  const { block } = parentNode;
  const parentAvailableDynamicProperties = parentNode.availableDynamicProperties || [];

  const availableDynamicProperties = [...parentAvailableDynamicProperties];

  const { component, staticPropValues = {} } = block;

  const newAvailableDynamicProperties: AvailableDynamicProp[][] = staticPropValues?.fields?.map(
    (field: DataComponentField) => {
      return mountAvailableDynamicProps(parentNode.id, component, field);
    },
  );

  availableDynamicProperties.push(...flatMap(newAvailableDynamicProperties));

  return {
    ...childNode,
    availableDynamicProperties,
  };
};

export const recursiveAddAvailableProps = (parentNode: Node): Node => {
  if (!parentNode) return null;

  parentNode.children = parentNode.children
    ?.map((childNode) => recursiveSpreadDynamicAvailability(parentNode, childNode))
    ?.map(recursiveAddAvailableProps);

  return parentNode;
};

// ---------------------------- // List Functions // --------------------------------- //
export const filterNodeListByBlockType = (nodeList: Node[], blockType: OptionBlocks): Node[] => {
  return nodeList.filter((node) => node.block.component === blockType);
};

// Aux functions
const mountAvailableDynamicProps = (
  ownerNodeId: string,
  component: OptionBlocks,
  field?: DataComponentField,
): AvailableDynamicProp[] => {
  if (!component) return [];
  return [
    {
      id: field?.id ?? null,
      name: field?.name ?? null,
      path: field?.path ?? null,
      ownerNodeId,

      properties: field?.properties ?? null,
      type: field?.type ?? null,
    },
  ];
};

// ---------------------------- // Page Helpers // --------------------------------- //
export function getPageNodeFields(page: ComposerPage): DataComponentField[] {
  const fields: DataComponentField[] = [
    {
      id: '$pageIsProtected',
      name: 'Protected Page',
      type: types.boolean,
    },
  ];

  if (!page?.path) return fields;
  const { path } = page;

  const dynamicParams = getPathParams(path);

  if (dynamicParams.length) {
    fields.push({
      id: '$pageParams',
      name: 'Page Params',
      path: '',
      properties: dynamicParams,
      type: types.object,
    });
  }

  if (page.isProtected) {
    fields.push({
      id: 'user',
      name: 'User',
      path: '',
      nullable: false,
      properties: [
        { name: 'name', type: types.string },
        { name: 'email', type: types.string },
        { name: 'picture', type: types.string },
        { name: 'sub', type: types.id },
      ],
      type: 'Object',
    });
  }

  return fields;
}

export const getPathParams = (path: string): DynamicProperty[] => {
  if (!path) return [];

  const dynamicParams = path
    .split('/')
    .filter((param) => param.includes(':'))
    .map((param) => param.replace(':', ''));

  return dynamicParams.map((param) => ({
    type: types.id,
    name: param,
  }));
};

export function getPageNodeEvents(page: ComposerPage): Node['block']['events'] {
  const pageEvents = get(BlockFactory.initializeBlock('page'), 'events', {});

  const events = {};

  for (const [eventId, eventConfig] of Object.entries(pageEvents)) {
    set(events, [eventId], {
      // @ts-ignore
      ...eventConfig,
      id: eventId,
    });
  }

  return events;
}
