import { ThemeMultipartComponent } from '@types';

type RichTextEditorSizes = 'sm' | 'md' | 'lg';
type RichTextEditorVariants = 'default';

type RichTextEditorParts = 'editor-label' | 'editor-control';

export const PTRichTextEditor: ThemeMultipartComponent<
  RichTextEditorSizes,
  RichTextEditorVariants,
  RichTextEditorParts
> = {
  parts: ['editor-label', 'editor-control'],

  variants: {
    default: {
      'editor-label': {
        color: 'pt-ui.500',
        fontWeight: 'pt-normal',
      },
      'editor-control': {
        width: '100%',
        minHeight: '4rem',
        color: 'pt-ui.500',
        background: 'pt-white',
        border: 'pt-sm',
        borderColor: 'pt-ui.200',
        borderRadius: 'pt-xs',
        _invalid: {
          borderColor: 'pt-danger.600',
        },
        _hover: {
          color: 'pt-ui.600',
          borderColor: 'pt-ui.400',
        },
      },
    },
  },

  sizes: {
    sm: {
      'editor-label': {
        fontSize: 'pt-xs',
        marginBottom: 'pt-xs',
      },
      'editor-control': {
        fontSize: 'pt-sm',
        paddingX: 'pt-xl',
        paddingY: 'pt-xs',
      },
    },
    md: {
      'editor-label': {
        fontSize: 'pt-sm',
        marginBottom: 'pt-sm',
      },
      'editor-control': {
        fontSize: 'pt-md',
        paddingX: 'pt-xl',
        paddingY: 'pt-sm',
      },
    },
    lg: {
      'editor-label': {
        fontSize: 'pt-md',
        marginBottom: 'pt-md',
      },
      'editor-control': {
        fontSize: 'pt-md',
        paddingX: 'pt-xl',
        paddingY: 'pt-sm',
      },
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: null,
  },
};
