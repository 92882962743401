import React, { FC, useMemo } from 'react';

import { Flex as ChakraFlex, Tooltip as ChakraTooltip } from '@chakra-ui/react';

import { InputBase } from '@types';

import { Icon, Select, Text } from 'components/ds';

interface Props extends InputBase {
  options: (
    | string
    | number
    | {
        label: string;
        value: string | number;
      }
  )[];
}

export const InputSelect: FC<Props> = ({
  label,
  description,
  value,
  options,
  disabled,
  onChange,
  onBlur,
  ...rest
}) => {
  const treatedOptions: {
    label: string;
    value: string | number;
  }[] = useMemo(() => {
    if (!options) return [];

    return options.map((opt) => {
      if (typeof opt === 'number') {
        return {
          value: opt,
          label: String(opt),
        };
      }

      if (typeof opt === 'string') {
        return {
          value: opt,
          label: opt,
        };
      }

      return opt;
    });
  }, [options]);

  return (
    <ChakraFlex gridGap="sm" flexDirection="column" {...rest}>
      <ChakraFlex gridGap="sm" alignItems="center">
        <Text color="ui.700" variant="body-2">
          {label}
        </Text>
        {description && (
          <ChakraTooltip label={description} borderRadius="xs" placement="top" hasArrow={true}>
            <Icon size="sm" color="ui.700" name="info-outline" />
          </ChakraTooltip>
        )}
      </ChakraFlex>
      <Select placeholder="Select" value={value} isDisabled={disabled} onChange={onChange} onBlur={onBlur}>
        {treatedOptions?.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </ChakraFlex>
  );
};
