import { component } from './component';
import { ComponentBlock } from '@types';
import { MutationContainerProps } from './index';
import { types } from 'core/types';

export const block: ComponentBlock<'mutationContainer', MutationContainerProps, any> = {
  id: 'mutationContainer',
  render: component,
  props: {
    operationName: {
      type: types.any,
    },
  },
  events: {
    mutation: {
      name: 'Unknown',
      inputArguments: [],
      fields: [],
    },
  },
  triggers: {
    onFailure: {
      name: 'On failure',
      description: 'After the action results in a error',
    },
    onSuccess: {
      name: 'On success',
      description: "After the action successfully it's finished",
    },
  },
};
