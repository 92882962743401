import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface DateInputProps {
  name: string;
  size: 'sm' | 'md' | 'lg';
  label: string;
  value: string;
  placeholder: string;
}

export interface DateInputTriggers {
  onBlur: (value: string) => void;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<DateInputProps>;
