import React, { FC, useEffect, useState } from 'react';

import {
  Flex as ChakraFlex,
  FormControl as ChakraFormControl,
  Text as ChakraText,
  forwardRef,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { RichTextEditor } from '@application/components/common';

import { defaultProps } from './protocol';
import { RichTextEditorProps, RichTextEditorTriggers } from './index';

export const component: FC<ComponentReceivedProps<RichTextEditorProps, RichTextEditorTriggers>> = forwardRef(
  ({ props = defaultProps, inheritedData, triggers }, ref) => {
    const { value, readOnly, placeholder, label, size, isInvalid, ...rest } = props;
    const { onBlur } = triggers;

    const [state, setState] = useState(value);

    const baseInputStyles = useMultiStyleConfig('PTBaseInput', { size });

    useEffect(() => {
      setState(value);
    }, [value]);

    return (
      <ChakraFormControl display="flex" flexDirection="column" {...rest}>
        {label && <ChakraText sx={baseInputStyles['base-input-label'] || {}}>{label}</ChakraText>}
        <ChakraFlex
          minHeight="8rem"
          sx={baseInputStyles['base-input-control'] || {}}
          ref={ref}
          aria-invalid={isInvalid}
          aria-disabled={readOnly}
          onBlur={() => onBlur && onBlur({ ...inheritedData, value: state })}
          {...rest}
        >
          <RichTextEditor
            value={value}
            readOnly={readOnly}
            placeholder={placeholder}
            onChange={(value) => setState(value)}
          />
        </ChakraFlex>
      </ChakraFormControl>
    );
  },
);
