import { DocumentNode } from 'graphql';

import { DataComponentField, InputFields } from '@types';

import { MutationNamePrefix, QueryNamePrefix } from './ApiEngine';

export type OperationType = 'query' | 'mutation' | 'subscription';

export enum OperationsEnum {
  Query = 'Query',
  Mutation = 'Mutation',
}

export interface IOperation {
  name: string;
  operation: DocumentNode;

  dataset: string;
  operationType: OperationType;
  type: QueryNamePrefix | MutationNamePrefix;

  inputArguments?: InputArguments;
  fields?: IReturnableFields;
}

export const operationsPrefixList = Object.values({
  ...QueryNamePrefix,
  ...MutationNamePrefix,
});

export type InputArguments = InputFields[];
export type IReturnableFields = DataComponentField[];
