import { cloneDeep, uniqBy } from 'lodash';
import { ComposerAction, ComposerState } from '@types';

import { actions, undoActions } from './composerActions';

export const handleNodeTreeAction = (state: ComposerState, action: ComposerAction): ComposerState => {
  const copiedState = cloneDeep(state);

  const actionHandler = actions[action.type];
  if (!actionHandler) return state;

  const { state: newState, action: newAction, error } = actionHandler(copiedState, action);

  if (error) {
    newState.actions.error = error;
    return newState;
  }

  if (newAction) {
    newState.actions.prev.push(newAction);
    newState.actions.next = [];
  }

  return newState;
};

export const handleUndoNodeTreeAction = (state: ComposerState): ComposerState => {
  const copiedState = cloneDeep(state);

  const undoAction = copiedState.actions.prev.pop();
  if (!undoAction) return copiedState;

  const actionHandler = undoActions[undoAction.type];
  if (!actionHandler) return state;

  const { state: newState, action: newUndoAction } = actionHandler(copiedState, undoAction);

  if (newUndoAction) {
    newState.actions.next.push(newUndoAction);
  }

  return newState;
};

export const handleRedoNodeTreeAction = (state: ComposerState): ComposerState => {
  const copiedState = cloneDeep(state);

  const redoAction = copiedState.actions.next.pop();
  if (!redoAction) return copiedState;

  const actionHandler = actions[redoAction.type];
  if (!actionHandler) return state;

  const { state: newState, action: newRedoAction } = actionHandler(copiedState, redoAction);

  if (newRedoAction) {
    newState.actions.prev.push(newRedoAction);
  }

  return newState;
};
