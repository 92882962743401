import React, { Children, cloneElement, FC, isValidElement, useContext, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { find } from 'lodash';
import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql/language/printer';

import { CompilerContext, EngineContext } from '@application/compiler/contexts';
import { Loading, Text } from '@application/components/ds';
import { useIntrospectionFactory } from '@application/lib/customHooks';

interface PublicPageProps {
  dynamicData: Record<string, any>;
}

export const PublicPage: FC<PublicPageProps> = ({ children, dynamicData }) => {
  const { engine } = useContext(EngineContext);
  const { config } = useContext(CompilerContext);

  const [introspectionFactory, introspectionIsLoading, introspectionError] = useIntrospectionFactory(
    config.api.endpoint,
    null,
    true,
  );

  useEffect(() => {
    if (engine && !introspectionIsLoading && introspectionFactory) {
      engine.addOperators({
        data_query: {
          name: 'data_query',
          description: 'Makes a query to the database',
          handler: async ({
            operationName,
            input,
          }: {
            operationName: string;
            input: Record<string, any>;
          }) => {
            const operation = find(introspectionFactory.queryOperations, { name: operationName });

            if (!operation) throw new Error('Operation not found');

            const client = new GraphQLClient(process.env.URL_PUBLIC_API, {
              headers: {
                'api-url': config.api.endpoint,
                region: process.env.URL_PUBLIC_REGION,
              },
            });

            const resp = await client.request(print(operation.operation), input);

            return resp[operationName];
          },
        },
      });
    }
  }, [engine, introspectionFactory, introspectionIsLoading]);

  if (introspectionError) {
    return (
      <Box border="pt-sm" borderColor="pt-red.500" padding="pt-lg" textAlign="center" color="pt-red.600">
        <Text mb="sm">gStudio wasn't able to connect with your API</Text>
        <Text>Try again later or contact our support!</Text>
      </Box>
    );
  }

  return (
    <Loading width="100%" height="100%" isLoading={introspectionIsLoading}>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            dynamicData,
          });
        }

        return child;
      })}
    </Loading>
  );
};
