import React, { FC, useContext, useMemo } from 'react';

import {
  chakra,
  Flex as ChakraFlex,
  Menu as ChakraMenu,
  MenuButton as ChakraMenuButton,
  MenuItem as ChakraMenuItem,
  MenuList as ChakraMenuList,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { v4 as uuidV4 } from 'uuid';

import { CompilerContext } from '@application/compiler/contexts';
import { Icon, useToast } from '@application/components/ds';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { MenuOption, MenuProps } from './index';

const exampleOptions: MenuOption[] = [
  {
    label: 'Option 1',
    icon: 'add',
    id: uuidV4(),
  },
  {
    label: 'Option 2',
    icon: 'minus',
    id: uuidV4(),
  },
];

export const component: FC<ComponentReceivedProps<MenuProps, unknown>> = ({
  props = defaultProps,
  triggers,
  inheritedData,
}) => {
  const { menuOptions, menuButtonIsIcon, menuButtonIcon, menuButtonPlaceholder, ...rest } = props;

  const toast = useToast();

  const { config } = useContext(CompilerContext);
  const {
    'menu-button': menuButtonStyles,
    'menu-icon-button': menuIconButtonStyles,
    'menu-list': menuListStyles,
    'menu-item': menuItemStyles,
  } = useMultiStyleConfig('PTMenu', {});

  const isThemeMode = config.mode === 'theme';

  const getMenuOptions = useMemo(
    () =>
      (isThemeMode ? exampleOptions : menuOptions)?.map(({ id, label, icon }) => ({
        id,
        label,
        icon,
        action: () => {
          if (!isThemeMode) {
            if (triggers[id]) {
              return triggers[id](inheritedData);
            }

            toast({
              title: 'Missing operation',
              description: 'Define a operation for this option',
              status: 'danger',
            });
          }
        },
      })),
    [menuOptions, triggers, inheritedData],
  );

  return (
    <ChakraMenu placement="bottom-end">
      <ChakraMenuButton sx={menuButtonIsIcon ? menuIconButtonStyles : menuButtonStyles}>
        {menuButtonIsIcon ? (
          <Icon
            width="fit-content"
            height="fit-content"
            name={menuButtonIcon}
            color={menuIconButtonStyles.color as string}
            {...rest}
          />
        ) : (
          <ChakraFlex {...rest} alignItems="center">
            <chakra.span
              color={menuButtonStyles.color as string}
              fontSize={menuButtonStyles.fontSize as string}
              fontFamily={menuButtonStyles.fontFamily as string}
            >
              {menuButtonPlaceholder || 'Select an option'}
            </chakra.span>
            <Icon
              name="chevron-down"
              color={menuButtonStyles.color as string}
              fontSize={menuButtonStyles.fontSize as string}
              fontFamily={menuButtonStyles.fontFamily as string}
            />
          </ChakraFlex>
        )}
      </ChakraMenuButton>
      {getMenuOptions?.length > 0 && (
        <ChakraMenuList sx={menuListStyles}>
          {getMenuOptions?.map(({ id, label, icon, action }) => (
            <ChakraMenuItem
              key={id}
              sx={menuItemStyles}
              icon={icon && <Icon size="sm" name={icon} color={menuItemStyles.color as string} />}
              onClick={() => action()}
            >
              {label}
            </ChakraMenuItem>
          ))}
        </ChakraMenuList>
      )}
    </ChakraMenu>
  );
};
