import React, { FC } from 'react';

import {
  FormControl as ChakraFormControl,
  Input as ChakraInput,
  Text as ChakraText,
  forwardRef,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { BaseInputProps } from './index';
import { defaultProps } from './protocol';

export const component: FC<ComponentReceivedProps<BaseInputProps, unknown>> = forwardRef(
  ({ props = defaultProps }, ref) => {
    const { label, size, ...rest } = props;

    const styles = useMultiStyleConfig('PTBaseInput', { size });

    return (
      <ChakraFormControl>
        {label && <ChakraText sx={styles['base-input-label'] || {}}>{label}</ChakraText>}
        <ChakraInput {...rest} sx={styles['base-input-control'] || {}} ref={ref} />
      </ChakraFormControl>
    );
  },
);
