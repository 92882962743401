import React, { Children, cloneElement, FC, isValidElement } from 'react';

import {
  Accordion as ChakraAccordion,
  AccordionButton as ChakraAccordionButton,
  AccordionIcon as ChakraAccordionIcon,
  AccordionItem as ChakraAccordionItem,
  AccordionPanel as ChakraAccordionPanel,
  Flex as ChakraFlex,
  Text as ChakraText,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { AccordionProps } from '.';
import { defaultProps } from './protocol';

export const component: FC<ComponentReceivedProps<AccordionProps, unknown>> = ({
  children,
  inheritedData,
  props = defaultProps,
}) => {
  const themeStyles = useMultiStyleConfig('Accordion', {});

  const { data, title, ...rest } = props;

  const isDynamicTitle = title.includes('{{') && title.includes('}}');

  const getAccordionData = data ? (Array.isArray(data) ? data : [data]) : [{}];
  const getSplittedTitle = title.split('{{').map((string) => string.replace('}}', ''));
  const getTitleKey = isDynamicTitle && getSplittedTitle[getSplittedTitle.length - 1].split('.')[1];

  const childrenWithProps = (item: Record<string, unknown>, index: number) =>
    Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          key: index,
          dynamicData: {
            ...inheritedData,
            $item: item,
            $index: index,
          },
        });
      }

      return child;
    });

  return getAccordionData?.length ? (
    <ChakraAccordion
      width="100%"
      height="fit-content"
      border="1px solid"
      sx={themeStyles.accordion}
      {...rest}
    >
      {getAccordionData?.map((item, idx) => (
        <ChakraAccordionItem
          width="100%"
          _first={{ borderTop: 'pt-none' }}
          _last={{ borderBottom: 'pt-none' }}
          key={idx}
        >
          <ChakraAccordionButton
            outline="none"
            _focus={{ outline: 'none' }}
            sx={themeStyles['accordion-button']}
          >
            <ChakraFlex width="100%" justifyContent="space-between">
              <ChakraText>
                {isDynamicTitle
                  ? getSplittedTitle.length > 1
                    ? getSplittedTitle[0] + item[getTitleKey] || `Item ${idx + 1}`
                    : item[getTitleKey] || `Item ${idx + 1}`
                  : title || `Item ${idx + 1}`}
              </ChakraText>
              <ChakraAccordionIcon />
            </ChakraFlex>
          </ChakraAccordionButton>
          <ChakraAccordionPanel sx={themeStyles['accordion-panel'] || {}}>
            {childrenWithProps(item, idx)}
          </ChakraAccordionPanel>
        </ChakraAccordionItem>
      ))}
    </ChakraAccordion>
  ) : (
    <ChakraFlex
      width="100%"
      height="100%"
      border="1px dashed"
      background="pt-ui.50"
      alignItems="center"
      borderColor="pt-ui.200"
      justifyContent="center"
    >
      <ChakraText fontWeight="pt-medium" color="pt-ui.500">
        No data found.
      </ChakraText>
    </ChakraFlex>
  );
};
