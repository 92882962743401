import { ComponentBlock } from '@types';

import { types } from 'core/types';

import { ButtonProps, ButtonTriggers } from './index';
import { component } from './component';

export const block: ComponentBlock<'button', ButtonProps, ButtonTriggers> = {
  id: 'button',
  render: component,
  props: {
    label: {
      type: types.string,
    },
    colorScheme: {
      type: types.string,
    },
    variant: {
      type: types.string,
    },
    size: {
      type: types.string,
    },
    leftIcon: {
      type: types.string,
    },
    rightIcon: {
      type: types.string,
    },
    isDisabled: {
      type: types.boolean,
    },
  },
  triggers: {
    onClick: {
      name: 'On click',
      description: 'When the button is clicked',
    },
  },
};
