import { EPropType } from '@types';

import { ProtocolFactory } from 'lib/protocols';

import { CardParts, CardProps } from './index';

export const protocolFactory = new ProtocolFactory<CardProps, CardParts>({
  name: 'Card',
  editorProps: [
    {
      name: 'cardTitle',
      label: 'Card title',
      description: 'The title of the card header.',
      defaultValue: 'Example Title',
      required: false,
      type: EPropType.String,
      allowDataLink: true,
    },
  ],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
  themeProps: ['width', 'height', 'size'],
  themeConfig: {
    allowMultipart: true,
    defaultProps: ['size'],
    sizes: ['sm', 'md', 'lg'],
    parts: ['card', 'card-header', 'card-body'],
    partsConfig: {
      card: {
        baseStyleProps: ['border', 'borderRadius', 'boxShadow', 'borderColor', 'background'],
      },
      'card-header': {
        baseStyleProps: ['borderBottom', 'borderColor', 'background', 'color'],
        sizeProps: ['paddingHorizontal', 'paddingVertical'],
      },
      'card-body': {
        baseStyleProps: ['background'],
        sizeProps: ['paddingHorizontal', 'paddingVertical'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
