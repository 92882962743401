import { ComponentBlock } from '@types';

import { types } from 'core/types';

import { BadgeProps } from './index';
import { component } from './component';

export const block: ComponentBlock<'badge', BadgeProps, unknown> = {
  id: 'badge',
  render: component,
  props: {
    label: {
      type: types.string,
    },
    colorScheme: {
      type: types.string,
    },
    variant: {
      type: types.string,
    },
    size: {
      type: types.string,
    },
    leftIcon: {
      type: types.string,
    },
    rightIcon: {
      type: types.string,
    },
  },
};
