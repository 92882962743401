import { v4 as uuidV4 } from 'uuid';

import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { SelectProps } from './index';

export const protocolFactory = new ProtocolFactory<SelectProps>({
  name: 'Select',
  editorProps: [
    {
      type: EPropType.String,
      name: 'name',
      required: false,
      label: 'Name',
      description: 'Name of the select',
      defaultValue: uuidV4(),
    },
    {
      type: EPropType.String,
      name: 'label',
      required: true,
      label: 'Label',
      description: 'The label of the textarea inside of the form control',
      defaultValue: 'Example',
    },
    {
      type: EPropType.String,
      name: 'placeholder',
      required: false,
      label: 'Placeholder',
      description: 'Text inside the select if no value is provided',
      defaultValue: 'Select a option',
    },
    {
      type: EPropType.List,
      name: 'options',
      required: false,
      label: 'Select options',
      description: 'Options availables to be selected',
      defaultValue: [],
    },
  ],
  themeProps: ['size'],
});

export const defaultProps = protocolFactory.createDefaultProps();
