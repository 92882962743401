import React, { FC, useEffect, useState } from 'react';

import { Flex as ChakraFlex, useMultiStyleConfig } from '@chakra-ui/react';

import { IconName } from '@types';

import { Icon } from 'components/ds';

interface Item {
  icon: IconName;
  value: unknown;
}

export interface MultiToggleProps {
  value: unknown;
  items: Item[];
  onChange: (item: Item) => void;
}

export const MultiToggle: FC<MultiToggleProps> = ({ value, items, onChange }) => {
  const styles = useMultiStyleConfig('MultiToggle', {});

  const [selectedItem, setSelectedItem] = useState<Item>();

  const handleToggleInput = (item: Item) => {
    setSelectedItem(item);
    onChange(item);
  };

  useEffect(() => {
    const manuallySelectedItem = items?.find((item) => item.value === value);

    setSelectedItem(manuallySelectedItem);
  }, [value, items]);

  return (
    <ChakraFlex sx={styles['multi-toggle']}>
      {items.map((item, idx) => (
        <ChakraFlex
          key={idx}
          sx={styles['multi-toggle-item']}
          bg={selectedItem === item && 'white'}
          color={selectedItem === item ? 'primary.500' : 'ui.500'}
          boxShadow={selectedItem === item && 'large'}
          _hover={{
            color: selectedItem === item ? 'primary.700' : 'ui.700',
          }}
          onClick={() => handleToggleInput(item)}
        >
          <Icon name={item.icon} size="md" />
        </ChakraFlex>
      ))}
    </ChakraFlex>
  );
};
