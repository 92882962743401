export const capitalize = (text: string | undefined): string => {
  if (!text) return;
  return `${text.toString().charAt(0).toUpperCase()}${text.toString().slice(1)}`;
};

export const formatToLowerCase = (text: string): string =>
  `${text.toString().charAt(0).toLowerCase()}${text.toString().slice(1)}`;

export const formatSeparator = (name: string): string => {
  if (!name) return;
  return name
    .split('-')
    .map((n) => capitalize(n))
    .join('');
};

// eslint-disable-next-line no-control-regex
export const insertSeparator = (text: string): string => text.replaceAll(/[ 	]/g, '-');

export const toCamelCase = (string: string | undefined): string => {
  if (!string || typeof string !== 'string') return '';

  const text = string
    .replaceAll(/\s+(.)|(-+[a-zA-Z])/g, (c: string) =>
      c.length > 1 ? c.charAt(1).toUpperCase() : c.toUpperCase(),
    )
    .replaceAll(/\s+(.)|(-)/g, '')
    .trim();

  return `${text.charAt(0).toLowerCase()}${text.slice(1)}`;
};

export const toKebabCase = (string: string | undefined): string => {
  if (!string || typeof string !== 'string') return '';

  return string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase()
    .trim();
};

export const toEnumFieldFormat = (string: string): string => {
  if (!string || typeof string !== 'string') return '';

  const text = string
    .replaceAll(/\s+(.)|(-+[a-zA-Z])|(-+[0-9])/g, (c: string) =>
      c.length > 1 ? `${c.charAt(1).toUpperCase()}` : c.toLowerCase(),
    )
    .trim();

  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};

export const toDatasetFormat = (string: string): string => {
  if (!string || typeof string !== 'string') return '';

  const text = string
    .replaceAll(/\s+(.)|(-+[a-zA-Z])/g, (c: string) =>
      c.length > 1 ? `-${c.charAt(1).toLowerCase()}` : c.toLowerCase(),
    )
    .trim();

  return `${text.charAt(0).toLowerCase()}${text.slice(1)}`;
};

export const showDatasetFormatted = (string: string): string => {
  if (!string || typeof string !== 'string') return '';

  const text = string
    .replaceAll(/-+[a-z]|(\b[A-Z])/g, (c: string) =>
      c.length > 1 ? ` ${c.charAt(1).toUpperCase()}` : ` ${c.toUpperCase()}`,
    )
    .replaceAll(/([a-z][A-Z])|([a-zA-Z][0-9])/g, (c: string) =>
      c.length > 1 ? `${c.charAt(0)} ${c.charAt(1).toUpperCase()}` : c,
    )
    .trim();

  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};
