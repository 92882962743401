import { get, isObject } from 'lodash';

import { allowedUserColors, EPropType, ThemeOverride, ThemeProtocolItem } from '@types';

import { coreIcons } from '@application/components/ds/Icon/CoreIcons';
import { replaceThemePrefix } from '@application/lib/defaultTheme/helpers';

const coreIconsOptions = Object.keys(coreIcons).map((icon) => ({ label: icon, value: icon }));

export type SharedKeys =
  | 'variant'
  | 'size'
  | 'icon'
  | 'leftIcon'
  | 'rightIcon'
  | 'colorScheme'
  | 'width'
  | 'height'
  | 'minWidth'
  | 'minHeight'
  | 'maxWidth'
  | 'maxHeight'
  | 'boxShadow'
  | 'htmlTag'
  | 'overflow';

type Shared = ThemeProtocolItem<SharedKeys>;

export const shared: Shared = {
  width: {
    type: EPropType.String,
    name: 'width',
    label: 'Width',
    description: 'Width of your component',
    required: false,
    defaultValue: '100%',
    input: 'InputText',
    inputProps: {
      width: '50%',
    },
    themeGetter: (): [] => [],
  },
  height: {
    type: EPropType.String,
    name: 'height',
    label: 'Height',
    description: 'Height of your component',
    required: false,
    defaultValue: '100%',
    input: 'InputText',
    inputProps: {
      width: '50%',
    },
    themeGetter: (): [] => [],
  },
  minWidth: {
    type: EPropType.String,
    name: 'minWidth',
    label: 'Min width',
    description: 'Min width of your component',
    required: false,
    defaultValue: 'fit-content',
    input: 'InputText',
    inputProps: {
      width: '50%',
    },
    themeGetter: (): [] => [],
  },
  minHeight: {
    type: EPropType.String,
    name: 'minHeight',
    label: 'Min height',
    description: 'Min height of your component',
    required: false,
    defaultValue: 'fit-content',
    input: 'InputText',
    inputProps: {
      width: '50%',
    },
    themeGetter: (): [] => [],
  },
  maxWidth: {
    type: EPropType.String,
    name: 'maxWidth',
    label: 'Max width',
    description: 'Max width of your component',
    required: false,
    defaultValue: '100%',
    input: 'InputText',
    inputProps: {
      width: '50%',
    },
    themeGetter: (): [] => [],
  },
  maxHeight: {
    type: EPropType.String,
    name: 'maxHeight',
    label: 'Max height',
    description: 'Max height of your component',
    required: false,
    defaultValue: '100%',
    input: 'InputText',
    inputProps: {
      width: '50%',
    },
    themeGetter: (): [] => [],
  },
  variant: {
    type: EPropType.String,
    name: 'variant',
    label: 'Variant',
    description: 'Variation of your component',
    required: false,
    defaultValue: '',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride, componentName: string): string[] => {
      const variants = get(theme, ['components', componentName, 'variants'], {});

      return Object.keys(variants);
    },
  },
  size: {
    type: EPropType.String,
    name: 'size',
    label: 'Size',
    description: 'Size of your component',
    required: false,
    defaultValue: 'md',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride, componentName: string): string[] => {
      return Object.keys(theme?.components[componentName]?.sizes) ?? [];
    },
  },
  colorScheme: {
    type: EPropType.String,
    name: 'colorScheme',
    label: 'Color scheme',
    description: 'Color of your component',
    required: false,
    defaultValue: 'primary',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) => {
      return (
        Object.entries(theme.colors ?? {})
          .filter(([colorName, val]) => {
            return allowedUserColors.includes(colorName) && isObject(val);
          })
          .map(([colorName]) => ({
            label: replaceThemePrefix(colorName),
            value: colorName,
          })) ?? []
      );
    },
  },
  boxShadow: {
    type: EPropType.String,
    name: 'boxShadow',
    label: 'Shadow',
    description: 'Box shadow of the component',
    required: false,
    defaultValue: '',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.shadows ?? {}).map((shadow) => ({
        label: replaceThemePrefix(shadow),
        value: shadow,
      })),
  },
  icon: {
    type: EPropType.String,
    name: 'icon',
    label: 'Icon',
    description: 'Icon name',
    required: false,
    defaultValue: null,
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (_, __, customIcons = []) => {
      const themeCustomIcons = customIcons?.map(({ name: label, link: value }) => ({ label, value }));
      return [...themeCustomIcons, ...coreIconsOptions].sort((a, b) => (a.label < b.label ? -1 : 1));
    },
  },
  leftIcon: {
    type: EPropType.String,
    name: 'leftIcon',
    label: 'Left icon',
    description: 'The left icon name',
    required: true,
    defaultValue: null,
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (_, __, customIcons = []) => {
      const themeCustomIcons = customIcons?.map(({ name: label, link: value }) => ({ label, value }));
      return [...themeCustomIcons, ...coreIconsOptions].sort((a, b) => (a.label < b.label ? -1 : 1));
    },
  },
  rightIcon: {
    type: EPropType.String,
    name: 'rightIcon',
    label: 'Right icon',
    description: 'The right icon name',
    required: true,
    defaultValue: null,
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (_, __, customIcons = []) => {
      const themeCustomIcons = customIcons?.map(({ name: label, link: value }) => ({ label, value }));
      return [...themeCustomIcons, ...coreIconsOptions].sort((a, b) => (a.label < b.label ? -1 : 1));
    },
  },
  htmlTag: {
    type: EPropType.String,
    name: 'htmlTag',
    label: 'HTML tag',
    description: 'The tag that will be replaced by the component',
    required: true,
    defaultValue: 'h2',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    options: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
  },
  overflow: {
    type: EPropType.String,
    name: 'overflow',
    label: 'Overflow',
    description: 'The overflow type of the component',
    required: false,
    defaultValue: 'none',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    options: ['none', 'hidden', 'auto'],
  },
};
