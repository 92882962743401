import React from 'react';

import { chakra, IconProps as ChakraIconProps, forwardRef, useStyleConfig } from '@chakra-ui/react';
import { merge } from 'lodash';
import SVG from 'react-inlinesvg';

import { IconSizes, IconVariants } from '@application/theme';

import { IconName } from '@types';

import { coreIcons } from './CoreIcons';
import { customIcons } from './CustomIcons';

const ChakraSVG = chakra(SVG);

export interface IconProps extends ChakraIconProps {
  title?: string;
  name?: IconName;
  size?: IconSizes;
  src?: string;
  variant?: IconVariants;
}

export const Icon = forwardRef<IconProps, 'div'>(({ name, size, variant, src, ...props }, ref) => {
  const styles = useStyleConfig('Icon', { size, variant });
  const icons = merge(coreIcons, customIcons);

  const RenderedIcon = icons[name] ?? icons['question-outline'];

  // @ts-ignore
  if (src) return <ChakraSVG fill={props.color || styles.color} sx={styles} src={src} {...props} />;

  // @ts-ignore
  return <RenderedIcon ref={ref} sx={styles} {...props} />;
});
