import React from 'react';

interface Types {
  string: 'String';
  boolean: 'Boolean';
  number: 'Number';
  id: 'ID';
  object: 'Object';
  list: 'List';
  any: 'Any';
}

export const types: Types = {
  string: 'String',
  boolean: 'Boolean',
  number: 'Number',
  id: 'ID',
  object: 'Object',
  list: 'List',
  any: 'Any',
};
