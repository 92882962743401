import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export type AccordionParts = 'accordion' | 'accordion-button' | 'accordion-panel';

export interface AccordionProps {
  title: string;
  data: Record<string, unknown>[] | Record<string, unknown>;
  allowToggle: boolean;
  allowMultiple: boolean;
}

export default {
  component,
  block,
  defaultProps,
  protocolFactory,
} as CompilerComponent<AccordionProps, AccordionParts>;
