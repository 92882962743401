import { EPropType, ThemeOverride, ThemeProtocolItem } from '@types';

import { replaceThemePrefix } from '@application/lib/defaultTheme/helpers';

export type SpaceKeys =
  | 'padding'
  | 'paddingVertical'
  | 'paddingHorizontal'
  | 'paddingTop'
  | 'paddingBottom'
  | 'paddingRight'
  | 'paddingLeft'
  | 'marginHorizontal'
  | 'marginVertical'
  | 'marginTop'
  | 'marginBottom'
  | 'gridGap'
  | 'gridColumnGap'
  | 'gridRowGap'
  | 'boxSize';

type Space = ThemeProtocolItem<SpaceKeys>;

export const space: Space = {
  padding: {
    type: EPropType.String,
    name: 'padding',
    required: false,
    label: 'Padding',
    description: null,
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  paddingHorizontal: {
    type: EPropType.String,
    name: 'paddingX',
    required: false,
    label: 'Padding horizontal',
    description: null,
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  paddingVertical: {
    type: EPropType.String,
    input: 'InputSelect',
    name: 'paddingY',
    required: false,
    label: 'Padding vertical',
    description: null,
    defaultValue: '0',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  paddingBottom: {
    type: EPropType.String,
    name: 'paddingBottom',
    required: false,
    label: 'Padding bottom',
    description: null,
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  paddingLeft: {
    type: EPropType.String,
    name: 'paddingLeft',
    required: false,
    label: 'Padding left',
    description: null,
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  paddingRight: {
    type: EPropType.String,
    name: 'paddingRight',
    required: false,
    label: 'Padding right',
    description: null,
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  paddingTop: {
    type: EPropType.String,
    name: 'paddingTop',
    required: false,
    label: 'Padding top',
    description: null,
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  // Margin
  marginHorizontal: {
    type: EPropType.String,
    name: 'marginX',
    required: false,
    label: 'Horizontal margin',
    description: null,
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  marginVertical: {
    type: EPropType.String,
    name: 'marginY',
    required: false,
    label: 'Vertical margin',
    description: null,
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  marginTop: {
    type: EPropType.String,
    name: 'marginTop',
    required: false,
    label: 'Top margin',
    description: null,
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  marginBottom: {
    type: EPropType.String,
    name: 'marginBottom',
    required: false,
    label: 'Margin bottom',
    description: null,
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  gridGap: {
    type: EPropType.String,
    name: 'gridGap',
    required: false,
    label: 'Grid gap',
    description: 'The columns and rows gap between the childrens of a container/grid',
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '100%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  gridColumnGap: {
    type: EPropType.String,
    name: 'gridColumnGap',
    required: false,
    label: 'Grid column gap',
    description: 'The columns gap between the childrens of a container/grid',
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  gridRowGap: {
    type: EPropType.String,
    name: 'gridRowGap',
    required: false,
    label: 'Grid row gap',
    description: 'The rows gap between the childrens of a container/grid',
    defaultValue: '0',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    themeGetter: (theme: ThemeOverride) =>
      Object.keys(theme.space ?? {}).map((space) => ({
        label: replaceThemePrefix(space),
        value: space,
      })),
  },
  boxSize: {
    type: EPropType.String,
    name: 'boxSize',
    required: false,
    label: 'Box size',
    description: 'Size of the icon',
    defaultValue: '16px',
    input: 'InputSelect',
    inputProps: {
      width: '50%',
    },
    options: ['12px', '16px', '18px', '20px', '24px', '32px'],
  },
};
