import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

interface ImageOption {
  id: string;
  value: string;
}

export interface ImageGalleryProps {
  imageGalleryData: ImageOption[];
  imageGalleryShowBullets: boolean;
  imageGalleryShowFullscreenButton: boolean;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<ImageGalleryProps>;
