import { useEffect, useState } from 'react';

import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql/language/printer';
import { useQuery, useQueryClient } from 'react-query';
import gql from 'graphql-tag';

import { Introspection } from '@types';

import { IntrospectionFactory } from '@introspection';

import { formatSeparator } from '@application/utils/stringManipulation';

export const useIntrospectionFactory = (
  apiUrl: string,
  token: string,
  enabled = true,
): [IntrospectionFactory, boolean, boolean] => {
  const queryClient = useQueryClient();

  // If the token is defined it request goes to the private api
  // If not and it's enabled, request goes to the public api
  const queryEnabled = token || enabled;

  // If the tokens change we must change the key to revalidate the query
  const uniqIdentifier = token ? token?.substring(0, 5) : 'public';

  const { data, isIdle, isLoading, isError } = useQuery(
    ['Introspection', apiUrl, uniqIdentifier],
    async () => {
      if (token) {
        return await new GraphQLClient(apiUrl, {
          headers: { Authorization: token },
        }).request(print(GetIntrospectionQuery));
      }

      return await new GraphQLClient(process.env.URL_PUBLIC_API, {
        headers: {
          'api-url': apiUrl,
          region: process.env.URL_PUBLIC_REGION,
        },
      }).request(print(GetIntrospectionQuery));
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!apiUrl && !!queryEnabled,
      onError: (err) => {
        console.error(err);
      },
    },
  );

  if (!apiUrl) {
    return [null, false, false];
  }

  if (isIdle || isLoading) return [undefined, true, isError];

  const introspectionFactory = new IntrospectionFactory(data?.__schema?.types);

  return [introspectionFactory, false, isError];
};

export const getDatasetOperation = (
  operations: Introspection.IOperation[],
  datasetName: string,
  operation: string,
): Introspection.IOperation =>
  operations?.find((x) => x.name === `${operation}${formatSeparator(datasetName)}`);

export enum QueryOperation {
  Get = 'get',
  List = 'list',
}

export const useIntrospectionByOperationName = (
  apiUrl: string,
  token: string,
  operationName: string,
  enabled = true,
): [Introspection.IOperation, boolean] => {
  const [operation, setOperation] = useState(undefined);

  const [introspectionFactory, loadingIntrospectionFactory] = useIntrospectionFactory(apiUrl, token, enabled);

  useEffect(() => {
    if (!loadingIntrospectionFactory && introspectionFactory) {
      const foundQuery = introspectionFactory.operations.find(
        (operation) => operation.name === operationName,
      );

      setOperation(foundQuery ?? null);
    }
  }, [loadingIntrospectionFactory, introspectionFactory, operationName]);

  return [operation, typeof operation === 'undefined'];
};

export const GetIntrospectionQuery = gql`
  query GetIntrospectionQuery {
    __schema {
      types {
        kind
        name
        fields {
          name
          type {
            name
            kind
            ofType {
              name
              kind
            }
            fields {
              name
              type {
                name
                kind
                ofType {
                  name
                  kind
                }
                fields {
                  name
                }
              }
            }
          }
          args {
            name
            description
            defaultValue
            type {
              name
              kind
              ofType {
                name
                kind
              }
            }
          }
        }
        inputFields {
          name
          description
          type {
            name
            kind
            ofType {
              name
              kind
            }
            fields {
              name
              type {
                name
                kind
              }
            }
          }
          defaultValue
        }

        enumValues {
          name
          description
        }
      }
    }
  }
`;
