import { AWSConnection, AWSInput, Organization } from '@types';

import { queryAdmin } from '../adminApi';

import { CreateOrganization } from './mutations';
import { GetOrganization, ListOrganizations } from './queries';

// Get Organization
export const getOrganization = (
  variables: {
    id: string;
  },
  token: string,
): Promise<{ getOrganization: Organization }> => {
  return queryAdmin(GetOrganization, variables)(token);
};

// Get Organization
export const listOrganizations = (
  variables: { limit?: number },
  token: string,
): Promise<{ listOrganizations: AWSConnection<Organization> }> => {
  return queryAdmin(ListOrganizations, variables)(token);
};

// Create Organization
type CreateOrganizationInput = Pick<Organization, 'accountId' | 'connection' | 'name'>;
export const createOrganization = (
  variables: AWSInput<CreateOrganizationInput>,
  token: string,
): Promise<{ createOrganization: Organization }> => {
  return queryAdmin(CreateOrganization, variables)(token);
};
