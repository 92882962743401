import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export type CardParts = 'card' | 'card-header' | 'card-body';

export interface CardProps {
  cardTitle?: string;
  size: 'sm' | 'md' | 'lg';
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<CardProps, CardParts>;
