import React, { FC, useEffect, useState } from 'react';

import {
  Button as ChakraButton,
  Flex as ChakraFlex,
  IconButton as ChakraIconButton,
  Menu as ChakraMenu,
  MenuButton as ChakraMenuButton,
  MenuItem as ChakraMenuItem,
  MenuList as ChakraMenuList,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';

import { Icon } from 'components/ds';
import { IconName } from '@types';
import { MenuSelectSizes, MenuSelectVariants } from '@application/theme';

export interface MenuSelectOption {
  value: any;
  label: string;
  icon?: IconName;
}

export interface MenuSelectProps {
  icon?: IconName;
  colorScheme?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isFullWidth?: boolean;
  onChange?: (option: MenuSelectOption) => void;
  options?: Array<MenuSelectOption>;
  placeholder?: string;
  size?: MenuSelectSizes;
  value?: MenuSelectOption | string;
  variant?: MenuSelectVariants;
}

export const MenuSelect: FC<MenuSelectProps> = ({
  colorScheme,
  icon,
  isDisabled,
  isLoading,
  isFullWidth,
  onChange,
  options,
  placeholder,
  size,
  value,
  variant,
}) => {
  const [selectedOption, setSelectedOption] = useState<MenuSelectOption>();

  const styles = useMultiStyleConfig('MenuSelect', { size, variant, colorScheme });

  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        const valueOption = options?.find((opt) => opt.value === value);
        if (valueOption) setSelectedOption(valueOption);
      } else {
        setSelectedOption(value);
      }
    }
  }, [value]);

  function handleOnChange(selectedOption: MenuSelectOption) {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  }

  return (
    <>
      <ChakraMenu matchWidth={!icon} isLazy={true}>
        {({ isOpen }) => (
          <ChakraFlex width={isFullWidth && '100%'} overflow={!icon && 'hidden'}>
            {icon ? (
              // @ts-ignore
              <ChakraMenuButton
                data-testid="menu-select-icon-button"
                width={isFullWidth ? '100%' : 'fit-content'}
                as={ChakraIconButton}
                icon={<Icon name={icon} />}
                sx={styles['menu-icon-button']}
              />
            ) : (
              // @ts-ignore
              <ChakraMenuButton
                textAlign="left"
                data-testid="menu-select-button"
                isTruncated={true}
                as={ChakraButton}
                sx={styles['menu-button']}
                width={isFullWidth ? '100%' : 'fit-content'}
                isLoading={isLoading}
                fontWeight={selectedOption ? 'bold' : 'normal'}
                isDisabled={isDisabled}
                rightIcon={
                  isOpen ? <TriangleUpIcon boxSize="0.5rem" /> : <TriangleDownIcon boxSize="0.5rem" />
                }
              >
                {selectedOption ? selectedOption.label : placeholder}
              </ChakraMenuButton>
            )}
            <ChakraMenuList sx={styles['menu-list']}>
              {options?.length > 0 ? (
                options.map((option, idx) => (
                  <ChakraMenuItem
                    key={idx}
                    sx={styles['menu-item']}
                    data-testid={`menu-select-item-${idx}`}
                    onClick={() => handleOnChange(option)}
                    {...(option.icon && {
                      icon: <Icon size="sm" name={option.icon} />,
                    })}
                  >
                    {option.label}
                  </ChakraMenuItem>
                ))
              ) : (
                <ChakraMenuItem isDisabled={true} data-testid="menu-select-item" sx={styles['menu-item']}>
                  No options.
                </ChakraMenuItem>
              )}
            </ChakraMenuList>
          </ChakraFlex>
        )}
      </ChakraMenu>
    </>
  );
};

MenuSelect.displayName = 'MenuSelect';
