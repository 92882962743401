import { CircularProgressProps } from './index';
import { component } from './component';
import { ComponentBlock } from '@types';
import { types } from 'core/types';

export const block: ComponentBlock<'circularProgress', CircularProgressProps, unknown> = {
  id: 'circularProgress',
  render: component,
  props: {
    value: {
      type: types.number,
    },
    list: {
      type: types.list,
    },
    property: {
      type: types.string,
    },
    size: {
      type: types.string,
    },
    colorScheme: {
      type: types.string,
    },
  },
};
