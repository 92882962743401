import React, { FC, useEffect, useState } from 'react';

import {
  FormControl as ChakraFormControl,
  NumberDecrementStepper as ChakraNumberDecrementStepper,
  NumberIncrementStepper as ChakraNumberIncrementStepper,
  NumberInput as ChakraNumberInput,
  NumberInputField as ChakraNumberInputField,
  NumberInputStepper as ChakraNumberInputStepper,
  Text as ChakraText,
  forwardRef,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { NumberInputProps, NumberInputTriggers } from './index';

export const component: FC<ComponentReceivedProps<NumberInputProps, NumberInputTriggers>> = forwardRef(
  ({ props = defaultProps, triggers }, ref) => {
    const { size, label, placeholder, value, ...rest } = props;
    const { onBlur } = triggers;

    const [state, setState] = useState(value);

    const baseInputStyles = useMultiStyleConfig('PTBaseInput', { size });

    useEffect(() => {
      setState(value);
    }, [value]);

    return (
      <ChakraFormControl>
        {label && <ChakraText sx={baseInputStyles['base-input-label']}>{label}</ChakraText>}
        <ChakraNumberInput
          {...rest}
          ref={ref}
          value={state}
          onBlur={({ target }) => onBlur && onBlur(target.value)}
          onChange={(valueAsString, valueAsNumber: number) => setState(valueAsNumber)}
        >
          <ChakraNumberInputField
            outline="none"
            background="pt-transparent"
            placeholder={placeholder}
            sx={baseInputStyles['base-input-control']}
            _focus={{
              outline: 'none',
            }}
          />
          <ChakraNumberInputStepper>
            <ChakraNumberIncrementStepper paddingX="pt-xs" fontSize="pt-xs" />
            <ChakraNumberDecrementStepper paddingX="pt-xs" fontSize="pt-xs" />
          </ChakraNumberInputStepper>
        </ChakraNumberInput>
      </ChakraFormControl>
    );
  },
);
