import { Column } from 'react-table';

import { capitalize } from './stringManipulation';
import { checkTypeAndFormatDate } from './formatDate';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createColumn = (
  items: Record<string, any>[] | undefined,
  hasActionButton = false,
  removeFields: string[] = [],
): Column[] => {
  if (!items) return [];

  const columns = Object.keys(items[0] ?? {}).reduce((acc, itemKey) => {
    if (removeFields.includes(itemKey)) {
      return acc;
    }

    if (itemKey.startsWith('_') || (itemKey.endsWith('Relation') && !itemKey.charAt(0).match(/[A-Z]/g))) {
      return acc;
    }

    return [
      ...acc,
      {
        accessor: itemKey,
        Header: capitalize(itemKey),
      },
    ];
  }, []);

  return hasActionButton
    ? [...columns, { accessor: 'actionButton', Header: '' }, { accessor: 'actionButton2', Header: '' }]
    : columns;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createData = (
  items: Record<string, any>[] | undefined,
  removeFields: string[] = [],
  actionButton: React.ReactNode = null,
  actionButton2: React.ReactNode = null,
) => {
  if (!items) {
    return [];
  }

  return items.map((item) => {
    const formatedData = Object.keys(item).reduce((acc, curr) => {
      if (removeFields.includes(curr)) {
        return acc;
      }

      return (acc = {
        ...acc,
        [curr]: item[curr] ? checkTypeAndFormatDate(item[curr]) : '',
      });
    }, {});

    return actionButton && actionButton2
      ? { ...formatedData, actionButton, actionButton2 }
      : actionButton && !actionButton2
      ? { ...formatedData, actionButton }
      : formatedData;
  });
};
