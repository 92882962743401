import { IconProps as ChakraIconProps } from '@chakra-ui/react';

import { ThemeComponent } from '@types';

export type IconSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl';
export type IconVariants = 'default';

export const Icon: ThemeComponent<IconSizes, IconVariants, ChakraIconProps> = {
  baseStyle: () => ({}),

  sizes: {
    xs: {
      boxSize: '0.75rem',
      padding: '0px',
    },
    sm: {
      boxSize: '1rem',
      padding: '1px',
    },
    md: {
      boxSize: '1.5rem',
      padding: '2px',
    },
    lg: {
      boxSize: '2rem',
      padding: '3px',
    },
    xl: {
      boxSize: '1.75rem',
      padding: '4px',
    },
    '2xl': {
      boxSize: '2rem',
      padding: '5px',
    },
    '3xl': {
      boxSize: '2.125rem',
      padding: '6px',
    },
    '4xl': {
      boxSize: '2.5rem',
      padding: '7px',
    },
    '5xl': {
      boxSize: '2.75rem',
      padding: '8px',
    },
    '6xl': {
      boxSize: '3rem',
      padding: '0px',
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: null,
  },
};
