import { component } from './component';
import { ComponentBlock } from '@types';
import { DataContainerProps } from './index';
import { types } from 'core/types';

export const block: ComponentBlock<'dataContainer', DataContainerProps, any> = {
  id: 'dataContainer',
  render: component,
  props: {
    operationName: {
      type: types.string,
    },
    args: {
      type: types.object,
    },
    fields: {
      type: types.list,
    },
  },
  events: {
    refetch: {
      name: 'Refetch',
      inputArguments: [],
      fields: [],
    },
  },
};
