import React, { FC, useContext, useMemo } from 'react';

import {
  Flex as ChakraFlex,
  Heading as ChakraHeading,
  Text as ChakraText,
  useMultiStyleConfig,
  useToken,
} from '@chakra-ui/react';
import { ResponsiveLine } from '@nivo/line';

import { ComponentReceivedProps } from '@types';

import { CompilerContext } from '@application/compiler/contexts';

import { defaultProps } from './protocol';
import { LineChartProps } from '.';

const exampleData = [
  {
    id: 'default',
    data: [
      { x: 'Coffee', y: 20 },
      { x: 'Rice', y: 30 },
      { x: 'Tomatoes', y: 70 },
      { x: 'Apple', y: 60 },
    ],
  },
];

export const component: FC<ComponentReceivedProps<LineChartProps, unknown>> = ({ props = defaultProps }) => {
  const { chartData, chartCategory, chartValue, chartCategoryLabel, chartValueLabel } = props;

  const { config } = useContext(CompilerContext);
  const { 'chart-series': seriesStyle, 'chart-axis': axisStyle } = useMultiStyleConfig('PTLineChart', {});

  const [axisFontSize] = useToken('fontSizes', [axisStyle.fontSize as string]);
  const [axisFontWeight] = useToken('fontWeights', [axisStyle.fontWeight as string]);
  const [seriesBackground, axisFontColor] = useToken('colors', [
    seriesStyle.background as string,
    axisStyle.color as string,
  ]);

  if ((!chartData || !chartCategory || !chartValue) && config.mode !== 'theme')
    return (
      <ChakraFlex width="100%" height="100%">
        <ChakraFlex
          width="100%"
          height="100%"
          border="pt-sm"
          gridGap="pt-sm"
          background="pt-ui.50"
          alignItems="center"
          borderStyle="dashed"
          borderColor="pt-info.200"
          borderRadius="pt-xs"
          flexDirection="column"
          justifyContent="center"
        >
          <ChakraHeading color="pt-info.500" fontSize="pt-xl">
            Missing configuration
          </ChakraHeading>
          <ChakraText maxWidth="20rem" color="pt-ui.500" textAlign="center">
            For the propertly work of the chart you need to define a{' '}
            <ChakraText as="span" color="pt-info.500">
              data
            </ChakraText>
            ,{' '}
            <ChakraText as="span" color="pt-info.500">
              category
            </ChakraText>{' '}
            and{' '}
            <ChakraText as="span" color="pt-info.500">
              value
            </ChakraText>
            .
          </ChakraText>
        </ChakraFlex>
      </ChakraFlex>
    );

  const isThemeMode = useMemo(() => config.mode === 'theme', [config.mode]);

  const getFormatedData = useMemo(() => {
    if (!isThemeMode) {
      const items = [];

      const formatedChartData = chartData?.map(
        (item) =>
          item[chartCategory] &&
          item[chartValue] && {
            x: item[chartCategory],
            y: item[chartValue] as number,
          },
      );

      formatedChartData.reduce((previous, current) => {
        if (!previous[current?.x]) {
          previous[current?.x] = {
            x: current?.x,
            y: 0,
          };

          items.push(previous[current?.x]);
        }

        previous[current?.x].y += current?.y;

        return previous;
      }, {});

      return [
        {
          id: 'default',
          data: items,
        },
      ];
    }
  }, [chartData]);

  return (
    <ResponsiveLine
      data={isThemeMode ? exampleData : getFormatedData}
      colors={seriesBackground}
      useMesh={true}
      enableArea={true}
      margin={{ top: 60, bottom: 60, left: 70, right: 70 }}
      theme={{
        axis: {
          ticks: {
            text: {
              fontSize: axisFontSize,
              fontWeight: axisFontWeight,
              fill: axisFontColor,
            },
          },
          legend: {
            text: {
              fontSize: axisFontSize,
              fontWeight: axisFontWeight,
              fill: axisFontColor,
            },
          },
        },
      }}
      axisBottom={{
        legend: chartCategoryLabel || chartCategory || 'Product',
        legendPosition: 'middle',
        legendOffset: 42,
      }}
      axisLeft={{
        legend: chartValueLabel || chartValue || 'Total',
        legendPosition: 'middle',
        legendOffset: -52,
      }}
    />
  );
};
