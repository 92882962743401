import { CompilerComponent, OptionBlocks } from '@types';

import AccordionComponent, { AccordionProps } from './Accordion';
import AspectRatioBoxComponent, { AspectRatioBoxProps } from './AspectRatioBox';
import BadgeComponent, { BadgeProps } from './Badge';
import BarChartComponent, { BarChartProps } from './BarChart';
import BaseInputComponent, { BaseInputProps } from './BaseInput';
import BoardComponent, { BoardProps } from './Board';
import BreadcrumbComponent, { BreadcrumbProps } from './Breadcrumb';
import ButtonComponent, { ButtonProps, ButtonTriggers } from './Button';
import CalendarComponent, { CalendarProps } from './Calendar';
import CardComponent, { CardProps } from './Card';
import CheckboxComponent, { CheckboxProps } from './Checkbox';
import CircularProgressComponent, { CircularProgressProps } from './CircularProgress';
import ContainerComponent, { ContainerProps } from './Container';
import DataContainerComponent, { DataContainerProps } from './DataContainer';
import DateInputComponent, { DateInputProps } from './DateInput';
import DevelopmentEnvironment, { DevelopmentEnvironmentProps } from './DevelopmentEnvironment';
import DividerComponent, { DividerProps } from './Divider';
import DynamicFormComponent, { DynamicFormProps } from './DynamicForm';
import GridComponent, { GridProps } from './Grid';
import GridItemComponent, { GridItemProps } from './GridItem';
import HeaderComponent, { HeaderProps } from './Header';
import IconComponent, { IconProps } from './Icon';
import ImageComponent, { ImageProps } from './Image';
import ImageGalleryComponent, { ImageGalleryProps } from './ImageGallery';
import ImageUploadComponent, { ImageUploadProps, ImageUploadTriggers } from './ImageUpload';
import InputComponent, { InputProps } from './Input';
import LayoutRouterView from './LayoutRouterView';
import LineChartComponent, { LineChartProps } from './LineChart';
import ListComponent, { ListProps } from './List';
import MapComponent, { MapProps } from './Map';
import MenuComponent, { MenuProps } from './Menu';
import ModalComponent, { ModalProps } from './Modal';
import MutationComponent, { MutationContainerProps } from './MutationContainer';
import NumberInputComponent, { NumberInputProps } from './NumberInput';
import PageComponent, { PageProps } from './Page';
import PieChartComponent, { PieChartProps } from './PieChart';
import ProgressComponent, { ProgressProps } from './Progress';
import RadioAlternativeButtonComponent, { RadioAlternativeButtonProps } from './RadioAlternativeButton';
import RadioAlternativeComponent, { RadioAlternativeProps } from './RadioAlternative';
import RadioComponent, { RadioProps } from './Radio';
import RichTextEditorComponent, { RichTextEditorProps, RichTextEditorTriggers } from './RichTextEditor';
import SelectComponent, { SelectProps } from './Select';
import SidebarComponent, { SidebarProps } from './Sidebar';
import StoriesComponent, { StoriesProps } from './Stories';
import TableComponent, { TableProps } from './Table';
import TextareaComponent, { TextareaProps } from './Textarea';
import TextComponent, { TextProps } from './Text';
import TitleComponent, { TitleProps } from './Title';
import WordCloudComponent, { WordCloudProps } from './WordCloud';

export const components: Record<OptionBlocks, CompilerComponent<any, any>> = {
  accordion: AccordionComponent,
  aspectRatio: AspectRatioBoxComponent,
  barChart: BarChartComponent,
  badge: BadgeComponent,
  baseInput: BaseInputComponent,
  breadcrumb: BreadcrumbComponent,
  button: ButtonComponent,
  board: BoardComponent,
  card: CardComponent,
  calendar: CalendarComponent,
  checkbox: CheckboxComponent,
  circularProgress: CircularProgressComponent,
  container: ContainerComponent,
  dataContainer: DataContainerComponent,
  dateInput: DateInputComponent,
  developmentEnvironment: DevelopmentEnvironment,
  divider: DividerComponent,
  grid: GridComponent,
  gridItem: GridItemComponent,
  header: HeaderComponent,
  title: TitleComponent,
  icon: IconComponent,
  image: ImageComponent,
  imageGallery: ImageGalleryComponent,
  imageUpload: ImageUploadComponent,
  input: InputComponent,
  layoutRouterView: LayoutRouterView,
  list: ListComponent,
  lineChart: LineChartComponent,
  map: MapComponent,
  menu: MenuComponent,
  modal: ModalComponent,
  mutationContainer: MutationComponent,
  numberInput: NumberInputComponent,
  progress: ProgressComponent,
  radio: RadioComponent,
  radioAlternative: RadioAlternativeComponent,
  radioAlternativeButton: RadioAlternativeButtonComponent,
  richTextEditor: RichTextEditorComponent,
  select: SelectComponent,
  sidebar: SidebarComponent,
  stories: StoriesComponent,
  subForm: DynamicFormComponent,
  dynamicForm: DynamicFormComponent,
  page: PageComponent,
  pieChart: PieChartComponent,
  table: TableComponent,
  text: TextComponent,
  textarea: TextareaComponent,
  wordCloud: WordCloudComponent,
};

export type {
  AccordionProps,
  AspectRatioBoxProps,
  BarChartProps,
  BadgeProps,
  BaseInputProps,
  BreadcrumbProps,
  ButtonProps,
  ButtonTriggers,
  BoardProps,
  CardProps,
  CalendarProps,
  CheckboxProps,
  CircularProgressProps,
  ContainerProps,
  DateInputProps,
  DataContainerProps,
  DevelopmentEnvironmentProps,
  DividerProps,
  HeaderProps,
  IconProps,
  InputProps,
  GridProps,
  GridItemProps,
  ListProps,
  LineChartProps,
  ImageProps,
  ImageGalleryProps,
  ImageUploadProps,
  ImageUploadTriggers,
  MapProps,
  MenuProps,
  ModalProps,
  MutationContainerProps,
  NumberInputProps,
  ProgressProps,
  RadioProps,
  RadioAlternativeProps,
  RadioAlternativeButtonProps,
  RichTextEditorProps,
  RichTextEditorTriggers,
  PageProps,
  PieChartProps,
  SelectProps,
  SidebarProps,
  StoriesProps,
  DynamicFormProps,
  TableProps,
  TextProps,
  TextareaProps,
  TitleProps,
  WordCloudProps,
};
