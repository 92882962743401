import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { CircularProgressParts, CircularProgressProps } from './index';

export const protocolFactory = new ProtocolFactory<CircularProgressProps, CircularProgressParts>({
  name: 'CircularProgress',
  editorProps: [
    {
      type: EPropType.Int,
      name: 'value',
      label: 'Value',
      required: true,
      description: 'The value of the progress',
      defaultValue: 0,
    },
  ],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
  themeProps: ['size', 'colorScheme'],
  themeConfig: {
    allowMultipart: true,
    allowColorScheme: true,
    parts: ['track', 'filledTrack', 'label'],
    sizes: ['sm', 'md', 'lg'],
    defaultProps: ['size', 'colorScheme'],
    partsConfig: {
      track: {
        variantProps: ['background'],
      },
      filledTrack: {
        variantProps: ['background'],
      },
      label: {
        variantProps: ['fontSize', 'fontWeight', 'color'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
