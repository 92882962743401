import React, { FC, useState } from 'react';

import {
  Flex as ChakraFlex,
  InputRightElement as ChakraInputRightElement,
  Modal as ChakraModal,
  ModalBody as ChakraModalBody,
  ModalCloseButton as ChakraModalCloseButton,
  ModalContent as ChakraModalContent,
  ModalFooter as ChakraModalFooter,
  ModalHeader as ChakraModalHeader,
  ModalOverlay as ChakraModalOverlay,
  Tooltip as ChakraTooltip,
  useDisclosure,
} from '@chakra-ui/react';
import dynamic from 'next/dynamic';

import { InputBase } from '@types';

import { Button, Heading, Icon, Input, Text } from '@application/components/ds';

const ReactJson = dynamic(import('react-json-view'), {
  ssr: false,
});

type InputJsonProps = InputBase;

export const InputJson: FC<InputJsonProps> = ({
  label,
  value,
  description,
  placeholder,
  defaultValue,
  disabled,
  onChange,
  ...props
}) => {
  const modalDisclosure = useDisclosure();

  const [sourceState, setSourceState] = useState(value || defaultValue);

  const handleModalClose = () => {
    setSourceState(value || defaultValue);
    modalDisclosure.onClose();
  };

  const handleModalConfirm = () => {
    onChange(sourceState);
    modalDisclosure.onClose();
  };

  return (
    <>
      <ChakraFlex width="100%" gridGap="sm" flexDirection="column" {...props}>
        {label && (
          <ChakraFlex gridGap="sm" alignItems="center">
            <Text color="ui.700" variant="body-2">
              {label}
            </Text>
            {description && (
              <ChakraTooltip label={description} borderRadius="xs" placement="top" hasArrow={true}>
                <Icon size="sm" color="ui.700" name="info-outline" />
              </ChakraTooltip>
            )}
          </ChakraFlex>
        )}
        <Input
          width="100%"
          value={JSON.stringify(value || defaultValue)}
          placeholder={placeholder}
          isDisabled={true}
        >
          {!disabled && (
            <ChakraInputRightElement
              color="ui.500"
              cursor="pointer"
              border="sm"
              background="white"
              borderColor="ui.200"
              borderRightRadius="xs"
              transitionDuration="0.3s"
              children={<Icon name="edit" />}
              _hover={{
                color: 'primary.500',
              }}
              onClick={() => modalDisclosure.onOpen()}
            />
          )}
        </Input>
      </ChakraFlex>
      <ChakraModal
        size="3xl"
        isCentered={true}
        scrollBehavior="inside"
        isOpen={modalDisclosure.isOpen}
        onClose={() => handleModalClose()}
      >
        <ChakraModalOverlay />
        <ChakraModalContent borderRadius="sm" overflow="auto">
          <ChakraModalHeader>
            <ChakraFlex flexDirection="column">
              <Heading color="ui.900">Editor</Heading>
              <Text color="ui.500" variant="body-2">
                Use the editor bellow to create a static data for the field.
              </Text>
            </ChakraFlex>
          </ChakraModalHeader>
          <ChakraModalCloseButton borderRadius="sm" top="12px" />
          <ChakraModalBody overflow="auto">
            <ChakraFlex
              padding="lg"
              border="sm"
              borderStyle="dotted"
              borderColor="ui.300"
              borderRadius="xs"
              overflow="auto"
            >
              <ReactJson
                src={sourceState}
                name={false}
                shouldCollapse={false}
                displayDataTypes={false}
                displayObjectSize={false}
                onAdd={({ updated_src }) => setSourceState(updated_src)}
                onEdit={({ updated_src }) => setSourceState(updated_src)}
                onDelete={({ updated_src }) => setSourceState(updated_src)}
              />
            </ChakraFlex>
          </ChakraModalBody>
          <ChakraModalFooter gridGap="sm">
            <Button variant="secondary" onClick={() => handleModalClose()}>
              Cancel
            </Button>
            <Button onClick={() => handleModalConfirm()}>Confirm</Button>
          </ChakraModalFooter>
        </ChakraModalContent>
      </ChakraModal>
    </>
  );
};
