import React from 'react';

import { theme } from 'theme/theme';
import { ThemeProvider } from 'components/ds';

import { Toast, ToastStatus } from './Toast';

import {
  ToastOptions as ChakraToastOptions,
  createStandaloneToast as createChakraStandaloneToast,
} from '@chakra-ui/react';

export interface UseToastOptions {
  title?: string;
  description?: string;
  status?: ToastStatus;
  position?: ChakraToastOptions['position'];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useToast = () => {
  const toast = ({ title, description, status, position = 'bottom-right' }: UseToastOptions) =>
    createChakraStandaloneToast({
      defaultOptions: {
        position,
        render: ({ onClose }) => (
          <ThemeProvider theme={theme}>
            <Toast title={title} description={description} status={status} onClose={onClose} />
          </ThemeProvider>
        ),
      },
    })({});

  return toast;
};
