import { ThemeMultipartComponent } from '@types';

export type DynamicFormSizes = 'sm' | 'md' | 'lg';
export type DynamicFormVariants = 'default' | 'alternative';
export type DynamicFormParts =
  | 'dynamic-form-container'
  | 'dynamic-form-header-container'
  | 'dynamic-form-header-title'
  | 'dynamic-form-header-description'
  | 'dynamic-form-children-tabs'
  | 'dynamic-form-children-tab-panel'
  | 'dynamic-form-children-tab-panel-record-card'
  | 'dynamic-form-children-tab-panel-action'
  | 'dynamic-form-button-container';

type DynamicFormThemeComponent = ThemeMultipartComponent<
  DynamicFormSizes,
  DynamicFormVariants,
  DynamicFormParts
>;

export const PTDynamicForm: DynamicFormThemeComponent = {
  parts: [
    'dynamic-form-container',
    'dynamic-form-header-container',
    'dynamic-form-header-title',
    'dynamic-form-header-description',
    'dynamic-form-children-tabs',
    'dynamic-form-children-tab-panel',
    'dynamic-form-children-tab-panel-record-card',
    'dynamic-form-children-tab-panel-action',
    'dynamic-form-button-container',
  ],

  variants: {
    default: {
      'dynamic-form-container': {
        paddingX: 'pt-md',
        paddingY: 'pt-md',
        border: 'pt-sm',
        borderColor: 'pt-ui.200',
        borderRadius: 'pt-sm',
        background: 'pt-white',
        boxShadow: 'pt-large',
        flexDirection: 'column',
      },
      'dynamic-form-header-container': {
        gridGap: 'pt-xs',
        marginBottom: 'pt-md',
        flexDirection: 'column',
      },
      'dynamic-form-header-title': {
        fontSize: 'pt-xl',
        fontWeight: 'pt-bold',
        fontFamily: 'pt-body',
        color: 'pt-ui.900',
      },
      'dynamic-form-header-description': {
        fontSize: 'pt-md',
        fontWeight: 'pt-regular',
        fontFamily: 'pt-body',
        color: 'pt-ui.500',
      },
      'dynamic-form-children-tabs': {
        flex: '1',
        color: 'pt-ui.500',
        paddingY: 'pt-md',
        paddingX: 'pt-md',
        fontSize: 'pt-sm',
        fontFamily: 'pt-body',
        borderColor: 'pt-transparent',
        borderTopRadius: 'pt-sm',
        transitionDuration: '0.2s',
        _hover: {
          background: 'pt-ui.50',
        },
        _selected: {
          background: 'pt-ui.100',
          borderColor: 'pt-ui.200',
          color: 'pt-ui.700',
        },
      },
      'dynamic-form-children-tab-panel': {
        gridGap: 'pt-md',
        padding: 'pt-md',
        display: 'flex',
        background: 'pt-ui.100',
        flexDirection: 'column',
        borderBottomRadius: 'pt-sm',
      },
      'dynamic-form-children-tab-panel-record-card': {
        border: 'pt-sm',
        padding: 'pt-md',
        gridGap: 'pt-md',
        background: 'pt-white',
        borderColor: 'pt-ui.200',
        borderRadius: 'pt-sm',
        boxShadow: 'pt-large',
      },
      'dynamic-form-children-tab-panel-action': {
        width: '100%',
        padding: 'pt-md',
        color: 'pt-ui.500',
        cursor: 'pointer',
        border: 'pt-sm',
        fontSize: 'pt-xs',
        fontFamily: 'pt-body',
        textAlign: 'center',
        borderColor: 'pt-ui.300',
        borderRadius: 'pt-sm',
        borderStyle: 'dashed',
        background: 'pt-ui.200',
        alignItems: 'center',
        justifyContent: 'center',
        transitionDuration: '0.2s',
        _hover: {
          color: 'pt-ui.600',
        },
      },
      'dynamic-form-button-container': {
        justifyContent: 'flex-end',
      },
    },
    alternative: {
      'dynamic-form-container': {
        paddingY: 'pt-none',
        paddingX: 'pt-sm',
        border: 'pt-none',
        borderColor: 'pt-transparent',
        borderRadius: 'pt-none',
        background: 'pt-transparent',
        boxShadow: 'pt-none',
        flexDirection: 'column',
      },
      'dynamic-form-header-container': {
        gridGap: 'pt-xs',
        marginBottom: 'pt-md',
        flexDirection: 'column',
      },
      'dynamic-form-header-title': {
        fontSize: 'pt-xl',
        fontWeight: 'pt-bold',
        fontFamily: 'pt-body',
        color: 'pt-ui.900',
      },
      'dynamic-form-header-description': {
        fontSize: 'pt-md',
        fontWeight: 'pt-regular',
        fontFamily: 'pt-body',
        color: 'pt-ui.500',
      },
      'dynamic-form-children-tabs': {
        flex: '1',
        color: 'pt-ui.500',
        paddingY: 'pt-md',
        paddingX: 'pt-md',
        fontSize: 'pt-sm',
        fontFamily: 'pt-body',
        borderColor: 'pt-transparent',
        borderTopRadius: 'pt-sm',
        transitionDuration: '0.2s',
        _hover: {
          background: 'pt-ui.50',
        },
        _selected: {
          background: 'pt-ui.100',
          borderColor: 'pt-ui.200',
          color: 'pt-ui.700',
        },
      },
      'dynamic-form-children-tab-panel': {
        gridGap: 'pt-md',
        padding: 'pt-md',
        display: 'flex',
        background: 'pt-ui.100',
        flexDirection: 'column',
        borderBottomRadius: 'pt-sm',
      },
      'dynamic-form-children-tab-panel-record-card': {
        border: 'pt-sm',
        padding: 'pt-md',
        gridGap: 'pt-md',
        background: 'pt-white',
        borderColor: 'pt-ui.200',
        borderRadius: 'pt-sm',
        boxShadow: 'pt-large',
      },
      'dynamic-form-children-tab-panel-action': {
        width: '100%',
        padding: 'pt-md',
        color: 'pt-ui.500',
        cursor: 'pointer',
        border: 'pt-sm',
        fontSize: 'pt-xs',
        fontFamily: 'pt-body',
        textAlign: 'center',
        borderColor: 'pt-ui.300',
        borderRadius: 'pt-sm',
        borderStyle: 'dashed',
        background: 'pt-ui.200',
        alignItems: 'center',
        justifyContent: 'center',
        transitionDuration: '0.2s',
        _hover: {
          color: 'pt-ui.600',
        },
      },
      'dynamic-form-button-container': {
        justifyContent: 'flex-end',
      },
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: null,
  },
};
