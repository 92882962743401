import React, { FC } from 'react';

import { Radio as ChakraRadio } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { RadioProps } from './index';

export const component: FC<ComponentReceivedProps<RadioProps, any>> = ({ props = defaultProps, style }) => {
  const { size, label } = props;

  return (
    <ChakraRadio style={style} size={size}>
      {label}
    </ChakraRadio>
  );
};
