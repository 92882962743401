import { ThemeMultipartComponent } from '@types';

export type SidebarSizes = 'sm' | 'md' | 'lg';
export type SidebarVariants = 'default';
export type SidebarParts =
  | 'sidebar-container'
  | 'sidebar-item'
  | 'sidebar-profile-container'
  | 'sidebar-profile-name'
  | 'sidebar-profile-email'
  | 'sidebar-button';

export const PTSidebar: ThemeMultipartComponent<SidebarSizes, SidebarVariants, SidebarParts> = {
  parts: [
    'sidebar-container',
    'sidebar-item',
    'sidebar-profile-container',
    'sidebar-profile-name',
    'sidebar-profile-email',
    'sidebar-button',
  ],

  variants: {
    default: {
      'sidebar-container': {
        height: '100%',
        background: 'pt-white',
        borderRight: 'pt-sm',
        flexDirection: 'column',
        borderRightColor: 'pt-ui.200',
      },
      'sidebar-item': {
        width: '100%',
        color: 'pt-primary.400',
        cursor: 'pointer',
        gridGap: 'pt-md',
        paddingX: 'pt-lg',
        paddingY: 'pt-md',
        borderLeft: 'pt-md',
        borderLeftColor: 'pt-transparent',
        transitionDuration: '0.3s',
        alignItems: 'center',
        fontFamily: 'pt-body',
        _hover: {
          borderLeftColor: 'pt-transparent',
          background: 'pt-primary.50',
          color: 'pt-primary.500',
        },
        _selected: {
          borderLeftColor: 'pt-primary.500',
          color: 'pt-primary.500',
        },
      },
      'sidebar-profile-container': {
        paddingX: 'pt-lg',
        paddingY: 'pt-md',
        borderTop: 'pt-sm',
        borderTopColor: 'pt-ui.200',
        background: 'pt-transparent',
      },
      'sidebar-profile-name': {
        color: 'pt-ui.700',
        fontSize: 'pt-sm',
      },
      'sidebar-profile-email': {
        color: 'pt-ui.500',
        fontSize: 'pt-sm',
      },
      'sidebar-button': {
        top: '50%',
        right: '-20px',
        border: 'pt-sm',
        color: 'pt-ui.700',
        cursor: 'pointer',
        padding: 'pt-sm',
        position: 'absolute',
        transform: 'translateY(-50%)',
        background: 'pt-white',
        borderColor: 'pt-ui.200',
        borderRadius: 'pt-lg',
      },
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: null,
  },
};
