import { createContext } from 'react';
import { IdToken, User } from '@auth0/auth0-spa-js';
import { UseMutationResult, UseQueryResult } from 'react-query';

export interface AuthContextState {
  user: User;

  isAuthenticated: boolean;
  isLoading: boolean;

  getIdTokenClaims?: UseMutationResult<IdToken | undefined>;
  handleRedirectCallback?: UseMutationResult<void>;
  loginWithRedirect?: UseMutationResult<void>;
  logout: UseMutationResult<void>;
  refetchUser?: UseQueryResult;
}

export const AuthContext = createContext<AuthContextState>({
  user: null,
  isLoading: false,
  isAuthenticated: false,
  refetchUser: null,
  handleRedirectCallback: null,
  getIdTokenClaims: null,
  logout: null,
});
AuthContext.displayName = 'AuthContext';

export const AuthProvider = AuthContext.Provider;
