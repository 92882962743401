import { ComponentBlock } from '@types';

import { types } from '@application/core/types';

import { component } from './component';
import { PieChartProps } from '.';

export const block: ComponentBlock<'pieChart', PieChartProps, unknown> = {
  id: 'pieChart',
  render: component,
  props: {
    chartCategory: {
      type: types.string,
    },
    chartCategoryLabel: {
      type: types.string,
    },
    chartValue: {
      type: types.string,
    },
    chartValueLabel: {
      type: types.string,
    },
    chartData: {
      type: types.list,
    },
  },
};
