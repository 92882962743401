import { ComponentReceivedProps, DataComponentField, InputFields } from './Component';
import { FC } from 'react';

import { Operation as JsonLogicalEngineOperation } from 'json-logical-engine/dist/types';

export interface Block<K extends ComponentBlock<I, any, any> = any, I = any> {
  // The component identificator
  component: OptionBlocks;

  // This runs during the first render of the component, and provides a context for the mounted component data
  // that can be used for other operations, renders, events...
  condition?: {
    // The expression itself
    operation: JsonLogicalEngineOperation;

    // If the result is true the component will display
    useResultForRender?: boolean;
  };

  dynamicPropValues?: Record<string, BlockDynamicPropertyBinding>;
  triggers?: Record<string, ComponentBlockTriggerConfig>;

  staticPropValues?: any;

  // The mapping of available events of this block(its here so we can chhange in runtimme)
  events?: Record<string, any>;
}

// This keys are used on dynamicData contextAPI and should be not
type SpecialPropKeys = 'dynamicData' | 'addDynamicDataProperty' | 'deleteDynamicDataProperty';

export interface ComponentBlock<BlockId, ComponentPropsInterface, ComponentTriggerInterface> {
  id: BlockId & OptionBlocks;

  render: FC<ComponentReceivedProps<ComponentPropsInterface, ComponentTriggerInterface>>;
  condition?: {};

  props?: Omit<ComponentProps<ComponentPropsInterface>, SpecialPropKeys>;

  triggers?: ComponentTriggers<ComponentTriggerInterface>;
  events?: ComponentEvents<ComponentTriggerInterface>;
}

export type ComponentProps<ComponentPropsInterface> = Record<
  keyof ComponentPropsInterface & string,
  ComponentBlockPropsConfig
>;
export type ComponentBlockPropsConfig = {
  type: PropType;
  required?: boolean;
  disableEdition?: boolean;
};

export type ComponentTriggers<ComponentTriggerInterface> = Record<
  keyof ComponentTriggerInterface & string,
  ComponentBlockTriggerConfig
>;
export type ComponentBlockTriggerConfig = {
  name: string;
  description: string;
  operation?: JsonLogicalEngineOperation;
};

export type ComponentEvents<ComponentEventInterface> = Record<
  keyof ComponentEventInterface & string,
  ComponentBlockEventConfig
>;
export type ComponentBlockEventConfig = {
  name: string;
  description?: string;
  inputArguments: InputFields[];
  fields: DataComponentField[];
};

export interface BlockDynamicPropertyBinding {
  // Used as staticProps(this value will be provided for the handler)
  value: any;

  // Used to categorize early if we should use the value or the dynamicConfig
  dynamic: boolean;
  dynamicConfig?: {
    id: string;
    path: string;
  };

  // Used to process the prop itSelf
  handler?: JsonLogicalEngineOperation;
}

export interface BlockParams<K extends ComponentBlock<I, any, any>, I = any> {
  component: OptionBlocks;
}

export interface BlockDefs<K extends ComponentBlock<any, any, any>> {
  staticPropValues?: Block<K>['staticPropValues'];
  dynamicPropValues?: Block<K>['dynamicPropValues'];

  condition?: Block<K>['condition'];

  triggers?: Block<K>['triggers'];
  events?: Block<K>['events'];
}

export type PropType =
  | 'Any'
  | 'ID'
  | 'Number'
  | 'String'
  | 'Textarea'
  | 'Richtext'
  | 'Boolean'
  | 'List'
  | 'Enum'
  | 'Object'
  | 'Email'
  | 'Phone'
  | 'Date'
  | 'Image';

export enum EPropType {
  Any = 'Any',
  Id = 'ID',
  Int = 'Number',
  Float = 'Number',
  String = 'String',
  Boolean = 'Boolean',
  Enum = 'Enum',
  Object = 'Object',
  List = 'List',
  Image = 'Image',
  Textarea = 'Textarea',
  Richtext = 'Richtext',
  Date = 'Date',
}

export interface InputArg {
  // The hardcodedValue
  value?: any;
  // A jsonLogic expression that could be used to handle the argument
  handler?: any;
  // The path of this argument value(ex: "filter.id.eq")
  path: string;

  // If dynamic is toggled the argument will look for a dynamic property and will not use "value" attribute
  dynamic: boolean;
  dynamicConfig?: {
    // Id of the dynamicProperty
    id: string;
    // Path inside of it
    path: string;
  };
}

// These ones are handled by the system and should not be added by the user
export type SystemBlocks = 'page' | 'layoutRouterView';

export type OptionBlocks =
  | 'accordion'
  | 'aspectRatio'
  | 'badge'
  | 'barChart'
  | 'breadcrumb'
  | 'baseInput'
  | 'button'
  | 'board'
  | 'card'
  | 'calendar'
  | 'checkbox'
  | 'circularProgress'
  | 'container'
  | 'dataContainer'
  | 'dateInput'
  | 'developmentEnvironment'
  | 'divider'
  | 'dynamicForm'
  | 'grid'
  | 'gridItem'
  | 'header'
  | 'list'
  | 'lineChart'
  | 'icon'
  | 'input'
  | 'image'
  | 'imageGallery'
  | 'imageUpload'
  | 'map'
  | 'menu'
  | 'modal'
  | 'numberInput'
  | 'mutationContainer'
  | 'progress'
  | 'pieChart'
  | 'radio'
  | 'radioAlternative'
  | 'radioAlternativeButton'
  | 'richTextEditor'
  | 'subForm'
  | 'sidebar'
  | 'select'
  | 'stories'
  | 'table'
  | 'text'
  | 'textarea'
  | 'title'
  | 'wordCloud'
  | SystemBlocks;
