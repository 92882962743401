import React, { FC } from 'react';

import { Badge as ChakraBadge, useStyleConfig } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { Icon } from '@application/components/ds';
import { showDatasetFormatted } from '@application/utils/stringManipulation';

import { BadgeProps } from './index';
import { defaultProps } from './protocol';

export const component: FC<ComponentReceivedProps<BadgeProps, unknown>> = ({ props = defaultProps }) => {
  const { label, colorScheme, variant, size, leftIcon, rightIcon, ...rest } = props;

  const themeStyle = useStyleConfig('PTBadge', { colorScheme, variant, size });

  return (
    <ChakraBadge width="fit-content" sx={themeStyle} {...rest}>
      {leftIcon && <Icon marginRight="4px" name={leftIcon} />}
      {showDatasetFormatted(label)}
      {rightIcon && <Icon marginLeft="4px" name={rightIcon} />}
    </ChakraBadge>
  );
};
