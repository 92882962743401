import { DynamicFormParts, DynamicFormVariants } from '@application/lib/defaultTheme';

import { CompilerComponent, ControlType, FieldType } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface DynamicFormProps {
  dataset: string;
  datasetsFieldsReference: Record<string, Record<string, DynamicFormDatasetField>>;

  fieldsCustomization: DynamicFormFieldCustomization[];

  updateId?: string;
  operation: 'create' | 'update';

  attributeToCurrentUser?: boolean;
  variant: DynamicFormVariants;

  headerTitle: string;
  headerDescription: string;

  customButtonLabel: string;

  isFormButtonFullWidth: boolean;
}

export interface DynamicFormTriggers {
  onSuccess: () => void;
  onFailure: () => void;
}

export interface DynamicFormDatasetField {
  type: FieldType;
  helpText?: string;
  controlType?: ControlType;
  indexFieldName?: string;
}

export interface DynamicFormFieldCustomization {
  id?: string;
  name: string;
  defaultValue?: any;
  disabled?: boolean;
  visible?: boolean;
  dynamic?: boolean;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<DynamicFormProps, DynamicFormParts>;
