import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { ComposerState } from '@types';

import composerReducer from './composer';
import dataReducer, { DataState } from './data';

export interface Store {
  composer: ComposerState;
  data: DataState;
}

export const createStore = () =>
  configureStore({
    reducer: combineReducers({
      composer: composerReducer,
      data: dataReducer,
    }),
    middleware: getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  });

export const store = createStore();
