import { v4 as uuidV4 } from 'uuid';

import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { ImageUploadParts, ImageUploadProps } from './index';

export const protocolFactory = new ProtocolFactory<ImageUploadProps, ImageUploadParts>({
  name: 'ImageUpload',
  editorProps: [
    {
      type: EPropType.String,
      name: 'name',
      required: false,
      label: 'Name',
      description: 'Name of the image upload',
      defaultValue: uuidV4(),
    },
    {
      type: EPropType.String,
      name: 'label',
      required: false,
      label: 'Label',
      description: 'The label of the input inside of the form control',
      defaultValue: 'Example',
    },
  ],
  themeProps: ['size'],
  themeConfig: {
    allowMultipart: true,
    defaultProps: ['size', 'variant'],
    sizes: ['sm', 'md', 'lg'],
    parts: ['image-upload-label', 'image-upload-control'],
    partsConfig: {
      'image-upload-label': {
        variantProps: ['color', 'fontWeight'],
        baseStyleProps: ['fontSize', 'marginBottom'],
      },
      'image-upload-control': {
        pseudos: ['_hover', '_invalid', '_disabled'],
        pseudoProps: ['color', 'background', 'border', 'borderColor', 'borderRadius'],
        variantProps: ['color', 'background', 'border', 'borderColor', 'borderRadius'],
        sizeProps: ['fontSize', 'paddingHorizontal', 'paddingVertical'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
