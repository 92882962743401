import { ComponentBlock } from '@types';

import { types } from 'core/types';

import { component } from './component';
import { NumberInputProps } from './index';

export const block: ComponentBlock<'numberInput', NumberInputProps, unknown> = {
  id: 'numberInput',
  render: component,
  props: {
    name: {
      type: types.string,
    },
    value: {
      type: types.number,
    },
    label: {
      type: types.string,
    },
    placeholder: {
      type: types.string,
    },
    size: {
      type: types.string,
    },
  },
  triggers: {
    onBlur: {
      name: 'On blur',
      description: 'When the control is unfocused',
    },
  },
};
