import React, { FC, useState } from 'react';

import { chakra, Flex as ChakraFlex, useMultiStyleConfig } from '@chakra-ui/react';
import { EventProps } from 'react-big-calendar';

import { CalendarPopover } from './CalendarPopover';

import { Event } from '../component';

export interface CalendarMonthEventProps extends EventProps<Event> {
  canEdit: boolean;
  onEdit: (event: Event) => void;
  onDelete: (event: Event) => void;
}

export const CalendarMonthEvent: FC<CalendarMonthEventProps> = ({ event, canEdit, onEdit, onDelete }) => {
  const { 'calendar-month-event': calendarMonthEventStyles } = useMultiStyleConfig('PTCalendar', {});

  const [isOpen, setIsOpen] = useState(false);

  return (
    event && (
      <CalendarPopover
        event={event}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onEdit={(event) => onEdit(event)}
        onDelete={(event) => onDelete(event)}
      >
        <ChakraFlex sx={calendarMonthEventStyles} onClick={() => canEdit && setIsOpen(true)}>
          <chakra.p isTruncated={true} fontWeight="bold" fontSize="xs" fontFamily="body">
            {event.title}
          </chakra.p>
        </ChakraFlex>
      </CalendarPopover>
    )
  );
};
