import { ComponentBlock } from '@types';

import { types } from '@application/core/types';

import { component } from './component';
import { ImageProps } from './index';

export const block: ComponentBlock<'image', ImageProps, any> = {
  id: 'image',
  render: component,
  props: {
    width: {
      type: types.string,
    },
    height: {
      type: types.string,
    },
    name: {
      type: types.string,
    },
    url: {
      type: types.string,
    },
  },
};
