import React, { FC } from 'react';

import {
  Input as ChakraInput,
  InputGroup as ChakraInputGroup,
  InputGroupProps as ChakraInputGroupProps,
  InputProps as ChakraInputProps,
  forwardRef,
  useStyleConfig,
} from '@chakra-ui/react';

import { InputSizes, InputVariants } from '@application/theme';

export interface InputProps extends ChakraInputProps {
  size?: InputSizes;
  variant?: InputVariants | ChakraInputProps['variant'];
  inputGroupProps?: ChakraInputGroupProps;
}

export const Input: FC<InputProps> = forwardRef(
  ({ size, variant, colorScheme, children, inputGroupProps, ...props }, ref) => {
    const styles = useStyleConfig('Input', { size, variant, colorScheme });

    return (
      <ChakraInputGroup {...inputGroupProps}>
        <ChakraInput width="fit-content" data-testid="input" size={size} ref={ref} sx={styles} {...props} />
        {children}
      </ChakraInputGroup>
    );
  },
);

Input.displayName = 'Input';
