import React, { FC, useMemo, useState } from 'react';

import { ASTNode } from 'graphql';
import {
  Flex as ChakraFlex,
  FormControl as ChakraFormControl,
  Radio as ChakraRadio,
  RadioGroup as ChakraRadioGroup,
  Text as ChakraText,
  useDisclosure,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { get } from 'lodash';
import { useQuery } from 'react-query';

import { Button, Loading } from '@application/components/ds';
import { Icon, Modal } from '@application/components/common';
import { showDatasetFormatted, toDatasetFormat } from '@application/utils/stringManipulation';

import { FactoryDataset } from '@introspection/factories/DatasetsOperationsFactory';

interface Props {
  datasetName: string;
  datasets: Record<string, FactoryDataset>;
  indexFieldName: string;

  value: string;

  onChange: (newId: string) => void;
  executeApiOperation: (
    operation: ASTNode,
    operationName: string,
    variables: Record<string, any>,
  ) => Promise<any>;
}

export const DatasetRelationInput: FC<Props> = ({
  datasetName,
  datasets,
  value,
  indexFieldName,
  onChange,
  executeApiOperation,
}) => {
  const [chosenValue, setChosenValue] = useState(value);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const onConfirm = () => {
    onChange(chosenValue);
    onClose();
  };

  const dataset = datasets[datasetName];

  // TODO: Define how to get it;
  const datasetLabelField = useMemo(
    () => indexFieldName ?? `${toDatasetFormat(datasetName)}Name`,
    [indexFieldName],
  );

  const baseInputStyles = useMultiStyleConfig('PTBaseInput', {});

  const { data: relationValue, isLoading: isLoadingRelation } = useQuery(
    ['datasets', datasetName, 'get', value],
    () =>
      executeApiOperation(dataset.operations.get.operation, dataset.operations.get.operationName, {
        id: value,
      }),
    {
      refetchOnMount: false,
      enabled: !!value && !!dataset,
    },
  );

  const { data: optionsList, isLoading: isLoadingList } = useQuery<Record<string, any>[]>(
    ['datasets', datasetName, 'list'],
    async () => {
      const resp = await executeApiOperation(
        dataset.operations.list.operation,
        dataset.operations.list.operationName,
        {},
      );

      return get(resp, 'items', []);
    },
    {
      enabled: isOpen,
    },
  );

  return (
    <ChakraFormControl>
      <ChakraText sx={baseInputStyles['base-input-label'] || {}}>
        {showDatasetFormatted(datasetName)}
      </ChakraText>

      <ChakraFlex
        border="pt-sm"
        padding="pt-sm"
        paddingLeft="pt-md"
        background="pt-ui.50"
        alignItems="center"
        borderColor="pt-ui.200"
        borderRadius="pt-sm"
        justifyContent="space-between"
      >
        <Loading boxSize="20px" minHeight="30px" isLoading={isLoadingRelation}>
          {value ? (
            <ChakraFlex
              color="pt-white"
              display="flex"
              fontSize="pt-sm"
              paddingX="pt-md"
              paddingY="pt-xs"
              alignItems="center"
              background="pt-primary.500"
              borderRadius="pt-full"
            >
              {get(relationValue, datasetLabelField, 'Unnamed')}
              <Icon size="xs" name="close" cursor="pointer" marginLeft="pt-sm" onClick={() => onChange('')} />
            </ChakraFlex>
          ) : (
            <ChakraText fontSize="pt-sm" fontFamily="pt-body" color="pt-ui.500">
              No relation
            </ChakraText>
          )}
        </Loading>
        <Button variant="secondary" size="sm" onClick={onOpen}>
          Configure Relation
        </Button>
      </ChakraFlex>

      <Modal
        subTitle="Chose your relation"
        title={`${showDatasetFormatted(datasetName)} Relation`}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onConfirm}
      >
        <Loading width="100%" height="100%" isLoading={isLoadingList}>
          <ChakraFlex gridGap="md" flexDirection="column">
            <ChakraText fontFamily="pt-body" fontWeight="pt-bold" color="pt-ui.700">
              Items List
            </ChakraText>

            <ChakraRadioGroup
              display="flex"
              flexDirection="column"
              value={chosenValue}
              onChange={(val: string) => setChosenValue(val)}
            >
              {optionsList?.map((option) => (
                <>
                  <ChakraRadio marginBottom="pt-sm" key={option.id} value={option.id}>
                    {get(option, datasetLabelField, 'Unnamed')}
                  </ChakraRadio>
                </>
              ))}
            </ChakraRadioGroup>
          </ChakraFlex>
        </Loading>
      </Modal>
    </ChakraFormControl>
  );
};
