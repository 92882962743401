import gql from 'graphql-tag';

import { capitalize } from '@application/utils/stringManipulation';

import { Introspection } from '@types';

import { BaseIntrospectionFactory } from './BaseIntrospectionFactory';

/**
 * It's used to create the gql operation
 * IT WAS NOT REFACTORED YET!
 * @param operation {IntrospectionType} - The query/mutation/subscription operation that will be created
 * @param operationType {string} - Type of the operation
 */

export class OperationFactory extends BaseIntrospectionFactory {
  getInputFieldsType(operation: Introspection.IntrospectionType): string {
    const { args } = operation;

    if (!args) return;

    return args.reduce((prev: string, curr, index) => {
      const inputFieldTypeName = curr.type.name ? curr.type.name : curr.type.ofType.name;

      const newInputField = `${curr.name}: ${inputFieldTypeName}`;

      const requiredSymbol = curr.type.kind === 'NON_NULL' ? '!' : '';
      const inputComma = index > 0 ? ', ' : '';

      return `${prev}${inputComma}$${newInputField}${requiredSymbol}`;
    }, '');
  }

  getOperationVariables(operation: Introspection.IntrospectionType): string {
    const { args } = operation;

    if (!args) return;

    return args.reduce((prev: string, curr, index) => {
      const comma = index > 0 ? ', ' : '';

      return `${prev}${comma}${curr.name}: $${curr.name}`;
    }, '');
  }

  getFields(field: Introspection.IntrospectionType, mappedDatasets: string[]): string {
    const fields = field.type?.fields || field.fields;

    const newMappedDatasets: string[] = [...mappedDatasets];

    if (!fields) return;

    return fields.reduce((prev: string, curr) => {
      const isNestedDataset = curr.name.startsWith('_') || curr.name.endsWith('Relation');

      if (isNestedDataset) {
        // This is checking if it's a level nested field
        if (mappedDatasets.length) return prev;
        newMappedDatasets.push(curr.name);
      }

      // Which means that is a nested dataset of a nested dataset
      if (field.name.startsWith('_')) return prev;

      if (curr?.type?.kind === 'LIST') {
        const nestedType = this.findTypeByName(curr.type.ofType.name);

        return `${prev} ${curr.name} {
            ${this.getFields(nestedType, newMappedDatasets)}
          }
        `;
      }

      let fieldType = curr;
      if (isNestedDataset && curr.type.name) {
        if (curr.type?.kind !== 'SCALAR') {
          fieldType = this.findTypeByName(curr.type.name);
        }
      }

      let deepFields: string = fieldType.name;
      const hasDeepFields = fieldType?.fields;

      if (hasDeepFields) {
        deepFields = `${curr.name} {
          ${this.getFields(fieldType, newMappedDatasets)}
        }`;
      }

      return `${prev} ${deepFields}`;
    }, '');
  }

  mountOperation(
    operation: Introspection.IntrospectionType,
    operationType: Introspection.OperationType,
  ): any {
    const operationName = operation.name;

    const inputFieldsType = this.getInputFieldsType(operation);
    const mountedInputFields = inputFieldsType ? `(${inputFieldsType})` : '';

    const operationVariables = this.getOperationVariables(operation);
    const mountedOperationVariables = operationVariables ? `(${operationVariables})` : '';

    const fields = this.getFields(operation, []);

    return gql`
    ${operationType} ${capitalize(operationName)}${mountedInputFields} {
      ${operationName}${mountedOperationVariables} {
          ${fields}
      }
    }
  `;
  }
}
