import { ComponentBlock } from '@types';

import { types } from '@application/core/types';

import { component } from './component';
import { DynamicFormProps, DynamicFormTriggers } from './index';

export const block: ComponentBlock<'dynamicForm', DynamicFormProps, DynamicFormTriggers> = {
  id: 'dynamicForm',
  render: component,
  props: {
    dataset: {
      type: types.string,
    },
    operation: {
      type: types.string,
    },
    updateId: {
      type: types.id,
      required: false,
    },
    attributeToCurrentUser: {
      type: types.boolean,
      required: false,
    },
    datasetsFieldsReference: {
      type: types.object,
      required: false,
    },
    customButtonLabel: {
      type: types.string,
    },
    fieldsCustomization: {
      type: types.list,
      required: false,
    },
    headerTitle: {
      type: types.string,
    },
    headerDescription: {
      type: types.string,
    },
    isFormButtonFullWidth: {
      type: types.boolean,
    },
    variant: {
      type: types.string,
    },
  },
  events: {
    // @ts-ignore
    mutation: {
      name: 'Mutation',
      inputArguments: [],
      fields: [],
    },
  },
  triggers: {
    onFailure: {
      name: 'On failure',
      description: 'After the action results in a error',
    },
    onSuccess: {
      name: 'On success',
      description: "After the action successfully it's finished",
    },
  },
};
