import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface TitleProps {
  content: string;
  htmlTag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<TitleProps>;
