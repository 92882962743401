import { CalendarParts } from '@application/lib/defaultTheme';

import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface CalendarProps {
  width: string;
  height: string;
  calendarData: Record<string, unknown>[];
  calendarDataCollection: string;
  calendarEventTitleProperty: string;
  calendarEventStartProperty: string;
  calendarEventEndProperty: string;
  calendarEventAllDayProperty: string;
  fieldsRelations: Record<string, string>;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<CalendarProps, CalendarParts>;
