import { get, isNull, isUndefined, set } from 'lodash';

import { Block, Node } from '@types';

export const interpolateDynamicVariable = (
  str = '',
  providedDynamicData: Record<string, unknown>,
): string => {
  const matchRegex = /\$(\w+)(\.\w+)+/g;

  const foundVariables = str.match(matchRegex);

  if (!foundVariables) return str;

  for (const variable of foundVariables) {
    const dynamicPath = (variable.includes('$pageParams') ? variable : variable.replace('$', '')).split('.');
    const dynamicData = get(providedDynamicData, dynamicPath);
    const dynamicDataType = typeof dynamicData;

    if (!dynamicData) {
      str = str.replace(variable, 'NOT FOUND');

      continue;
    }

    if (['number', 'string'].includes(dynamicDataType)) {
      str = str.replace(variable, dynamicData);
    }
  }

  return str;
};

type GetComponentPropsArgs = {
  node: Node;
  unifiedDynamicData: Record<string, unknown>;
};
export function getComponentProps(args: GetComponentPropsArgs): Record<string, any> {
  const { node, unifiedDynamicData } = args;

  const { block } = node;

  const getStaticProps = (selectedBlock: Block) => {
    if (!selectedBlock?.staticPropValues) return {};

    const staticMappingsPropsList = Object.entries(selectedBlock.staticPropValues);

    return staticMappingsPropsList.reduce((prev, curr) => {
      const [parentPropKey, parentPropValue] = curr;

      if (typeof parentPropValue === 'string') {
        set(prev, parentPropKey, interpolateDynamicVariable(parentPropValue, unifiedDynamicData));
      }

      if (parentPropKey === 'fieldsCustomization' && parentPropValue) {
        const arrayPropEntries = Object.entries(parentPropValue);

        for (const [fieldKey, fieldValue] of arrayPropEntries) {
          const fieldkeyEntries = Object.entries(fieldValue);

          for (const [fieldKeyProp, fieldKeyValue] of fieldkeyEntries) {
            if (typeof fieldKeyValue === 'string') {
              set(
                prev,
                `${parentPropKey}.${fieldKey}.${fieldKeyProp}`,
                interpolateDynamicVariable(fieldKeyValue, unifiedDynamicData),
              );
            } else {
              set(prev, `${parentPropKey}.${fieldKey}.${fieldKeyProp}`, fieldKeyValue);
            }
          }
        }
      }

      return prev;
    }, {});
  };

  const getDynamicProps = (selectedBlock: Block) => {
    if (!selectedBlock.dynamicPropValues) return {};
    const dynamicMappingsPropsList = Object.entries(selectedBlock.dynamicPropValues);

    return dynamicMappingsPropsList.reduce((prev, curr) => {
      const [propKey, propValue] = curr;

      const { dynamic, dynamicConfig, value } = propValue;

      if (!dynamic || !dynamicConfig) {
        if (isUndefined(value) || isNull(value)) return prev;

        let staticValue = value;

        if (typeof staticValue === 'string') {
          staticValue = interpolateDynamicVariable(value, unifiedDynamicData);
        }

        return {
          ...prev,
          [propKey]: staticValue,
        };
      }

      const { id, path } = dynamicConfig;
      const pathArr = path?.split('.')?.filter((p: string) => p);

      const dynamicValue = get(unifiedDynamicData, [id, ...pathArr]);

      if (typeof dynamicValue === 'undefined') return prev;

      // if (handler) {
      //   dynamicValue = runLogic(handler, {
      //     ...unifiedDynamicData,
      //     $value: dynamicValue,
      //   });
      // }

      // @ts-ignore
      set(prev, propKey, dynamicValue);

      return prev;
    }, {});
  };

  // Mapping all the componentDynamicProps
  const componentDynamicProps = {
    ...getStaticProps(block),
    ...getDynamicProps(block),
  };

  return {
    ...block.staticPropValues,
    ...componentDynamicProps,
  };
}
