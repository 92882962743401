import { get } from 'lodash';

import { EditorFormField, ThemeOverride } from '@types';

import { ThemeProtocolKeys, themeProtocols } from '@application/lib/protocols/themeProtocols';

import { ComponentProtocolThemeConfig, ComponentProtocolThemePartConfig, ThemeConfigForm } from './types';

export class ThemeConfigFactory<ComponentParts> {
  private name: string;
  private themeConfig: ComponentProtocolThemeConfig<ComponentParts>;
  private theme: ThemeOverride;

  constructor(
    componentName: string,
    themeConfig: ComponentProtocolThemeConfig<ComponentParts>,
    theme: ThemeOverride,
  ) {
    const fixedChakraComponentNames = ['Accordion', 'Checkbox', 'Table', 'Progress'];

    this.name = fixedChakraComponentNames.includes(componentName) ? componentName : `PT${componentName}`;
    this.themeConfig = themeConfig;
    this.theme = theme;
  }

  get componentName(): string {
    return this.name;
  }

  get hasColorSchemeMode(): boolean {
    return this.themeConfig?.allowColorScheme;
  }

  get isMultipart(): boolean {
    return this.themeConfig?.allowMultipart;
  }

  get themeComponent(): Record<string, any> {
    return get(this.theme, ['components', this.name], {});
  }

  /** Returns the default props fields of the component, like variant or size */
  get defaultPropsFields(): EditorFormField[] {
    return this.populateThemeProtocolList(this.themeConfig.defaultProps);
  }

  /** Finds and return a list of the current theme component variants */
  get variants(): string[] {
    const chakraDefaultVariants = Object.keys(this.themeComponent.variants ?? {});
    return [...chakraDefaultVariants, ...(this.themeConfig.variants || [])];
  }

  /** Returns a list of the component sizes */
  get sizes(): string[] {
    return this.themeConfig?.sizes ?? [];
  }

  /** Returns a list of the component parts */
  get parts(): string[] {
    return this.themeConfig.parts ?? [];
  }

  /** Creates the theme config and handles the multipart */
  createThemeConfigForms(): ThemeConfigForm<ComponentParts>[] {
    if (!this.themeConfig.allowMultipart) {
      return [this.createThemeConfigForm(this.themeConfig.defaultConfig)];
    }

    return this.themeConfig.parts?.map((part) => {
      const partConfig = this.themeConfig.partsConfig[part];

      if (!partConfig) return null;

      return this.createThemeConfigForm(partConfig, part);
    });
  }

  /** Creates a single part theme config */
  private createThemeConfigForm(
    partConfig: ComponentProtocolThemePartConfig,
    partName?: ComponentParts & string,
  ): ThemeConfigForm<ComponentParts> {
    return {
      name: partName ?? 'default',
      baseStyleFields: this.createBaseStyleFields(partConfig),
      sizeStyleFields: this.createSizeFields(partConfig),
      variantFields: this.createVariantsFields(partConfig),
    };
  }

  private createBaseStyleFields(partConfig: ComponentProtocolThemePartConfig): EditorFormField[] {
    return this.populateThemeProtocolList(partConfig.baseStyleProps);
  }

  private createSizeFields(partConfig: ComponentProtocolThemePartConfig): EditorFormField[] {
    return this.populateThemeProtocolList(partConfig.sizeProps);
  }

  private createVariantsFields(partConfig: ComponentProtocolThemePartConfig): EditorFormField[] {
    const variantThemeProtocols = this.populateThemeProtocolList(partConfig.variantProps);
    const pseudoThemeProtocols = this.populateThemeProtocolList(partConfig.pseudoProps);

    const { pseudos } = partConfig;

    const variantProps: EditorFormField[] = [];

    if (variantThemeProtocols.length) {
      for (const variantProtocol of variantThemeProtocols) {
        variantProps.push(variantProtocol);
      }
    }

    if (pseudoThemeProtocols.length) {
      for (const pseudo of pseudos) {
        for (const pseudoProtocol of pseudoThemeProtocols) {
          variantProps.push({
            ...pseudoProtocol,
            groupBy: [pseudo],
            name: [pseudo, pseudoProtocol.name].join('.'),
          });
        }
      }
    }

    return variantProps;
  }

  private populateThemeProtocolList(themeProtocolKeys: ThemeProtocolKeys[] = []): EditorFormField[] {
    const themeProtocols: EditorFormField[] = [];

    for (const themeProtocolKey of themeProtocolKeys) {
      const themeProtocol = this.findThemeProtocol(themeProtocolKey);

      if (themeProtocol) {
        themeProtocols.push(themeProtocol);
      }
    }

    return themeProtocols;
  }

  private findThemeProtocol(themeKey: ThemeProtocolKeys): EditorFormField {
    const themeProp = themeProtocols[themeKey];

    if (!themeProp) return null;

    let themePropOptions = [];

    if (themeProp.name === 'size') {
      themePropOptions = this.sizes;
    } else if (themeProp.name === 'variant') {
      themePropOptions = this.variants;
    } else {
      themePropOptions = themeProp.options || themeProp.themeGetter(this.theme, this.name);
    }

    return {
      ...themeProp,
      options: themePropOptions,
    };
  }
}
