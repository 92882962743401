import { Block, CompilerComponent, Node, OptionBlocks } from '@types';
import { merge } from 'lodash';

import { blocks } from '@application/blocks';
import { components } from '@application/components/compiler';

/**
 * This class is meant to be used in everything that uses components/blocks/nodes during
 * ex: create component based on a protocol
 */
export class BlockFactory {
  block: Block;

  constructor(blockName: OptionBlocks, props: Partial<Block>) {
    this.block = BlockFactory.initializeBlock(blockName, props);
  }

  static initializeBlock(blockName: OptionBlocks, props?: Partial<Block>) {
    const componentCompiler = BlockFactory.getCompilerComponent(blockName);
    const blockInitializer = blocks[blockName];

    const defaultBlock = {
      staticPropValues: {
        ...componentCompiler.defaultProps,
      },
    };

    if (!props) {
      return blockInitializer(defaultBlock);
    }

    delete props.component;
    const blockValues = merge(defaultBlock, props ?? {});

    return blockInitializer(blockValues);
  }

  // instantiation options
  static fromBlock(block: Block) {
    const blockFactory = new BlockFactory(block.component, block);

    return blockFactory;
  }

  static fromNode(node: Node) {
    const blockFactory = new BlockFactory(node.block.component, node.block);

    return blockFactory;
  }

  static getCompilerComponent(componentName: OptionBlocks): CompilerComponent<any, any> {
    const component = components[componentName];

    if (!component) {
      const defaultComponent: OptionBlocks = 'container';

      return components[defaultComponent];
    }

    return component ?? null;
  }
}
