import { EPropType } from '@types';

import { ProtocolFactory } from '@application/lib/protocols';

import { CheckboxParts, CheckboxProps } from './index';

export const protocolFactory = new ProtocolFactory<CheckboxProps, CheckboxParts>({
  name: 'Checkbox',
  editorProps: [
    {
      type: EPropType.Boolean,
      name: 'value',
      required: true,
      label: 'Value',
      description: 'The value of the checkbox',
      defaultValue: false,
      allowDataLink: true,
    },
    {
      type: EPropType.String,
      name: 'label',
      required: true,
      label: 'Label',
      description: 'The label of the checkbox',
      defaultValue: 'Example',
    },
  ],
  themeProps: ['colorScheme'],
  themeConfig: {
    allowMultipart: true,
    allowColorScheme: true,
    parts: ['control', 'label'],
    defaultProps: ['colorScheme'],
    partsConfig: {
      control: {
        pseudos: ['_checked', '_disabled'],
        pseudoProps: ['borderRadius', 'borderColor', 'background', 'color', 'fontSize'],
        variantProps: ['color', 'border', 'borderRadius', 'background', 'borderColor'],
      },
      label: {
        pseudos: ['_checked', '_disabled'],
        pseudoProps: ['color', 'fontWeight'],
        variantProps: ['color', 'fontSize', 'fontWeight'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
