import { ProtocolFactory } from 'lib/protocols';

import { MutationContainerProps } from './index';

export const protocolFactory = new ProtocolFactory<MutationContainerProps>({
  name: 'MutationContainer',
  editorProps: [],
  editorConfig: {
    disableDesignSection: true,
    disableDataLinkSection: false,
    disableLogicSection: false,
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
