import { EPropType } from '@types';

import { ProtocolFactory } from 'lib/protocols';

import { GridProps } from '.';

export const protocolFactory = new ProtocolFactory<GridProps>({
  name: 'Grid',
  editorProps: [
    {
      type: EPropType.Int,
      name: 'templateColumns',
      label: 'Columns',
      description: 'The number of columns inside the grid',
      defaultValue: 1,
      required: false,
    },
    {
      type: EPropType.Int,
      name: 'templateRows',
      label: 'Rows',
      description: 'The number of rows inside the grid',
      defaultValue: 1,
      required: false,
    },
  ],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: true,
    disableLogicSection: true,
  },
  themeProps: [
    'width',
    'height',
    'gridGap',
    'gridColumnGap',
    'gridRowGap',
    'alignItems',
    'justifyContent',
    'paddingHorizontal',
    'paddingVertical',
    'marginHorizontal',
    'marginVertical',
    'borderRadius',
    'background',
  ],
});

export const defaultProps = protocolFactory.createDefaultProps();
