import React, { FC } from 'react';

import { ChakraProvider, ChakraProviderProps } from '@chakra-ui/react';

import gStudioTheme from '@application/theme';

export const ThemeProvider: FC<Partial<ChakraProviderProps>> = ({
  children,
  theme = gStudioTheme,
  ...props
}) => {
  return (
    <ChakraProvider theme={theme} {...props}>
      {children}
    </ChakraProvider>
  );
};
