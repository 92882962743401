import React, { Children, cloneElement, FC, isValidElement } from 'react';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { ListProps } from './index';

export const component: FC<ComponentReceivedProps<ListProps, any>> = ({
  props = defaultProps,
  children,
  inheritedData,
}) => {
  const { list } = props;

  const childrenWithProps = (item: unknown, index: number) => {
    return Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          key: index,
          dynamicData: {
            ...inheritedData,
            item: item,
            index: index,
          },
        });
      }

      return child;
    });
  };

  return <>{list?.map(childrenWithProps)}</>;
};
