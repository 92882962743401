import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export type ImageUploadParts = 'image-upload-label' | 'image-upload-control';

export interface ImageUploadProps {
  name: string;
  size: 'sm' | 'md' | 'lg';
  label: string;
  value: string;
}

export interface ImageUploadTriggers {
  onChange: (value: string) => void;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<ImageUploadProps, ImageUploadParts>;
