import gql from 'graphql-tag';

export const CreateAccount = gql`
  mutation CreateAccount($organizationId: ID!, $data: CreateAccountInput!) {
    createAccount(organizationId: $organizationId, data: $data) {
      account {
        id
        organizationId
        alias
        clientId
        apiUrl
        userId
        accountStatus
        updateDate
        createDate
      }

      accountOperation {
        id
        accountId
        organizationId
        alias
        userId
        action
        accountOperationStatus
        createDate
        updateDate
      }
    }
  }
`;

export const UpdateAccount = gql`
  mutation UpdateAccount($organizationId: ID!, $id: ID!, $data: UpdateAccountInput!) {
    updateAccount(organizationId: $organizationId, id: $id, data: $data) {
      account {
        id
        organizationId
        alias
        clientId
        apiUrl
        userId
        accountStatus
        updateDate
        createDate
      }

      accountOperation {
        id
        accountId
        organizationId
        alias
        userId
        action
        accountOperationStatus
        createDate
        updateDate
      }
    }
  }
`;

export const DisableAccount = gql`
  mutation DisableAccount($organizationId: ID!, $id: ID!) {
    disableAccount(organizationId: $organizationId, id: $id) {
      account {
        id
        organizationId
        alias
        clientId
        apiUrl
        userId
        accountStatus
        updateDate
        createDate
      }

      accountOperation {
        id
        accountId
        organizationId
        alias
        userId
        action
        accountOperationStatus
        createDate
        updateDate
      }
    }
  }
`;
