import { MenuParts } from '@application/lib/defaultTheme';
import { ProtocolFactory } from '@application/lib/protocols';

import { MenuProps } from './index';

export const protocolFactory = new ProtocolFactory<MenuProps, MenuParts>({
  name: 'Menu',
  editorProps: [],
  editorConfig: {
    disableDataLinkSection: false,
    disableDesignSection: true,
    disableLogicSection: false,
  },
  themeProps: [],
  themeConfig: {
    defaultProps: [],
    allowMultipart: true,
    allowColorScheme: true,
    parts: ['menu-button', 'menu-icon-button', 'menu-list', 'menu-item'],
    partsConfig: {
      'menu-button': {
        variantProps: [
          'paddingHorizontal',
          'paddingVertical',
          'border',
          'borderRadius',
          'borderColor',
          'color',
          'background',
        ],
        pseudos: ['_hover'],
        pseudoProps: ['background', 'borderColor'],
      },
      'menu-icon-button': {
        variantProps: [
          'paddingHorizontal',
          'paddingVertical',
          'border',
          'borderRadius',
          'borderColor',
          'color',
          'background',
        ],
        pseudos: ['_hover'],
        pseudoProps: ['background', 'borderColor'],
      },
      'menu-list': {
        variantProps: ['paddingVertical', 'border', 'borderRadius', 'borderColor', 'background'],
      },
      'menu-item': {
        variantProps: ['color', 'background', 'fontSize', 'fontFamily'],
        pseudos: ['_hover', '_focus'],
        pseudoProps: ['background'],
      },
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
