import { ThemeMultipartComponent } from '@types';

export type StoriesSizes = 'sm' | 'md' | 'lg';
export type StoriesVariants = 'default';
export type StoriesParts = 'stories-container' | 'stories-text' | 'stories-see-more';

export const PTStories: ThemeMultipartComponent<StoriesSizes, StoriesVariants, StoriesParts> = {
  parts: ['stories-container', 'stories-text', 'stories-see-more'],

  baseStyle: {
    'stories-container': {
      border: 'pt-none',
      borderColor: 'pt-transparent',
      boxShadow: 'pt-large',
      borderRadius: 'pt-sm',
    },
    'stories-text': {
      color: 'pt-white',
      padding: 'pt-md',
      textAlign: 'center',
      textStyle: 'pt-medium',
      alignItems: 'center',
      justifyContent: 'center',
    },
    'stories-see-more': {
      color: 'pt-white',
      cursor: 'pointer',
      paddingY: 'pt-md',
      paddingX: 'pt-md',
      useSelect: 'none',
      background: 'pt-transparent',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      fontSize: 'pt-sm',
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: 'pt-primary',
  },
};
