import React, { FC, useRef } from 'react';

import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody as ChakraAlertDialogBody,
  AlertDialogContent as ChakraAlertDialogContent,
  AlertDialogFooter as ChakraAlertDialogFooter,
  AlertDialogHeader as ChakraAlertDialogHeader,
  AlertDialogOverlay as ChakraAlertDialogOverlay,
  AlertDialogProps as ChakraAlertDialogProps,
  CloseButton as ChakraCloseButton,
  Flex as ChakraFlex,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { Button, Heading, Text } from '@application/components/ds';
import { ButtonVariants } from '@application/theme';

export interface AlertDialogProps extends Omit<ChakraAlertDialogProps, 'leastDestructiveRef' | 'onClose'> {
  title?: string;
  buttonLabel?: string;
  buttonVariant?: ButtonVariants;
  noCloseButton?: boolean;
  description?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onConfirm: () => void;
  onClose?: () => void;
}

export const AlertDialog: FC<AlertDialogProps> = ({
  isOpen,
  isDisabled,
  isLoading,
  children,
  noCloseButton,
  buttonLabel = 'Confirm',
  buttonVariant = 'primary',
  title,
  description,
  onConfirm,
  onClose,
  ...props
}) => {
  const dialogCancelRef = useRef();
  const styles = useMultiStyleConfig('AlertDialog', {
    size: props.size,
    variant: props.variant,
    colorScheme: props.colorScheme,
  });

  return (
    <ChakraAlertDialog leastDestructiveRef={dialogCancelRef} isOpen={isOpen} onClose={onClose} {...props}>
      <ChakraAlertDialogOverlay>
        <ChakraAlertDialogContent sx={styles['alert-dialog-content']}>
          <ChakraAlertDialogHeader sx={styles['alert-dialog-header']}>
            <ChakraFlex flexDirection="column">
              <Heading>{title}</Heading>
              <Text color="ui.500" variant="body-1">
                {description}
              </Text>
            </ChakraFlex>
            {!noCloseButton && (
              <ChakraCloseButton sx={styles['alert-dialog-header-close']} onClick={() => onClose()} />
            )}
          </ChakraAlertDialogHeader>
          <ChakraAlertDialogBody sx={styles['alert-dialog-body']}>{children}</ChakraAlertDialogBody>
          <ChakraAlertDialogFooter sx={styles['alert-dialog-footer']}>
            {!noCloseButton && (
              <Button variant="secondary" data-testid="alert-dialog-cancel-button" onClick={() => onClose()}>
                Cancel
              </Button>
            )}
            <Button
              data-testid="alert-dialog-confirm-button"
              variant={buttonVariant}
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={() => onConfirm()}
            >
              {buttonLabel}
            </Button>
          </ChakraAlertDialogFooter>
        </ChakraAlertDialogContent>
      </ChakraAlertDialogOverlay>
    </ChakraAlertDialog>
  );
};
