import React, { FC, useEffect, useState } from 'react';

import {
  FormControl as ChakraFormControl,
  Text as ChakraText,
  Textarea as ChakraTextarea,
  forwardRef,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { TextareaProps, TextareaTriggers } from './index';

export const component: FC<ComponentReceivedProps<TextareaProps, TextareaTriggers>> = forwardRef(
  ({ props = defaultProps, triggers }, ref) => {
    const { label, size, value, ...rest } = props;
    const { onBlur } = triggers;

    const [state, setState] = useState(value);

    useEffect(() => {
      setState(value);
    }, [value]);

    const baseInputStyles = useMultiStyleConfig('PTBaseInput', { size });

    return (
      <ChakraFormControl>
        {label && <ChakraText sx={baseInputStyles['base-input-label']}>{label}</ChakraText>}
        <ChakraTextarea
          {...rest}
          sx={{ ...baseInputStyles['base-input-control'], minHeight: '5rem' }}
          ref={ref}
          value={state}
          onBlur={({ target }) => onBlur && onBlur(target.value)}
          onChange={({ target }) => setState(target.value)}
        />
      </ChakraFormControl>
    );
  },
);
