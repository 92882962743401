import { BoardParts } from '@application/lib/defaultTheme';

import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface Card {
  id?: string;
  title?: string;
  description?: string;
  tag?: string;
  column?: string;
  row?: number;
}

export interface Column {
  id: string;
  label: string;
}

export interface BoardProps {
  boardColumns: Column[];
  boardIsBlocked: boolean;
  boardData: Record<string, unknown>[];
  boardDataCollection: string;
  boardCardTitleProperty: string;
  boardCardDescriptionProperty: string;
  boardCardTagProperty: string;
  boardCardTagPropertyOptions: unknown[];
  boardCardColumnIndexProperty: string;
  boardCardRowIndexProperty: string;
  fieldsRelations: Record<string, string>;
}

export interface BoardTriggers {
  onDoubleClick: (args: { card: Card }) => void;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<BoardProps, BoardParts>;
