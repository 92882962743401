import React, {
  Children,
  cloneElement,
  FC,
  isValidElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { cloneDeep, find, get, set } from 'lodash';
import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql/language/printer';
import { useQuery } from 'react-query';

import { AuthContext } from 'compiler/contexts/AuthContext';

import { CompilerContext, EngineContext } from '@application/compiler/contexts';
import { Loading } from '@application/components/ds';
import { QueryOperation, useDebounce, useIntrospectionFactory } from '@application/lib/customHooks';

interface ProtectedPageProps {
  dynamicData: Record<string, unknown>;
}

export const ProtectedPage: FC<ProtectedPageProps> = ({ children, dynamicData }) => {
  const { engine } = useContext(EngineContext);
  const { config } = useContext(CompilerContext);

  const { user, isLoading, isAuthenticated, getIdTokenClaims, loginWithRedirect, logout } =
    useContext(AuthContext);

  const [isMounted, setIsMounted] = useState(false);

  const isProduction = config.mode === 'production';

  const needAuth = useMemo(
    () => isProduction && !isLoading && !isAuthenticated,
    [isLoading, isAuthenticated],
  );

  useDebounce(
    () => {
      if (needAuth) {
        loginWithRedirect.mutate({});
      } else {
        setIsMounted(true);
      }
    },
    1500,
    [needAuth],
  );

  const isRoleBasedPage = useMemo(() => !!dynamicData.$pageRole, [dynamicData]);
  const getUserRole = useMemo(() => get(user, ['https://gstudioapp.com/user_metadata', 'role']), [user]);

  const userQuery = useQuery(
    ['user'],
    async () => {
      if (!isProduction) return config.auth?.user;

      const token = await getIdTokenClaims.mutateAsync({});

      if (!token?.__raw) {
        await loginWithRedirect.mutateAsync({});
      }

      return {
        ...user,
        token: token?.__raw,
      };
    },
    {
      retry: false,
      enabled: isProduction ? !needAuth && isMounted : true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError(error) {
        console.error(error);
      },
    },
  );

  const [introspectionFactory, introspectionIsLoading] = useIntrospectionFactory(
    config.api.endpoint,
    userQuery.data?.token,
    !userQuery.isLoading && !userQuery.isIdle,
  );

  useEffect(() => {
    if (engine && !introspectionIsLoading && introspectionFactory) {
      engine.addOperators({
        data_query: {
          name: 'data_query',
          description: 'Makes a query to the database',
          handler: async ({
            operationName,
            input,
          }: {
            operationName: string;
            input: Record<string, any>;
          }) => {
            const operation = find(introspectionFactory.queryOperations, { name: operationName });

            if (!operation) throw new Error('Introspection operation not found');
            if (!userQuery.data.token) throw new Error('We had a problem with the authentication');

            const client = new GraphQLClient(config.api.endpoint, {
              headers: { Authorization: userQuery.data.token },
            });

            const resp = await client.request(print(operation.operation), input);
            const data = resp[operationName];

            if (operationName?.startsWith(QueryOperation.List)) {
              const length = get(data, 'items.length', 0);

              if (!length) return false;
            }

            return data;
          },
        },
        data_operation: {
          name: 'data_operation',
          description: 'Makes an operation at the database',
          handler: async ({
            operationName,
            input,
          }: {
            operationName: string;
            input: Record<string, any>;
          }) => {
            const clonedInput = cloneDeep(input);
            const operation = find(introspectionFactory.mutationOperations, { name: operationName });

            if (clonedInput?.id) set(clonedInput, ['id'], input.id.replace('$', '').trim());
            if (!operation) throw new Error('Operation not found');

            const client = new GraphQLClient(config.api.endpoint, {
              headers: { Authorization: userQuery.data.token },
            });

            const resp = await client.request(print(operation.operation), clonedInput);

            return resp[operationName];
          },
        },
        logout: {
          name: 'logout',
          description: 'Logout the authenticated user',
          handler: async () => {
            if (isProduction && !needAuth) {
              await logout.mutateAsync({});
            }
          },
        },
      });

      return () => {
        engine.removeOperators(['data_operation']);
      };
    }
  }, [engine, introspectionFactory, introspectionIsLoading]);

  useEffect(() => {
    if (isProduction && !needAuth) {
      if (isRoleBasedPage && user && dynamicData.$pageRole !== getUserRole) {
        config.router.push({
          path: '/unathorized',
        });
      }
    }
  }, [isRoleBasedPage, user, getUserRole, needAuth]);

  return (
    <Loading
      width="100%"
      height="100%"
      isLoading={userQuery.isLoading || userQuery.isIdle || introspectionIsLoading}
    >
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            dynamicData: {
              ...dynamicData,
              user: userQuery.data,
            },
          });
        }

        return child;
      })}{' '}
    </Loading>
  );
};
