import { Id } from '../AWSUtils';

import { ApiEngineTrack } from './index';

export interface Account extends ApiEngineTrack {
  id: Id;
  organizationId: Id;
  alias: string;
  clientId?: Id;
  apiUrl?: string;
  accountStatus: EAccountStatus;
}

enum EAccountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
