import { cloneDeep, findIndex, merge } from 'lodash';
import { composerErrors } from './errors';

import * as Core from 'core/node';
import {
  AddComposerActionPayload,
  ComposerAction,
  ComposerError,
  ComposerErrorCode,
  ComposerState,
  DeleteComposerActionPayload,
  DuplicateComposerActionPayload,
  EditComposerActionPayload,
  MoveComposerAction,
} from '@types';
import { getLayoutOrPageFromStateWithMode, updateRootNode } from './nodeTree';
import {
  nodesWithChildren,
  uneditableBlocks,
} from '@application/components/pages/Project/Editor/EditorNodeTree/helpers/nodeTree.config';
import {
  recursiveDuplicateNode,
  recursiveFindElement,
  recursiveFindParentElement,
} from '@application/lib/nodeTree/nodeTreeUtils';

type ComposerActionReturn = { state: ComposerState; action: ComposerAction; error?: ComposerError };
type ComposerActions = Record<
  ComposerAction['type'],
  (state: ComposerState, payload: ComposerAction) => ComposerActionReturn
>;

const returnError = (state: ComposerState, errorCode: ComposerErrorCode) => {
  return {
    state,
    action: null,
    error: composerErrors[errorCode],
  };
};

const addNode = (
  state: ComposerState,
  action: ComposerAction<AddComposerActionPayload>,
): ComposerActionReturn => {
  const { targetNodeId, node } = action.payload;

  const layoutOrPage = getLayoutOrPageFromStateWithMode(state);
  if (!layoutOrPage) {
    return returnError(state, ComposerErrorCode.ROOT_NODE_NOT_FOUND);
  }

  const nodeStructure = layoutOrPage.nodesStructure;

  const foundParentElement = recursiveFindElement(nodeStructure, targetNodeId);
  if (!foundParentElement) {
    return returnError(state, ComposerErrorCode.PARENT_NODE_NOT_FOUND);
  }

  foundParentElement.children.push(node);

  updateRootNode(state);

  return { state, action };
};

const deleteNode = (
  state: ComposerState,
  action: ComposerAction<DeleteComposerActionPayload>,
): ComposerActionReturn => {
  const { targetNodeId, node } = action.payload;

  if (uneditableBlocks.includes(node.block.component)) {
    return returnError(state, ComposerErrorCode.NODE_NOT_DELETABLE);
  }

  const layoutOrPage = getLayoutOrPageFromStateWithMode(state);
  if (!layoutOrPage) {
    return returnError(state, ComposerErrorCode.ROOT_NODE_NOT_FOUND);
  }

  const nodeStructure = layoutOrPage.nodesStructure;

  const foundParentElement = recursiveFindElement(nodeStructure, targetNodeId);
  if (!foundParentElement) {
    return returnError(state, ComposerErrorCode.PARENT_NODE_NOT_FOUND);
  }

  foundParentElement.children = Core.deleteChildById(foundParentElement, node.id).children;

  updateRootNode(state);

  return { state, action };
};

const editNode = (
  state: ComposerState,
  action: ComposerAction<EditComposerActionPayload>,
): ComposerActionReturn => {
  const { targetNodeId, node } = action.payload;

  if (uneditableBlocks.includes(node.block.component)) {
    return returnError(state, ComposerErrorCode.NODE_NOT_EDITABLE);
  }

  const layoutOrPage = getLayoutOrPageFromStateWithMode(state);
  if (!layoutOrPage) {
    return returnError(state, ComposerErrorCode.ROOT_NODE_NOT_FOUND);
  }

  const nodeStructure = layoutOrPage.nodesStructure;

  const foundNode = recursiveFindElement(nodeStructure, targetNodeId);
  const oldNode = cloneDeep(foundNode);

  if (!foundNode) {
    return returnError(state, ComposerErrorCode.NODE_NOT_FOUND);
  }

  const editedNode = Core.editNode(foundNode, node);
  merge(foundNode, editedNode);

  updateRootNode(state);

  const newAction = merge(cloneDeep(action), {
    payload: {
      oldNode,
    },
  });

  return { state, action: newAction };
};

const uneditNode = (
  state: ComposerState,
  action: ComposerAction<EditComposerActionPayload>,
): ComposerActionReturn => {
  const { targetNodeId, node, oldNode } = action.payload;

  const layoutOrPage = getLayoutOrPageFromStateWithMode(state);
  if (!layoutOrPage) {
    return returnError(state, ComposerErrorCode.ROOT_NODE_NOT_FOUND);
  }

  const nodeStructure = layoutOrPage.nodesStructure;

  const foundNode = recursiveFindElement(nodeStructure, targetNodeId);

  if (foundNode) {
    const editedNode = Core.editNode(foundNode, oldNode);

    merge(foundNode, editedNode);
  }

  updateRootNode(state);

  const newAction = merge(cloneDeep(action), {
    payload: {
      oldNode,
      node,
    },
  });

  return { state, action: newAction };
};

const duplicateNode = (
  state: ComposerState,
  action: ComposerAction<DuplicateComposerActionPayload>,
): ComposerActionReturn => {
  const { node, targetNodeId, parentId } = action.payload;

  if (uneditableBlocks.includes(node.block.component)) {
    return returnError(state, ComposerErrorCode.UNIQUE_NODE);
  }

  const layoutOrPage = getLayoutOrPageFromStateWithMode(state);
  if (!layoutOrPage) {
    return returnError(state, ComposerErrorCode.ROOT_NODE_NOT_FOUND);
  }

  const nodeStructure = layoutOrPage.nodesStructure;

  const foundParentElement = recursiveFindElement(nodeStructure, parentId);
  if (!foundParentElement) {
    return returnError(state, ComposerErrorCode.PARENT_NODE_NOT_FOUND);
  }

  const foundChildElement = recursiveFindElement(foundParentElement, targetNodeId);
  if (!foundChildElement) {
    return returnError(state, ComposerErrorCode.NODE_NOT_FOUND);
  }

  const newNode = recursiveDuplicateNode(node);
  foundParentElement.children.push(newNode);

  const newAction = merge(cloneDeep(action), {
    payload: {
      newNode,
    },
  });

  updateRootNode(state);

  return { state, action: newAction };
};

const removeDuplicateNode = (
  state: ComposerState,
  action: ComposerAction<DuplicateComposerActionPayload>,
): ComposerActionReturn => {
  const { parentId, newNode } = action.payload;

  const layoutOrPage = getLayoutOrPageFromStateWithMode(state);
  if (!layoutOrPage) {
    return returnError(state, ComposerErrorCode.ROOT_NODE_NOT_FOUND);
  }

  const nodeStructure = layoutOrPage.nodesStructure;

  const foundParentElement = recursiveFindElement(nodeStructure, parentId);
  if (!foundParentElement) {
    return returnError(state, ComposerErrorCode.PARENT_NODE_NOT_FOUND);
  }

  const foundChildElement = recursiveFindElement(foundParentElement, newNode.id);
  if (!foundChildElement) {
    return returnError(state, ComposerErrorCode.NODE_NOT_FOUND);
  }

  foundParentElement.children = Core.deleteChildById(foundParentElement, newNode.id).children;

  const newAction = merge(cloneDeep(action), {
    payload: {
      newNode,
    },
  });

  updateRootNode(state);

  return { state, action: newAction };
};

const moveUpNode = (
  state: ComposerState,
  action: ComposerAction<MoveComposerAction>,
): ComposerActionReturn => {
  const { targetNodeId } = action.payload;

  const layoutOrPage = getLayoutOrPageFromStateWithMode(state);
  if (!layoutOrPage) {
    return returnError(state, ComposerErrorCode.ROOT_NODE_NOT_FOUND);
  }

  const nodeStructure = layoutOrPage.nodesStructure;

  const parentNode = recursiveFindParentElement(nodeStructure, targetNodeId);
  if (!parentNode) {
    return returnError(state, ComposerErrorCode.PARENT_NODE_NOT_FOUND);
  }

  const node = Core.findChildById(parentNode, targetNodeId);

  const prevIndex = findIndex(parentNode.children, { id: targetNodeId });
  const newIndex = prevIndex - 1;

  if (newIndex >= 0) {
    parentNode.children[prevIndex] = parentNode.children[newIndex];
    parentNode.children[newIndex] = node;
  } else {
    parentNode.children.shift();
    parentNode.children.push(node);
  }

  updateRootNode(state);

  return { state, action };
};

const moveDownNode = (
  state: ComposerState,
  action: ComposerAction<MoveComposerAction>,
): ComposerActionReturn => {
  const { targetNodeId } = action.payload;

  const layoutOrPage = getLayoutOrPageFromStateWithMode(state);
  if (!layoutOrPage) {
    return returnError(state, ComposerErrorCode.ROOT_NODE_NOT_FOUND);
  }

  const nodeStructure = layoutOrPage.nodesStructure;

  const parentNode = recursiveFindParentElement(nodeStructure, targetNodeId);
  if (!parentNode) {
    return returnError(state, ComposerErrorCode.PARENT_NODE_NOT_FOUND);
  }

  const node = Core.findChildById(parentNode, targetNodeId);

  const prevIndex = findIndex(parentNode.children, { id: targetNodeId });
  const newIndex = prevIndex + 1;

  if (newIndex !== parentNode.children.length) {
    parentNode.children[prevIndex] = parentNode.children[newIndex];
    parentNode.children[newIndex] = node;
  } else {
    parentNode.children.pop();
    parentNode.children.unshift(node);
  }

  updateRootNode(state);

  return { state, action };
};

const moveInNode = (
  state: ComposerState,
  action: ComposerAction<MoveComposerAction>,
): ComposerActionReturn => {
  const { targetNodeId } = action.payload;

  const layoutOrPage = getLayoutOrPageFromStateWithMode(state);
  if (!layoutOrPage) {
    return returnError(state, ComposerErrorCode.ROOT_NODE_NOT_FOUND);
  }

  const nodeStructure = layoutOrPage.nodesStructure;

  const parentNode = recursiveFindParentElement(nodeStructure, targetNodeId);
  if (!parentNode) {
    return returnError(state, ComposerErrorCode.PARENT_NODE_NOT_FOUND);
  }

  const node = Core.findChildById(parentNode, targetNodeId);
  const nodeIndex = findIndex(parentNode.children, { id: targetNodeId });

  const prevSibling = parentNode.children[nodeIndex - 1];

  if (!prevSibling) {
    return returnError(state, ComposerErrorCode.PARENT_NODE_NOT_FOUND);
  }

  if (!nodesWithChildren.includes(prevSibling.block?.component)) {
    return returnError(state, ComposerErrorCode.NODE_NOT_CHILDREN);
  }

  parentNode.children.splice(nodeIndex, 1);
  prevSibling.children.push(node);

  updateRootNode(state);

  return { state, action };
};

const moveOutNode = (
  state: ComposerState,
  action: ComposerAction<MoveComposerAction>,
): ComposerActionReturn => {
  const { targetNodeId } = action.payload;

  const layoutOrPage = getLayoutOrPageFromStateWithMode(state);
  if (!layoutOrPage) {
    return returnError(state, ComposerErrorCode.ROOT_NODE_NOT_FOUND);
  }

  const nodeStructure = layoutOrPage.nodesStructure;

  const parentNode = recursiveFindParentElement(nodeStructure, targetNodeId);
  if (!parentNode) {
    return returnError(state, ComposerErrorCode.PARENT_NODE_NOT_FOUND);
  }

  const grandparentNode = recursiveFindParentElement(nodeStructure, parentNode.id);
  if (!grandparentNode) {
    return returnError(state, ComposerErrorCode.PARENT_NODE_NOT_FOUND);
  }

  const node = Core.findChildById(parentNode, targetNodeId);

  const parentNodeIndex = findIndex(grandparentNode.children, { id: parentNode.id });
  const nodeIndex = findIndex(parentNode.children, { id: targetNodeId });

  parentNode.children.splice(nodeIndex, 1);
  grandparentNode.children.splice(parentNodeIndex + 1, 0, node);

  updateRootNode(state);

  return { state, action };
};

export const actions: ComposerActions = {
  add: addNode,
  delete: deleteNode,
  edit: editNode,
  duplicate: duplicateNode,
  moveUp: moveUpNode,
  moveDown: moveDownNode,
  moveIn: moveInNode,
  moveOut: moveOutNode,
};

export const undoActions: ComposerActions = {
  add: deleteNode,
  delete: addNode,
  edit: uneditNode,
  duplicate: removeDuplicateNode,
  moveUp: moveDownNode,
  moveDown: moveUpNode,
  moveIn: moveOutNode,
  moveOut: moveInNode,
};
