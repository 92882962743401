import React, { FC } from 'react';

import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  forwardRef,
  useStyleConfig,
} from '@chakra-ui/react';

export const Select: FC<ChakraSelectProps> = forwardRef(
  ({ children, variant, size, colorScheme, ...props }, ref) => {
    const styles = useStyleConfig('Select', { colorScheme, variant, size });

    return (
      <ChakraSelect sx={styles} ref={ref} {...props}>
        {children}
      </ChakraSelect>
    );
  },
);

Select.displayName = 'Select';
