import React, { FC } from 'react';

import { Flex as ChakraFlex, useMultiStyleConfig } from '@chakra-ui/react';
import { HeaderProps } from 'react-big-calendar';
import moment from 'moment';

export const CalendarMonthHeader: FC<HeaderProps> = ({ date }) => {
  const { 'calendar-month-header': calendarMonthHeaderStyles } = useMultiStyleConfig('PTCalendar', {});

  return <ChakraFlex sx={calendarMonthHeaderStyles}>{moment(date).format('ddd')}</ChakraFlex>;
};
