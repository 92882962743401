import { ThemeMultipartComponent } from '@types';

type BaseInputSizes = 'sm' | 'md' | 'lg';
type BaseInputVariants = 'default';

type BaseInputParts = 'base-input-label' | 'base-input-control';

export const PTBaseInput: ThemeMultipartComponent<BaseInputSizes, BaseInputVariants, BaseInputParts> = {
  parts: ['base-input-label', 'base-input-control'],

  variants: {
    default: {
      'base-input-label': {
        color: 'pt-ui.500',
        fontWeight: 'pt-normal',
        fontFamily: 'pt-body',
        fontSize: 'pt-md',
      },
      'base-input-control': {
        width: '100%',
        minHeight: '2.6rem',
        outline: 'none',
        color: 'pt-ui.500',
        background: 'pt-white',
        border: 'pt-sm',
        borderColor: 'pt-ui.200',
        borderRadius: 'pt-xs',
        _placeholder: {
          fontFamily: 'pt-body',
        },
        _focus: {
          outline: 'none',
          borderColor: 'pt-primary.500',
          boxShadow: `0px 0px 0px 3px var(--chakra-colors-pt-primary-100)`,
          _invalid: {
            boxShadow: `0px 0px 0px 3px var(--chakra-colors-pt-danger-100)`,
          },
        },
        _invalid: {
          borderColor: 'pt-danger.600',
        },
        _hover: {
          color: 'pt-ui.600',
          border: 'pt-sm',
          borderColor: 'pt-primary.500',
          borderRadius: 'pt-xs',
          background: 'pt-white',
        },
      },
    },
  },

  sizes: {
    sm: {
      'base-input-label': {
        fontSize: 'pt-xs',
        marginBottom: 'pt-xs',
      },
      'base-input-control': {
        fontSize: 'pt-sm',
        paddingX: 'pt-sm',
        paddingY: 'pt-xs',
      },
    },
    md: {
      'base-input-label': {
        fontSize: 'pt-sm',
        marginBottom: 'pt-sm',
      },
      'base-input-control': {
        fontSize: 'pt-md',
        paddingX: 'pt-md',
        paddingY: 'pt-sm',
      },
    },
    lg: {
      'base-input-label': {
        fontSize: 'pt-md',
        marginBottom: 'pt-md',
      },
      'base-input-control': {
        fontSize: 'pt-md',
        paddingX: 'pt-lg',
        paddingY: 'pt-sm',
      },
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: null,
  },
};
