import { isPlainObject, mapValues } from 'lodash';

import { Introspection } from '@types';

type MapValuesDeep = (obj: object, fn: (val: any, key: string, obj: object) => void) => object;

export const mapValuesDeep: MapValuesDeep = (obj, fn) => {
  return mapValues(obj, (val, key) => {
    if (isPlainObject(val)) {
      return mapValuesDeep(val, fn);
    }
    return fn(val, key, obj);
  });
};

export const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isReservedWord = (word: string): boolean =>
  Introspection.reservedWords.includes(word.toLowerCase());
