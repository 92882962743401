import fetch from 'isomorphic-unfetch';

export const uploadFileToS3 = async (file: File): Promise<string> => {
  const { uploadURL, photoFilename } = await (
    await fetch(`${process.env.PRESIGN_IMAGE}`, {
      method: 'POST',
      body: JSON.stringify({
        fileType: file.type,
      }),
    })
  ).json();

  await fetch(uploadURL, {
    method: 'PUT',
    body: file,
  });

  return `${process.env.URL_IMAGE}${photoFilename}`;
};
