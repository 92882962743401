import { ComponentBlock } from '@types';

import { types } from '@application/core/types';

import { component } from './component';
import { MapProps } from '.';

export const block: ComponentBlock<'map', MapProps, unknown> = {
  id: 'map',
  render: component,
  props: {
    mapMarkersData: {
      type: types.list,
    },
    mapMarkersGeolocation: {
      type: types.string,
    },
    mapMarkersTooltip: {
      type: types.string,
    },
  },
};
