import { GridProps as ChakraGridProps } from '@chakra-ui/react';

import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface GridProps {
  width: string;
  height: string;
  templateColumns: ChakraGridProps['templateColumns'];
  templateRows: ChakraGridProps['templateRows'];
}

export default {
  component,
  block,
  defaultProps,
  protocolFactory,
} as CompilerComponent<GridProps>;
