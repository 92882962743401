import { CompilerComponent, IconName } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface IconProps {
  icon?: IconName;
  size: 'sm' | 'md' | 'lg';
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<IconProps>;
