import { ThemeMultipartComponent } from '@types';

type AccordionSizes = 'sm' | 'md' | 'lg';
type AccordionVariants = 'default';

type AccordionParts = 'accordion' | 'accordion-button' | 'accordion-panel';

export const PTAccordion: ThemeMultipartComponent<AccordionSizes, AccordionVariants, AccordionParts> = {
  parts: ['accordion', 'accordion-button', 'accordion-panel'],

  baseStyle: {
    accordion: {
      boxShadow: 'pt-large',
      background: 'pt-white',
      borderColor: 'pt-ui.300',
      borderRadius: 'pt-sm',
      overflow: 'hidden',
    },
    'accordion-button': {
      color: 'pt-ui.900',
      background: 'pt-white',
    },
    'accordion-panel': {
      paddingY: 'pt-md',
      paddingX: 'pt-md',
      background: 'pt-white',
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: 'pt-primary',
  },
};
