import { createContext } from 'react';

export interface ContextState {
  data: DataStructure;
  addDynamicDataProperty?: (newData: Record<string, any>) => void;
  deleteDynamicDataProperty?: (propertyId: string) => void;
}

type DataStructure = Record<string, any>;

export const DynamicPropsContext = createContext<ContextState>({ data: {} });
DynamicPropsContext.displayName = 'DynamicPropsContext';

export const DynamicPropsProvider = DynamicPropsContext.Provider;
