import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { BadgeProps } from './index';

export const protocolFactory = new ProtocolFactory<BadgeProps>({
  name: 'Badge',
  editorProps: [
    {
      type: [EPropType.String, EPropType.Enum],
      name: 'label',
      required: true,
      label: 'Label',
      description: 'Text inside the badge',
      defaultValue: 'Badge',
      allowDataLink: true,
    },
  ],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: false,
    disableLogicSection: true,
  },
  themeProps: ['variant', 'colorScheme', 'size', 'leftIcon', 'rightIcon'],
  themeConfig: {
    sizes: ['sm', 'md', 'lg'],
    allowColorScheme: true,
    allowMultipart: false,
    defaultProps: ['size', 'variant', 'colorScheme'],
    defaultConfig: {
      variantProps: ['color', 'background', 'borderRadius'],
      sizeProps: ['fontSize', 'lineHeight', 'paddingHorizontal', 'paddingVertical'],
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
