import { createContext } from 'react';

import { Engine } from 'json-logical-engine';

export interface EngineContext {
  engine: Engine;
}

export const EngineContext = createContext<EngineContext>({ engine: null });
EngineContext.displayName = 'EngineContext';

export const EngineProvider = EngineContext.Provider;
