import { component } from './component';
import { ComponentBlock } from '@types';
import { DevelopmentEnvironmentProps } from './index';
import { types } from 'core/types';

export const block: ComponentBlock<'developmentEnvironment', DevelopmentEnvironmentProps, any> = {
  id: 'developmentEnvironment',
  render: component,
  props: {
    theme: {
      type: types.string,
    },
    renderBgColor: {
      type: types.string,
    },
    hideEditor: {
      type: types.boolean,
    },
    html: {
      type: types.string,
    },
    css: {
      type: types.string,
    },
  },
  events: {},
  triggers: {
    onSaveDraft: {
      name: 'On save draft',
      description: '',
    },
    onPublish: {
      name: 'On publish',
      description: '',
    },
  },
};
