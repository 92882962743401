export const colors = {
  transparent: 'transparent',
  black: '#000',
  white: '#fff',

  primary: {
    50: '#F9F5FF',
    100: '#F3EAFF',
    200: '#E1CBFF',
    300: '#CEACFF',
    400: '#AA6DFF',
    500: '#852FFF',
    600: '#782AE6',
    700: '#501C99',
    800: '#3C1573',
    900: '#280E4D',
  },
  supportA: {
    50: '#FFF5F5',
    100: '#FFECEC',
    200: '#FFCECE',
    300: '#FFB1B1',
    400: '#FF7777',
    500: '#FF3C3C',
    600: '#E63636',
    700: '#992424',
    800: '#731B1B',
    900: '#4D1212',
  },
  supportB: {
    50: '#FFFBF3',
    100: '#FFF6E7',
    200: '#FFE9C4',
    300: '#FFDCA1',
    400: '#FFC25A',
    500: '#FFA813',
    600: '#E69711',
    700: '#99650B',
    800: '#734C09',
    900: '#4D3206',
  },
  supportC: {
    50: '#F3FDF7',
    100: '#E7FAF0',
    200: '#C4F3D9',
    300: '#A1EBC2',
    400: '#5ADD94',
    500: '#13CE66',
    600: '#11B95C',
    700: '#0B7C3D',
    800: '#095D2E',
    900: '#063E1F',
  },
  supportD: {
    50: '#F2F8FF',
    100: '#E6F2FF',
    200: '#BFDEFF',
    300: '#99C9FF',
    400: '#4DA1FF',
    500: '#0079FF',
    600: '#006DE6',
    700: '#004999',
    800: '#003673',
    900: '#00244D',
  },
  ui: {
    50: '#FAFAFA',
    100: '#F5F5F6',
    200: '#E6E5E8',
    300: '#D6D5DA',
    400: '#B8B6BF',
    500: '#9996A3',
    600: '#8A8793',
    700: '#5C5A62',
    800: '#454449',
    900: '#2F2C3D',
  },
};
