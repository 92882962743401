import React, { FC } from 'react';

import { Button as ChakraButton, useStyleConfig } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { Icon } from '@application/components/ds';

import { ButtonProps, ButtonTriggers } from './index';
import { defaultProps } from './protocol';

export const component: FC<ComponentReceivedProps<ButtonProps, ButtonTriggers>> = ({
  props = defaultProps,
  triggers,
  inheritedData,
}) => {
  const { variant, colorScheme, size, label, leftIcon, rightIcon, ...rest } = props;
  const { onClick } = triggers;

  const themeStyle = useStyleConfig('PTButton', { variant, size, colorScheme });

  return (
    <ChakraButton
      sx={themeStyle}
      aria-label={label}
      leftIcon={leftIcon && <Icon name={leftIcon} />}
      rightIcon={rightIcon && <Icon name={rightIcon} />}
      onClick={() => onClick && onClick(inheritedData)}
      {...rest}
    >
      {label}
    </ChakraButton>
  );
};
