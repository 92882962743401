import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { AspectRatioBoxProps } from './index';

export const protocolFactory = new ProtocolFactory<AspectRatioBoxProps>({
  name: 'AspectRatioBox',
  editorProps: [
    {
      type: EPropType.String,
      name: 'ratio',
      required: false,
      label: 'Ratio',
      description: 'Proportion of the image',
      defaultValue: '1/1',
      values: ['', '1/1', '4/3', '16/9'],
    },
    {
      type: EPropType.Int,
      name: 'px',
      required: false,
      label: 'Horizontal padding',
      description: 'Space inside the box in the horizontal direction',
      defaultValue: 4,
      values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
      type: EPropType.Int,
      name: 'py',
      required: true,
      label: 'Vertical padding',
      description: 'Space inside the box in the vertical direction',
      defaultValue: 4,
      values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
      type: EPropType.String,
      name: 'borderWidth',
      required: false,
      label: 'Border width(in pixels)',
      description: 'Width of the border',
      defaultValue: '10px',
    },
    {
      type: EPropType.String,
      name: 'borderColor',
      required: false,
      label: 'Border Color',
      description: 'Border color of the box(used in combination with borderWidth)',
      defaultValue: 'white',
    },
    {
      type: EPropType.String,
      name: 'borderRadius',
      required: false,
      label: 'Border Color',
      description: 'Border color of the box(used in combination with borderWidth)',
      defaultValue: 'md',
      values: ['sm', 'md', 'lg'],
    },
  ],
});

export const defaultProps = protocolFactory.createDefaultProps();
