import { ThemeMultipartComponent } from '@types';

type ImageUploadSizes = 'sm' | 'md' | 'lg';
type ImageUploadVariants = 'default';

type ImageUploadParts = 'image-upload-label' | 'image-upload-control';

export const PTImageUpload: ThemeMultipartComponent<ImageUploadSizes, ImageUploadVariants, ImageUploadParts> =
  {
    parts: ['image-upload-label', 'image-upload-control'],

    variants: {
      default: {
        'image-upload-label': {
          color: 'pt-ui.500',
          fontWeight: 'pt-normal',
        },
        'image-upload-control': {
          width: '100%',
          outline: 'none',
          color: 'pt-ui.500',
          background: 'pt-white',
          border: 'pt-sm',
          borderColor: 'pt-ui.200',
          borderRadius: 'pt-xs',
          _focus: {
            outline: 'none',
          },
          _invalid: {
            borderColor: 'pt-danger.600',
          },
          _hover: {
            color: 'pt-ui.600',
            border: 'pt-sm',
            borderColor: 'pt-ui.400',
            borderRadius: 'pt-xs',
            background: 'pt-white',
          },
        },
      },
    },

    sizes: {
      sm: {
        'image-upload-label': {
          fontSize: 'pt-xs',
          marginBottom: 'pt-xs',
        },
        'image-upload-control': {
          fontSize: 'pt-sm',
          paddingX: 'pt-sm',
          paddingY: 'pt-xs',
        },
      },
      md: {
        'image-upload-label': {
          fontSize: 'pt-sm',
          marginBottom: 'pt-sm',
        },
        'image-upload-control': {
          fontSize: 'pt-md',
          paddingX: 'pt-md',
          paddingY: 'pt-sm',
        },
      },
      lg: {
        'image-upload-label': {
          fontSize: 'pt-md',
          marginBottom: 'pt-md',
        },
        'image-upload-control': {
          fontSize: 'pt-md',
          paddingX: 'pt-lg',
          paddingY: 'pt-sm',
        },
      },
    },

    defaultProps: {
      size: 'md',
      variant: 'default',
      colorScheme: null,
    },
  };
