import React, { FC } from 'react';

import { chakra, TextareaProps as ChakraTextareaProps, useStyleConfig } from '@chakra-ui/react';

// @ts-ignore
import ReactAutocompleteInput from 'react-autocomplete-input';

const ReactAutocompleteInputFactory = chakra(ReactAutocompleteInput);

export interface InterpolationInputProps extends Omit<ChakraTextareaProps, 'onChange'> {
  options: string[];
  onChange: (value: string) => void;
}

export const InterpolationInput: FC<InterpolationInputProps> = ({ options, ...props }) => {
  const styles = useStyleConfig('InterpolationInput', {});

  return (
    <ReactAutocompleteInputFactory
      {...props}
      sx={styles}
      regex={'^[a-zA-Z0-9_-_.]+$'}
      offsetX={5}
      offsetY={20}
      trigger={['$']}
      maxOptions={8}
      options={options}
    />
  );
};
