import React, { FC, useContext } from 'react';

import { ASTNode } from 'graphql';
import {
  chakra,
  Flex as ChakraFlex,
  FormControl as ChakraFormControl,
  Text as ChakraText,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { get, isEmpty, some } from 'lodash';

import { PropType } from '@types';

import { capitalize, showDatasetFormatted } from '@application/utils/stringManipulation';
import { CompilerContext } from '@application/compiler/contexts';

import { FactoryDataset } from '@introspection/factories/DatasetsOperationsFactory';

import { DatasetRelationInput, UserRelationInput } from './SpecialInputs';
import { DynamicCompilerInput } from './DynamicCompilerInput';

import { DynamicFormProps } from '..';
import { exampleChildrenDatasetFieldsReference, exampleChildrenDatasetFormFields } from '../mock';

interface Props {
  datasets: Record<string, FactoryDataset>;
  datasetName: string;
  parentDatasetName: string;
  attributeToCurrentUser?: boolean;
  operation: DynamicFormProps['operation'];
  datasetsFieldsReference: DynamicFormProps['datasetsFieldsReference'];
  updateId: string;
  executeApiOperation: (
    operation: ASTNode,
    operationName: string,
    variables: Record<string, unknown>,
  ) => Promise<unknown>;
}

export const ChildForm: FC<Props> = ({
  datasets,
  datasetName,
  parentDatasetName,
  datasetsFieldsReference,
  operation,
  executeApiOperation,
  attributeToCurrentUser,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { config } = useContext(CompilerContext);
  const {
    'dynamic-form-children-tab-panel-action': dynamicFormChildrenTabPanelActionStyles,
    'dynamic-form-children-tab-panel-record-card': dynamicFormChildrenTabPabelRecordCard,
  } = useMultiStyleConfig('PTDynamicForm', {});

  const { fields, append, remove } = useFieldArray({
    control,
    name: datasetName,
    keyName: 'fieldId',
  });

  const isThemeMode = config.mode === 'theme';

  const dataset = datasets[datasetName];
  const datasetReferenceFields = !isThemeMode && datasetsFieldsReference[datasetName];

  if (!dataset && !isThemeMode) return null;

  const datasetOperation = !isThemeMode && dataset.operations[operation];

  const formFields = isThemeMode ? exampleChildrenDatasetFormFields : datasetOperation.formFields;

  const isUserDataset =
    some(formFields, {
      name: 'id',
    }) && datasetName.endsWith('User');

  const newField = formFields.reduce((prev, curr) => {
    return {
      ...prev,
      [curr.name]: '',
    };
  }, {});

  return (
    <>
      {fields.map((item, index) => (
        <ChakraFlex flexDirection="column" key={item.fieldId} sx={dynamicFormChildrenTabPabelRecordCard}>
          {formFields.map((formField) => {
            const relationalField = formField.name.endsWith('RelationId');
            const relationalDatasetName = formField.name.replace('RelationId', '');

            const capitalizedRelationalDatasetName = capitalize(relationalDatasetName);

            const isParentRelation = capitalizedRelationalDatasetName.startsWith(parentDatasetName);
            const isIdField = formField.name === 'id';

            const required = formField.inputConfig.required && !isParentRelation && !isIdField;

            return (
              <>
                <Controller
                  control={control}
                  rules={{ required }}
                  key={`${datasetName}[${index}]${formField.name}`}
                  name={`${datasetName}[${index}].${formField.name}`}
                  defaultValue={(get(item, [formField.name]) || formField.defaultValue) ?? ''}
                  render={({ field: { onChange, value } }) => {
                    const isVisible =
                      typeof formField.inputConfig?.visible === 'boolean' && formField.inputConfig?.visible;

                    if (!attributeToCurrentUser && isUserDataset && operation === 'create') {
                      return <UserRelationInput value={value} name={formField.name} />;
                    }

                    if (!isVisible) {
                      return null;
                    }

                    const fieldError = get(errors, [datasetName, index, formField.name]);

                    if (relationalField) {
                      // Means that this field is a relation to the parentDataset
                      if (isParentRelation) return null;

                      const referenceField = datasetReferenceFields[relationalDatasetName];

                      return (
                        <ChakraFormControl isInvalid={fieldError}>
                          <DatasetRelationInput
                            value={value}
                            datasets={datasets}
                            datasetName={capitalizedRelationalDatasetName}
                            indexFieldName={referenceField?.indexFieldName}
                            executeApiOperation={executeApiOperation}
                            onChange={onChange}
                          />
                          {!isEmpty(fieldError) && (
                            <ChakraText fontSize="pt-sm" color="pt-danger.500" marginTop="pt-xs">
                              Required
                            </ChakraText>
                          )}
                        </ChakraFormControl>
                      );
                    }

                    const { controlType } = isThemeMode
                      ? exampleChildrenDatasetFieldsReference[formField.name]
                      : datasetReferenceFields[formField.name];

                    return (
                      <ChakraFormControl
                        id={`${datasetName}[${index}]${formField.name}`}
                        isInvalid={fieldError}
                      >
                        <DynamicCompilerInput
                          name={formField.name}
                          value={value}
                          label={showDatasetFormatted(formField.name)}
                          options={formField.options}
                          isInvalid={!isEmpty(fieldError)}
                          fieldType={
                            (controlType && (capitalize(controlType?.toLowerCase()) as PropType)) ||
                            (formField.type as PropType)
                          }
                        />
                        {!isEmpty(fieldError) && (
                          <ChakraText
                            color="pt-danger.500"
                            fontSize="pt-sm"
                            marginTop="pt-xs"
                            fontFamily="pt-body"
                          >
                            Required
                          </ChakraText>
                        )}
                      </ChakraFormControl>
                    );
                  }}
                />
              </>
            );
          })}
          <ChakraFlex justifyContent="flex-end">
            <chakra.p
              color="pt-danger.500"
              cursor="pointer"
              fontSize="pt-sm"
              fontFamily="pt-body"
              _hover={{ textDecoration: 'underline' }}
              onClick={() => remove(index)}
            >
              Remove record
            </chakra.p>
          </ChakraFlex>
        </ChakraFlex>
      ))}
      <ChakraFlex sx={dynamicFormChildrenTabPanelActionStyles} onClick={() => append(newField)}>
        + Add new register
      </ChakraFlex>
    </>
  );
};
