import { ComponentBlock } from '@types';

import { types } from '@application/core/types';

import { CheckboxProps, CheckboxTriggers } from './index';
import { component } from './component';

export const block: ComponentBlock<'checkbox', CheckboxProps, CheckboxTriggers> = {
  id: 'checkbox',
  render: component,
  props: {
    value: {
      type: types.boolean,
    },
    colorScheme: {
      type: types.string,
    },
    label: {
      type: types.string,
    },
  },
  triggers: {
    onChange: {
      name: 'On change',
      description: 'When the control is changed',
    },
  },
};
