import {
  ArrowBackIcon,
  ArrowDownIcon,
  ArrowForwardIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
  AtSignIcon,
  AttachmentIcon,
  BellIcon,
  CalendarIcon,
  ChatIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  CloseIcon,
  CopyIcon,
  DownloadIcon,
  DragHandleIcon,
  EditIcon,
  EmailIcon,
  ExternalLinkIcon,
  HamburgerIcon,
  InfoIcon,
  InfoOutlineIcon,
  LinkIcon,
  LockIcon,
  MinusIcon,
  MoonIcon,
  NotAllowedIcon,
  PhoneIcon,
  PlusSquareIcon,
  QuestionIcon,
  QuestionOutlineIcon,
  RepeatClockIcon,
  RepeatIcon,
  Search2Icon,
  SearchIcon,
  SettingsIcon,
  SmallAddIcon,
  SmallCloseIcon,
  SpinnerIcon,
  StarIcon,
  SunIcon,
  TimeIcon,
  TriangleDownIcon,
  TriangleUpIcon,
  UnlockIcon,
  UpDownIcon,
  ViewIcon,
  ViewOffIcon,
  WarningIcon,
  WarningTwoIcon,
} from '@chakra-ui/icons';
import { CoreIcons } from '@types';
import React, { ReactNode } from 'react';

export const coreIcons: Record<CoreIcons, ReactNode> = {
  hamburger: HamburgerIcon,
  copy: CopyIcon,
  search: SearchIcon,
  'search-2': Search2Icon,
  moon: MoonIcon,
  sun: SunIcon,
  'small-add': SmallAddIcon,
  settings: SettingsIcon,
  'check-circle': CheckCircleIcon,
  lock: LockIcon,
  unlock: UnlockIcon,
  view: ViewIcon,
  'view-off': ViewOffIcon,
  download: DownloadIcon,
  repeat: RepeatIcon,
  'repeat-clock': RepeatClockIcon,
  edit: EditIcon,
  'chevron-right': ChevronRightIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-down': ChevronDownIcon,
  'chevron-up': ChevronUpIcon,
  'arrow-forward': ArrowForwardIcon,
  'arrow-up': ArrowUpIcon,
  'arrow-down': ArrowDownIcon,
  'arrow-back': ArrowBackIcon,
  'external-link': ExternalLinkIcon,
  link: LinkIcon,
  'plus-square': PlusSquareIcon,
  chat: ChatIcon,
  calendar: CalendarIcon,
  time: TimeIcon,
  attachment: AttachmentIcon,
  'up-down': UpDownIcon,
  'at-sign': AtSignIcon,
  'arrow-left': ArrowLeftIcon,
  'arrow-right': ArrowRightIcon,
  star: StarIcon,
  email: EmailIcon,
  phone: PhoneIcon,
  spinner: SpinnerIcon,
  'drag-handle': DragHandleIcon,
  close: CloseIcon,
  'small-close': SmallCloseIcon,
  'not-allowed': NotAllowedIcon,
  'triangle-down': TriangleDownIcon,
  'triangle-up': TriangleUpIcon,
  bell: BellIcon,
  'info-outline': InfoOutlineIcon,
  info: InfoIcon,
  question: QuestionIcon,
  'question-outline': QuestionOutlineIcon,
  warning: WarningIcon,
  'warning-2': WarningTwoIcon,
  'arrow-up-down': ArrowUpDownIcon,
  check: CheckIcon,
  minus: MinusIcon,
};
