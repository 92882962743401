import React, { FC, useContext, useMemo } from 'react';

import { Flex as ChakraFlex } from '@chakra-ui/react';
import { useQuery } from 'react-query';

import { EPropType } from '@types';

import { CompilerContext } from '@application/compiler/contexts';
import { Loading } from '@application/components/ds';

import { ApiClient } from '@services';
import { DynamicCompilerInput } from '../DynamicCompilerInput';

interface UserRelationInputProps {
  name: string;
  value: string;
}

export const UserRelationInput: FC<UserRelationInputProps> = ({ value, name }) => {
  const { config } = useContext(CompilerContext);

  const clientUsersQuery = useQuery(['query', 'organization', config.organizationId, 'users'], async () => {
    const resp = await ApiClient.listOrganizationApplicationUsers(config.organizationId);

    return resp.data;
  });

  const options = useMemo(() => {
    if (clientUsersQuery.isLoading) return [];

    return clientUsersQuery.data?.map((user) => ({
      value: user.sub,
      label: `${user.name} (${user.email})`,
    }));
  }, [clientUsersQuery.data, clientUsersQuery.isLoading]);

  return (
    <ChakraFlex
      border="pt-sm"
      marginY="pt-sm"
      padding="pt-sm"
      alignItems="center"
      background="pt-ui.50"
      borderColor="pt-ui.200"
      borderRadius="pt-sm"
      justifyContent="space-between"
    >
      <Loading width="100%" minHeight="2.6rem" isLoading={clientUsersQuery.isLoading}>
        <DynamicCompilerInput
          label="User"
          name={name}
          value={value}
          isInvalid={false}
          options={options ?? []}
          fieldType={EPropType.Id}
        />
      </Loading>
    </ChakraFlex>
  );
};
