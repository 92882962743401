import React, { FC } from 'react';

import { chakra, Flex as ChakraFlex, useMultiStyleConfig } from '@chakra-ui/react';
import { ToolbarProps, View, Views } from 'react-big-calendar';
import moment from 'moment';

import { Icon } from '@application/components/ds';

export const CalendarToolbar: FC<ToolbarProps> = ({ view: currentView, views, date, onView, onNavigate }) => {
  const { 'calendar-toolbar-title': calendarToolbarTitleStyles } = useMultiStyleConfig('PTCalendar', {});

  return (
    <ChakraFlex width="100%" marginBottom="md" justifyContent="space-between">
      <ChakraFlex width="100%" sx={calendarToolbarTitleStyles}>
        {currentView === Views.MONTH
          ? moment(date).format('MMMM YYYY')
          : moment(date).format('DD, MMMM of YYYY')}
      </ChakraFlex>
      <ChakraFlex
        padding="xs"
        gridGap="xs"
        border="sm"
        borderColor="ui.200"
        background="ui.100"
        borderRadius="sm"
      >
        {(views as View[]).map((view) => (
          <>
            <ChakraFlex
              width="6rem"
              cursor="pointer"
              alignItems="center"
              fontFamily="heading"
              borderRadius="sm"
              justifyContent="center"
              color={view === currentView ? 'ui.900' : 'ui.700'}
              border={view === currentView && 'sm'}
              background={view === currentView && 'white'}
              borderColor={view === currentView && 'ui.200'}
              transitionDuration="0.3s"
              _hover={{
                color: 'ui.900',
                background: view !== currentView && 'ui.200',
              }}
              onClick={() => onView(view)}
            >
              <chakra.p textTransform="capitalize">{view}</chakra.p>
            </ChakraFlex>
          </>
        ))}
      </ChakraFlex>
      <ChakraFlex width="100%" gridGap="sm" justifyContent="flex-end" alignItems="center">
        <ChakraFlex
          color="ui.900"
          border="sm"
          cursor="pointer"
          padding="sm"
          background="white"
          borderColor="ui.200"
          alignItems="center"
          borderRadius="sm"
          justifyContent="center"
          transitionDuration="0.3s"
          onClick={() => onNavigate('PREV')}
          _hover={{
            background: 'ui.100',
          }}
        >
          <Icon name="chevron-left" />
        </ChakraFlex>
        <ChakraFlex
          color="ui.900"
          width="6rem"
          border="sm"
          cursor="pointer"
          padding="sm"
          background="white"
          borderColor="ui.200"
          alignItems="center"
          borderRadius="sm"
          justifyContent="center"
          transitionDuration="0.3s"
          onClick={() => onNavigate('TODAY')}
          _hover={{
            background: 'ui.100',
          }}
        >
          <chakra.p fontFamily="heading" fontWeight="bold">
            Today
          </chakra.p>
        </ChakraFlex>
        <ChakraFlex
          color="ui.900"
          border="sm"
          cursor="pointer"
          padding="sm"
          background="white"
          alignItems="center"
          borderColor="ui.200"
          borderRadius="sm"
          justifyContent="center"
          transitionDuration="0.3s"
          onClick={() => onNavigate('NEXT')}
          _hover={{
            background: 'ui.100',
          }}
        >
          <Icon name="chevron-right" />
        </ChakraFlex>
      </ChakraFlex>
    </ChakraFlex>
  );
};
