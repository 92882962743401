import React, { useEffect, useState } from 'react';

import * as Sentry from '@sentry/nextjs';
import { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { Hydrate as ReactQueryHydrate } from 'react-query/hydration';
import { Provider as ReduxProvider } from 'react-redux';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';

import 'leaflet/dist/leaflet.css';
import 'nprogress/nprogress.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-gallery/styles/css/image-gallery.css';

import { store } from '@application/state/store';

// Setting react-query
setLogger({
  // Log is not importing for sentry
  log: (message) => {
    console.log(message);
    Sentry.captureMessage(message);
  },
  // Warn is not importing for sentry
  warn: (message) => {
    console.warn(message);
    Sentry.captureMessage(`warning: ${message}`);
  },
  error: (error) => {
    console.error(error);
    Sentry.captureException(error);
  },
});

NProgress.configure({ showSpinner: false, trickleSpeed: 300 });

export default function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient());

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', () => NProgress.start());
    router.events.on('routeChangeError', () => NProgress.done());
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', () => NProgress.start());
      router.events.off('routeChangeError', () => NProgress.done());
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryHydrate state={pageProps.dehydratedState}>
          <Component {...pageProps} />
        </ReactQueryHydrate>
      </QueryClientProvider>
    </ReduxProvider>
  );
}
