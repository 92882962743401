import { Menu as ChakraMenu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

interface Props {
  minWidth: string;
  menuItems: { label: string; path: string }[];
}

export const Menu: FC<Props> = ({ children, minWidth, menuItems, ...props }) => {
  const { push } = useRouter();

  return (
    <ChakraMenu {...props}>
      <MenuButton _focus={{ outline: 'none' }}>{children}</MenuButton>
      <MenuList minWidth={minWidth}>
        {menuItems.map((item, idx) => (
          <MenuItem key={idx} onClick={() => push(item.path)}>
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </ChakraMenu>
  );
};
