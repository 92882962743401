import { Optional } from 'utility-types';

import { AWSConnection, AWSInput, Theme } from '@types';

import { queryAdmin } from '../adminApi';

import { CreateTheme, DeleteTheme, UpdateTheme } from './mutations';
import { GetTheme, ListThemes } from './queries';

// Queries
export const getTheme = (
  variables: Pick<Theme, 'organizationId' | 'id'>,
  token: string,
): Promise<{ getTheme: Theme }> => {
  return queryAdmin(GetTheme, variables)(token);
};

export const listThemes = (
  variables: { organizationId: string },
  token: string,
): Promise<{ listThemes: AWSConnection<Theme> }> => {
  return queryAdmin(ListThemes, variables)(token);
};

// Mutations
type CreateThemeInput = Pick<Theme, 'organizationId' | 'name' | 'theme' | 'customFonts' | 'customIcons'>;
export const createTheme = (
  variables: AWSInput<CreateThemeInput>,
  token: string,
): Promise<{ createTheme: Theme }> => {
  return queryAdmin(CreateTheme, variables)(token);
};

type RequiredUpdateThemeInput = Pick<Theme, 'id' | 'organizationId'>;
type OptionalUpdateThemeInput = Optional<Pick<Theme, 'name' | 'theme' | 'customFonts' | 'customIcons'>>;
// Mutations

type UpdateThemeInput = RequiredUpdateThemeInput & OptionalUpdateThemeInput;

export const updateTheme = (
  variables: AWSInput<UpdateThemeInput>,
  token: string,
): Promise<{ updateTheme: Theme }> => {
  return queryAdmin(UpdateTheme, variables)(token);
};

type DeleteThemeInput = Pick<Theme, 'id' | 'organizationId'>;
export const deleteTheme = (
  variables: AWSInput<DeleteThemeInput>,
  token: string,
): Promise<{ deleteTheme: Pick<Theme, 'id' | 'organizationId' | 'name'> }> => {
  return queryAdmin(DeleteTheme, variables)(token);
};
