import { filter } from 'lodash';
import { v4 as uuidV4 } from 'uuid';

import { DataComponentField, InputFields, Introspection } from '@types';

import { BaseIntrospectionFactory } from './BaseIntrospectionFactory';

/**
 * This factory it's used to create our typpings from the schema
 */
export class OperationTyppingsFactory extends BaseIntrospectionFactory {
  /**
   * Used to map the operation inputFields
   */
  mountOperationInputArguments(operation: Introspection.IntrospectionType): InputFields[] {
    return operation.args.map((arg) => this.mountOperationInputArg(arg));
  }

  /**
   * Used to map the operation returnable fields
   */
  mountOperationReturnableFields(operation: Introspection.IntrospectionType): DataComponentField[] {
    return [this.mountOperationReturnableField(operation, operation.name)];
  }

  mountOperationReturnableField(
    field: Introspection.IntrospectionType,
    prevPath: string,
  ): DataComponentField {
    const fieldType = this.findFieldType(field);

    const nullable = field.type?.kind !== 'NON_NULL';
    const type = this.getTypeTypping(fieldType);

    const properties: DataComponentField[] = [];

    const currentFieldIsNestedDataset = this.typeIsNestedDataset(field.name);

    if (field.type.kind === 'LIST') {
      const mountedFields = fieldType.fields.map((field) =>
        this.mountOperationReturnableField(field, field.name),
      );
      properties.push(...mountedFields);

      return {
        id: uuidV4(),
        name: field.name,
        nullable,
        properties,
        type: this.getTypeTypping(field.type),
        path: prevPath,
      };
    }

    if (fieldType.kind === 'OBJECT') {
      // We only allow 1 level of nested
      const notNestedFields = filter(
        fieldType.fields,
        (field) => !(currentFieldIsNestedDataset && this.typeIsNestedDataset(field.name)),
      );

      const mountedFields = notNestedFields?.map((field) =>
        this.mountOperationReturnableField(field, `${prevPath}.${field.name}`),
      );
      properties.push(...mountedFields);
    }

    if (fieldType.kind === 'ENUM') {
      const options = this.mountEnumOptions(fieldType.name);

      // Todo -> Create a function to create the DataComponentField
      return {
        id: uuidV4(),
        name: field.name,
        path: prevPath, // TODO
        properties,
        options,
        nullable,
        type,
      };
    }

    return {
      id: uuidV4(),
      name: field.name,
      path: prevPath, // TODO
      properties,
      nullable,
      type,
    };
  }

  /**
   * It mounts a singular typed input argument
   */
  private mountOperationInputArg(arg: Introspection.IntrospectionArgumentsProps): InputFields {
    const argType = this.findArgType(arg);

    const required = arg.type?.kind === 'NON_NULL';
    const type = this.getTypeTypping(argType);

    const fields: InputFields[] = [];

    if (argType.kind === 'INPUT_OBJECT') {
      fields.push(...this.mountInputObjectFields(argType.name));
    }

    return {
      name: arg.name,
      required,

      fields,
      type,
    };
  }

  /**
   * Handles a inputObject behaviour and returns its nested fields types mounted
   */
  private mountInputObjectFields(typeName: string): InputFields[] {
    const inputType = this.findTypeByName(typeName);

    return inputType.inputFields.map((inputField) => this.mountInputFieldType(inputField));
  }

  /**
   * It mounts a singular typed inputField from a inputObject
   */
  private mountInputFieldType(inputField: Introspection.IntrospectionType): InputFields {
    const required = inputField.type?.kind === 'NON_NULL';

    const fields: InputFields[] = [];

    if (inputField.type.kind === 'LIST') {
      fields.push(this.mountType(inputField.type.ofType));

      return {
        name: inputField.name,
        required,

        fields,
        type: this.getTypeTypping(inputField.type),
      };
    }

    const inputFieldType = this.findInputObjectFieldType(inputField);
    const type = this.getTypeTypping(inputFieldType);

    const options: InputFields['options'] = [];

    if (inputFieldType?.kind === 'ENUM') {
      options.push(...this.mountEnumOptions(inputFieldType.name));
    }

    if (inputFieldType?.kind === 'INPUT_OBJECT') {
      fields.push(...this.mountInputObjectFields(inputFieldType.name));
    }

    return {
      name: inputField.name,
      required,
      options,

      fields,
      type,
    };
  }

  /**
   * It mounts a singular typed graphqlType
   * It's recomended to use as a helper from a list | object graphqlType
   */
  private mountType(type: Introspection.IntrospectionType): InputFields {
    const required = type?.kind === 'NON_NULL';

    const fields: InputFields[] = [];

    if (type.kind === 'INPUT_OBJECT') {
      fields.push(...this.mountInputObjectFields(type.name));
    }

    return {
      name: type.name,
      required,

      fields,
      type: this.getTypeTypping(type),
    };
  }
}
