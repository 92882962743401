import { v4 as uuidV4 } from 'uuid';

import { ProtocolFactory } from 'lib/protocols';

import { EPropType } from '@types';

import { DateInputProps } from './index';

export const protocolFactory = new ProtocolFactory<DateInputProps>({
  name: 'DateInput',
  editorProps: [
    {
      type: EPropType.String,
      name: 'name',
      required: false,
      label: 'Name',
      description: 'Name of the input',
      defaultValue: uuidV4(),
    },
    {
      type: EPropType.String,
      name: 'label',
      required: false,
      label: 'Label',
      description: 'The label of the input inside of the form control',
      defaultValue: 'Example',
    },
    {
      type: EPropType.String,
      name: 'placeholder',
      required: false,
      label: 'Placeholder',
      description: 'Text inside the input if no value is provided',
      defaultValue: 'Insert some text here',
    },
  ],
  themeProps: ['size'],
});

export const defaultProps = protocolFactory.createDefaultProps();
