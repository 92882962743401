import React, { FC, useMemo } from 'react';

import { Flex as ChakraFlex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { Button, Heading, Input, Select, Text, Textarea } from '@application/components/ds';
import { showDatasetFormatted } from '@application/utils/stringManipulation';

import { Card } from '../';

interface BoardCardModalProps {
  card: Card;
  tagOptions: unknown[];
  isEditMode: boolean;
  onConfirm: (card: Card) => void;
  onClose: () => void;
}

export const BoardCardModal: FC<BoardCardModalProps> = ({
  card,
  tagOptions,
  isEditMode,
  onClose,
  onConfirm,
}) => {
  const { register, watch } = useForm({ defaultValues: card });

  const values = watch();

  const isFormValid = useMemo(() => values.tag && values.title && values.description, [values]);

  return (
    <ChakraFlex
      top="0"
      left="0"
      width="100%"
      height="100%"
      padding="md"
      overflow="auto"
      position="absolute"
      alignItems="center"
      justifyContent="center"
      background="rgba(0,0,0,0.5)"
    >
      <ChakraFlex
        width="100%"
        border="sm"
        maxWidth="30rem"
        boxShadow="large"
        borderColor="ui.300"
        background="white"
        borderRadius="sm"
        flexDirection="column"
      >
        <ChakraFlex padding="md" flexDirection="column" gridGap="sm">
          <ChakraFlex flexDirection="column">
            <Heading color="ui.900">{isEditMode ? 'Update' : 'Insert'} card</Heading>
            <Text color="ui.700" variant="body-2">
              Fill the following fields to {isEditMode ? 'update the card' : 'insert a new card'}
            </Text>
          </ChakraFlex>
          <ChakraFlex gridGap="sm" flexDirection="column">
            <ChakraFlex width="100%" gridGap="sm">
              <ChakraFlex width="50%" gridGap="xs" flexDirection="column">
                <Text variant="body-2" color="ui.700">
                  Title
                </Text>
                <Input width="100%" placeholder="Insert card title..." {...register('title')} />
              </ChakraFlex>
              <ChakraFlex width="50%" gridGap="xs" flexDirection="column">
                <Text variant="body-2" color="ui.700">
                  Tag
                </Text>
                <Select placeholder="Insert card tag" {...register('tag')}>
                  {tagOptions?.map((option, idx) => (
                    <option value={String(option)} key={idx}>
                      {showDatasetFormatted(String(option))}
                    </option>
                  ))}
                </Select>
              </ChakraFlex>
            </ChakraFlex>
            <ChakraFlex gridGap="xs" flexDirection="column">
              <Text variant="body-2" color="ui.700">
                Description
              </Text>
              <Textarea placeholder="Insert card description..." {...register('description')} />
            </ChakraFlex>
          </ChakraFlex>
        </ChakraFlex>
        <ChakraFlex
          gridGap="sm"
          padding="md"
          borderTop="sm"
          borderTopColor="ui.300"
          justifyContent="flex-end"
        >
          <Button variant="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button isDisabled={!isFormValid} onClick={() => onConfirm(values)}>
            Confirm
          </Button>
        </ChakraFlex>
      </ChakraFlex>
    </ChakraFlex>
  );
};
