import { darken as polishedDarken, lighten as polishedLighten } from 'polished';
import _isNumber from 'lodash/isNumber';

import { ColorsBoxProps } from './ColorSection/ThemeColorsBox';

export type Colors = ColorsBoxProps['colors'];

export type KeyOfColors = keyof Colors;

export const generateShadeColors = (color: string): Colors => {
  const lighten = (percent = 1) => polishedLighten(percent, color);
  const darken = (percent = 1) => polishedDarken(percent, color);

  return {
    50: lighten(0.45),
    100: lighten(0.35),
    200: lighten(0.25),
    300: lighten(0.15),
    400: lighten(0.05),
    500: color,
    600: darken(0.05),
    700: darken(0.15),
    800: darken(0.25),
    900: darken(0.35),
  };
};

export const convertStringToNumber = (value: string | number): number =>
  _isNumber(value) ? value : parseInt(value, 10);

export const isNotMainColor = (colorKey: string): boolean => convertStringToNumber(colorKey) !== 500;

export const shadesSortByAsc = (first: string, second: string): number =>
  convertStringToNumber(first) - convertStringToNumber(second);
