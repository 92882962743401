import { ComponentBlock } from '@types';

import { types } from 'core/types';

import { component } from './component';
import { GridItemProps } from '.';

export const block: ComponentBlock<'gridItem', GridItemProps, unknown> = {
  id: 'gridItem',
  render: component,
  props: {
    colStart: {
      type: types.number,
    },
    colEnd: {
      type: types.number,
    },
    rowStart: {
      type: types.number,
    },
    rowEnd: {
      type: types.number,
    },
  },
};
