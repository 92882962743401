import { Introspection } from '@types';

import {
  BaseIntrospectionFactory,
  DatasetsOperationsFactory,
  FactoryDataset,
  OperationFactory,
  OperationTyppingsFactory,
} from '@introspection/factories';

export class IntrospectionFactory extends BaseIntrospectionFactory {
  private datasetsOperationsFactory: DatasetsOperationsFactory;
  private operationFactory: OperationFactory;
  private operationTyppingsFactory: OperationTyppingsFactory;

  constructor(introspectionTypes: Introspection.IntrospectionType[]) {
    super(introspectionTypes);

    this.operationFactory = new OperationFactory(introspectionTypes);
    this.operationTyppingsFactory = new OperationTyppingsFactory(introspectionTypes);
    this.datasetsOperationsFactory = new DatasetsOperationsFactory(introspectionTypes);
  }

  get operations(): Introspection.IOperation[] {
    return [...this.queryOperations, ...this.mutationOperations];
  }

  get queryOperations(): Introspection.IOperation[] {
    return this.queryType?.fields?.map((operation) => this.mountOperation(operation, 'query'));
  }

  get mutationOperations(): Introspection.IOperation[] {
    return this.mutationType?.fields?.map((operation) => this.mountOperation(operation, 'mutation'));
  }

  get datasets(): Record<string, FactoryDataset> {
    return this.datasetsOperationsFactory.getDatasets();
  }

  mountOperation(
    operation: Introspection.IntrospectionType,
    operationType: 'query' | 'mutation',
  ): Introspection.IOperation {
    const [operationPrefix] = Introspection.operationsPrefixList.filter((prefix) =>
      operation.name.includes(prefix),
    );

    const dataset = operation.name.replace(operationPrefix, '');

    return {
      name: operation.name,
      operation: this.operationFactory.mountOperation(operation, operationType),

      operationType,
      dataset,
      type: operationPrefix,

      inputArguments: this.operationTyppingsFactory.mountOperationInputArguments(operation),
      fields: this.operationTyppingsFactory.mountOperationReturnableFields(operation),
    };
  }
}
