export enum GstudioOrganizationRoles {
  Owner = 'owner',
  Editor = 'editor',
  Viewer = 'viewer',
}

export enum ApplicationRoles {
  Admin = 'admin',
  Manager = 'manager',
  User = 'user',
}
