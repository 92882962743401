import React, { FC } from 'react';

import { Flex as ChakraFlex, useStyleConfig } from '@chakra-ui/react';
import type { Props as RichMarkdownEditorProps } from 'rich-markdown-editor';

import { RichTextEditor as CommonRichTextEditor } from 'components/common';

export interface RichTextEditorProps
  extends Omit<
    Partial<RichMarkdownEditorProps>,
    | 'embeds'
    | 'tooltip'
    | 'extensions'
    | 'placeholder'
    | 'defaultValue'
    | 'onFocus'
    | 'onChange'
    | 'onClickLink'
  > {
  size?: string;
  variant?: string;
  isInvalid?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

export const RichTextEditor: FC<RichTextEditorProps> = ({ size, variant, readOnly, isInvalid, ...props }) => {
  const styles = useStyleConfig('RichTextEditor', { size, variant });

  return (
    <ChakraFlex sx={styles} aria-invalid={isInvalid} aria-disabled={readOnly}>
      <CommonRichTextEditor readOnly={readOnly} {...props} />
    </ChakraFlex>
  );
};
