import React, { FC, useRef } from 'react';

import { Box } from '@chakra-ui/react';

import { Button, ButtonProps, useToast } from 'components/ds';

interface Props extends ButtonProps {
  onChange: any;
}

export const ImageButtonUpload: FC<Props> = ({ children, onChange, ...rest }) => {
  const toast = useToast();
  const ref = useRef(null);

  const handleFileInput = (selectedFile: File) => {
    if (!selectedFile) {
      toast({
        title: 'Something going wrong!',
        description: 'An error has ocourred, try again please',
        status: 'danger',
      });
    }

    onChange(selectedFile);
  };

  return (
    <Box>
      <Button size="md" onClick={() => ref?.current.click()} {...rest}>
        {children}
      </Button>
      <Box display="none">
        <input
          type="file"
          accept="image/*"
          ref={ref}
          onChange={(event) => handleFileInput(event.target.files[0])}
        />
      </Box>
    </Box>
  );
};
