/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { colors } from '../foundations';
import { getColorSchemeOrDefault } from '../utils/variants';
import { ThemeMultipartComponent } from '@types';

export type MenuSelectSizes = 'sm' | 'md' | 'lg' | string;
export type MenuSelectVariants = 'default' | 'with-icon';

type MenuSelectParts = 'menu-button' | 'menu-icon-button' | 'menu-list' | 'menu-item';

export const MenuSelect: ThemeMultipartComponent<MenuSelectSizes, MenuSelectVariants, MenuSelectParts> = {
  parts: ['menu-button', 'menu-item', 'menu-list'],

  baseStyle: () => ({
    'menu-list': {
      minWidth: '10rem',
      borderRadius: 'xs',
    },
    'menu-item': {
      height: '1.875rem',
      fontSize: 'sm',
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflowX: 'hidden',
    },
  }),

  sizes: {
    sm: () => ({
      'menu-button': {
        fontSize: 'sm',
        lineHeight: 'sm',
      },
      'menu-item': {
        fontSize: 'sm',
        lineHeight: 'sm',
      },
    }),

    md: () => ({
      'menu-button': {
        fontSize: 'md',
        lineHeight: 'md',
      },
      'menu-item': {
        fontSize: 'md',
        lineHeight: 'md',
      },
    }),

    lg: () => ({
      'menu-button': {
        fontSize: 'lg',
        lineHeight: 'lg',
      },
      'menu-item': {
        fontSize: 'lg',
        lineHeight: 'lg',
      },
    }),
  },

  variants: {
    default: ({ colorScheme }) => {
      const colorSchemeDefault = getColorSchemeOrDefault<keyof typeof colors>({
        colorScheme: colorScheme as keyof typeof colors,
        colorSchemeDefault: 'ui',
      });

      return {
        'menu-button': {
          minWidth: '10rem',
          height: '2.5rem',
          border: 'sm',
          backgroundColor: 'white',
          borderColor: `${colorSchemeDefault}.200`,
          color: `${colorSchemeDefault}.700`,
          _hover: {
            backgroundColor: `${colorSchemeDefault}.100`,
          },
          _focus: {
            boxShadow: `0px 0px 0px 3px ${colors[colorSchemeDefault]?.[100]}`,
          },
          _disabled: {
            color: `${colorSchemeDefault}.500`,
            cursor: 'not-allowed',
            _hover: {
              backgroundColor: `${colorSchemeDefault}.50`,
            },
          },
        },
        'menu-icon-button': {
          height: '2.5rem',
          border: 'sm',
          borderRadius: 'sm',
          borderColor: `${colorSchemeDefault}.200`,
          backgroundColor: 'white',
          color: `${colorSchemeDefault}.700`,
          _hover: {
            backgroundColor: `${colorSchemeDefault}.100`,
          },
          _focus: {
            boxShadow: `0px 0px 0px 3px ${colors[colorSchemeDefault]?.[100]}`,
          },
          _disabled: {
            color: `${colorSchemeDefault}.500`,
            cursor: 'not-allowed',
            _hover: {
              backgroundColor: `${colorSchemeDefault}.50`,
            },
          },
        },
        'menu-list': {
          pt: 'sm',
          boxShadow: 'unset',
          border: 'xs',
          zIndex: '100',
          borderColor: `${colorSchemeDefault}.200`,
        },
        'menu-item': {
          color: `${colorSchemeDefault}.700`,
          _hover: {
            backgroundColor: `${colorSchemeDefault}.100`,
          },
          _focus: {
            backgroundColor: `${colorSchemeDefault}.100`,
          },
        },
      };
    },
    'with-icon': ({ colorScheme }) => {
      const colorSchemeDefault = getColorSchemeOrDefault<keyof typeof colors>({
        colorScheme: colorScheme as keyof typeof colors,
        colorSchemeDefault: 'ui',
      });

      return {
        'menu-icon-button': {
          bg: 'unset',
          height: 'fit-content',
          minWidth: 'unset',
          minHeight: 'unset',
          padding: 'unset',
          paddingY: 'xs',
          borderRadius: 'xs',
          color: `${colorSchemeDefault}.900`,
          _focus: {
            boxShadow: `0px 0px 0px 3px ${colors[colorSchemeDefault]?.[200]}`,
          },
          _hover: {
            color: `${colorSchemeDefault}.900`,
            bg: `${colorSchemeDefault}.100`,
          },
        },
        'menu-item': {
          color: `${colorSchemeDefault}.700`,
          _hover: {
            bg: `${colorSchemeDefault}.100`,
          },
          _focus: {
            bg: `${colorSchemeDefault}.100`,
          },
        },
        'menu-list': {
          pt: 'sm',
          boxShadow: 'unset',
          border: 'xs',
          borderColor: `${colorSchemeDefault}.100`,
        },
      };
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: null,
  },
};
