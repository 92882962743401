import { ProtocolFactory } from '@application/lib/protocols';

import { EPropType } from '@types';

import { ButtonProps } from './index';

export const protocolFactory = new ProtocolFactory<ButtonProps>({
  name: 'Button',
  editorProps: [
    {
      name: 'label',
      type: [EPropType.String, EPropType.Enum, EPropType.Int, EPropType.Float],
      required: true,
      label: 'Label',
      description: 'Text inside the button',
      defaultValue: 'Foo',
      allowDataLink: true,
    },
    {
      name: 'isDisabled',
      type: EPropType.Boolean,
      required: false,
      label: 'Disabled',
      description: 'Define if the button is disabled',
      defaultValue: false,
      allowDataLink: true,
    },
  ],
  editorConfig: {
    disableDataLinkSection: false,
    disableDesignSection: false,
    disableLogicSection: false,
  },
  themeProps: ['variant', 'colorScheme', 'size', 'leftIcon', 'rightIcon'],
  themeConfig: {
    allowColorScheme: true,
    sizes: ['sm', 'md', 'lg'],
    defaultProps: ['size', 'variant', 'colorScheme'],
    defaultConfig: {
      sizeProps: ['lineHeight', 'fontSize', 'paddingHorizontal', 'paddingVertical'],
      variantProps: ['background', 'color', 'border', 'borderColor', 'borderRadius'],
      pseudos: ['_hover', '_focus', '_disabled'],
      pseudoProps: ['boxShadow', 'background', 'border', 'borderColor', 'color'],
    },
  },
});

export const defaultProps = protocolFactory.createDefaultProps();
