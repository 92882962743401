import { ComponentBlock } from '@types';
import { types } from '@application/core/types';

import { component } from './component';
import { IconProps } from './index';

export const block: ComponentBlock<'icon', IconProps, any> = {
  id: 'icon',
  render: component,
  props: {
    icon: {
      type: types.string,
    },
    size: {
      type: types.string,
    },
  },
};
