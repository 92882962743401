import { Box, BoxProps, Flex, useDisclosure, useToast } from '@chakra-ui/react';
import React, { FC } from 'react';

import { Button, Icon, Text } from 'components/common';
import { IconName } from '@types';

interface Props extends BoxProps {
  title: string;
  description: string;
  icon: IconName;
  bg: string;
  borderColor: string;
  iconColor: string;
}

export const Notification: FC<Props> = ({
  children,
  title,
  description,
  icon,
  bg,
  borderColor,
  iconColor,
  ...props
}) => {
  const toast = useToast();
  const { onClose } = useDisclosure({ defaultIsOpen: true });

  return (
    <Button
      onClick={() =>
        toast({
          position: 'bottom-left',
          render: () => (
            <Flex
              margin={2}
              borderWidth="sm"
              paddingY={3}
              paddingLeft={3}
              width="368px"
              borderRadius="sm"
              justifyContent="space-between"
              alignItems="center"
              bg={bg}
              borderColor={borderColor}
              boxShadow="sm"
              {...props}
            >
              {children}

              <Flex flexDir="row">
                <Flex marginRight={3}>
                  <Icon name={icon} w={18} h={18} color={iconColor} />
                </Flex>

                <Box>
                  <Text color="ui.900" fontSize="md" fontWeight="500">
                    {title}
                  </Text>

                  <Text color="ui.700" fontSize="sm" marginTop={2}>
                    {description}{' '}
                  </Text>
                </Box>
              </Flex>

              <Button
                variant="unstyled"
                width="15px"
                size="sm"
                marginRight={3}
                _focus={{ outline: 'none' }}
                onClick={onClose}
              >
                <Icon name="close" w={18} h={18} color="ui.500" />
              </Button>
            </Flex>
          ),
        })
      }
    >
      Show Toast
    </Button>
  );
};
