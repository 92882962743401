import { EPropType } from '@types';
import { ProtocolFactory } from 'lib/protocols';

import { GridItemProps } from '.';

export const protocolFactory = new ProtocolFactory<GridItemProps>({
  name: 'GridItem',
  editorProps: [
    {
      name: 'colStart',
      label: 'Column start',
      description: 'The initial column for the item',
      type: EPropType.Int,
      defaultValue: 0,
      required: false,
    },
    {
      name: 'colEnd',
      label: 'Column end',
      description: 'The final column for the item',
      type: EPropType.Int,
      defaultValue: 1,
      required: false,
    },
    {
      name: 'rowStart',
      label: 'Row start',
      description: 'The initial row for the item',
      type: EPropType.Int,
      defaultValue: 0,
      required: false,
    },
    {
      name: 'rowEnd',
      label: 'Row end',
      description: 'The final row for the item',
      type: EPropType.Int,
      defaultValue: 1,
      required: false,
    },
  ],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: true,
    disableLogicSection: true,
  },
  themeProps: [
    'width',
    'height',
    'paddingHorizontal',
    'paddingVertical',
    'marginHorizontal',
    'marginVertical',
    'alignItems',
    'justifyContent',
    'borderRadius',
    'background',
  ],
});

export const defaultProps = protocolFactory.createDefaultProps();
