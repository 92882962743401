import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface TextareaProps {
  name: string;
  label: string;
  value: string;
  resize: 'vertical' | 'horizontal' | 'none';
  placeholder: string;
  size: 'sm' | 'md' | 'lg';
}

export interface TextareaTriggers {
  onBlur: (value: string) => void;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<TextareaProps>;
