import { ButtonProps, Button as ChakraButton, useStyleConfig } from '@chakra-ui/react';
import React, { FC } from 'react';

import { ButtonSizes, ButtonVariants } from '@application/theme';

export interface Props extends ButtonProps {
  size?: ButtonSizes;
  variant?: ButtonVariants | ButtonProps['variant'];
}

export const Button: FC<Props> = ({ size, variant, children, ...props }) => {
  const styles = useStyleConfig('Button', { size, variant });

  return (
    <ChakraButton sx={styles} {...props}>
      {children}
    </ChakraButton>
  );
};
