import { cloneDeep, get, mapValues, set } from 'lodash';
import { extendTheme } from '@chakra-ui/react';

import { defaultTheme } from '@application/lib/defaultTheme';
import { mapValuesDeep } from '@application/lib/helpers';
import { ThemeBlock } from '@application/components/Theme/model/theme-block';
import { ThemeOverride } from '@types';
import gStudioTheme from '@application/theme';

export { NextFontLoader } from './NextFontLoader';

export const customTheme = (themeBlock: ThemeBlock | undefined): ThemeOverride | null => {
  const customTheme = cloneDeep(themeBlock?.theme ?? defaultTheme);

  const { components } = customTheme;
  const newComponents = cloneDeep(components);

  const replaceDynamicThemeVariable = (str = '', args: Record<string, string>) => {
    const reg = /\{{(.*?)\}}/g;
    const foundVariables = str?.match(reg);

    if (!foundVariables) return str;

    for (const variable of foundVariables) {
      const variableAttr = variable.replace(/[{|}]/g, '');
      const dynamicData = get(args, variableAttr);

      str = str.replace(variable, dynamicData);
    }

    return str;
  };

  mapValues(components, (componentConfig, componentName) => {
    mapValues(componentConfig?.variants, (variantConfig: Record<string, unknown>, variantName) => {
      set(newComponents, [componentName, 'variants', variantName], (args: Record<string, string>) =>
        mapValuesDeep(variantConfig, (propValue) => replaceDynamicThemeVariable(propValue, args)),
      );
    });
  });

  // @ts-ignore
  return extendTheme(gStudioTheme, defaultTheme, {
    ...customTheme,
    components: {
      ...customTheme.components,
      ...newComponents,
    },
  });
};
