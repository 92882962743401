import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { ASTNode } from 'graphql';
import {
  Calendar,
  momentLocalizer,
  CalendarProps as ReactCalendarProps,
  stringOrDate,
  Views,
} from 'react-big-calendar';
import { chakra, useMultiStyleConfig } from '@chakra-ui/react';
import { cloneDeep, findIndex, uniqBy } from 'lodash';
import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql/language/printer';
import { useMutation } from 'react-query';
import { v4 as uuidV4 } from 'uuid';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import { CompilerContext, EngineContext } from '@application/compiler/contexts';
import { useIntrospectionFactory } from '@application/lib/customHooks/useIntrospection';
import { useToast } from '@application/components/ds';

import { ComponentReceivedProps } from '@types';

import { AvailableOperations, handleOperationSubmit } from './operations';
import { CalendarProps } from './index';
import { defaultProps } from './protocol';

import { CalendarCSSReset } from './components/CalendarCSSReset';
import { CalendarMonthEvent } from './components/CalendarMonthEvent';
import { CalendarMonthHeader } from './components/CalendarMonthHeader';
import { CalendarToolbar } from './components/CalendarToolbar';
import { CalendarWeekEvent } from './components/CalendarWeekEvent';
import { CalendarWeekHeader } from './components/CalendarWeekHeader';

export interface Event {
  id?: string;
  title?: string;
  start?: Date;
  end?: Date;
  allDay?: boolean;
}

const ReactBigCalendar = chakra(
  withDragAndDrop<Event>(Calendar as React.ComponentType<ReactCalendarProps<Event>>),
);

const exampleData = [
  {
    id: uuidV4(),
    title: 'Event 1',
    start: moment().toDate(),
    end: moment().add(1, 'hour').toDate(),
    allDay: false,
  },
  {
    id: uuidV4(),
    title: 'Event 2',
    start: moment().add(1, 'day').toDate(),
    end: moment().add(2, 'hour').toDate(),
    allDay: false,
  },
  {
    id: uuidV4(),
    title: 'Event 3',
    start: moment().subtract(1, 'day').toDate(),
    end: moment().add(3, 'hour').toDate(),
    allDay: true,
  },
];

export const component: FC<ComponentReceivedProps<CalendarProps, unknown>> = ({
  props = defaultProps,
  inheritedData,
}) => {
  const toast = useToast();

  const { engine } = useContext(EngineContext);
  const { config } = useContext(CompilerContext);

  const { endpoint } = config.api;
  const { user } = inheritedData;
  const {
    calendarData,
    calendarEventTitleProperty,
    calendarEventStartProperty,
    calendarEventEndProperty,
    calendarEventAllDayProperty,
    calendarDataCollection,
    fieldsRelations,
    width = '100%',
    height = '100%',
    ...rest
  } = props;

  const [introspectionFactory] = useIntrospectionFactory(endpoint, user?.token);

  const [formattedEvents, setFormattedEvents] = useState<Event[]>([]);

  const isThemeMode = config.mode === 'theme';

  const getMomentLocalizer = momentLocalizer(moment);
  const getFormattedEvents = () => {
    if (
      calendarData?.length > 0 &&
      calendarEventTitleProperty &&
      calendarEventStartProperty &&
      calendarEventEndProperty &&
      calendarEventAllDayProperty
    ) {
      const newData = calendarData?.map((event) => {
        if (
          moment(event[calendarEventStartProperty]).isValid() &&
          moment(event[calendarEventEndProperty]).isValid()
        ) {
          return {
            id: event?.id,
            title: event[calendarEventTitleProperty],
            start: moment(event[calendarEventStartProperty]).toDate(),
            end: moment(event[calendarEventEndProperty]).toDate(),
            allDay: event[calendarEventAllDayProperty],
          };
        }
      }) as Event[];

      return uniqBy(newData, 'id');
    }

    return [];
  };

  const {
    'calendar-container': calendarContainerStyles,
    'calendar-month-cell-date': calendarMonthCellDateStyles,
    'calendar-month-cell-date-current': calendarMonthCellDateCurrentStyles,
    'calendar-month-event': calendarMonthEventStyles,
    'calendar-week-time-cell': calendarWeekTimeCellStyles,
    'calendar-week-event': calendarWeekEventStyles,
  } = useMultiStyleConfig('PTCalendar', {});

  async function executeApiOperation(
    operation: ASTNode,
    operationName: string,
    variables: Record<string, unknown>,
  ) {
    const resp = await new GraphQLClient(endpoint, {
      headers: {
        Authorization: user?.token,
      },
    }).request(print(operation), variables);

    return resp[operationName] ?? {};
  }

  const onSubmitCardMutation = useMutation(
    (input: { values: Event; operation: AvailableOperations }) => {
      const datasets = introspectionFactory?.datasets ?? {};

      const formatedValues = {
        id: input.values.id,
        [fieldsRelations.title]: input.values.title,
        [fieldsRelations.start]: input.values.start,
        [fieldsRelations.end]: input.values.end,
      };

      const response = handleOperationSubmit({
        executeApiOperation,
        dataset: datasets[calendarDataCollection],
        operation: input.operation,
      })(formatedValues);

      return response;
    },
    {
      onSuccess: () => {
        toast({
          title: 'Event updated!',
          description: `The event was successfully updated.`,
        });

        const refetchOperator = engine
          ?.getOperatorList()
          ?.find(({ operator }) => operator === `refetch_list${calendarDataCollection}`);

        if (refetchOperator)
          engine?.runOperation({
            operator: refetchOperator.operator,
            args: {},
          });
      },
      onError: () => {
        setFormattedEvents(getFormattedEvents());

        toast({
          title: 'Something wrong happened!',
          description: 'Check your fields configuration or your collection, then try again.',
          status: 'danger',
        });
      },
    },
  );

  const canSubmitOperations = useMemo(
    () => !!calendarDataCollection && !!fieldsRelations && !isThemeMode,
    [calendarDataCollection && fieldsRelations && isThemeMode],
  );

  const handleEventEdit = ({ event }: { event: Event }) => {
    const clonedFormattedEvents = cloneDeep(formattedEvents);
    const eventIndex = findIndex(clonedFormattedEvents, { id: event.id });

    clonedFormattedEvents.splice(eventIndex, 1, event);

    onSubmitCardMutation.mutate({
      operation: 'update',
      values: event,
    });
  };

  const handleEventDelete = ({ event }: { event: Event }) => {
    const clonedFormattedEvents = cloneDeep(formattedEvents);
    const eventIndex = findIndex(clonedFormattedEvents, { id: event.id });

    clonedFormattedEvents.splice(eventIndex, 1);

    onSubmitCardMutation.mutate({
      values: event,
      operation: 'delete',
    });
  };

  const handleEventSelectOrDrop = (args: { event?: Event; start: stringOrDate; end: stringOrDate }) => {
    const clonedFormattedEvents = cloneDeep(formattedEvents);

    const { event, start, end } = args;

    if (event) {
      const eventIndex = findIndex(clonedFormattedEvents, { id: event.id });

      const updatedEvent: Event = {
        ...(event as Event),
        start: moment(start).toDate(),
        end: moment(end).toDate(),
      };

      clonedFormattedEvents.splice(eventIndex, 1, updatedEvent);

      setFormattedEvents(clonedFormattedEvents);

      onSubmitCardMutation.mutate({
        operation: 'update',
        values: updatedEvent,
      });
    } else {
      const newEvent: Event = {
        title: 'Untitled',
        allDay: false,
        start: moment(start).toDate(),
        end: moment(end).toDate(),
      };

      clonedFormattedEvents.push(newEvent);

      setFormattedEvents(clonedFormattedEvents);

      onSubmitCardMutation.mutate({
        operation: 'create',
        values: newEvent,
      });
    }
  };

  useEffect(
    () => setFormattedEvents(getFormattedEvents()),
    [
      calendarData,
      calendarEventTitleProperty,
      calendarEventStartProperty,
      calendarEventEndProperty,
      calendarEventAllDayProperty,
    ],
  );

  return (
    <>
      <CalendarCSSReset />
      <ReactBigCalendar
        titleAccessor="title"
        startAccessor="start"
        endAccessor="end"
        allDayAccessor="allDay"
        step={30}
        timeslots={2}
        width={width}
        height={height}
        views={[Views.MONTH, Views.WEEK, Views.DAY]}
        events={isThemeMode ? exampleData : formattedEvents}
        selectable={canSubmitOperations}
        defaultView={Views.WEEK}
        localizer={getMomentLocalizer}
        components={{
          toolbar: CalendarToolbar,
          month: {
            header: CalendarMonthHeader,
            event: ({ event, ...rest }) => (
              <CalendarMonthEvent
                event={event as Event}
                canEdit={canSubmitOperations}
                onEdit={(event) => handleEventEdit({ event })}
                onDelete={(event) => handleEventDelete({ event })}
                {...rest}
              />
            ),
          },
          week: {
            header: CalendarWeekHeader,
            event: ({ event, ...rest }) => (
              <CalendarWeekEvent
                event={event as Event}
                canEdit={canSubmitOperations}
                onEdit={(event) => handleEventEdit({ event })}
                onDelete={(event) => handleEventDelete({ event })}
                {...rest}
              />
            ),
          },
          day: {
            event: ({ event, ...rest }) => (
              <CalendarWeekEvent
                event={event as Event}
                canEdit={canSubmitOperations}
                onEdit={(event) => handleEventEdit({ event })}
                onDelete={(event) => handleEventDelete({ event })}
                {...rest}
              />
            ),
          },
        }}
        onSelectSlot={({ start, end }) => canSubmitOperations && handleEventSelectOrDrop({ start, end })}
        onEventResize={({ event, start, end }) =>
          canSubmitOperations && handleEventSelectOrDrop({ event, start, end })
        }
        onEventDrop={({ event, start, end }) =>
          canSubmitOperations && handleEventSelectOrDrop({ event, start, end })
        }
        sx={{
          '.rbc-time-view': calendarContainerStyles,
          '.rbc-month-view': calendarContainerStyles,
          '.rbc-date-cell': calendarMonthCellDateStyles,
          '.rbc-current': calendarMonthCellDateCurrentStyles,
          '.rbc-slot-selection': calendarWeekEventStyles,
          '.rbc-label': calendarWeekTimeCellStyles,
          '.rbc-show-more': {
            ...calendarWeekTimeCellStyles,
            paddingY: 'pt-sm',
          },
          '.rbc-timeslot-group, .rbc-time-header-content, .rbc-day-bg, .rbc-month-row, .rbc-time-slot': {
            borderColor: calendarContainerStyles.borderColor,
          },
          '.rbc-month-header': {
            borderBottom: 'sm',
            borderColor: calendarContainerStyles.borderColor,
          },
          '.rbc-time-content': {
            border: 'sm',
            borderColor: calendarContainerStyles.borderColor,
          },
          '.rbc-addons-dnd-resize-ew-icon': {
            color: calendarMonthEventStyles.borderColor,
          },
          '.rbc-addons-dnd-resize-ns-icon': {
            color: calendarWeekEventStyles.borderColor,
          },
          '.rbc-selected-cell': {
            background: calendarMonthEventStyles.background,
          },
          '.rbc-current-time-indicator': {
            height: '1px',
            background: calendarWeekEventStyles.borderColor,
          },
        }}
        {...rest}
      />
    </>
  );
};
