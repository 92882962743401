import { get } from 'lodash';
import { useQuery, UseQueryResult } from 'react-query';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/client';
import jwt_decode from 'jwt-decode';

import { AWSAppsyncResponse, GstudioOrganizationRoles, Organization } from '@types';

import { useToast } from '@application/components/ds';

import { AdminService } from '@services';

interface UseOrganizationReturn {
  organizationQuery: UseQueryResult<Organization | null>;
  organizationId: string;
  role: GstudioOrganizationRoles;
}

export const useOrganization = (): UseOrganizationReturn => {
  const [session, loadingSession] = useSession();

  const router = useRouter();
  const toast = useToast();

  const organizationId = String(router.query.organizationId);

  const decodedSessionIdToken = jwt_decode(session?.idToken);
  const organizationRole = get(decodedSessionIdToken, [
    'https://gstudioapp.com/app_metadata',
    'organizations',
    organizationId,
    'role',
  ]);

  const organizationQuery = useQuery<Organization>(
    ['query', 'organizations', organizationId],
    async () => {
      const resp = await AdminService.getOrganization({ id: organizationId }, session.idToken);

      return resp.getOrganization;
    },
    {
      enabled: !loadingSession,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError(error: { response: AWSAppsyncResponse }) {
        const unauthorized = error.response.errors.some((error) => error.errorType === 'Unauthorized');

        if (unauthorized) {
          toast({
            status: 'danger',
            title: 'Unauthorized',
            description: 'You are not part of this organization',
          });

          router.push('/401');
        }
      },
    },
  );

  return {
    organizationQuery,
    organizationId,
    role: organizationRole,
  };
};
