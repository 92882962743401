import React, { FC, useState } from 'react';

import { Flex as ChakraFlex, Tooltip as ChakraTooltip } from '@chakra-ui/react';

import { InputBase } from '@types';

import { Icon, Input, Text } from '@application/components/ds';

type InputTextProps = InputBase;

export const InputText: FC<InputTextProps> = ({
  label,
  value,
  invalid,
  disabled,
  description,
  placeholder,
  onChange,
  ...props
}) => {
  const [inputState, setInputState] = useState(value);

  return (
    <ChakraFlex gridGap="sm" flexDirection="column" {...props}>
      {label && (
        <ChakraFlex gridGap="sm" alignItems="center">
          <Text color="ui.700" variant="body-2">
            {label}
          </Text>
          {description && (
            <ChakraTooltip label={description} borderRadius="xs" placement="top" hasArrow={true}>
              <Icon size="sm" color="ui.700" name="info-outline" />
            </ChakraTooltip>
          )}
        </ChakraFlex>
      )}
      <Input
        width="100%"
        value={inputState}
        isInvalid={invalid}
        isDisabled={disabled}
        placeholder={placeholder}
        onChange={(ev) => setInputState(ev.target.value)}
        onBlur={(ev) => onChange && onChange(ev.target.value)}
      />
    </ChakraFlex>
  );
};
