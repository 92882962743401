import { CompilerComponent, DataHandlersComponents } from '@types';
import { Operation as JsonLogicalEngineOperation } from 'json-logical-engine/dist/types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface DataContainerProps extends DataHandlersComponents {
  operationName: string;
  args: JsonLogicalEngineOperation['args'];
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<DataContainerProps>;
