import React, { FC } from 'react';

import { Button, Flex, Text } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { RadioAlternativeButtonProps } from './index';

export const component: FC<ComponentReceivedProps<RadioAlternativeButtonProps, any>> = ({
  props = defaultProps,
  triggers,
  inheritedData,
  children,
}) => {
  const { label, value, index, disabled, ...rest } = props;
  const { onClick } = triggers;

  return (
    <Button
      height="4.5rem"
      m={2}
      width="100%"
      role="radio"
      variant="outline"
      colorScheme="gray"
      isDisabled={disabled}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      onClick={() =>
        onClick({
          ...inheritedData,
          $value: value,
        })
      }
      _disabled={{
        opacity: 0.8,
      }}
      {...rest}
    >
      <Flex alignItems="center" px={2} width="100%">
        <Text
          background="#F7FAFC"
          borderRadius={7}
          px={3}
          py={2}
          mr={6}
          color="#4A5568"
          fontSize={24}
          fontWeight={400}
        >
          {index}
        </Text>
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Text color="#4A5568" fontSize={24}>
            {label}
          </Text>
          {children}
        </Flex>
      </Flex>
    </Button>
  );
};
