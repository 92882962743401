import React, { FC, useEffect, useMemo, useState } from 'react';

import { Box, Button, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import dynamic from 'next/dynamic';
import ReactHtmlParser from 'react-html-parser';

import { ComponentReceivedProps } from '@types';

import { useDebounce } from '@application/lib/customHooks';

import { defaultProps } from './protocol';
import { DevelopmentEnvironmentProps } from './index';

// This is being overwritten by cssReset, and should be redefined manually :(
const headingBaseStyles = {
  h1: {
    fontSize: '2em',
    fontWeight: 'bolder',
  },
  h2: {
    fontSize: '1.5em',
    fontWeight: 'bolder',
  },
  h3: {
    fontSize: '1.17em',
    fontWeight: 'bolder',
  },
  h4: {
    fontSize: '1em',
    fontWeight: 'bolder',
  },
  h5: {
    fontSize: '.83em',
    fontWeight: 'bolder',
  },
  h6: {
    fontSize: '.67em',
    fontWeight: 'bolder',
  },
};

export const component: FC<ComponentReceivedProps<DevelopmentEnvironmentProps, any>> = ({
  node,
  style,
  props = defaultProps,
  inheritedData,
  triggers,
}) => {
  const { theme, renderBgColor, hideEditor, html, css } = props;

  const [htmlCode, setHtmlCode] = useState(html ?? '');
  const [editorHtmlCode, setEditorHtmlCode] = useState(html ?? '');

  const [cssCode, setCssCode] = useState(css ?? '');
  const [editorCssCode, setEditorCssCode] = useState(css ?? '');

  const [renderedCode, setRenderedCode] = useState('');

  const { onPublish, onSaveDraft } = triggers;

  useDebounce(
    () => {
      const cleanHtml = DOMPurify.sanitize(editorHtmlCode);

      setHtmlCode(editorHtmlCode);
    },
    500,
    [editorHtmlCode],
  );
  useDebounce(
    () => {
      const cleanCss = DOMPurify.sanitize(editorCssCode, { FORCE_BODY: true });

      setCssCode(cleanCss);
    },
    500,
    [editorCssCode],
  );

  useEffect(() => {
    setRenderedCode(`
      <html lang="html">
        <body>
          <style>${cssCode}</style>
          ${htmlCode}
        </body>
      </html>
    `);
  }, [htmlCode, cssCode]);

  const AceEditor = dynamic(() => {
    import('ace-builds');

    import('ace-builds/webpack-resolver');
    import('ace-builds/src-noconflict/ace');

    import('ace-builds/src-noconflict/mode-css');
    import('ace-builds/src-noconflict/mode-html');

    import('ace-builds/src-noconflict/theme-monokai');
    import('ace-builds/src-noconflict/theme-solarized_dark');
    import('ace-builds/src-noconflict/theme-xcode');

    return import('react-ace');
  });

  // function transform(node) {
  //   if (node.type === 'tag' && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']) {
  //     return <Box as={node.name}>{processNodes(node.children, transform)}</Box>;
  //   }
  // }

  if (hideEditor) {
    return <Box style={style}>{useMemo(() => ReactHtmlParser(renderedCode), [renderedCode])}</Box>;
  }

  return (
    <Box style={style} sx={headingBaseStyles}>
      <Tabs width="100%">
        <TabList>
          <Tab>Project.html</Tab>
          <Tab>Project.css</Tab>
        </TabList>

        <TabPanels>
          <TabPanel display="flex">
            <Box width="50%">
              {useMemo(() => {
                return (
                  <AceEditor
                    width="100%"
                    mode="html"
                    theme={theme}
                    onChange={setEditorHtmlCode}
                    value={htmlCode}
                    name={node.id}
                    setOptions={{
                      useWorker: false,
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      tabSize: 2,
                    }}
                    editorProps={{ $blockScrolling: true }}
                  />
                );
              }, [theme])}
            </Box>
            <Box width="50%" bg={renderBgColor}>
              {useMemo(() => ReactHtmlParser(renderedCode), [renderedCode])}
            </Box>
          </TabPanel>

          <TabPanel display="flex">
            <Box width="50%">
              {useMemo(() => {
                return (
                  <AceEditor
                    width="100%"
                    mode="css"
                    theme={theme}
                    onChange={setEditorCssCode}
                    value={cssCode}
                    name={node.id}
                    setOptions={{
                      useWorker: false,
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      tabSize: 2,
                    }}
                    editorProps={{ $blockScrolling: true }}
                  />
                );
              }, [theme])}
            </Box>
            <Box width="50%" bg={renderBgColor}>
              {useMemo(() => ReactHtmlParser(renderedCode), [renderedCode])}
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Box my={6}>
        <Button
          size="sm"
          mr={6}
          onClick={() =>
            onSaveDraft({
              ...inheritedData,
              $value: {
                css: editorCssCode,
                html: editorHtmlCode,
              },
            })
          }
        >
          Entwurf speichern
        </Button>
        <Button
          size="sm"
          color="teal.500"
          onClick={() =>
            onPublish({
              ...inheritedData,
              $value: {
                css: editorCssCode,
                html: editorHtmlCode,
              },
            })
          }
        >
          Projekt veröffentlichen
        </Button>
      </Box>
    </Box>
  );
};
