import { IconProps, NotAllowedIcon } from '@chakra-ui/icons';
import { merge } from 'lodash';
import React, { FC } from 'react';

import { coreIcons } from './core';
import { customIcons } from './CustomIcons';
import { IconName } from '@types';

interface Props extends IconProps {
  name?: IconName;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const Icon: FC<Props> = ({ name, size = 'md', ...rest }) => {
  const icons = merge(customIcons, coreIcons);

  const boxSizes: Record<Props['size'], string> = {
    xs: '.75em',
    sm: '1em',
    md: '1.5em',
    lg: '2em',
  };

  const RenderedIcon = icons[name] ?? NotAllowedIcon;

  // @ts-ignore
  return <RenderedIcon boxSize={boxSizes[size]} {...rest} />;
};
