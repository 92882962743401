import { createContext, SetStateAction } from 'react';
import { Node } from '@types';

export interface EditorState {
  setEditorState: (newEditorState: SetStateAction<EditorState>) => void;

  onSave: () => void;

  // Inspect mode
  isInspectModeActive?: boolean;
  onToggleInspectMode: () => void;
  inspectedNode?: Node;
  onInspectedNodeChange: (nodeId: string | null) => void;

  // It must be kept in sync with the state management tool
  rootNode: Node;
  onAddNode?: (targetNodeId: string, node: Node) => void;
  onDeleteNode?: (targetNodeId: string, node: Node) => void;
  onEditNode?: (nodeId: string, editedNode: Node) => void;
  onDuplicateNode?: (parentNodeId: string, sourceNode: Node) => void;
  onDragNode?: (prevParentId: string, newParentId: string, node: Node) => void;

  onMoveUp?: (targetNodeId: string) => void;
  onMoveDown?: (targetNodeId: string) => void;

  onMoveIn?: (targetNodeId: string) => void;
  onMoveOut?: (targetNodeId: string) => void;

  onRedo?: () => void;
  onUndo?: () => void;

  focusedNode?: Node;
  focusNode?: (nodeId: string | null) => void;
  selectedRouteNode?: Node;
  router: {
    currentRoute: string;
    onRouteChange: (args: { path: string; route?: string }) => void;
  };
}

export const EditorContext = createContext<EditorState>({
  setEditorState: null,

  isInspectModeActive: false,
  onToggleInspectMode: console.log,
  inspectedNode: null,
  onInspectedNodeChange: console.log,

  rootNode: null,

  onSave: console.log,
  onAddNode: console.log,
  onDeleteNode: console.log,
  onEditNode: console.log,
  onDuplicateNode: console.log,
  onDragNode: console.log,

  focusedNode: null,
  focusNode: console.log,

  selectedRouteNode: null,
  router: {
    currentRoute: '/',
    onRouteChange: console.log,
  },
});

EditorContext.displayName = 'EditorProvider';

export const EditorProvider = EditorContext.Provider;
