import { ComponentBlock } from '@types';

import { types } from 'core/types';

import { component } from './component';
import { HeaderProps } from './index';

export const block: ComponentBlock<'header', HeaderProps, unknown> = {
  id: 'header',
  render: component,
  props: {
    headerShowLogo: {
      type: types.boolean,
    },
    headerRoutes: {
      type: types.list,
    },
  },
};
