import { ThemeMultipartComponent } from '@types';

type ProgressSizes = 'sm' | 'md' | 'lg';
type ProgressVariants = 'default';
type ProgressParts = 'track' | 'filledTrack';

export const PTProgress: ThemeMultipartComponent<ProgressSizes, ProgressVariants, ProgressParts> = {
  parts: ['track', 'filledTrack'],

  variants: {
    default: {
      track: {
        borderRadius: 'pt-sm',
        background: 'pt-ui.200',
      },
      filledTrack: {
        background: 'pt-primary.500',
      },
    },
  },

  sizes: {
    sm: {
      track: {
        height: '0.5rem',
      },
    },
    md: {
      track: {
        height: '0.75rem',
      },
    },
    lg: {
      track: {
        height: '1rem',
      },
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: 'pt-primary',
  },
};
