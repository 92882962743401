import { ComponentProtocolEditorConfig, GStudioEditorProps, ThemeOverride } from '@types';

import { CustomIcon } from '@application/components/Theme/model/theme';
import { defaultTheme } from '@application/lib/defaultTheme';
import { ThemeProtocolKeys } from '@application/lib/protocols/themeProtocols';

import { ComponentProtocol, ComponentProtocolThemeConfig } from './types';

import { ComponentPropsFactory } from './ComponentPropsFactory';
import { ThemeConfigFactory } from './ThemeConfigFactory';

interface FactoryConfig<ComponentProps, ComponentParts> {
  /** Name of the component */
  name: string;

  /** Properties used to set the component props inside the Editor */
  editorProps?: GStudioEditorProps<ComponentProps>[];

  /** Properties used to set the component props inside the Editor */
  editorConfig?: ComponentProtocolEditorConfig;

  /** Properties used to set the theme forms inside the Editor */
  themeProps?: ThemeProtocolKeys[];

  /** Properties used to set the theme configuration form */
  themeConfig?: ComponentProtocolThemeConfig<ComponentParts>;
}

/**
 * This class is meant to be used in definitions of components using the protocols
 * ex: create component based on a protocol
 */
export class ProtocolFactory<ComponentProps, ComponentParts = 'default'> {
  private factoryConfig: FactoryConfig<ComponentProps, ComponentParts>;

  protected componentPropsFactory: ComponentPropsFactory<ComponentProps>;

  constructor(factoryConfig: FactoryConfig<ComponentProps, ComponentParts>) {
    this.factoryConfig = factoryConfig;

    this.componentPropsFactory = new ComponentPropsFactory<ComponentProps>(
      factoryConfig.name,
      factoryConfig.editorProps,
      factoryConfig.themeProps,
      factoryConfig.themeConfig?.variants || [],
      factoryConfig.themeConfig?.sizes || [],
    );
  }

  createProtocol(theme?: ThemeOverride, customIcons?: CustomIcon[]): ComponentProtocol<ComponentProps> {
    return {
      name: this.factoryConfig.name,
      editorProps: this.componentPropsFactory.createEditorProps(),
      themeProps: this.componentPropsFactory.createThemeProps(theme ?? defaultTheme, customIcons),
    };
  }

  createDefaultProps(): ComponentProps {
    return this.componentPropsFactory.createDefaultProps();
  }

  get themeConfigParts(): ComponentParts[] {
    return this.factoryConfig.themeConfig.parts;
  }

  createThemeConfigFactory(theme: ThemeOverride = defaultTheme): ThemeConfigFactory<ComponentParts> {
    return new ThemeConfigFactory<ComponentParts>(
      this.factoryConfig.name,
      this.factoryConfig.themeConfig,
      theme,
    );
  }

  // Circuits
  get hasDesignSection(): boolean {
    return !this.factoryConfig.editorConfig?.disableDesignSection ?? true;
  }

  get hasDataLinkSection(): boolean {
    return !this.factoryConfig.editorConfig?.disableDataLinkSection ?? true;
  }

  get hasLogicSection(): boolean {
    return !this.factoryConfig.editorConfig?.disableLogicSection ?? true;
  }
}
