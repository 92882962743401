import React, { FC } from 'react';

import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  useStyleConfig,
} from '@chakra-ui/react';

export type CheckboxProps = ChakraCheckboxProps;

export const Checkbox: FC<CheckboxProps> = ({ children, variant, size, ...props }) => {
  const styles = useStyleConfig('Checkbox', { variant, size });

  return (
    <ChakraCheckbox sx={styles} {...props}>
      {children}
    </ChakraCheckbox>
  );
};
