import React, { FC } from 'react';

export const CalendarCSSReset: FC = () => {
  return (
    <style jsx={true} global={true}>
      {`
        .rbc-header {
          padding: 0px;
          border: none;
          border-left: none !important;
        }

        .rbc-event {
          padding: 0px;
          border: 0px !important;
          border-radius: 0px;
          background-color: transparent;
        }

        .rbc-event-label {
          display: none;
        }

        .rbc-selected {
          background: transparent !important;
          overflow: unset !important;
        }

        .rbc-today {
          background-color: transparent !important;
        }

        .rbc-time-header {
          border-right: 0px !important;
        }

        .rbc-time-header-cell {
          min-height: unset !important;
        }

        .rbc-addons-dnd-resize-ns-anchor {
          padding: 6px 0px;
        }

        .rbc-addons-dnd-resize-ew-anchor {
          display: flex;
          padding: 0px 4px;
          align-items: center;
          justify-content: center;
          top: 0px !important;
        }

        .rbc-time-header-gutter {
          border-bottom: none !important;
        }

        .rbc-allday-cell {
          min-height: 0px;
        }

        .rbc-allday-cell > .rbc-row-bg > .rbc-day-bg {
          border-left: 0px !important;
        }

        .rbc-time-slot {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .rbc-addons-dnd-row-body > .rbc-row {
          min-height: 0px;
        }

        .rbc-month-row {
          overflow: unset !important;
        }

        .rbc-off-range-bg {
          background: transparent !important;
        }

        .rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot:nth-child(2) {
          display: none;
        }
      `}
    </style>
  );
};
