import { component } from './component';
import { ComponentBlock } from '@types';
import { ListProps } from './index';
import { types } from 'core/types';

export const block: ComponentBlock<'list', ListProps, any> = {
  id: 'list',
  render: component,
  props: {
    columnsNumber: {
      type: types.number,
    },
    fields: {
      type: types.list,
      disableEdition: true,
    },
    list: {
      type: types.list,
    },
  },
};
