import { ThemeComponent, ThemeMultipartComponent } from '@types';

export type ColorHues = '50' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';
export type ColorPalette =
  | 'pt-transparent'
  | 'pt-white'
  | 'pt-black'
  | 'pt-ui'
  | 'pt-primary'
  | 'pt-success'
  | 'pt-warning'
  | 'pt-danger'
  | 'pt-info'
  | string;

export type BorderSizes = 'pt-none' | 'pt-sm' | 'pt-md' | 'pt-lg';
export type BorderRadiusSizes = 'pt-none' | 'pt-xs' | 'pt-sm' | 'pt-md' | 'pt-lg';
export type SpacingSizes = 'pt-none' | 'pt-xs' | 'pt-sm' | 'pt-md' | 'pt-lg' | 'pt-xl' | 'pt-xxl';

export type ShadowTypes = 'pt-none' | 'pt-base' | 'pt-medium' | 'pt-large';

export const allowedUserColors: ColorPalette[] = [
  'pt-white',
  'pt-black',
  'pt-transparent',
  'pt-primary',
  'pt-ui',
  'pt-danger',
  'pt-info',
  'pt-success',
  'pt-warning',
];

export interface TextStyle {
  fontFamily: string;
  fontSize: string;
  fontWeight: string;
  letterSpacing: string;
  lineHeight: string;
}

export interface ThemeOverride {
  borders: Record<BorderSizes, string>;
  colors: Record<ColorPalette, Record<ColorHues, string> | string>;
  fonts: Record<string, string>;
  fontSizes: Record<string, string>;
  fontWeights: Record<string, number>;
  letterSpacings: Record<string, string>;
  lineHeights: Record<string, string | number>;
  textStyles: Record<string, TextStyle>;
  space: Record<SpacingSizes, string>;
  shadows: Record<ShadowTypes, string>;
  radii: Record<BorderRadiusSizes, string>;
  components: Record<
    string,
    ThemeComponent<unknown, unknown, unknown> | ThemeMultipartComponent<unknown, unknown, unknown>
  >;
  styles: Record<string, unknown>;
}
