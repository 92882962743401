import React, { FC } from 'react';

import {
  NumberDecrementStepper as ChakraNumberDecrementStepper,
  NumberIncrementStepper as ChakraNumberIncrementStepper,
  NumberInput as ChakraNumberInput,
  NumberInputField as ChakraNumberInputField,
  NumberInputProps as ChakraNumberInputProps,
  NumberInputStepper as ChakraNumberInputStepper,
  forwardRef,
  useMultiStyleConfig,
} from '@chakra-ui/react';

export type NumberInputProps = ChakraNumberInputProps;

export const NumberInput: FC<NumberInputProps> = forwardRef(
  ({ size, variant, colorScheme, ...props }, ref) => {
    const styles = useMultiStyleConfig('NumberInput', { colorScheme, variant, size });

    return (
      <ChakraNumberInput sx={styles['number-input']} ref={ref} {...props}>
        <ChakraNumberInputField sx={styles['number-input-field']} />
        <ChakraNumberInputStepper sx={styles['number-input-stepper']}>
          <ChakraNumberIncrementStepper sx={styles['number-input-increment-stepper']} />
          <ChakraNumberDecrementStepper sx={styles['number-input-decrement-stepper']} />
        </ChakraNumberInputStepper>
      </ChakraNumberInput>
    );
  },
);

NumberInput.displayName = 'NumberInput';
