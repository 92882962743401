import React, { FC } from 'react';

import { Checkbox as ChakraCheckbox, useMultiStyleConfig } from '@chakra-ui/react';

import { ComponentReceivedProps } from '@types';

import { showDatasetFormatted } from '@application/utils/stringManipulation';

import { CheckboxProps, CheckboxTriggers } from './index';
import { defaultProps } from './protocol';

export const component: FC<ComponentReceivedProps<CheckboxProps, CheckboxTriggers>> = ({
  props = defaultProps,
  triggers,
}) => {
  const { label, colorScheme, value, ...rest } = props;
  const { onChange } = triggers;

  const themeStyles = useMultiStyleConfig('Checkbox', { colorScheme });

  return (
    <ChakraCheckbox
      {...rest}
      sx={themeStyles}
      isChecked={value}
      onChange={() => onChange && onChange(!value)}
    >
      {label && showDatasetFormatted(label)}
    </ChakraCheckbox>
  );
};
