import React, { FC, useContext, useMemo } from 'react';

import { useMultiStyleConfig } from '@chakra-ui/react';
import dynamic from 'next/dynamic';

import { ComponentReceivedProps } from '@types';

import { CompilerContext } from '@application/compiler/contexts';
import { Marker } from '@application/components/common/Map';

import { defaultProps } from './protocol';
import { MapProps } from '.';

const Map = dynamic(() => import('@application/components/common/Map').then(({ Map }) => Map), {
  ssr: false,
});

const exampleData: Marker[] = [
  { position: [40.73061, -73.935242], tooltip: 'New York' },
  { position: [-23.000372, -43.365894], tooltip: 'Rio de Janeiro' },
  { position: [51.509865, -0.118092], tooltip: 'Londres' },
];

export const component: FC<ComponentReceivedProps<MapProps, unknown>> = ({ props = defaultProps }) => {
  const { mapMarkersData, mapMarkersGeolocation, mapMarkersTooltip, ...rest } = props;

  const { config } = useContext(CompilerContext);
  const { 'map-container': mapContainerStyles } = useMultiStyleConfig('PTMap', {});

  const isThemeMode = config.mode === 'theme';

  const hasValidMarkers = useMemo(
    () => mapMarkersData?.every((marker) => (marker[mapMarkersGeolocation] as string)?.match(',')),
    [mapMarkersData, mapMarkersGeolocation, mapMarkersTooltip],
  );

  const getMarkers = useMemo(
    () =>
      mapMarkersGeolocation && hasValidMarkers
        ? (mapMarkersData?.map((marker) => ({
            tooltip: marker[mapMarkersTooltip],
            position: (marker[mapMarkersGeolocation] as string)
              ?.split(',')
              ?.map((position) => Number(position)),
          })) as Marker[])
        : [],
    [mapMarkersData, mapMarkersGeolocation, mapMarkersTooltip],
  );

  return <Map markers={isThemeMode ? exampleData : getMarkers} sx={mapContainerStyles} {...rest} />;
};
