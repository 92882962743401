import React, { FC, useMemo } from 'react';

import { chakra, Flex as ChakraFlex } from '@chakra-ui/react';
import ImageGallery from 'react-image-gallery';

import { ComponentReceivedProps } from '@types';

import { defaultProps } from './protocol';
import { ImageGalleryProps } from './index';

export const component: FC<ComponentReceivedProps<ImageGalleryProps, unknown>> = ({
  props = defaultProps,
}) => {
  const {
    imageGalleryData = [],
    imageGalleryShowBullets = false,
    imageGalleryShowFullscreenButton = false,
    ...rest
  } = props;

  const getFormattedImages = useMemo(
    () =>
      imageGalleryData.map(({ value }) => ({
        original: value,
        thumbnail: null,
      })),
    [imageGalleryData],
  );

  if (imageGalleryData.length === 0) {
    return (
      <ChakraFlex
        width="100%"
        height="100%"
        {...rest}
        border="pt-sm"
        padding="pt-lg"
        boxShadow="pt-large"
        borderRadius="pt-sm"
        alignItems="center"
        justifyContent="center"
        background="pt-info.100"
        borderColor="pt-info.200"
      >
        <chakra.p textAlign="center" fontSize="pt-md" fontFamily="pt-body" color="pt-info.500">
          <chakra.span textDecoration="underline" fontWeight="pt-bold">
            Images
          </chakra.span>{' '}
          are not defined
        </chakra.p>
      </ChakraFlex>
    );
  }

  return (
    <ChakraFlex backgroundColor="black" position="relative" overflow="auto" {...rest}>
      <ImageGallery
        items={getFormattedImages}
        showBullets={imageGalleryShowBullets}
        showFullscreenButton={imageGalleryShowFullscreenButton}
      />
    </ChakraFlex>
  );
};
