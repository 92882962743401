import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export type BarChartParts = 'chart-axis' | 'chart-series';

export interface BarChartProps {
  chartCategory: string;
  chartCategoryLabel: string;
  chartValue: string;
  chartValueLabel: string;
  chartData: {
    [key: string]: string | number;
  }[];
}

export default {
  component,
  block,
  defaultProps,
  protocolFactory,
} as CompilerComponent<BarChartProps, BarChartParts>;
