import { OptionBlocks } from '@types';

export const nodesWithChildren: OptionBlocks[] = [
  'accordion',
  'aspectRatio',
  'card',
  'container',
  'dataContainer',
  'grid',
  'gridItem',
  'list',
  'modal',
  'mutationContainer',
  'radioAlternative',
  'radioAlternativeButton',
  'page',
];

export const uneditableBlocks: OptionBlocks[] = ['layoutRouterView', 'page'];
