import { add, node } from '@application/core/node';
import { BlockFactory } from '@application/lib/core';
import { PageProps } from '@application/components/compiler';

import { Node } from '@types';

export const getEmptyPage = ({
  name = 'Undefined',
  path = '/',
  role,
  isProtected = false,
}: PageProps): Node => {
  const pageNode = node(
    BlockFactory.initializeBlock('page', {
      staticPropValues: {
        name,
        path,
        role,
        isProtected,
      },
    }),
  );

  const initialPageContainer = node(
    BlockFactory.initializeBlock('container', {
      staticPropValues: {
        flexDirection: 'row',
        height: '100%',
      },
    }),
  );

  return add(pageNode, initialPageContainer);
};
