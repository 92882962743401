export const colors = {
  'pt-transparent': 'transparent',
  'pt-white': '#ffffff',
  'pt-black': '#000000',
  'pt-primary': {
    '50': '#fce5d3',
    '100': '#f8c9a3',
    '200': '#f5ae74',
    '300': '#f19244',
    '400': '#ee7615',
    '500': '#d96a10',
    '600': '#c15e0e',
    '700': '#92470b',
    '800': '#623007',
    '900': '#331904',
  },
  'pt-ui': {
    '50': '#F7FAFC',
    '100': '#EDF2F7',
    '200': '#E2E8F0',
    '300': '#CBD5E0',
    '400': '#A0AEC0',
    '500': '#718096',
    '600': '#4A5568',
    '700': '#2D3748',
    '800': '#1A202C',
    '900': '#171923',
  },
  'pt-danger': {
    '50': '#ffecec',
    '100': '#ffd8d8',
    '200': '#ffb1b1',
    '300': '#ff8a8a',
    '400': '#ff6363',
    '500': '#ff3c3c',
    '600': '#cc3030',
    '700': '#992424',
    '800': '#661818',
    '900': '#330c0c',
  },
  'pt-info': {
    '50': '#e6f2ff',
    '100': '#cce4ff',
    '200': '#99c9ff',
    '300': '#66afff',
    '400': '#3394ff',
    '500': '#0079ff',
    '600': '#0061cc',
    '700': '#004999',
    '800': '#003066',
    '900': '#001833',
  },
  'pt-success': {
    '50': '#e7faf0',
    '100': '#d0f5e0',
    '200': '#a1ebc2',
    '300': '#71e2a3',
    '400': '#42d885',
    '500': '#13CE66',
    '600': '#0fa552',
    '700': '#0b7c3d',
    '800': '#085229',
    '900': '#042914',
  },
  'pt-warning': {
    '50': '#fff6e7',
    '100': '#ffeed0',
    '200': '#ffdca1',
    '300': '#ffcb71',
    '400': '#ffb942',
    '500': '#ffa813',
    '600': '#cc860f',
    '700': '#99650b',
    '800': '#664308',
    '900': '#332204',
  },
};
