import { component } from './component';
import { ComponentBlock } from '@types';
import { RadioAlternativeButtonProps } from './index';
import { types } from 'core/types';

export const block: ComponentBlock<'radioAlternativeButton', RadioAlternativeButtonProps, any> = {
  id: 'radioAlternativeButton',
  render: component,
  props: {
    index: {
      type: types.string,
    },
    label: {
      type: types.string,
    },
    value: {
      type: types.string,
    },
    disabled: {
      type: types.boolean,
    },
  },
  triggers: {
    onClick: {
      name: 'On click',
      description: 'When the button is clicked',
    },
  },
};
