import { EControlType, EDatasetFieldType, EditorFormField } from '@types';

import { DynamicFormDatasetField } from '.';

export const exampleParentDatasetFormFields: EditorFormField[] = [
  {
    label: 'Title',
    name: 'title',
    type: 'String',
    description: 'The title of the form',
    inputConfig: {
      visible: true,
    },
  },
  {
    label: 'Description',
    name: 'description',
    type: 'Textarea',
    description: 'The description of the form',
    inputConfig: {
      visible: true,
    },
  },
];

export const exampleChildrenDatasetFormFields: EditorFormField[] = [
  {
    label: 'Child title',
    name: 'childTitle',
    type: 'String',
    description: 'The title of the child form',
    inputConfig: {
      visible: true,
    },
  },
  {
    label: 'Child description',
    name: 'childDescription',
    type: 'Textarea',
    description: 'The description of the child form',
    inputConfig: {
      visible: true,
    },
  },
];

export const exampleParentDatasetFieldsReference: Record<string, DynamicFormDatasetField> = {
  title: {
    type: EDatasetFieldType.string,
  },
  description: {
    type: EDatasetFieldType.string,
    controlType: EControlType.textArea,
  },
};

export const exampleChildrenDatasetFieldsReference: Record<string, DynamicFormDatasetField> = {
  childTitle: {
    type: EDatasetFieldType.string,
  },
  childDescription: {
    type: EDatasetFieldType.string,
    controlType: EControlType.textArea,
  },
};

export const exampleChildrenDatasetsList: string[] = ['Children Dataset', 'Another Children Dataset'];
