import { ModalParts } from '@application/lib/defaultTheme';

import { CompilerComponent } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface ModalProps {
  modalTitle?: string;
  modalSubmitLabel?: string;
  modalCancelLabel?: string;
  modalHiddenFooter?: boolean;
  modalTriggerLabel?: string;
}

export interface ModalTriggers {
  onConfirm: () => void;
  onCancel: () => void;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<ModalProps, ModalParts>;
