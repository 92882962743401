import { ProtocolFactory } from '@application/lib/protocols';

import { ImageGalleryProps } from './index';

export const protocolFactory = new ProtocolFactory<ImageGalleryProps>({
  name: 'ImageGallery',
  editorProps: [],
  editorConfig: {
    disableDesignSection: false,
    disableDataLinkSection: true,
    disableLogicSection: true,
  },
  themeProps: [
    'width',
    'height',
    'maxWidth',
    'maxHeight',
    'border',
    'borderColor',
    'borderRadius',
    'boxShadow',
  ],
});

export const defaultProps = protocolFactory.createDefaultProps();
