import { ComponentBlock } from '@types';
import { types } from 'core/types';

import { CardProps } from './index';
import { component } from './component';

export const block: ComponentBlock<'card', CardProps, unknown> = {
  id: 'card',
  render: component,
  props: {
    cardTitle: {
      type: types.string,
    },
    size: {
      type: types.string,
    },
  },
};
