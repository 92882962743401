import { ProtocolFactory } from 'lib/protocols';

import { DividerProps } from './index';
import { EPropType } from '@types';

export const protocolFactory = new ProtocolFactory<DividerProps>({
  name: 'Divider',
  editorProps: [
    {
      type: EPropType.String,
      name: 'orientation',
      required: true,
      label: 'Divider orientation',
      description: 'Divider direction',
      defaultValue: 'vertical',
      values: ['vertical', 'horizantal'],
    },
    {
      type: EPropType.String,
      name: 'borderColor',
      required: true,
      label: 'Divider color',
      description: 'Color of the divider',
      defaultValue: 'gray.500',
    },
    {
      type: EPropType.String,
      name: 'borderWidth',
      required: true,
      label: 'Divider width',
      description: 'The width of the divider',
      defaultValue: '1px',
    },
  ],
});

export const defaultProps = protocolFactory.createDefaultProps();
