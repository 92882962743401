import { ThemeMultipartComponent } from '@types';

export type LineChartSizes = 'sm' | 'md' | 'lg';
export type LineChartVariants = 'default';
export type LineChartParts = 'chart-series' | 'chart-axis';

export const PTLineChart: ThemeMultipartComponent<LineChartSizes, LineChartVariants, LineChartParts> = {
  parts: ['chart-series', 'chart-axis'],

  baseStyle: {
    'chart-series': {
      background: 'pt-primary.500',
      fontWeight: 'pt-normal',
      fontSize: 'pt-sm',
      color: 'pt-white',
    },
    'chart-axis': {
      fontWeight: 'pt-normal',
      fontSize: 'pt-sm',
      color: 'pt-ui.500',
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: 'pt-primary',
  },
};
