import { CompilerComponent } from '@types';

import { StoriesParts } from '@application/lib/defaultTheme';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface StoriesProps {
  width: string;
  height: string;
  storiesData: Record<string, unknown>[];
  storiesHasLoop: boolean;
  storiesTextProperty: string;
  storiesLinkProperty: string;
  storiesSourceProperty: string;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<StoriesProps, StoriesParts>;
