import { ApplicationRoles, CompilerComponent, DataHandlersComponents } from '@types';

import { block } from './block';
import { component } from './component';
import { defaultProps, protocolFactory } from './protocol';

export interface PageProps extends DataHandlersComponents {
  name?: string;
  path: string;
  role?: ApplicationRoles;
  isProtected: boolean;
}

export default {
  block,
  component,
  defaultProps,
  protocolFactory,
} as CompilerComponent<PageProps>;
