import React, { FC, useContext, useMemo } from 'react';

import { chakra, Tooltip as ChakraTooltip } from '@chakra-ui/react';
import { isNil } from 'lodash';

import { ComponentReceivedProps } from '@types';

import { CompilerContext } from '@application/compiler/contexts';

import { defaultProps } from './protocol';
import { TextProps } from './index';

export const component: FC<ComponentReceivedProps<TextProps, unknown>> = ({ props = defaultProps }) => {
  const { content, ...rest } = props;

  const { config } = useContext(CompilerContext);

  const isEditorMode = useMemo(() => config.mode === 'edit', [config.mode]);
  const hasToShowPlaceholder = useMemo(() => isNil(content) && isEditorMode, [content, config]);

  return (
    <ChakraTooltip
      label="This is a placeholder for your dynamic value. It will only show in edit mode and when a value is not found."
      hasArrow={true}
      isDisabled={!hasToShowPlaceholder}
    >
      <chakra.p
        {...rest}
        textDecoration={hasToShowPlaceholder && 'underline'}
        textDecorationThickness="2px"
        textDecorationStyle="dotted"
        textDecorationColor="primary.500"
      >
        {!isNil(content) ? content : isEditorMode ? '{{ placeholder }}' : null}
      </chakra.p>
    </ChakraTooltip>
  );
};
