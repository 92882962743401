import { RequestBuilder } from '@services/lib';

import * as AdminRoutes from './admin';
import * as ApplicationRoutes from './application';
import * as StripeRoutes from './stripe';

export const apiRequestClient = () => {
  return new RequestBuilder(process.env.NEXTAUTH_URL);
};

export const ApiClient = {
  ...ApplicationRoutes,
  ...AdminRoutes,
  ...StripeRoutes,
};
