import React, { Children, cloneElement, FC, isValidElement, useContext, useEffect, useMemo } from 'react';

import { Flex as ChakraFlex, Spinner as ChakraSpinner } from '@chakra-ui/react';
import { get, isNil, mergeWith, orderBy } from 'lodash';
import { useQuery } from 'react-query';

import { ComponentReceivedProps } from '@types';

import { CompilerContext, EngineContext } from '@application/compiler/contexts';
import { Freemium, Text } from '@application/components/common';
import { QueryOperation } from '@application/lib/customHooks/useIntrospection';

import { DataContainerProps } from './index';
import { defaultProps } from './protocol';

export const component: FC<ComponentReceivedProps<DataContainerProps, unknown>> = ({
  props = defaultProps,
  children,
  inheritedData,
}) => {
  const { operationName, fields, args, ...rest } = props;

  const { engine } = useContext(EngineContext);
  const { config } = useContext(CompilerContext);

  const uniqIdentifier = useMemo(() => {
    if (!inheritedData) return undefined;
    return JSON.stringify(inheritedData);
  }, [operationName, inheritedData]);

  const isListOperation = operationName?.startsWith(QueryOperation.List);
  const operationIsConfigured = operationName && (isListOperation || args?.input);

  const queryResult = useQuery(
    [operationName, uniqIdentifier],
    async () => {
      const resp = await engine.runOperation(
        {
          operator: 'data_query',
          args: {
            operationName,
            ...args,
          },
        },
        inheritedData,
      );

      if (isListOperation && resp?.items) {
        resp.items = orderBy(resp.items, 'creationDate', 'desc');
      }

      return resp;
    },
    {
      enabled: !!engine, // user && user?.token && operationIsConfigured,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (engine && !queryResult.isLoading && queryResult.isSuccess) {
      const operatorName = `refetch_${operationName}`;
      engine.addOperators({
        [operatorName]: {
          name: operatorName,
          description: 'Refetch the dataContainer operation',
          handler: () => queryResult.refetch(),
        },
      });
    }
  }, [engine, queryResult.isSuccess, queryResult.isLoading]);

  if (config && !config.accountId) {
    return <Freemium isFreemium={true} />;
  }

  if (!operationIsConfigured) {
    return (
      <ChakraFlex
        width="100%"
        height="100%"
        border="1px solid"
        alignItems="center"
        borderColor="red.300"
        justifyContent="center"
        {...rest}
      >
        <Text fontWeight="bold" color="red.300">
          Operation was not configured
        </Text>
      </ChakraFlex>
    );
  }

  if (queryResult.isError) {
    return (
      <ChakraFlex
        width="100%"
        height="100%"
        border="1px solid"
        alignItems="center"
        borderColor="red.300"
        justifyContent="center"
      >
        <Text fontWeight="bold" color="red.300">
          {get(queryResult, 'error.message', 'An error occurred')}
        </Text>
      </ChakraFlex>
    );
  }

  if ((queryResult.isIdle || queryResult.isLoading || queryResult.isFetching) && !queryResult.isRefetching) {
    return (
      <ChakraFlex width="100%" height="100%" justifyContent="center" alignItems="center">
        <ChakraSpinner
          size="xl"
          speed="0.65s"
          color="pt-primary.500"
          thickness="4px"
          emptyColor="pt-ui.200"
        />
      </ChakraFlex>
    );
  }

  const customizedMerger = (sourceValue: unknown) => {
    if (!isNil(sourceValue)) {
      return sourceValue;
    }
  };

  return (
    <>
      {Children.map(children, (child, index) => {
        if (!child) return null;
        if (!queryResult) return child;

        if (isValidElement(child)) {
          const additionalDynamicData: Record<string, unknown> = {};

          for (const field of fields ?? []) {
            additionalDynamicData[field.id] = queryResult.data || {};
          }

          return cloneElement(child, {
            key: `${queryResult.dataUpdatedAt}-${index}`,
            dynamicData: mergeWith(inheritedData, additionalDynamicData, (_, sourceValue) =>
              customizedMerger(sourceValue),
            ),
          });
        }

        return child;
      })}
    </>
  );
};
