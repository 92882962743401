import gql from 'graphql-tag';

export const CreatePage = gql`
  mutation CreatePage($input: CreatePageInput!) {
    createPage(input: $input) {
      id

      projectId
      organizationId

      name
      path
      role

      isProtected

      nodesStructure

      createdAt
      updatedAt

      expiresAt
    }
  }
`;

export const UpdatePage = gql`
  mutation UpdatePage($input: UpdatePageInput!) {
    updatePage(input: $input) {
      id

      projectId
      organizationId

      name
      path
      role

      isProtected

      nodesStructure

      createdAt
      updatedAt

      expiresAt
    }
  }
`;

export const DeletePage = gql`
  mutation DeletePage($input: DeletePageInput!) {
    deletePage(input: $input) {
      id
      organizationId

      projectId

      name
      path
      role

      nodesStructure

      createdAt
      updatedAt

      expiresAt
    }
  }
`;
