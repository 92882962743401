import React, { FC } from 'react';

import { Flex as ChakraFlex, FlexProps as ChakraFlexProps, Spinner as ChakraSpinner } from '@chakra-ui/react';

export interface LoadingProps extends ChakraFlexProps {
  isLoading: boolean;
  boxSize?: ChakraFlexProps['boxSize'];
}

export const Loading: FC<LoadingProps> = ({ children, isLoading, boxSize, ...props }) => {
  if (isLoading) {
    return (
      <ChakraFlex minHeight="9rem" justifyContent="center" alignItems="center" {...props}>
        <ChakraSpinner speed="0.65s" color="ui.500" boxSize={boxSize} />
      </ChakraFlex>
    );
  }

  return <>{children}</>;
};
